@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;900&display=swap');

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}
@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}
@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}
@keyframes floating-lg {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes floating-md {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes floating-sm {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes show-navbar-collapse {
	0% {
		opacity: 0;
		transform: scale(0.95);
		transform-origin: 100% 0;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes hide-navbar-collapse {
	from {
		opacity: 1;
		transform: scale(1);
		transform-origin: 100% 0;
	}
	to {
		opacity: 0;
		transform: scale(0.95);
	}
}
@keyframes show-navbar-dropdown {
	0% {
		opacity: 0;
		transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
		transition: visibility 0.45s, opacity 0.45s, transform 0.45s;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}
@keyframes hide-navbar-dropdown {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translate(0, 10px);
	}
}
@keyframes show-dropdown {
	0% {
		opacity: 0;
		transform-origin: perspective(200px) rotateX(-2deg);
		transition: visibility 0.45s, opacity .5s;
	}
	100% {
		opacity: 1;
	}
}
@keyframes hide-dropdown {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translate(0, 10px);
	}
}
@keyframes bellshake {
	0% {
		transform: rotate(0);
	}
	15% {
		transform: rotate(5deg);
	}
	30% {
		transform: rotate(-5deg);
	}
	45% {
		transform: rotate(4deg);
	}
	60% {
		transform: rotate(-4deg);
	}
	75% {
		transform: rotate(2deg);
	}
	85% {
		transform: rotate(-2deg);
	}
	92% {
		transform: rotate(1deg);
	}
	100% {
		transform: rotate(0);
	}
}
@keyframes jackInTheBox {
	from {
		opacity: 0;
		transform: scale(0.1) rotate(30deg);
		transform-origin: center bottom;
	}
	50% {
		transform: rotate(-10deg);
	}
	70% {
		transform: rotate(3deg);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes animate-positive {
	0% {
		width: 0%;
	}
}

/*# sourceMappingURL=volt.css.map */
:root {
	--bs-blue: #0948B3;
	--bs-indigo: #31316A;
	--bs-purple: #8965e0;
	--bs-pink: #C96480;
	--bs-red: #FA5252;
	--bs-orange: #FF9F89;
	--bs-yellow: #f5b759;
	--bs-green: #05A677;
	--bs-teal: #1E90FF;
	--bs-cyan: #63b1bd;
	--bs-white: #ffffff;
	--bs-gray: #93a5be;
	--bs-gray-dark: #506690;
	--bs-primary: #262B40;
	--bs-secondary: #ffae38;
	--bs-tertiary: #1B998B;
	--bs-quaternary: #0948B3;
	--bs-primary-app: #EBF4F6;
	--bs-secondary-app: #424AA0;
	--bs-light: #eaedf2;
	--bs-lighten: #F0F3F6;
	--bs-success: #05A677;
	--bs-info: #0948B3;
	--bs-warning: #f5b759;
	--bs-danger: #FA5252;
	--bs-white: #ffffff;
	--bs-gray: #4A5073;
	--bs-neutral: #ffffff;
	--bs-dark: #262B40;
	--bs-soft: #f5f8fb;
	--bs-black: #2e3650;
	--bs-blue: #0948B3;
	--bs-pink: #C96480;
	--bs-cyan: #63b1bd;
	--bs-purple: #8965e0;
	--bs-soft-indigo: #f5e8ff;
	--bs-soft-green: #2CA58D;
	--bs-gray-100: #f3f7fa;
	--bs-gray-200: #f5f8fb;
	--bs-gray-300: #F0F3F6;
	--bs-gray-400: #eaedf2;
	--bs-gray-500: #d1d7e0;
	--bs-gray-600: #93a5be;
	--bs-gray-700: #66799e;
	--bs-gray-800: #506690;
	--bs-facebook: #3b5999;
	--bs-twitter: #1da1f2;
	--bs-google: #DB4337;
	--bs-instagram: #e4405f;
	--bs-pinterest: #bd081c;
	--bs-youtube: #cd201f;
	--bs-slack: #3aaf85;
	--bs-dribbble: #ea4c89;
	--bs-dropbox: #1E90FF;
	--bs-twitch: #4B367C;
	--bs-paypal: #ecb32c;
	--bs-behance: #0057ff;
	--bs-reddit: #E84422;
	--bs-github: #222222;
	--bs-font-sans-serif: "Nunito Sans", sans-serif;
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--animate-duration: 1s;
	--animate-delay: 1s;
	--animate-repeat: 1;
}
* {
	box-sizing: border-box;
	&::before {
		box-sizing: border-box;
	}
	&::after {
		box-sizing: border-box;
	}
}
body {
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #4A5073;
	background-color: #ffffff;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(46, 54, 80, 0);
}
[tabindex="-1"] {
	&:focus {
		&:not(:focus-visible) {
			outline: 0 !important;
		}
	}
}
hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
	&:not([size]) {
		height: 0.0625rem;
	}
}
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.375rem + 1.5vw);
	font-family: "Nunito Sans", sans-serif;
}
.h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.375rem + 1.5vw);
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.325rem + 0.9vw);
	font-family: "Nunito Sans", sans-serif;
}
.h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.325rem + 0.9vw);
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.3rem + 0.6vw);
	font-family: "Nunito Sans", sans-serif;
}
.h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.3rem + 0.6vw);
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.275rem + 0.3vw);
	font-family: "Nunito Sans", sans-serif;
}
.h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: calc(1.275rem + 0.3vw);
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
h5 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: 1.25rem;
	font-family: "Nunito Sans", sans-serif;
}
.h5 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: 1.25rem;
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: 1rem;
	font-family: "Nunito Sans", sans-serif;
}
.h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.3;
	color: #262B40;
	font-size: 1rem;
	font-family: "Nunito Sans", sans-serif;
	font-family: "Nunito Sans", sans-serif;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
	font-family: var(--bs-font-sans-serif);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.6;
}
abbr[title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}
address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}
ol {
	padding-left: 2rem;
	margin-top: 0;
	margin-bottom: 1rem;
	ol {
		margin-bottom: 0;
	}
	ul {
		margin-bottom: 0;
	}
	li {
		font-family: var(--bs-font-sans-serif);
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.6;
	}
}
ul {
	padding-left: 2rem;
	margin-top: 0;
	margin-bottom: 1rem;
	ul {
		margin-bottom: 0;
	}
	ol {
		margin-bottom: 0;
	}
	li {
		font-family: var(--bs-font-sans-serif);
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.6;
	}
}
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}
dt {
	font-weight: 600;
}
dd {
	margin-bottom: .5rem;
	margin-left: 0;
}
blockquote {
	margin: 0 0 1rem;
}
b {
	font-weight: 700;
}
strong {
	font-weight: 700;
	font-weight: 600;
}
small {
	font-size: 0.875em;
}
.small {
	font-size: 0.875em;
}
mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
sub {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
	bottom: -.25em;
}
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
	top: -.5em;
}
a {
	color: #262B40;
	text-decoration: none;
	color: #262B40;
	transition: all 0.2s ease;
	&:hover {
		color: #0a0b10;
		color: #0a0b10;
		cursor: pointer;
	}
	&:not([href]) {
		&:not([class]) {
			color: inherit;
			text-decoration: none;
			&:hover {
				color: inherit;
				text-decoration: none;
			}
		}
	}
	>code {
		color: inherit;
	}
}
pre {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
	-ms-overflow-style: scrollbar;
	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
}
code {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	font-size: 0.875em;
	color: #C96480;
	word-wrap: break-word;
}
kbd {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #ffffff;
	background-color: #4A5073;
	border-radius: 0.1rem;
	kbd {
		padding: 0;
		font-size: 1em;
		font-weight: 600;
	}
}
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
}
figure {
	margin: 0 0 1rem;
	display: block;
	margin: 0;
}
img {
	vertical-align: middle;
	max-width: 100%;
}
svg {
	vertical-align: middle;
}
table {
	caption-side: bottom;
	border-collapse: collapse;
}
caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #58677d;
	text-align: left;
}
th {
	text-align: inherit;
	text-align: -webkit-match-parent;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
thead {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
tbody {
	border-color: inherit;
	border-style: solid;
	border-width: 0;

	tr {
		vertical-align: middle;
	}
}
tfoot {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
td {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}
label {
	display: inline-block;
}
button {
	border-radius: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
		outline: 0;
	}
	&:not(:disabled) {
		cursor: pointer;
	}
}
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	overflow: visible;
}
select {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	word-wrap: normal;
}
optgroup {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	resize: vertical;
}
[role="button"] {
	cursor: pointer;
}
[list] {
	&::-webkit-calendar-picker-indicator {
		display: none;
	}
}
[type="button"] {
	-webkit-appearance: button;
	&:not(:disabled) {
		cursor: pointer;
	}
}
[type="reset"] {
	-webkit-appearance: button;
	&:not(:disabled) {
		cursor: pointer;
	}
}
[type="submit"] {
	-webkit-appearance: button;
	&:not(:disabled) {
		cursor: pointer;
	}
}
::-moz-focus-inner {
	padding: 0;
	border-style: none;
}
fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
	&:disabled {
		.btn {
			pointer-events: none;
			opacity: 0.65;
			box-shadow: none;
		}
	}
}
legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
	white-space: normal;
	+ {
		* {
			clear: left;
		}
	}
}
::-webkit-datetime-edit-fields-wrapper {
	padding: 0;
}
::-webkit-datetime-edit-text {
	padding: 0;
}
::-webkit-datetime-edit-minute {
	padding: 0;
}
::-webkit-datetime-edit-hour-field {
	padding: 0;
}
::-webkit-datetime-edit-day-field {
	padding: 0;
}
::-webkit-datetime-edit-month-field {
	padding: 0;
}
::-webkit-datetime-edit-year-field {
	padding: 0;
}
::-webkit-inner-spin-button {
	height: auto;
}
[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}
::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
	padding: 0;
}
::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}
output {
	display: inline-block;
}
iframe {
	border: 0;
	border: 0;
}
summary {
	display: list-item;
	cursor: pointer;
}
progress {
	vertical-align: baseline;
}
[hidden] {
	display: none !important;
}
.lead {
	font-size: 1.25rem;
	font-weight: 300;
	+ {
		.btn-wrapper {
			margin-top: 3rem;
		}
	}
}
.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 600;
	line-height: 1.3;
}
.display-2 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 600;
	line-height: 1.3;
}
.display-3 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 600;
	line-height: 1.3;
}
.display-4 {
	font-size: calc(1.3125rem + 0.75vw);
	font-weight: 600;
	line-height: 1.3;
}
.display-5 {
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.3;
}
.display-6 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.3;
}
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.list-inline {
	padding-left: 0;
	list-style: none;
}
.list-inline-item {
	display: inline-block;
	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}
.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}
.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-style: italic;
	& > &:last-child {
		margin-bottom: 0;
	}
	.blockquote-footer {
		font-size: 0.875rem;
		font-weight: 700;
	}
}
.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #93a5be;
	&::before {
		content: "\2014\00A0";
	}
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
.img-thumbnail {
	padding: 0.25rem;
	background-color: #ffffff;
	border: 0.0625rem solid #F0F3F6;
	border-radius: 0.5rem;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
	max-width: 100%;
	height: auto;
	border-width: 0.125rem;
	box-shadow: none;
}
.figure {
	display: inline-block;
}
.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}
.figure-caption {
	font-size: 0.875em;
	color: #93a5be;
}
.container {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.container-fluid {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.container-sm {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.container-md {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.container-lg {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.container-xl {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;
}
.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex: 1 0 100%;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);
	>* {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding-right: calc(var(--bs-gutter-x) / 2);
		padding-left: calc(var(--bs-gutter-x) / 2);
		margin-top: var(--bs-gutter-y);
	}
}
.col {
	flex: 1 0 0%;
}
.row-cols-auto {
	>* {
		flex: 0 0 auto;
		width: auto;
	}
}
.row-cols-1 {
	>* {
		flex: 0 0 auto;
		width: 100%;
	}
}
.row-cols-2 {
	>* {
		flex: 0 0 auto;
		width: 50%;
	}
}
.row-cols-3 {
	>* {
		flex: 0 0 auto;
		width: 33.33333%;
	}
}
.row-cols-4 {
	>* {
		flex: 0 0 auto;
		width: 25%;
	}
}
.row-cols-5 {
	>* {
		flex: 0 0 auto;
		width: 20%;
	}
}
.row-cols-6 {
	>* {
		flex: 0 0 auto;
		width: 16.66667%;
	}
}
.col-auto {
	flex: 0 0 auto;
	width: auto;
}
.col-1 {
	flex: 0 0 auto;
	width: 8.33333%;
}
.col-2 {
	flex: 0 0 auto;
	width: 16.66667%;
}
.col-3 {
	flex: 0 0 auto;
	width: 25%;
}
.col-4 {
	flex: 0 0 auto;
	width: 33.33333%;
}
.col-5 {
	flex: 0 0 auto;
	width: 41.66667%;
}
.col-6 {
	flex: 0 0 auto;
	width: 50%;
}
.col-7 {
	flex: 0 0 auto;
	width: 58.33333%;
}
.col-8 {
	flex: 0 0 auto;
	width: 66.66667%;
}
.col-9 {
	flex: 0 0 auto;
	width: 75%;
}
.col-10 {
	flex: 0 0 auto;
	width: 83.33333%;
}
.col-11 {
	flex: 0 0 auto;
	width: 91.66667%;
}
.col-12 {
	flex: 0 0 auto;
	width: 100%;
}
.offset-1 {
	margin-left: 8.33333%;
}
.offset-2 {
	margin-left: 16.66667%;
}
.offset-3 {
	margin-left: 25%;
}
.offset-4 {
	margin-left: 33.33333%;
}
.offset-5 {
	margin-left: 41.66667%;
}
.offset-6 {
	margin-left: 50%;
}
.offset-7 {
	margin-left: 58.33333%;
}
.offset-8 {
	margin-left: 66.66667%;
}
.offset-9 {
	margin-left: 75%;
}
.offset-10 {
	margin-left: 83.33333%;
}
.offset-11 {
	margin-left: 91.66667%;
}
.g-0 {
	--bs-gutter-x: 0;
	--bs-gutter-y: 0;
}
.gx-0 {
	--bs-gutter-x: 0;
}
.gy-0 {
	--bs-gutter-y: 0;
}
.g-1 {
	--bs-gutter-x: 0.25rem;
	--bs-gutter-y: 0.25rem;
}
.gx-1 {
	--bs-gutter-x: 0.25rem;
}
.gy-1 {
	--bs-gutter-y: 0.25rem;
}
.g-2 {
	--bs-gutter-x: 0.5rem;
	--bs-gutter-y: 0.5rem;
}
.gx-2 {
	--bs-gutter-x: 0.5rem;
}
.gy-2 {
	--bs-gutter-y: 0.5rem;
}
.g-3 {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 1rem;
}
.gx-3 {
	--bs-gutter-x: 1rem;
}
.gy-3 {
	--bs-gutter-y: 1rem;
}
.g-4 {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 1.5rem;
}
.gx-4 {
	--bs-gutter-x: 1.5rem;
}
.gy-4 {
	--bs-gutter-y: 1.5rem;
}
.g-5 {
	--bs-gutter-x: 3rem;
	--bs-gutter-y: 3rem;
}
.gx-5 {
	--bs-gutter-x: 3rem;
}
.gy-5 {
	--bs-gutter-y: 3rem;
}
.g-6 {
	--bs-gutter-x: 5rem;
	--bs-gutter-y: 5rem;
}
.gx-6 {
	--bs-gutter-x: 5rem;
}
.gy-6 {
	--bs-gutter-y: 5rem;
}
.g-7 {
	--bs-gutter-x: 8rem;
	--bs-gutter-y: 8rem;
}
.gx-7 {
	--bs-gutter-x: 8rem;
}
.gy-7 {
	--bs-gutter-y: 8rem;
}
.g-8 {
	--bs-gutter-x: 10rem;
	--bs-gutter-y: 10rem;
}
.gx-8 {
	--bs-gutter-x: 10rem;
}
.gy-8 {
	--bs-gutter-y: 10rem;
}
.g-9 {
	--bs-gutter-x: 11rem;
	--bs-gutter-y: 11rem;
}
.gx-9 {
	--bs-gutter-x: 11rem;
}
.gy-9 {
	--bs-gutter-y: 11rem;
}
.g-10 {
	--bs-gutter-x: 14rem;
	--bs-gutter-y: 14rem;
}
.gx-10 {
	--bs-gutter-x: 14rem;
}
.gy-10 {
	--bs-gutter-y: 14rem;
}
.g-11 {
	--bs-gutter-x: 16rem;
	--bs-gutter-y: 16rem;
}
.gx-11 {
	--bs-gutter-x: 16rem;
}
.gy-11 {
	--bs-gutter-y: 16rem;
}
.g-12 {
	--bs-gutter-x: 20rem;
	--bs-gutter-y: 20rem;
}
.gx-12 {
	--bs-gutter-x: 20rem;
}
.gy-12 {
	--bs-gutter-y: 20rem;
}
.g-sm {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 1rem;
}
.gx-sm {
	--bs-gutter-x: 1rem;
}
.gy-sm {
	--bs-gutter-y: 1rem;
}
.g-md {
	--bs-gutter-x: 2rem;
	--bs-gutter-y: 2rem;
}
.gx-md {
	--bs-gutter-x: 2rem;
}
.gy-md {
	--bs-gutter-y: 2rem;
}
.g-lg {
	--bs-gutter-x: 4rem;
	--bs-gutter-y: 4rem;
}
.gx-lg {
	--bs-gutter-x: 4rem;
}
.gy-lg {
	--bs-gutter-y: 4rem;
}
.g-xl {
	--bs-gutter-x: 8rem;
	--bs-gutter-y: 8rem;
}
.gx-xl {
	--bs-gutter-x: 8rem;
}
.gy-xl {
	--bs-gutter-y: 8rem;
}
.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #4A5073;
	--bs-table-striped-bg: rgba(46, 54, 80, 0.05);
	--bs-table-active-color: #4A5073;
	--bs-table-active-bg: rgba(46, 54, 80, 0.1);
	--bs-table-hover-color: #4A5073;
	--bs-table-hover-bg: rgb(204 204 204 / 8%);
	width: 100%;
	margin-bottom: 1rem;
	color: #4A5073;
	vertical-align: top;
	border-color: #eaedf2;
	> :not(caption) {
		>* {
			>* {
				padding: 0.75rem 0.5rem;
				background-color: var(--bs-table-bg);
				background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
				border-bottom-width: 0.0625rem;
			}
		}
	}
	& > :not(:last-child) {
		& > :last-child {
			>* {
				border-bottom-color: #eaedf2;
			}
		}
	}
	>tbody {
		vertical-align: inherit;
	}
	>thead {
		vertical-align: bottom;
	}
	thead {
		th {
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
			font-size: 0.75rem;
			text-transform: uppercase;
			font-weight: 700;
			border-top: 0.0625rem solid #eaedf2;
		}
	}
	th {
		font-weight: 600;
		padding: 1rem;
		font-size: 0.875rem;
		white-space: nowrap;
	}
	td {
		.progress {
			height: 3px;
			width: 120px;
			margin: 0;
		}
		font-size: 0.875rem;
		white-space: nowrap;
	}
	.thead-dark {
		th {
			background-color: #262B40;
			color: #ffffff;
			a {
				color: #262B40;
			}
		}
		[data-sort] {
			&::after {
				content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
				margin-left: .25rem;
			}
		}
	}
	.thead-light {
		th {
			background-color: #eaedf2;
			color: #262B40;
			a {
				color: #262B40;
			}
		}
		[data-sort] {
			&::after {
				content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
				margin-left: .25rem;
			}
		}
	}
	[data-sort] {
		cursor: pointer;
	}
	.custom-toggle {
		display: block;
	}
}
.caption-top {
	caption-side: top;
}
.table-sm > :not(caption) {
	>* {
		>* {
			padding: 0.25rem 0.25rem;
		}
	}
}
.table-bordered > :not(caption) {
	>* {
		border-width: 0.0625rem 0;
		>* {
			border-width: 0 0.0625rem;
		}
	}
}
.table-borderless > :not(caption) {
	>* {
		>* {
			border-bottom-width: 0;
		}
	}
}
.table-striped {
	>tbody {
		>tr {
			&:nth-of-type(odd) {
				--bs-table-accent-bg: var(--bs-table-striped-bg);
				color: var(--bs-table-striped-color);
			}
		}
	}
}
.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}
.table-hover {
	>tbody {
		>tr {
			&:hover {
				--bs-table-accent-bg: var(--bs-table-hover-bg);
				color: var(--bs-table-hover-color);
			}
		}
	}
	tr {
		transition: all 0.2s ease;
	}
}
.table-primary {
	--bs-table-bg: #c2c4ca;
	--bs-table-striped-bg: #babcc3;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #b2b5bc;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #b6b9c0;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #b2b5bc;
}
.table-secondary {
	--bs-table-bg: #fdedda;
	--bs-table-striped-bg: #f2e3d2;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #e8dacb;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #eddece;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #e8dacb;
}
.table-success {
	--bs-table-bg: #b9e6d9;
	--bs-table-striped-bg: #b2ddd1;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #aad3ca;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #aed8ce;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #aad3ca;
}
.table-info {
	--bs-table-bg: #baccea;
	--bs-table-striped-bg: #b3c4e2;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #abbcd9;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #afc0dd;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #abbcd9;
}
.table-warning {
	--bs-table-bg: #fcebd1;
	--bs-table-striped-bg: #f1e1ca;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #e7d8c3;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #ecddc6;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #e7d8c3;
}
.table-danger {
	--bs-table-bg: #fecfcf;
	--bs-table-striped-bg: #f3c7c8;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #e8bfc1;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #eec3c4;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #e8bfc1;
}
.table-light {
	--bs-table-bg: #eaedf2;
	--bs-table-striped-bg: #e0e3e9;
	--bs-table-striped-color: #262B40;
	--bs-table-active-bg: #d6dae0;
	--bs-table-active-color: #262B40;
	--bs-table-hover-bg: #dbdee5;
	--bs-table-hover-color: #262B40;
	color: #262B40;
	border-color: #d6dae0;
}
.table-dark {
	--bs-table-bg: #262B40;
	--bs-table-striped-bg: #31364a;
	--bs-table-striped-color: #ffffff;
	--bs-table-active-bg: #3c4053;
	--bs-table-active-color: #ffffff;
	--bs-table-hover-bg: #363b4e;
	--bs-table-hover-color: #ffffff;
	color: #ffffff;
	border-color: #3c4053;
}
.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.form-label {
	margin-bottom: 0.5rem;
}
.col-form-label {
	padding-top: 0.6125rem;
	padding-bottom: 0.6125rem;
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}
.col-form-label-lg {
	padding-top: 0.5625rem;
	padding-bottom: 0.5625rem;
	font-size: 1.25rem;
}
.col-form-label-sm {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	font-size: 0.875rem;
}
.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #58677d;
}
.form-control {
	display: block;
	width: 100%;
	min-height: calc(1.5em + 0.25rem);
	padding: 0.37rem 0.65rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.3;
	color: #66799e;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 0.0625rem solid #d1d7e0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	box-shadow: none;
	&:focus {
		color: #66799e;
		background-color: #ffffff;
		border-color: #566190;
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
	}
	&:-ms-input-placeholder {
		color: #506690;
		opacity: 1;
	}
	&::placeholder {
		color: #506690;
		opacity: 1;
	}
	&:disabled {
		background-color: #f5f8fb;
		opacity: 1;
	}
}
.form-control[readonly] {
	background-color: #f5f8fb;
	opacity: 1;
}
.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.55rem 0;
	margin-bottom: 0;
	line-height: 1.5;
	color: #4A5073;
	background-color: transparent;
	border: solid transparent;
	border-width: 0.0625rem 0;
}
.form-control-plaintext.form-control-sm {
	padding-right: 0;
	padding-left: 0;
}
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}
.form-control-sm {
	min-height: calc(1.5em + 0.625rem);
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.1rem;
}
.form-control-lg {
	min-height: calc(1.5em + 1.125rem);
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}
.form-control-color {
	max-width: 3rem;
	padding: 0.55rem;
	&::-moz-color-swatch {
		border-radius: 0.5rem;
	}
	&::-webkit-color-swatch {
		border-radius: 0.5rem;
	}
}
.form-select {
	display: block;
	width: 100%;
	height: calc(1.5em + 1.225rem);
	padding: 0.55rem 1.75rem 0.55rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #66799e;
	vertical-align: middle;
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 0.0625rem solid #d1d7e0;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		border-color: #566190;
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
		&::-ms-value {
			color: #66799e;
			background-color: #ffffff;
		}
	}
	&:disabled {
		color: #93a5be;
		background-color: #f5f8fb;
	}
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #66799e;
	}
	.form-select-lg {
		padding: 0.55rem 1.75rem 0.55rem 0.75rem;
	}
}
.form-select[multiple] {
	height: auto;
	padding-right: 0.75rem;
	background-image: none;
}
.form-select[size] {
	&:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}
}
.form-select-sm {
	height: calc(1.5em + 0.625rem);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
}
.form-select-lg {
	height: calc(1.5em + 1.125rem);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
}
.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.625em;
	margin-bottom: 0.125rem;
	.form-check-input {
		float: left;
		margin-left: -1.625em;
	}
}
.form-check-input {
	width: 1.125em;
	height: 1.125em;
	margin-top: 0.1875em;
	vertical-align: top;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid #d1d7e0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	transition: background-color 0.2s ease-in-out, background-position 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	&:active {
		filter: brightness(90%);
	}
	&:focus {
		border-color: #566190;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
	}
	&:checked {
		background-color: #262B40;
		border-color: #262B40;
	}
	&:checked[type="checkbox"] {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
	}
	&:checked[type="radio"] {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
	}
	&:disabled {
		pointer-events: none;
		filter: none;
		opacity: .5;
		~ {
			.form-check-label {
				opacity: .5;
			}
		}
	}
}
.form-check-input[type="checkbox"] {
	border-radius: 0.25em;
	&:indeterminate {
		background-color: #262B40;
		border-color: #262B40;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
	}
}
.form-check-input[type="radio"] {
	border-radius: 50%;
}
.form-check-input[disabled] {
	~ {
		.form-check-label {
			opacity: .5;
		}
	}
}
.form-switch {
	padding-left: 2.75em;
	padding-left: 2.75em;
	.form-check-input {
		width: 2.25em;
		margin-left: -2.75em;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2393a5be'/%3e%3c/svg%3e");
		background-position: left center;
		border-radius: 2.25em;
		height: 1.275em;
		&:focus {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23566190'/%3e%3c/svg%3e");
		}
		&:checked {
			background-position: right center;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
		}
	}
}
.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
	.form-check-input {
		~ {
			.valid-feedback {
				margin-left: .5em;
			}
			.invalid-feedback {
				margin-left: .5em;
			}
		}
	}
}
.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
	&:focus {
		+ {
			.btn {
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
			}
			.btn-primary {
				color: #ffffff;
				background-color: #181b28;
				border-color: #131620;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
			}
			.btn-secondary {
				color: #262B40;
				background-color: #f6ab56;
				border-color: #f5a54a;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
			}
			.btn-tertiary {
				color: #ffffff;
				background-color: #15786d;
				border-color: #136e64;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
			}
			.btn-quaternary {
				color: #ffffff;
				background-color: #07398f;
				border-color: #073482;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
			}
			.btn-primary-app {
				color: #262B40;
				background-color: #d1e5ea;
				border-color: #c8e1e6;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
			}
			.btn-secondary-app {
				color: #ffffff;
				background-color: #373d85;
				border-color: #33397c;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
			}
			.btn-light {
				color: #262B40;
				background-color: #d2d9e3;
				border-color: #cbd2df;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
			}
			.btn-lighten {
				color: #262B40;
				background-color: #d8e0e8;
				border-color: #d0dae3;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
			}
			.btn-success {
				color: #ffffff;
				background-color: #04815c;
				border-color: #047454;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
			}
			.btn-info {
				color: #ffffff;
				background-color: #07398f;
				border-color: #073482;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
			}
			.btn-warning {
				color: #262B40;
				background-color: #f3a735;
				border-color: #f2a229;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
			}
			.btn-danger {
				color: #ffffff;
				background-color: #f92d2d;
				border-color: #f92020;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
			}
			.btn-white {
				color: #262B40;
				background-color: #ececec;
				border-color: #e6e6e6;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
			}
			.btn-gray {
				color: #ffffff;
				background-color: #3b405c;
				border-color: #363a54;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
			}
			.btn-neutral {
				color: #262B40;
				background-color: #ececec;
				border-color: #e6e6e6;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
			}
			.btn-dark {
				color: #ffffff;
				background-color: #181b28;
				border-color: #131620;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
			}
			.btn-soft {
				color: #262B40;
				background-color: #dae5f0;
				border-color: #d1dfec;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
			}
			.btn-black {
				color: #ffffff;
				background-color: #202638;
				border-color: #1b2030;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
			}
			.btn-blue {
				color: #ffffff;
				background-color: #07398f;
				border-color: #073482;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
			}
			.btn-pink {
				color: #ffffff;
				background-color: #bf4869;
				border-color: #b94162;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
			}
			.btn-cyan {
				color: #262B40;
				background-color: #4aa2b0;
				border-color: #469aa7;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
			}
			.btn-purple {
				color: #ffffff;
				background-color: #7145da;
				border-color: #683bd7;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
			}
			.btn-soft-indigo {
				color: #262B40;
				background-color: #e4c2ff;
				border-color: #dfb5ff;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
			}
			.btn-soft-green {
				color: #ffffff;
				background-color: #248773;
				border-color: #217d6b;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
			}
			.btn-gray-100 {
				color: #262B40;
				background-color: #d8e5ef;
				border-color: #cfdfeb;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
			}
			.btn-gray-200 {
				color: #262B40;
				background-color: #dae5f0;
				border-color: #d1dfec;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
			}
			.btn-gray-300 {
				color: #262B40;
				background-color: #d8e0e8;
				border-color: #d0dae3;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
			}
			.btn-gray-400 {
				color: #262B40;
				background-color: #d2d9e3;
				border-color: #cbd2df;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
			}
			.btn-gray-500 {
				color: #262B40;
				background-color: #bac3d1;
				border-color: #b3bdcb;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
			}
			.btn-gray-600 {
				color: #ffffff;
				background-color: #7b91b0;
				border-color: #738aab;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
			}
			.btn-gray-700 {
				color: #ffffff;
				background-color: #566788;
				border-color: #516180;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
			}
			.btn-gray-800 {
				color: #ffffff;
				background-color: #425577;
				border-color: #3e4f6f;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
			}
			.btn-facebook {
				color: #ffffff;
				background-color: #30497d;
				border-color: #2d4474;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
				color: #ffffff;
				background-color: #30497d;
				border-color: #2d4474;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
			}
			.btn-twitter {
				color: #ffffff;
				background-color: #0d8ddc;
				border-color: #0c85d0;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
				color: #ffffff;
				background-color: #0d8ddc;
				border-color: #0c85d0;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
			}
			.btn-google {
				color: #ffffff;
				background-color: #c83024;
				border-color: #bd2d22;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
				color: #ffffff;
				background-color: #c83024;
				border-color: #bd2d22;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
			}
			.btn-instagram {
				color: #ffffff;
				background-color: #de1f44;
				border-color: #d31e40;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
				color: #ffffff;
				background-color: #de1f44;
				border-color: #d31e40;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
			}
			.btn-pinterest {
				color: #ffffff;
				background-color: #980617;
				border-color: #8c0615;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
				color: #ffffff;
				background-color: #980617;
				border-color: #8c0615;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
			}
			.btn-youtube {
				color: #ffffff;
				background-color: #ac1b1a;
				border-color: #a11918;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
				color: #ffffff;
				background-color: #ac1b1a;
				border-color: #a11918;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
			}
			.btn-slack {
				color: #ffffff;
				background-color: #30926f;
				border-color: #2d8968;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
				color: #ffffff;
				background-color: #30926f;
				border-color: #2d8968;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
			}
			.btn-dribbble {
				color: #ffffff;
				background-color: #e62a72;
				border-color: #e51e6b;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
				color: #ffffff;
				background-color: #e62a72;
				border-color: #e51e6b;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
			}
			.btn-dropbox {
				color: #ffffff;
				background-color: #007df7;
				border-color: #0077ea;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
				color: #ffffff;
				background-color: #007df7;
				border-color: #0077ea;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
			}
			.btn-twitch {
				color: #ffffff;
				background-color: #3b2a61;
				border-color: #362758;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
				color: #ffffff;
				background-color: #3b2a61;
				border-color: #362758;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
			}
			.btn-paypal {
				color: #262B40;
				background-color: #dea214;
				border-color: #d29913;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
				color: #262B40;
				background-color: #dea214;
				border-color: #d29913;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
			}
			.btn-behance {
				color: #ffffff;
				background-color: #004ad9;
				border-color: #0046cc;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
				color: #ffffff;
				background-color: #004ad9;
				border-color: #0046cc;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
			}
			.btn-reddit {
				color: #ffffff;
				background-color: #ce3515;
				border-color: #c33214;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
				color: #ffffff;
				background-color: #ce3515;
				border-color: #c33214;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
			}
			.btn-github {
				color: #ffffff;
				background-color: #0f0f0f;
				border-color: #090909;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
				color: #ffffff;
				background-color: #0f0f0f;
				border-color: #090909;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
			}
			.btn-outline-primary {
				box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
			}
			.btn-outline-secondary {
				box-shadow: 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
			}
			.btn-outline-tertiary {
				box-shadow: 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
			}
			.btn-outline-quaternary {
				box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
			}
			.btn-outline-primary-app {
				box-shadow: 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
			}
			.btn-outline-secondary-app {
				box-shadow: 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
			}
			.btn-outline-light {
				box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
			}
			.btn-outline-lighten {
				box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
			}
			.btn-outline-success {
				box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
			}
			.btn-outline-info {
				box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
			}
			.btn-outline-warning {
				box-shadow: 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
			}
			.btn-outline-danger {
				box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
			}
			.btn-outline-white {
				box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
			}
			.btn-outline-gray {
				box-shadow: 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
			}
			.btn-outline-neutral {
				box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
			}
			.btn-outline-dark {
				box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
			}
			.btn-outline-soft {
				box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
			}
			.btn-outline-black {
				box-shadow: 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
			}
			.btn-outline-blue {
				box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
			}
			.btn-outline-pink {
				box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
			}
			.btn-outline-cyan {
				box-shadow: 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
			}
			.btn-outline-purple {
				box-shadow: 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
			}
			.btn-outline-soft-indigo {
				box-shadow: 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
			}
			.btn-outline-soft-green {
				box-shadow: 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
			}
			.btn-outline-gray-100 {
				box-shadow: 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
			}
			.btn-outline-gray-200 {
				box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
			}
			.btn-outline-gray-300 {
				box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
			}
			.btn-outline-gray-400 {
				box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
			}
			.btn-outline-gray-500 {
				box-shadow: 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
			}
			.btn-outline-gray-600 {
				box-shadow: 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
			}
			.btn-outline-gray-700 {
				box-shadow: 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
			}
			.btn-outline-gray-800 {
				box-shadow: 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
			}
			.btn-outline-facebook {
				box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
			}
			.btn-outline-twitter {
				box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
			}
			.btn-outline-google {
				box-shadow: 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
			}
			.btn-outline-instagram {
				box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
			}
			.btn-outline-pinterest {
				box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
			}
			.btn-outline-youtube {
				box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
			}
			.btn-outline-slack {
				box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
			}
			.btn-outline-dribbble {
				box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
			}
			.btn-outline-dropbox {
				box-shadow: 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
			}
			.btn-outline-twitch {
				box-shadow: 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
			}
			.btn-outline-paypal {
				box-shadow: 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
			}
			.btn-outline-behance {
				box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
			}
			.btn-outline-reddit {
				box-shadow: 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
			}
			.btn-outline-github {
				box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
			}
			.accordion {
				.card {
					.card-header {
						.btn {
							outline: 0;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
	&:checked {
		+ {
			.btn {
				box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
				&:focus {
					box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25), inset 0 3px 5px rgba(46, 54, 80, 0.125);
				}
			}
			.btn-primary {
				color: #ffffff;
				background-color: #131620;
				border-color: #0e1018;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
				}
			}
			.btn-secondary {
				color: #262B40;
				background-color: #f5a54a;
				border-color: #f59f3d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
				}
			}
			.btn-tertiary {
				color: #ffffff;
				background-color: #136e64;
				border-color: #11635a;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
				}
			}
			.btn-quaternary {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-primary-app {
				color: #262B40;
				background-color: #c8e1e6;
				border-color: #bfdce2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
				}
			}
			.btn-secondary-app {
				color: #ffffff;
				background-color: #33397c;
				border-color: #2f3573;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
				}
			}
			.btn-light {
				color: #262B40;
				background-color: #cbd2df;
				border-color: #c3cbda;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
				}
			}
			.btn-lighten {
				color: #262B40;
				background-color: #d0dae3;
				border-color: #c8d3de;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
				}
			}
			.btn-success {
				color: #ffffff;
				background-color: #047454;
				border-color: #03684b;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
				}
			}
			.btn-info {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-warning {
				color: #262B40;
				background-color: #f2a229;
				border-color: #f19d1d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
				}
			}
			.btn-danger {
				color: #ffffff;
				background-color: #f92020;
				border-color: #f81414;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
				}
			}
			.btn-white {
				color: #262B40;
				background-color: #e6e6e6;
				border-color: #dfdfdf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
				}
			}
			.btn-gray {
				color: #ffffff;
				background-color: #363a54;
				border-color: #31354c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
				}
			}
			.btn-neutral {
				color: #262B40;
				background-color: #e6e6e6;
				border-color: #dfdfdf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
				}
			}
			.btn-dark {
				color: #ffffff;
				background-color: #131620;
				border-color: #0e1018;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
				}
			}
			.btn-soft {
				color: #262B40;
				background-color: #d1dfec;
				border-color: #c7d8e9;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
				}
			}
			.btn-black {
				color: #ffffff;
				background-color: #1b2030;
				border-color: #171b28;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
				}
			}
			.btn-blue {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-pink {
				color: #ffffff;
				background-color: #b94162;
				border-color: #b03d5d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
				}
			}
			.btn-cyan {
				color: #ffffff;
				background-color: #469aa7;
				border-color: #43919e;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
				}
			}
			.btn-purple {
				color: #ffffff;
				background-color: #683bd7;
				border-color: #6030d5;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
				}
			}
			.btn-soft-indigo {
				color: #262B40;
				background-color: #dfb5ff;
				border-color: #d9a8ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
				}
			}
			.btn-soft-green {
				color: #ffffff;
				background-color: #217d6b;
				border-color: #1f7362;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
				}
			}
			.btn-gray-100 {
				color: #262B40;
				background-color: #cfdfeb;
				border-color: #c6d9e7;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
				}
			}
			.btn-gray-200 {
				color: #262B40;
				background-color: #d1dfec;
				border-color: #c7d8e9;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
				}
			}
			.btn-gray-300 {
				color: #262B40;
				background-color: #d0dae3;
				border-color: #c8d3de;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
				}
			}
			.btn-gray-400 {
				color: #262B40;
				background-color: #cbd2df;
				border-color: #c3cbda;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
				}
			}
			.btn-gray-500 {
				color: #262B40;
				background-color: #b3bdcb;
				border-color: #abb6c6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
				}
			}
			.btn-gray-600 {
				color: #ffffff;
				background-color: #738aab;
				border-color: #6b84a6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
				}
			}
			.btn-gray-700 {
				color: #ffffff;
				background-color: #516180;
				border-color: #4c5b78;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
				}
			}
			.btn-gray-800 {
				color: #ffffff;
				background-color: #3e4f6f;
				border-color: #394967;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
				}
			}
			.btn-facebook {
				color: #ffffff;
				background-color: #2d4474;
				border-color: #293e6b;
				color: #ffffff;
				background-color: #2d4474;
				border-color: #293e6b;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
				}
			}
			.btn-twitter {
				color: #ffffff;
				background-color: #0c85d0;
				border-color: #0b7ec4;
				color: #ffffff;
				background-color: #0c85d0;
				border-color: #0b7ec4;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
				}
			}
			.btn-google {
				color: #ffffff;
				background-color: #bd2d22;
				border-color: #b22b20;
				color: #ffffff;
				background-color: #bd2d22;
				border-color: #b22b20;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
				}
			}
			.btn-instagram {
				color: #ffffff;
				background-color: #d31e40;
				border-color: #c81c3d;
				color: #ffffff;
				background-color: #d31e40;
				border-color: #c81c3d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
				}
			}
			.btn-pinterest {
				color: #ffffff;
				background-color: #8c0615;
				border-color: #800513;
				color: #ffffff;
				background-color: #8c0615;
				border-color: #800513;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
				}
			}
			.btn-youtube {
				color: #ffffff;
				background-color: #a11918;
				border-color: #961717;
				color: #ffffff;
				background-color: #a11918;
				border-color: #961717;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
				}
			}
			.btn-slack {
				color: #ffffff;
				background-color: #2d8968;
				border-color: #2a7f61;
				color: #ffffff;
				background-color: #2d8968;
				border-color: #2a7f61;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
				}
			}
			.btn-dribbble {
				color: #ffffff;
				background-color: #e51e6b;
				border-color: #dc1a65;
				color: #ffffff;
				background-color: #e51e6b;
				border-color: #dc1a65;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
				}
			}
			.btn-dropbox {
				color: #ffffff;
				background-color: #0077ea;
				border-color: #0070dd;
				color: #ffffff;
				background-color: #0077ea;
				border-color: #0070dd;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
				}
			}
			.btn-twitch {
				color: #ffffff;
				background-color: #362758;
				border-color: #302350;
				color: #ffffff;
				background-color: #362758;
				border-color: #302350;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
				}
			}
			.btn-paypal {
				color: #262B40;
				background-color: #d29913;
				border-color: #c69112;
				color: #262B40;
				background-color: #d29913;
				border-color: #c69112;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
				}
			}
			.btn-behance {
				color: #ffffff;
				background-color: #0046cc;
				border-color: #0041bf;
				color: #ffffff;
				background-color: #0046cc;
				border-color: #0041bf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
				}
			}
			.btn-reddit {
				color: #ffffff;
				background-color: #c33214;
				border-color: #b72f13;
				color: #ffffff;
				background-color: #c33214;
				border-color: #b72f13;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
				}
			}
			.btn-github {
				color: #ffffff;
				background-color: #090909;
				border-color: #020202;
				color: #ffffff;
				background-color: #090909;
				border-color: #020202;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
				}
			}
			.btn-outline-primary {
				color: #ffffff;
				background-color: #262B40;
				border-color: #262B40;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
				}
			}
			.btn-outline-secondary {
				color: #262B40;
				background-color: #F8BD7A;
				border-color: #F8BD7A;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
				}
			}
			.btn-outline-tertiary {
				color: #ffffff;
				background-color: #1B998B;
				border-color: #1B998B;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
				}
			}
			.btn-outline-quaternary {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-primary-app {
				color: #262B40;
				background-color: #EBF4F6;
				border-color: #EBF4F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
				}
			}
			.btn-outline-secondary-app {
				color: #ffffff;
				background-color: #424AA0;
				border-color: #424AA0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
				}
			}
			.btn-outline-light {
				color: #262B40;
				background-color: #eaedf2;
				border-color: #eaedf2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
				}
			}
			.btn-outline-lighten {
				color: #262B40;
				background-color: #F0F3F6;
				border-color: #F0F3F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
				}
			}
			.btn-outline-success {
				color: #ffffff;
				background-color: #05A677;
				border-color: #05A677;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
				}
			}
			.btn-outline-info {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-warning {
				color: #262B40;
				background-color: #f5b759;
				border-color: #f5b759;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
				}
			}
			.btn-outline-danger {
				color: #ffffff;
				background-color: #FA5252;
				border-color: #FA5252;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
				}
			}
			.btn-outline-white {
				color: #262B40;
				background-color: #ffffff;
				border-color: #ffffff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
				}
			}
			.btn-outline-gray {
				color: #ffffff;
				background-color: #4A5073;
				border-color: #4A5073;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
				}
			}
			.btn-outline-neutral {
				color: #262B40;
				background-color: #ffffff;
				border-color: #ffffff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
				}
			}
			.btn-outline-dark {
				color: #ffffff;
				background-color: #262B40;
				border-color: #262B40;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
				}
			}
			.btn-outline-soft {
				color: #262B40;
				background-color: #f5f8fb;
				border-color: #f5f8fb;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
				}
			}
			.btn-outline-black {
				color: #ffffff;
				background-color: #2e3650;
				border-color: #2e3650;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
				}
			}
			.btn-outline-blue {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-pink {
				color: #ffffff;
				background-color: #C96480;
				border-color: #C96480;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
				}
			}
			.btn-outline-cyan {
				color: #262B40;
				background-color: #63b1bd;
				border-color: #63b1bd;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
				}
			}
			.btn-outline-purple {
				color: #ffffff;
				background-color: #8965e0;
				border-color: #8965e0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
				}
			}
			.btn-outline-soft-indigo {
				color: #262B40;
				background-color: #f5e8ff;
				border-color: #f5e8ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
				}
			}
			.btn-outline-soft-green {
				color: #ffffff;
				background-color: #2CA58D;
				border-color: #2CA58D;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
				}
			}
			.btn-outline-gray-100 {
				color: #262B40;
				background-color: #f3f7fa;
				border-color: #f3f7fa;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
				}
			}
			.btn-outline-gray-200 {
				color: #262B40;
				background-color: #f5f8fb;
				border-color: #f5f8fb;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
				}
			}
			.btn-outline-gray-300 {
				color: #262B40;
				background-color: #F0F3F6;
				border-color: #F0F3F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
				}
			}
			.btn-outline-gray-400 {
				color: #262B40;
				background-color: #eaedf2;
				border-color: #eaedf2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
				}
			}
			.btn-outline-gray-500 {
				color: #262B40;
				background-color: #d1d7e0;
				border-color: #d1d7e0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
				}
			}
			.btn-outline-gray-600 {
				color: #262B40;
				background-color: #93a5be;
				border-color: #93a5be;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
				}
			}
			.btn-outline-gray-700 {
				color: #ffffff;
				background-color: #66799e;
				border-color: #66799e;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
				}
			}
			.btn-outline-gray-800 {
				color: #ffffff;
				background-color: #506690;
				border-color: #506690;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
				}
			}
			.btn-outline-facebook {
				color: #ffffff;
				background-color: #3b5999;
				border-color: #3b5999;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
				}
			}
			.btn-outline-twitter {
				color: #262B40;
				background-color: #1da1f2;
				border-color: #1da1f2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
				}
			}
			.btn-outline-google {
				color: #ffffff;
				background-color: #DB4337;
				border-color: #DB4337;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
				}
			}
			.btn-outline-instagram {
				color: #ffffff;
				background-color: #e4405f;
				border-color: #e4405f;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
				}
			}
			.btn-outline-pinterest {
				color: #ffffff;
				background-color: #bd081c;
				border-color: #bd081c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
				}
			}
			.btn-outline-youtube {
				color: #ffffff;
				background-color: #cd201f;
				border-color: #cd201f;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
				}
			}
			.btn-outline-slack {
				color: #262B40;
				background-color: #3aaf85;
				border-color: #3aaf85;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
				}
			}
			.btn-outline-dribbble {
				color: #ffffff;
				background-color: #ea4c89;
				border-color: #ea4c89;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
				}
			}
			.btn-outline-dropbox {
				color: #ffffff;
				background-color: #1E90FF;
				border-color: #1E90FF;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
				}
			}
			.btn-outline-twitch {
				color: #ffffff;
				background-color: #4B367C;
				border-color: #4B367C;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
				}
			}
			.btn-outline-paypal {
				color: #262B40;
				background-color: #ecb32c;
				border-color: #ecb32c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
				}
			}
			.btn-outline-behance {
				color: #ffffff;
				background-color: #0057ff;
				border-color: #0057ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
				}
			}
			.btn-outline-reddit {
				color: #ffffff;
				background-color: #E84422;
				border-color: #E84422;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
				}
			}
			.btn-outline-github {
				color: #ffffff;
				background-color: #222222;
				border-color: #222222;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
				}
			}
		}
	}
	&:active {
		+ {
			.btn {
				box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
				&:focus {
					box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25), inset 0 3px 5px rgba(46, 54, 80, 0.125);
				}
			}
			.btn-primary {
				color: #ffffff;
				background-color: #131620;
				border-color: #0e1018;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
				}
			}
			.btn-secondary {
				color: #262B40;
				background-color: #f5a54a;
				border-color: #f59f3d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
				}
			}
			.btn-tertiary {
				color: #ffffff;
				background-color: #136e64;
				border-color: #11635a;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
				}
			}
			.btn-quaternary {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-primary-app {
				color: #262B40;
				background-color: #c8e1e6;
				border-color: #bfdce2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
				}
			}
			.btn-secondary-app {
				color: #ffffff;
				background-color: #33397c;
				border-color: #2f3573;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
				}
			}
			.btn-light {
				color: #262B40;
				background-color: #cbd2df;
				border-color: #c3cbda;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
				}
			}
			.btn-lighten {
				color: #262B40;
				background-color: #d0dae3;
				border-color: #c8d3de;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
				}
			}
			.btn-success {
				color: #ffffff;
				background-color: #047454;
				border-color: #03684b;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
				}
			}
			.btn-info {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-warning {
				color: #262B40;
				background-color: #f2a229;
				border-color: #f19d1d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
				}
			}
			.btn-danger {
				color: #ffffff;
				background-color: #f92020;
				border-color: #f81414;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
				}
			}
			.btn-white {
				color: #262B40;
				background-color: #e6e6e6;
				border-color: #dfdfdf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
				}
			}
			.btn-gray {
				color: #ffffff;
				background-color: #363a54;
				border-color: #31354c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
				}
			}
			.btn-neutral {
				color: #262B40;
				background-color: #e6e6e6;
				border-color: #dfdfdf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
				}
			}
			.btn-dark {
				color: #ffffff;
				background-color: #131620;
				border-color: #0e1018;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
				}
			}
			.btn-soft {
				color: #262B40;
				background-color: #d1dfec;
				border-color: #c7d8e9;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
				}
			}
			.btn-black {
				color: #ffffff;
				background-color: #1b2030;
				border-color: #171b28;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
				}
			}
			.btn-blue {
				color: #ffffff;
				background-color: #073482;
				border-color: #063076;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
				}
			}
			.btn-pink {
				color: #ffffff;
				background-color: #b94162;
				border-color: #b03d5d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
				}
			}
			.btn-cyan {
				color: #ffffff;
				background-color: #469aa7;
				border-color: #43919e;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
				}
			}
			.btn-purple {
				color: #ffffff;
				background-color: #683bd7;
				border-color: #6030d5;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
				}
			}
			.btn-soft-indigo {
				color: #262B40;
				background-color: #dfb5ff;
				border-color: #d9a8ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
				}
			}
			.btn-soft-green {
				color: #ffffff;
				background-color: #217d6b;
				border-color: #1f7362;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
				}
			}
			.btn-gray-100 {
				color: #262B40;
				background-color: #cfdfeb;
				border-color: #c6d9e7;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
				}
			}
			.btn-gray-200 {
				color: #262B40;
				background-color: #d1dfec;
				border-color: #c7d8e9;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
				}
			}
			.btn-gray-300 {
				color: #262B40;
				background-color: #d0dae3;
				border-color: #c8d3de;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
				}
			}
			.btn-gray-400 {
				color: #262B40;
				background-color: #cbd2df;
				border-color: #c3cbda;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
				}
			}
			.btn-gray-500 {
				color: #262B40;
				background-color: #b3bdcb;
				border-color: #abb6c6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
				}
			}
			.btn-gray-600 {
				color: #ffffff;
				background-color: #738aab;
				border-color: #6b84a6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
				}
			}
			.btn-gray-700 {
				color: #ffffff;
				background-color: #516180;
				border-color: #4c5b78;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
				}
			}
			.btn-gray-800 {
				color: #ffffff;
				background-color: #3e4f6f;
				border-color: #394967;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
				}
			}
			.btn-facebook {
				color: #ffffff;
				background-color: #2d4474;
				border-color: #293e6b;
				color: #ffffff;
				background-color: #2d4474;
				border-color: #293e6b;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
				}
			}
			.btn-twitter {
				color: #ffffff;
				background-color: #0c85d0;
				border-color: #0b7ec4;
				color: #ffffff;
				background-color: #0c85d0;
				border-color: #0b7ec4;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
				}
			}
			.btn-google {
				color: #ffffff;
				background-color: #bd2d22;
				border-color: #b22b20;
				color: #ffffff;
				background-color: #bd2d22;
				border-color: #b22b20;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
				}
			}
			.btn-instagram {
				color: #ffffff;
				background-color: #d31e40;
				border-color: #c81c3d;
				color: #ffffff;
				background-color: #d31e40;
				border-color: #c81c3d;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
				}
			}
			.btn-pinterest {
				color: #ffffff;
				background-color: #8c0615;
				border-color: #800513;
				color: #ffffff;
				background-color: #8c0615;
				border-color: #800513;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
				}
			}
			.btn-youtube {
				color: #ffffff;
				background-color: #a11918;
				border-color: #961717;
				color: #ffffff;
				background-color: #a11918;
				border-color: #961717;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
				}
			}
			.btn-slack {
				color: #ffffff;
				background-color: #2d8968;
				border-color: #2a7f61;
				color: #ffffff;
				background-color: #2d8968;
				border-color: #2a7f61;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
				}
			}
			.btn-dribbble {
				color: #ffffff;
				background-color: #e51e6b;
				border-color: #dc1a65;
				color: #ffffff;
				background-color: #e51e6b;
				border-color: #dc1a65;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
				}
			}
			.btn-dropbox {
				color: #ffffff;
				background-color: #0077ea;
				border-color: #0070dd;
				color: #ffffff;
				background-color: #0077ea;
				border-color: #0070dd;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
				}
			}
			.btn-twitch {
				color: #ffffff;
				background-color: #362758;
				border-color: #302350;
				color: #ffffff;
				background-color: #362758;
				border-color: #302350;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
				}
			}
			.btn-paypal {
				color: #262B40;
				background-color: #d29913;
				border-color: #c69112;
				color: #262B40;
				background-color: #d29913;
				border-color: #c69112;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
				}
			}
			.btn-behance {
				color: #ffffff;
				background-color: #0046cc;
				border-color: #0041bf;
				color: #ffffff;
				background-color: #0046cc;
				border-color: #0041bf;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
				}
			}
			.btn-reddit {
				color: #ffffff;
				background-color: #c33214;
				border-color: #b72f13;
				color: #ffffff;
				background-color: #c33214;
				border-color: #b72f13;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
				}
			}
			.btn-github {
				color: #ffffff;
				background-color: #090909;
				border-color: #020202;
				color: #ffffff;
				background-color: #090909;
				border-color: #020202;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
				}
			}
			.btn-outline-primary {
				color: #ffffff;
				background-color: #262B40;
				border-color: #262B40;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
				}
			}
			.btn-outline-secondary {
				color: #262B40;
				background-color: #F8BD7A;
				border-color: #F8BD7A;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
				}
			}
			.btn-outline-tertiary {
				color: #ffffff;
				background-color: #1B998B;
				border-color: #1B998B;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
				}
			}
			.btn-outline-quaternary {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-primary-app {
				color: #262B40;
				background-color: #EBF4F6;
				border-color: #EBF4F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
				}
			}
			.btn-outline-secondary-app {
				color: #ffffff;
				background-color: #424AA0;
				border-color: #424AA0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
				}
			}
			.btn-outline-light {
				color: #262B40;
				background-color: #eaedf2;
				border-color: #eaedf2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
				}
			}
			.btn-outline-lighten {
				color: #262B40;
				background-color: #F0F3F6;
				border-color: #F0F3F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
				}
			}
			.btn-outline-success {
				color: #ffffff;
				background-color: #05A677;
				border-color: #05A677;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
				}
			}
			.btn-outline-info {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-warning {
				color: #262B40;
				background-color: #f5b759;
				border-color: #f5b759;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
				}
			}
			.btn-outline-danger {
				color: #ffffff;
				background-color: #FA5252;
				border-color: #FA5252;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
				}
			}
			.btn-outline-white {
				color: #262B40;
				background-color: #ffffff;
				border-color: #ffffff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
				}
			}
			.btn-outline-gray {
				color: #ffffff;
				background-color: #4A5073;
				border-color: #4A5073;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
				}
			}
			.btn-outline-neutral {
				color: #262B40;
				background-color: #ffffff;
				border-color: #ffffff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
				}
			}
			.btn-outline-dark {
				color: #ffffff;
				background-color: #262B40;
				border-color: #262B40;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
				}
			}
			.btn-outline-soft {
				color: #262B40;
				background-color: #f5f8fb;
				border-color: #f5f8fb;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
				}
			}
			.btn-outline-black {
				color: #ffffff;
				background-color: #2e3650;
				border-color: #2e3650;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
				}
			}
			.btn-outline-blue {
				color: #ffffff;
				background-color: #0948B3;
				border-color: #0948B3;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
				}
			}
			.btn-outline-pink {
				color: #ffffff;
				background-color: #C96480;
				border-color: #C96480;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
				}
			}
			.btn-outline-cyan {
				color: #262B40;
				background-color: #63b1bd;
				border-color: #63b1bd;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
				}
			}
			.btn-outline-purple {
				color: #ffffff;
				background-color: #8965e0;
				border-color: #8965e0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
				}
			}
			.btn-outline-soft-indigo {
				color: #262B40;
				background-color: #f5e8ff;
				border-color: #f5e8ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
				}
			}
			.btn-outline-soft-green {
				color: #ffffff;
				background-color: #2CA58D;
				border-color: #2CA58D;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
				}
			}
			.btn-outline-gray-100 {
				color: #262B40;
				background-color: #f3f7fa;
				border-color: #f3f7fa;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
				}
			}
			.btn-outline-gray-200 {
				color: #262B40;
				background-color: #f5f8fb;
				border-color: #f5f8fb;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
				}
			}
			.btn-outline-gray-300 {
				color: #262B40;
				background-color: #F0F3F6;
				border-color: #F0F3F6;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
				}
			}
			.btn-outline-gray-400 {
				color: #262B40;
				background-color: #eaedf2;
				border-color: #eaedf2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
				}
			}
			.btn-outline-gray-500 {
				color: #262B40;
				background-color: #d1d7e0;
				border-color: #d1d7e0;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
				}
			}
			.btn-outline-gray-600 {
				color: #262B40;
				background-color: #93a5be;
				border-color: #93a5be;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
				}
			}
			.btn-outline-gray-700 {
				color: #ffffff;
				background-color: #66799e;
				border-color: #66799e;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
				}
			}
			.btn-outline-gray-800 {
				color: #ffffff;
				background-color: #506690;
				border-color: #506690;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
				}
			}
			.btn-outline-facebook {
				color: #ffffff;
				background-color: #3b5999;
				border-color: #3b5999;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
				}
			}
			.btn-outline-twitter {
				color: #262B40;
				background-color: #1da1f2;
				border-color: #1da1f2;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
				}
			}
			.btn-outline-google {
				color: #ffffff;
				background-color: #DB4337;
				border-color: #DB4337;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
				}
			}
			.btn-outline-instagram {
				color: #ffffff;
				background-color: #e4405f;
				border-color: #e4405f;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
				}
			}
			.btn-outline-pinterest {
				color: #ffffff;
				background-color: #bd081c;
				border-color: #bd081c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
				}
			}
			.btn-outline-youtube {
				color: #ffffff;
				background-color: #cd201f;
				border-color: #cd201f;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
				}
			}
			.btn-outline-slack {
				color: #262B40;
				background-color: #3aaf85;
				border-color: #3aaf85;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
				}
			}
			.btn-outline-dribbble {
				color: #ffffff;
				background-color: #ea4c89;
				border-color: #ea4c89;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
				}
			}
			.btn-outline-dropbox {
				color: #ffffff;
				background-color: #1E90FF;
				border-color: #1E90FF;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
				}
			}
			.btn-outline-twitch {
				color: #ffffff;
				background-color: #4B367C;
				border-color: #4B367C;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
				}
			}
			.btn-outline-paypal {
				color: #262B40;
				background-color: #ecb32c;
				border-color: #ecb32c;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
				}
			}
			.btn-outline-behance {
				color: #ffffff;
				background-color: #0057ff;
				border-color: #0057ff;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
				}
			}
			.btn-outline-reddit {
				color: #ffffff;
				background-color: #E84422;
				border-color: #E84422;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
				}
			}
			.btn-outline-github {
				color: #ffffff;
				background-color: #222222;
				border-color: #222222;
				&:focus {
					box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
				}
			}
		}
	}
}
.form-file {
	--bs-form-file-height: calc(1.5em + 1.225rem);
	position: relative;
}
.form-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: var(--bs-form-file-height);
	margin: 0;
	opacity: 0;
	&:focus-within {
		~ {
			.form-file-label {
				border-color: #566190;
				box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
			}
		}
	}
	&:disabled {
		~ {
			.form-file-label {
				.form-file-text {
					background-color: #f5f8fb;
				}
			}
		}
	}
}
.form-file-input[disabled] {
	~ {
		.form-file-label {
			.form-file-text {
				background-color: #f5f8fb;
			}
		}
	}
}
.form-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	display: flex;
	height: var(--bs-form-file-height);
	border-color: #d1d7e0;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}
.form-file-text {
	display: block;
	flex-grow: 1;
	padding: 0.55rem 0.75rem;
	overflow: hidden;
	font-weight: 400;
	line-height: 1.5;
	color: #66799e;
	text-overflow: ellipsis;
	white-space: nowrap;
	background-color: #ffffff;
	border-color: inherit;
	border-style: solid;
	border-width: 0.0625rem;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
}
.form-file-button {
	display: block;
	flex-shrink: 0;
	padding: 0.55rem 0.75rem;
	margin-left: -0.0625rem;
	line-height: 1.5;
	color: #66799e;
	background-color: #ffffff;
	border-color: inherit;
	border-style: solid;
	border-width: 0.0625rem;
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
}
.form-file-sm {
	--bs-form-file-height: calc(1.5em + 0.625rem);
	font-size: 0.875rem;
	.form-file-text {
		padding: 0.25rem 0.5rem;
	}
	.form-file-button {
		padding: 0.25rem 0.5rem;
	}
}
.form-file-lg {
	--bs-form-file-height: calc(1.5em + 1.125rem);
	font-size: 1.25rem;
	.form-file-text {
		padding: 0.5rem 1rem;
	}
	.form-file-button {
		padding: 0.5rem 1rem;
	}
}
.form-range {
	width: 100%;
	height: 1.4rem;
	padding: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: none;
		&::-webkit-slider-thumb {
			box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
		}
		&::-moz-range-thumb {
			box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
		}
		&::-ms-thumb {
			box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
		}
	}
	&::-moz-focus-outer {
		border: 0;
	}
	&::-webkit-slider-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: -0.25rem;
		background-color: #262B40;
		border: 0;
		border-radius: 1rem;
		box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
		-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		-webkit-appearance: none;
		appearance: none;
		&:active {
			background-color: #6f7aaa;
		}
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #F0F3F6;
		border-color: transparent;
		border-radius: 1rem;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	}
	&::-moz-range-thumb {
		width: 1rem;
		height: 1rem;
		background-color: #262B40;
		border: 0;
		border-radius: 1rem;
		box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
		-moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		-moz-appearance: none;
		appearance: none;
		&:active {
			background-color: #6f7aaa;
		}
	}
	&::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #F0F3F6;
		border-color: transparent;
		border-radius: 1rem;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	}
	&::-ms-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: 0;
		margin-right: 0.2rem;
		margin-left: 0.2rem;
		background-color: #262B40;
		border: 0;
		border-radius: 1rem;
		box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
		-ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		appearance: none;
		&:active {
			background-color: #6f7aaa;
		}
	}
	&::-ms-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;
		border-width: 0.5rem;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	}
	&::-ms-fill-lower {
		background-color: #F0F3F6;
		border-radius: 1rem;
	}
	&::-ms-fill-upper {
		margin-right: 15px;
		background-color: #F0F3F6;
		border-radius: 1rem;
	}
	&:disabled {
		pointer-events: none;
		&::-webkit-slider-thumb {
			background-color: #d1d7e0;
		}
		&::-moz-range-thumb {
			background-color: #d1d7e0;
		}
		&::-ms-thumb {
			background-color: #d1d7e0;
		}
	}
}
.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	border-radius: 0.5rem;
	transition: all 0.2s ease;
	>.form-control {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
		&:focus {
			z-index: 3;
		}
	}
	>.form-select {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
		&:focus {
			z-index: 3;
		}
	}
	>.form-file {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
		.form-file-input {
			&:focus {
				~ {
					.form-file-label {
						z-index: 3;
					}
				}
			}
		}
		>.form-file-input {
			&:focus {
				z-index: 4;
			}
		}
		&:not(:last-child) {
			>.form-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:not(:first-child) {
			>.form-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	.btn {
		position: relative;
		z-index: 2;
		&:focus {
			z-index: 3;
		}
	}
	& > :not(:last-child) {
		&:not(.dropdown-toggle) {
			&:not(.dropdown-menu) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	> :not(:first-child) {
		&:not(.dropdown-menu) {
			margin-left: -0.0625rem;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	>.dropdown-toggle {
		&:nth-last-child(n+3) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
	}
	.form-control {
		&:focus {
			border-color: #d1d7e0;
			box-shadow: none;
			+ {
				.input-group-text {
					border-color: #d1d7e0;
				}
			}
		}
		box-shadow: none;
		&:not(:first-child) {
			border-left: 0;
			padding-left: 0;
		}
		&:not(:last-child) {
			border-right: 0;
			padding-right: 0;
		}
	}
}
.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.55rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #93a5be;
	text-align: center;
	white-space: nowrap;
	background-color: #ffffff;
	border: 0.0625rem solid #d1d7e0;
	border-radius: 0.5rem;
	font-size: 0.875rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group-lg {
	>.form-control {
		min-height: calc(1.5em + 1.125rem);
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		border-radius: 0.3rem;
	}
	>.form-select {
		height: calc(1.5em + 1.125rem);
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		border-radius: 0.3rem;
		padding-right: 1.75rem;
	}
	>.input-group-text {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		border-radius: 0.3rem;
	}
	>.btn {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		border-radius: 0.3rem;
	}
}
.input-group-sm {
	>.form-control {
		min-height: calc(1.5em + 0.625rem);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		border-radius: 0.1rem;
	}
	>.form-select {
		height: calc(1.5em + 0.625rem);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		border-radius: 0.1rem;
		padding-right: 1.75rem;
	}
	>.input-group-text {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		border-radius: 0.1rem;
	}
	>.btn {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		border-radius: 0.1rem;
	}
}
.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #05A677;
}
.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.875rem;
	color: #ffffff;
	background-color: rgba(5, 166, 119, 0.9);
	border-radius: 0.5rem;
}
.was-validated {
	&:valid {
		~ {
			.valid-feedback {
				display: block;
			}
			.valid-tooltip {
				display: block;
			}
		}
	}
	.form-control {
		&:valid {
			border-color: #05A677;
			padding-right: calc(1.5em + 1.1rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right calc(0.375em + 0.275rem) center;
			background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
			&:focus {
				border-color: #05A677;
				box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
			}
		}
		&:invalid {
			border-color: #FA5252;
			padding-right: calc(1.5em + 1.1rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right calc(0.375em + 0.275rem) center;
			background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
			&:focus {
				border-color: #FA5252;
				box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
			}
		}
	}
	textarea.form-control {
		&:valid {
			padding-right: calc(1.5em + 1.1rem);
			background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
		}
		&:invalid {
			padding-right: calc(1.5em + 1.1rem);
			background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
		}
	}
	.form-select {
		&:valid {
			border-color: #05A677;
			padding-right: calc(0.75em + 2.575rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
			background-position: right 0.75rem center, center right 1.75rem;
			background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
			&:focus {
				border-color: #05A677;
				box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
			}
		}
		&:invalid {
			border-color: #FA5252;
			padding-right: calc(0.75em + 2.575rem);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
			background-position: right 0.75rem center, center right 1.75rem;
			background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
			&:focus {
				border-color: #FA5252;
				box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
			}
		}
	}
	.form-check-input {
		&:valid {
			border-color: #05A677;
			&:checked {
				background-color: #05A677;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
			}
			~ {
				.form-check-label {
					color: #05A677;
				}
			}
		}
		&:invalid {
			border-color: #FA5252;
			&:checked {
				background-color: #FA5252;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
			}
			~ {
				.form-check-label {
					color: #FA5252;
				}
			}
		}
	}
	.form-file-input {
		&:valid {
			~ {
				.form-file-label {
					border-color: #05A677;
				}
			}
			&:focus {
				~ {
					.form-file-label {
						border-color: #05A677;
						box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
					}
				}
			}
		}
		&:invalid {
			~ {
				.form-file-label {
					border-color: #FA5252;
				}
			}
			&:focus {
				~ {
					.form-file-label {
						border-color: #FA5252;
						box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
					}
				}
			}
		}
	}
	&:invalid {
		~ {
			.invalid-feedback {
				display: block;
			}
			.invalid-tooltip {
				display: block;
			}
		}
	}
}
.is-valid {
	~ {
		.valid-feedback {
			display: block;
		}
		.valid-tooltip {
			display: block;
		}
	}
}
.form-control.is-valid {
	border-color: #05A677;
	padding-right: calc(1.5em + 1.1rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.275rem) center;
	background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
	&:focus {
		border-color: #05A677;
		box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
	}
}
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 1.1rem);
	background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}
.form-select.is-valid {
	border-color: #05A677;
	padding-right: calc(0.75em + 2.575rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 1.75rem;
	background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
	&:focus {
		border-color: #05A677;
		box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
	}
}
.form-check-input.is-valid {
	border-color: #05A677;
	&:checked {
		background-color: #05A677;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
	}
	~ {
		.form-check-label {
			color: #05A677;
		}
	}
}
.form-file-input.is-valid {
	~ {
		.form-file-label {
			border-color: #05A677;
		}
	}
	&:focus {
		~ {
			.form-file-label {
				border-color: #05A677;
				box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
			}
		}
	}
}
.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #FA5252;
}
.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.875rem;
	color: #ffffff;
	background-color: rgba(250, 82, 82, 0.9);
	border-radius: 0.5rem;
}
.is-invalid {
	~ {
		.invalid-feedback {
			display: block;
		}
		.invalid-tooltip {
			display: block;
		}
	}
}
.form-control.is-invalid {
	border-color: #FA5252;
	background-image: none;
	// padding-right: calc(1.5em + 1.1rem);
	// background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
	// background-repeat: no-repeat;
	// background-position: right calc(0.375em + 0.275rem) center;
	// background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
	&:focus {
		border-color: #FA5252;
		box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
	}
}
textarea.form-control.is-invalid {
	// padding-right: calc(1.5em + 1.1rem);
	// background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}
.form-select.is-invalid {
	border-color: #FA5252;
	// padding-right: calc(0.75em + 2.575rem);
	// background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
	// background-position: right 0.75rem center, center right 1.75rem;
	// background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
	&:focus {
		border-color: #FA5252;
		box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
	}
}
.select2.is-invalid .select2-selection {
	border-color: #FA5252;
	&:focus {
		border-color: #FA5252;
		box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
	}
}
.form-check-input.is-invalid {
	border-color: #FA5252;
	&:checked {
		background-color: #FA5252;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
	}
	~ {
		.form-check-label {
			color: #FA5252;
		}
	}
}
.form-file-input.is-invalid {
	~ {
		.form-file-label {
			border-color: #FA5252;
		}
	}
	&:focus {
		~ {
			.form-file-label {
				border-color: #FA5252;
				box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
			}
		}
	}
}
.btn {
	display: inline-block;
	font-weight: 600;
	line-height: 1.5;
	color: #4A5073;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 0.0625rem solid transparent;
	padding: 0.55rem 0.75rem;
	font-size: 1rem;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:hover {
		color: #4A5073;
	}
	&:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
	}
	&:active {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25), inset 0 3px 5px rgba(46, 54, 80, 0.125);
		}
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 0.65;
		box-shadow: none;
		pointer-events: all;
	}
	.badge {
		position: relative;
		top: -1px;
	}
	.badge-corner {
		position: absolute;
		top: -50%;
		right: .5rem;
		transform: translate(50%, 50%);
		margin: 0;
		border: 3px solid;
		padding-left: .5rem;
		padding-right: .5rem;
	}
}
.btn.active {
	box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25), inset 0 3px 5px rgba(46, 54, 80, 0.125);
	}
}
.btn.disabled {
	pointer-events: none;
	opacity: 0.65;
	box-shadow: none;
}
.btn-primary {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff !important;
		background-color: #181b28 !important;
		border-color: #131620 !important;
	}
	&:focus {
		color: #ffffff !important;
		background-color: #181b28 !important;
		border-color: #131620 !important;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(71, 75, 93, 0.5) !important;
	}
	&:active {
		color: #ffffff !important;
		background-color: #131620 !important;
		border-color: #0e1018 !important;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5) !important;
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
	}
}
.btn-primary.active {
	color: #ffffff;
	background-color: #131620;
	border-color: #0e1018;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
	}
}
.show {
	>.btn-primary.dropdown-toggle {
		color: #ffffff;
		background-color: #131620;
		border-color: #0e1018;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
		}
	}
	>.btn-secondary.dropdown-toggle {
		color: #262B40;
		background-color: #f5a54a;
		border-color: #f59f3d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
		}
	}
	>.btn-tertiary.dropdown-toggle {
		color: #ffffff;
		background-color: #136e64;
		border-color: #11635a;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
		}
	}
	>.btn-quaternary.dropdown-toggle {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	>.btn-primary-app.dropdown-toggle {
		color: #262B40;
		background-color: #c8e1e6;
		border-color: #bfdce2;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
		}
	}
	>.btn-secondary-app.dropdown-toggle {
		color: #ffffff;
		background-color: #33397c;
		border-color: #2f3573;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
		}
	}
	>.btn-light.dropdown-toggle {
		color: #262B40;
		background-color: #cbd2df;
		border-color: #c3cbda;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
		}
	}
	>.btn-lighten.dropdown-toggle {
		color: #262B40;
		background-color: #d0dae3;
		border-color: #c8d3de;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
		}
	}
	>.btn-success.dropdown-toggle {
		color: #ffffff;
		background-color: #047454;
		border-color: #03684b;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
		}
	}
	>.btn-info.dropdown-toggle {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	>.btn-warning.dropdown-toggle {
		color: #262B40;
		background-color: #f2a229;
		border-color: #f19d1d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
		}
	}
	>.btn-danger.dropdown-toggle {
		color: #ffffff;
		background-color: #f92020;
		border-color: #f81414;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
		}
	}
	>.btn-white.dropdown-toggle {
		color: #262B40;
		background-color: #e6e6e6;
		border-color: #dfdfdf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
		}
	}
	>.btn-gray.dropdown-toggle {
		color: #ffffff;
		background-color: #363a54;
		border-color: #31354c;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
		}
	}
	>.btn-neutral.dropdown-toggle {
		color: #262B40;
		background-color: #e6e6e6;
		border-color: #dfdfdf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
		}
	}
	>.btn-dark.dropdown-toggle {
		color: #ffffff;
		background-color: #131620;
		border-color: #0e1018;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
		}
	}
	>.btn-soft.dropdown-toggle {
		color: #262B40;
		background-color: #d1dfec;
		border-color: #c7d8e9;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
		}
	}
	>.btn-black.dropdown-toggle {
		color: #ffffff;
		background-color: #1b2030;
		border-color: #171b28;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
		}
	}
	>.btn-blue.dropdown-toggle {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	>.btn-pink.dropdown-toggle {
		color: #ffffff;
		background-color: #b94162;
		border-color: #b03d5d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
		}
	}
	>.btn-cyan.dropdown-toggle {
		color: #ffffff;
		background-color: #469aa7;
		border-color: #43919e;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
		}
	}
	>.btn-purple.dropdown-toggle {
		color: #ffffff;
		background-color: #683bd7;
		border-color: #6030d5;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
		}
	}
	>.btn-soft-indigo.dropdown-toggle {
		color: #262B40;
		background-color: #dfb5ff;
		border-color: #d9a8ff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
		}
	}
	>.btn-soft-green.dropdown-toggle {
		color: #ffffff;
		background-color: #217d6b;
		border-color: #1f7362;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
		}
	}
	>.btn-gray-100.dropdown-toggle {
		color: #262B40;
		background-color: #cfdfeb;
		border-color: #c6d9e7;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
		}
	}
	>.btn-gray-200.dropdown-toggle {
		color: #262B40;
		background-color: #d1dfec;
		border-color: #c7d8e9;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
		}
	}
	>.btn-gray-300.dropdown-toggle {
		color: #262B40;
		background-color: #d0dae3;
		border-color: #c8d3de;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
		}
	}
	>.btn-gray-400.dropdown-toggle {
		color: #262B40;
		background-color: #cbd2df;
		border-color: #c3cbda;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
		}
	}
	>.btn-gray-500.dropdown-toggle {
		color: #262B40;
		background-color: #b3bdcb;
		border-color: #abb6c6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
		}
	}
	>.btn-gray-600.dropdown-toggle {
		color: #ffffff;
		background-color: #738aab;
		border-color: #6b84a6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
		}
	}
	>.btn-gray-700.dropdown-toggle {
		color: #ffffff;
		background-color: #516180;
		border-color: #4c5b78;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
		}
	}
	>.btn-gray-800.dropdown-toggle {
		color: #ffffff;
		background-color: #3e4f6f;
		border-color: #394967;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
		}
	}
	>.btn-facebook.dropdown-toggle {
		color: #ffffff;
		background-color: #2d4474;
		border-color: #293e6b;
		color: #ffffff;
		background-color: #2d4474;
		border-color: #293e6b;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
		}
	}
	>.btn-twitter.dropdown-toggle {
		color: #ffffff;
		background-color: #0c85d0;
		border-color: #0b7ec4;
		color: #ffffff;
		background-color: #0c85d0;
		border-color: #0b7ec4;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
		}
	}
	>.btn-google.dropdown-toggle {
		color: #ffffff;
		background-color: #bd2d22;
		border-color: #b22b20;
		color: #ffffff;
		background-color: #bd2d22;
		border-color: #b22b20;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
		}
	}
	>.btn-instagram.dropdown-toggle {
		color: #ffffff;
		background-color: #d31e40;
		border-color: #c81c3d;
		color: #ffffff;
		background-color: #d31e40;
		border-color: #c81c3d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
		}
	}
	>.btn-pinterest.dropdown-toggle {
		color: #ffffff;
		background-color: #8c0615;
		border-color: #800513;
		color: #ffffff;
		background-color: #8c0615;
		border-color: #800513;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
		}
	}
	>.btn-youtube.dropdown-toggle {
		color: #ffffff;
		background-color: #a11918;
		border-color: #961717;
		color: #ffffff;
		background-color: #a11918;
		border-color: #961717;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
		}
	}
	>.btn-slack.dropdown-toggle {
		color: #ffffff;
		background-color: #2d8968;
		border-color: #2a7f61;
		color: #ffffff;
		background-color: #2d8968;
		border-color: #2a7f61;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
		}
	}
	>.btn-dribbble.dropdown-toggle {
		color: #ffffff;
		background-color: #e51e6b;
		border-color: #dc1a65;
		color: #ffffff;
		background-color: #e51e6b;
		border-color: #dc1a65;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
		}
	}
	>.btn-dropbox.dropdown-toggle {
		color: #ffffff;
		background-color: #0077ea;
		border-color: #0070dd;
		color: #ffffff;
		background-color: #0077ea;
		border-color: #0070dd;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
		}
	}
	>.btn-twitch.dropdown-toggle {
		color: #ffffff;
		background-color: #362758;
		border-color: #302350;
		color: #ffffff;
		background-color: #362758;
		border-color: #302350;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
		}
	}
	>.btn-paypal.dropdown-toggle {
		color: #262B40;
		background-color: #d29913;
		border-color: #c69112;
		color: #262B40;
		background-color: #d29913;
		border-color: #c69112;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
		}
	}
	>.btn-behance.dropdown-toggle {
		color: #ffffff;
		background-color: #0046cc;
		border-color: #0041bf;
		color: #ffffff;
		background-color: #0046cc;
		border-color: #0041bf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
		}
	}
	>.btn-reddit.dropdown-toggle {
		color: #ffffff;
		background-color: #c33214;
		border-color: #b72f13;
		color: #ffffff;
		background-color: #c33214;
		border-color: #b72f13;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
		}
	}
	>.btn-github.dropdown-toggle {
		color: #ffffff;
		background-color: #090909;
		border-color: #020202;
		color: #ffffff;
		background-color: #090909;
		border-color: #020202;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
		}
	}
	.dropdown-menu {
		animation: show-dropdown .2s ease forwards;
	}
}
.btn-primary.disabled {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
}
.btn-secondary {
	color: #262B40;
	background-color: #F8BD7A;
	border-color: #F8BD7A;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #f6ab56;
		border-color: #f5a54a;
	}
	&:focus {
		color: #262B40;
		background-color: #f6ab56;
		border-color: #f5a54a;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f5a54a;
		border-color: #f59f3d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #F8BD7A;
		border-color: #F8BD7A;
	}
}
.btn-secondary.active {
	color: #262B40;
	background-color: #f5a54a;
	border-color: #f59f3d;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(217, 167, 113, 0.5);
	}
}
.btn-secondary.disabled {
	color: #262B40;
	background-color: #F8BD7A;
	border-color: #F8BD7A;
}
.btn-tertiary {
	color: #ffffff;
	background-color: #1B998B;
	border-color: #1B998B;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #15786d;
		border-color: #136e64;
	}
	&:focus {
		color: #ffffff;
		background-color: #15786d;
		border-color: #136e64;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #136e64;
		border-color: #11635a;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #1B998B;
		border-color: #1B998B;
	}
}
.btn-tertiary.active {
	color: #ffffff;
	background-color: #136e64;
	border-color: #11635a;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(61, 168, 156, 0.5);
	}
}
.btn-tertiary.disabled {
	color: #ffffff;
	background-color: #1B998B;
	border-color: #1B998B;
}
.btn-quaternary {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
	}
	&:focus {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
}
.btn-quaternary.active {
	color: #ffffff;
	background-color: #073482;
	border-color: #063076;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
}
.btn-quaternary.disabled {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
}
.btn-primary-app {
	color: #262B40;
	background-color: #EBF4F6;
	border-color: #EBF4F6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d1e5ea;
		border-color: #c8e1e6;
	}
	&:focus {
		color: #262B40;
		background-color: #d1e5ea;
		border-color: #c8e1e6;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #c8e1e6;
		border-color: #bfdce2;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #EBF4F6;
		border-color: #EBF4F6;
	}
}
.btn-primary-app.active {
	color: #262B40;
	background-color: #c8e1e6;
	border-color: #bfdce2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 214, 219, 0.5);
	}
}
.btn-primary-app.disabled {
	color: #262B40;
	background-color: #EBF4F6;
	border-color: #EBF4F6;
}
.btn-secondary-app {
	color: #ffffff;
	background-color: #424AA0;
	border-color: #424AA0;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #373d85;
		border-color: #33397c;
	}
	&:focus {
		color: #ffffff;
		background-color: #373d85;
		border-color: #33397c;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #33397c;
		border-color: #2f3573;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #424AA0;
		border-color: #424AA0;
	}
}
.btn-secondary-app.active {
	color: #ffffff;
	background-color: #33397c;
	border-color: #2f3573;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(94, 101, 174, 0.5);
	}
}
.btn-secondary-app.disabled {
	color: #ffffff;
	background-color: #424AA0;
	border-color: #424AA0;
}
.btn-light {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d2d9e3;
		border-color: #cbd2df;
	}
	&:focus {
		color: #262B40;
		background-color: #d2d9e3;
		border-color: #cbd2df;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #cbd2df;
		border-color: #c3cbda;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
	}
}
.btn-light.active {
	color: #262B40;
	background-color: #cbd2df;
	border-color: #c3cbda;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
	}
}
.btn-light.disabled {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
}
.btn-lighten {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d8e0e8;
		border-color: #d0dae3;
	}
	&:focus {
		color: #262B40;
		background-color: #d8e0e8;
		border-color: #d0dae3;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d0dae3;
		border-color: #c8d3de;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
	}
}
.btn-lighten.active {
	color: #262B40;
	background-color: #d0dae3;
	border-color: #c8d3de;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
	}
}
.btn-lighten.disabled {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
}
.btn-success {
	color: #ffffff;
	background-color: #05A677;
	border-color: #05A677;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #04815c;
		border-color: #047454;
	}
	&:focus {
		color: #ffffff;
		background-color: #04815c;
		border-color: #047454;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #047454;
		border-color: #03684b;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #05A677;
		border-color: #05A677;
	}
}
.btn-success.active {
	color: #ffffff;
	background-color: #047454;
	border-color: #03684b;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(43, 179, 139, 0.5);
	}
}
.btn-success.disabled {
	color: #ffffff;
	background-color: #05A677;
	border-color: #05A677;
}
.btn-info {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
	}
	&:focus {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
}
.btn-info.active {
	color: #ffffff;
	background-color: #073482;
	border-color: #063076;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
}
.btn-info.disabled {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
}
.btn-warning {
	color: #262B40;
	background-color: #f5b759;
	border-color: #f5b759;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #f3a735;
		border-color: #f2a229;
	}
	&:focus {
		color: #262B40;
		background-color: #f3a735;
		border-color: #f2a229;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f2a229;
		border-color: #f19d1d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #f5b759;
		border-color: #f5b759;
	}
}
.btn-warning.active {
	color: #262B40;
	background-color: #f2a229;
	border-color: #f19d1d;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 162, 85, 0.5);
	}
}
.btn-warning.disabled {
	color: #262B40;
	background-color: #f5b759;
	border-color: #f5b759;
}
.btn-danger {
	color: #ffffff;
	background-color: #FA5252;
	border-color: #FA5252;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #f92d2d;
		border-color: #f92020;
	}
	&:focus {
		color: #ffffff;
		background-color: #f92d2d;
		border-color: #f92020;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #f92020;
		border-color: #f81414;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #FA5252;
		border-color: #FA5252;
	}
}
.btn-danger.active {
	color: #ffffff;
	background-color: #f92020;
	border-color: #f81414;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(251, 108, 108, 0.5);
	}
}
.btn-danger.disabled {
	color: #ffffff;
	background-color: #FA5252;
	border-color: #FA5252;
}
.btn-white {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #ececec;
		border-color: #e6e6e6;
	}
	&:focus {
		color: #262B40;
		background-color: #ececec;
		border-color: #e6e6e6;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #e6e6e6;
		border-color: #dfdfdf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
	}
}
.btn-white.active {
	color: #262B40;
	background-color: #e6e6e6;
	border-color: #dfdfdf;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
	}
}
.btn-white.disabled {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-gray {
	color: #ffffff;
	background-color: #4A5073;
	border-color: #4A5073;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #3b405c;
		border-color: #363a54;
	}
	&:focus {
		color: #ffffff;
		background-color: #3b405c;
		border-color: #363a54;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #363a54;
		border-color: #31354c;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #4A5073;
		border-color: #4A5073;
	}
}
.btn-gray.active {
	color: #ffffff;
	background-color: #363a54;
	border-color: #31354c;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(101, 106, 136, 0.5);
	}
}
.btn-gray.disabled {
	color: #ffffff;
	background-color: #4A5073;
	border-color: #4A5073;
}
.btn-neutral {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #ececec;
		border-color: #e6e6e6;
	}
	&:focus {
		color: #262B40;
		background-color: #ececec;
		border-color: #e6e6e6;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #e6e6e6;
		border-color: #dfdfdf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
	}
}
.btn-neutral.active {
	color: #262B40;
	background-color: #e6e6e6;
	border-color: #dfdfdf;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(222, 223, 226, 0.5);
	}
}
.btn-neutral.disabled {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-dark {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #181b28;
		border-color: #131620;
	}
	&:focus {
		color: #ffffff;
		background-color: #181b28;
		border-color: #131620;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #131620;
		border-color: #0e1018;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
	}
}
.btn-dark.active {
	color: #ffffff;
	background-color: #131620;
	border-color: #0e1018;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(71, 75, 93, 0.5);
	}
}
.btn-dark.disabled {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
}
.btn-soft {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #dae5f0;
		border-color: #d1dfec;
	}
	&:focus {
		color: #262B40;
		background-color: #dae5f0;
		border-color: #d1dfec;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d1dfec;
		border-color: #c7d8e9;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
	}
}
.btn-soft.active {
	color: #262B40;
	background-color: #d1dfec;
	border-color: #c7d8e9;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
	}
}
.btn-soft.disabled {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
}
.btn-black {
	color: #ffffff;
	background-color: #2e3650;
	border-color: #2e3650;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #202638;
		border-color: #1b2030;
	}
	&:focus {
		color: #ffffff;
		background-color: #202638;
		border-color: #1b2030;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #1b2030;
		border-color: #171b28;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #2e3650;
		border-color: #2e3650;
	}
}
.btn-black.active {
	color: #ffffff;
	background-color: #1b2030;
	border-color: #171b28;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(77, 84, 106, 0.5);
	}
}
.btn-black.disabled {
	color: #ffffff;
	background-color: #2e3650;
	border-color: #2e3650;
}
.btn-blue {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
	}
	&:focus {
		color: #ffffff;
		background-color: #07398f;
		border-color: #073482;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #073482;
		border-color: #063076;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
}
.btn-blue.active {
	color: #ffffff;
	background-color: #073482;
	border-color: #063076;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 99, 190, 0.5);
	}
}
.btn-blue.disabled {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
}
.btn-pink {
	color: #ffffff;
	background-color: #C96480;
	border-color: #C96480;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #bf4869;
		border-color: #b94162;
	}
	&:focus {
		color: #ffffff;
		background-color: #bf4869;
		border-color: #b94162;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #b94162;
		border-color: #b03d5d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #C96480;
		border-color: #C96480;
	}
}
.btn-pink.active {
	color: #ffffff;
	background-color: #b94162;
	border-color: #b03d5d;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 123, 147, 0.5);
	}
}
.btn-pink.disabled {
	color: #ffffff;
	background-color: #C96480;
	border-color: #C96480;
}
.btn-cyan {
	color: #262B40;
	background-color: #63b1bd;
	border-color: #63b1bd;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #4aa2b0;
		border-color: #469aa7;
	}
	&:focus {
		color: #262B40;
		background-color: #4aa2b0;
		border-color: #469aa7;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #469aa7;
		border-color: #43919e;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #63b1bd;
		border-color: #63b1bd;
	}
}
.btn-cyan.active {
	color: #ffffff;
	background-color: #469aa7;
	border-color: #43919e;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(90, 157, 170, 0.5);
	}
}
.btn-cyan.disabled {
	color: #262B40;
	background-color: #63b1bd;
	border-color: #63b1bd;
}
.btn-purple {
	color: #ffffff;
	background-color: #8965e0;
	border-color: #8965e0;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #7145da;
		border-color: #683bd7;
	}
	&:focus {
		color: #ffffff;
		background-color: #7145da;
		border-color: #683bd7;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #683bd7;
		border-color: #6030d5;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #8965e0;
		border-color: #8965e0;
	}
}
.btn-purple.active {
	color: #ffffff;
	background-color: #683bd7;
	border-color: #6030d5;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(155, 124, 229, 0.5);
	}
}
.btn-purple.disabled {
	color: #ffffff;
	background-color: #8965e0;
	border-color: #8965e0;
}
.btn-soft-indigo {
	color: #262B40;
	background-color: #f5e8ff;
	border-color: #f5e8ff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #e4c2ff;
		border-color: #dfb5ff;
	}
	&:focus {
		color: #262B40;
		background-color: #e4c2ff;
		border-color: #dfb5ff;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #dfb5ff;
		border-color: #d9a8ff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #f5e8ff;
		border-color: #f5e8ff;
	}
}
.btn-soft-indigo.active {
	color: #262B40;
	background-color: #dfb5ff;
	border-color: #d9a8ff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 204, 226, 0.5);
	}
}
.btn-soft-indigo.disabled {
	color: #262B40;
	background-color: #f5e8ff;
	border-color: #f5e8ff;
}
.btn-soft-green {
	color: #ffffff;
	background-color: #2CA58D;
	border-color: #2CA58D;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #248773;
		border-color: #217d6b;
	}
	&:focus {
		color: #ffffff;
		background-color: #248773;
		border-color: #217d6b;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #217d6b;
		border-color: #1f7362;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #2CA58D;
		border-color: #2CA58D;
	}
}
.btn-soft-green.active {
	color: #ffffff;
	background-color: #217d6b;
	border-color: #1f7362;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(76, 179, 158, 0.5);
	}
}
.btn-soft-green.disabled {
	color: #ffffff;
	background-color: #2CA58D;
	border-color: #2CA58D;
}
.btn-gray-100 {
	color: #262B40;
	background-color: #f3f7fa;
	border-color: #f3f7fa;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d8e5ef;
		border-color: #cfdfeb;
	}
	&:focus {
		color: #262B40;
		background-color: #d8e5ef;
		border-color: #cfdfeb;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #cfdfeb;
		border-color: #c6d9e7;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #f3f7fa;
		border-color: #f3f7fa;
	}
}
.btn-gray-100.active {
	color: #262B40;
	background-color: #cfdfeb;
	border-color: #c6d9e7;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(212, 216, 222, 0.5);
	}
}
.btn-gray-100.disabled {
	color: #262B40;
	background-color: #f3f7fa;
	border-color: #f3f7fa;
}
.btn-gray-200 {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #dae5f0;
		border-color: #d1dfec;
	}
	&:focus {
		color: #262B40;
		background-color: #dae5f0;
		border-color: #d1dfec;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d1dfec;
		border-color: #c7d8e9;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
	}
}
.btn-gray-200.active {
	color: #262B40;
	background-color: #d1dfec;
	border-color: #c7d8e9;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(214, 217, 223, 0.5);
	}
}
.btn-gray-200.disabled {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
}
.btn-gray-300 {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d8e0e8;
		border-color: #d0dae3;
	}
	&:focus {
		color: #262B40;
		background-color: #d8e0e8;
		border-color: #d0dae3;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d0dae3;
		border-color: #c8d3de;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
	}
}
.btn-gray-300.active {
	color: #262B40;
	background-color: #d0dae3;
	border-color: #c8d3de;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
	}
}
.btn-gray-300.disabled {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
}
.btn-gray-400 {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #d2d9e3;
		border-color: #cbd2df;
	}
	&:focus {
		color: #262B40;
		background-color: #d2d9e3;
		border-color: #cbd2df;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #cbd2df;
		border-color: #c3cbda;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
	}
}
.btn-gray-400.active {
	color: #262B40;
	background-color: #cbd2df;
	border-color: #c3cbda;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 208, 215, 0.5);
	}
}
.btn-gray-400.disabled {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
}
.btn-gray-500 {
	color: #262B40;
	background-color: #d1d7e0;
	border-color: #d1d7e0;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #bac3d1;
		border-color: #b3bdcb;
	}
	&:focus {
		color: #262B40;
		background-color: #bac3d1;
		border-color: #b3bdcb;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #b3bdcb;
		border-color: #abb6c6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #d1d7e0;
		border-color: #d1d7e0;
	}
}
.btn-gray-500.active {
	color: #262B40;
	background-color: #b3bdcb;
	border-color: #abb6c6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(183, 189, 200, 0.5);
	}
}
.btn-gray-500.disabled {
	color: #262B40;
	background-color: #d1d7e0;
	border-color: #d1d7e0;
}
.btn-gray-600 {
	color: #262B40;
	background-color: #93a5be;
	border-color: #93a5be;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #7b91b0;
		border-color: #738aab;
	}
	&:focus {
		color: #ffffff;
		background-color: #7b91b0;
		border-color: #738aab;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #738aab;
		border-color: #6b84a6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #93a5be;
		border-color: #93a5be;
	}
}
.btn-gray-600.active {
	color: #ffffff;
	background-color: #738aab;
	border-color: #6b84a6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(131, 147, 171, 0.5);
	}
}
.btn-gray-600.disabled {
	color: #262B40;
	background-color: #93a5be;
	border-color: #93a5be;
}
.btn-gray-700 {
	color: #ffffff;
	background-color: #66799e;
	border-color: #66799e;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #566788;
		border-color: #516180;
	}
	&:focus {
		color: #ffffff;
		background-color: #566788;
		border-color: #516180;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #516180;
		border-color: #4c5b78;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #66799e;
		border-color: #66799e;
	}
}
.btn-gray-700.active {
	color: #ffffff;
	background-color: #516180;
	border-color: #4c5b78;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(125, 141, 173, 0.5);
	}
}
.btn-gray-700.disabled {
	color: #ffffff;
	background-color: #66799e;
	border-color: #66799e;
}
.btn-gray-800 {
	color: #ffffff;
	background-color: #506690;
	border-color: #506690;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #425577;
		border-color: #3e4f6f;
	}
	&:focus {
		color: #ffffff;
		background-color: #425577;
		border-color: #3e4f6f;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #3e4f6f;
		border-color: #394967;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #506690;
		border-color: #506690;
	}
}
.btn-gray-800.active {
	color: #ffffff;
	background-color: #3e4f6f;
	border-color: #394967;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(106, 125, 161, 0.5);
	}
}
.btn-gray-800.disabled {
	color: #ffffff;
	background-color: #506690;
	border-color: #506690;
}
.btn-facebook {
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #30497d;
		border-color: #2d4474;
		color: #ffffff;
		background-color: #30497d;
		border-color: #2d4474;
	}
	&:focus {
		color: #ffffff;
		background-color: #30497d;
		border-color: #2d4474;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
		color: #ffffff;
		background-color: #30497d;
		border-color: #2d4474;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #2d4474;
		border-color: #293e6b;
		color: #ffffff;
		background-color: #2d4474;
		border-color: #293e6b;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #3b5999;
		border-color: #3b5999;
		color: #ffffff;
		background-color: #3b5999;
		border-color: #3b5999;
	}
}
.btn-facebook.active {
	color: #ffffff;
	background-color: #2d4474;
	border-color: #293e6b;
	color: #ffffff;
	background-color: #2d4474;
	border-color: #293e6b;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(88, 114, 168, 0.5);
	}
}
.btn-facebook.disabled {
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
}
.btn-twitter {
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #0d8ddc;
		border-color: #0c85d0;
		color: #ffffff;
		background-color: #0d8ddc;
		border-color: #0c85d0;
	}
	&:focus {
		color: #ffffff;
		background-color: #0d8ddc;
		border-color: #0c85d0;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
		color: #ffffff;
		background-color: #0d8ddc;
		border-color: #0c85d0;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0c85d0;
		border-color: #0b7ec4;
		color: #ffffff;
		background-color: #0c85d0;
		border-color: #0b7ec4;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #1da1f2;
		border-color: #1da1f2;
		color: #262B40;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
}
.btn-twitter.active {
	color: #ffffff;
	background-color: #0c85d0;
	border-color: #0b7ec4;
	color: #ffffff;
	background-color: #0c85d0;
	border-color: #0b7ec4;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 143, 215, 0.5);
	}
}
.btn-twitter.disabled {
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
}
.btn-google {
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #c83024;
		border-color: #bd2d22;
		color: #ffffff;
		background-color: #c83024;
		border-color: #bd2d22;
	}
	&:focus {
		color: #ffffff;
		background-color: #c83024;
		border-color: #bd2d22;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
		color: #ffffff;
		background-color: #c83024;
		border-color: #bd2d22;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #bd2d22;
		border-color: #b22b20;
		color: #ffffff;
		background-color: #bd2d22;
		border-color: #b22b20;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #DB4337;
		border-color: #DB4337;
		color: #ffffff;
		background-color: #DB4337;
		border-color: #DB4337;
	}
}
.btn-google.active {
	color: #ffffff;
	background-color: #bd2d22;
	border-color: #b22b20;
	color: #ffffff;
	background-color: #bd2d22;
	border-color: #b22b20;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(224, 95, 85, 0.5);
	}
}
.btn-google.disabled {
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
}
.btn-instagram {
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #de1f44;
		border-color: #d31e40;
		color: #ffffff;
		background-color: #de1f44;
		border-color: #d31e40;
	}
	&:focus {
		color: #ffffff;
		background-color: #de1f44;
		border-color: #d31e40;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
		color: #ffffff;
		background-color: #de1f44;
		border-color: #d31e40;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #d31e40;
		border-color: #c81c3d;
		color: #ffffff;
		background-color: #d31e40;
		border-color: #c81c3d;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #e4405f;
		border-color: #e4405f;
		color: #ffffff;
		background-color: #e4405f;
		border-color: #e4405f;
	}
}
.btn-instagram.active {
	color: #ffffff;
	background-color: #d31e40;
	border-color: #c81c3d;
	color: #ffffff;
	background-color: #d31e40;
	border-color: #c81c3d;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 93, 119, 0.5);
	}
}
.btn-instagram.disabled {
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
}
.btn-pinterest {
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #980617;
		border-color: #8c0615;
		color: #ffffff;
		background-color: #980617;
		border-color: #8c0615;
	}
	&:focus {
		color: #ffffff;
		background-color: #980617;
		border-color: #8c0615;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
		color: #ffffff;
		background-color: #980617;
		border-color: #8c0615;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #8c0615;
		border-color: #800513;
		color: #ffffff;
		background-color: #8c0615;
		border-color: #800513;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #bd081c;
		border-color: #bd081c;
		color: #ffffff;
		background-color: #bd081c;
		border-color: #bd081c;
	}
}
.btn-pinterest.active {
	color: #ffffff;
	background-color: #8c0615;
	border-color: #800513;
	color: #ffffff;
	background-color: #8c0615;
	border-color: #800513;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(199, 45, 62, 0.5);
	}
}
.btn-pinterest.disabled {
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
}
.btn-youtube {
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #ac1b1a;
		border-color: #a11918;
		color: #ffffff;
		background-color: #ac1b1a;
		border-color: #a11918;
	}
	&:focus {
		color: #ffffff;
		background-color: #ac1b1a;
		border-color: #a11918;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
		color: #ffffff;
		background-color: #ac1b1a;
		border-color: #a11918;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #a11918;
		border-color: #961717;
		color: #ffffff;
		background-color: #a11918;
		border-color: #961717;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #cd201f;
		border-color: #cd201f;
		color: #ffffff;
		background-color: #cd201f;
		border-color: #cd201f;
	}
}
.btn-youtube.active {
	color: #ffffff;
	background-color: #a11918;
	border-color: #961717;
	color: #ffffff;
	background-color: #a11918;
	border-color: #961717;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(213, 65, 65, 0.5);
	}
}
.btn-youtube.disabled {
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
}
.btn-slack {
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #30926f;
		border-color: #2d8968;
		color: #ffffff;
		background-color: #30926f;
		border-color: #2d8968;
	}
	&:focus {
		color: #ffffff;
		background-color: #30926f;
		border-color: #2d8968;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
		color: #ffffff;
		background-color: #30926f;
		border-color: #2d8968;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #2d8968;
		border-color: #2a7f61;
		color: #ffffff;
		background-color: #2d8968;
		border-color: #2a7f61;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #3aaf85;
		border-color: #3aaf85;
		color: #262B40;
		background-color: #3aaf85;
		border-color: #3aaf85;
	}
}
.btn-slack.active {
	color: #ffffff;
	background-color: #2d8968;
	border-color: #2a7f61;
	color: #ffffff;
	background-color: #2d8968;
	border-color: #2a7f61;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(55, 155, 123, 0.5);
	}
}
.btn-slack.disabled {
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
}
.btn-dribbble {
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #e62a72;
		border-color: #e51e6b;
		color: #ffffff;
		background-color: #e62a72;
		border-color: #e51e6b;
	}
	&:focus {
		color: #ffffff;
		background-color: #e62a72;
		border-color: #e51e6b;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
		color: #ffffff;
		background-color: #e62a72;
		border-color: #e51e6b;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #e51e6b;
		border-color: #dc1a65;
		color: #ffffff;
		background-color: #e51e6b;
		border-color: #dc1a65;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #ea4c89;
		border-color: #ea4c89;
		color: #ffffff;
		background-color: #ea4c89;
		border-color: #ea4c89;
	}
}
.btn-dribbble.active {
	color: #ffffff;
	background-color: #e51e6b;
	border-color: #dc1a65;
	color: #ffffff;
	background-color: #e51e6b;
	border-color: #dc1a65;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(237, 103, 155, 0.5);
	}
}
.btn-dribbble.disabled {
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
}
.btn-dropbox {
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #007df7;
		border-color: #0077ea;
		color: #ffffff;
		background-color: #007df7;
		border-color: #0077ea;
	}
	&:focus {
		color: #ffffff;
		background-color: #007df7;
		border-color: #0077ea;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
		color: #ffffff;
		background-color: #007df7;
		border-color: #0077ea;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0077ea;
		border-color: #0070dd;
		color: #ffffff;
		background-color: #0077ea;
		border-color: #0070dd;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #1E90FF;
		border-color: #1E90FF;
		color: #ffffff;
		background-color: #1E90FF;
		border-color: #1E90FF;
	}
}
.btn-dropbox.active {
	color: #ffffff;
	background-color: #0077ea;
	border-color: #0070dd;
	color: #ffffff;
	background-color: #0077ea;
	border-color: #0070dd;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(64, 161, 255, 0.5);
	}
}
.btn-dropbox.disabled {
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
}
.btn-twitch {
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #3b2a61;
		border-color: #362758;
		color: #ffffff;
		background-color: #3b2a61;
		border-color: #362758;
	}
	&:focus {
		color: #ffffff;
		background-color: #3b2a61;
		border-color: #362758;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
		color: #ffffff;
		background-color: #3b2a61;
		border-color: #362758;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #362758;
		border-color: #302350;
		color: #ffffff;
		background-color: #362758;
		border-color: #302350;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #4B367C;
		border-color: #4B367C;
		color: #ffffff;
		background-color: #4B367C;
		border-color: #4B367C;
	}
}
.btn-twitch.active {
	color: #ffffff;
	background-color: #362758;
	border-color: #302350;
	color: #ffffff;
	background-color: #362758;
	border-color: #302350;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 84, 144, 0.5);
	}
}
.btn-twitch.disabled {
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
}
.btn-paypal {
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #262B40;
		background-color: #dea214;
		border-color: #d29913;
		color: #262B40;
		background-color: #dea214;
		border-color: #d29913;
	}
	&:focus {
		color: #262B40;
		background-color: #dea214;
		border-color: #d29913;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
		color: #262B40;
		background-color: #dea214;
		border-color: #d29913;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d29913;
		border-color: #c69112;
		color: #262B40;
		background-color: #d29913;
		border-color: #c69112;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: #ecb32c;
		border-color: #ecb32c;
		color: #262B40;
		background-color: #ecb32c;
		border-color: #ecb32c;
	}
}
.btn-paypal.active {
	color: #262B40;
	background-color: #d29913;
	border-color: #c69112;
	color: #262B40;
	background-color: #d29913;
	border-color: #c69112;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(206, 159, 47, 0.5);
	}
}
.btn-paypal.disabled {
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
}
.btn-behance {
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #004ad9;
		border-color: #0046cc;
		color: #ffffff;
		background-color: #004ad9;
		border-color: #0046cc;
	}
	&:focus {
		color: #ffffff;
		background-color: #004ad9;
		border-color: #0046cc;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
		color: #ffffff;
		background-color: #004ad9;
		border-color: #0046cc;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0046cc;
		border-color: #0041bf;
		color: #ffffff;
		background-color: #0046cc;
		border-color: #0041bf;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #0057ff;
		border-color: #0057ff;
		color: #ffffff;
		background-color: #0057ff;
		border-color: #0057ff;
	}
}
.btn-behance.active {
	color: #ffffff;
	background-color: #0046cc;
	border-color: #0041bf;
	color: #ffffff;
	background-color: #0046cc;
	border-color: #0041bf;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
	}
}
.btn-behance.disabled {
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
}
.btn-reddit {
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #ce3515;
		border-color: #c33214;
		color: #ffffff;
		background-color: #ce3515;
		border-color: #c33214;
	}
	&:focus {
		color: #ffffff;
		background-color: #ce3515;
		border-color: #c33214;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
		color: #ffffff;
		background-color: #ce3515;
		border-color: #c33214;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #c33214;
		border-color: #b72f13;
		color: #ffffff;
		background-color: #c33214;
		border-color: #b72f13;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #E84422;
		border-color: #E84422;
		color: #ffffff;
		background-color: #E84422;
		border-color: #E84422;
	}
}
.btn-reddit.active {
	color: #ffffff;
	background-color: #c33214;
	border-color: #b72f13;
	color: #ffffff;
	background-color: #c33214;
	border-color: #b72f13;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 96, 67, 0.5);
	}
}
.btn-reddit.disabled {
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
}
.btn-github {
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075);
	&:hover {
		color: #ffffff;
		background-color: #0f0f0f;
		border-color: #090909;
		color: #ffffff;
		background-color: #0f0f0f;
		border-color: #090909;
	}
	&:focus {
		color: #ffffff;
		background-color: #0f0f0f;
		border-color: #090909;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
		color: #ffffff;
		background-color: #0f0f0f;
		border-color: #090909;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #090909;
		border-color: #020202;
		color: #ffffff;
		background-color: #090909;
		border-color: #020202;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: #222222;
		border-color: #222222;
		color: #ffffff;
		background-color: #222222;
		border-color: #222222;
	}
}
.btn-github.active {
	color: #ffffff;
	background-color: #090909;
	border-color: #020202;
	color: #ffffff;
	background-color: #090909;
	border-color: #020202;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
	}
}
.btn-github.disabled {
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
}
.btn-outline-primary {
	color: #262B40;
	border-color: #262B40;
	&:hover {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: transparent;
	}
}
.btn-outline-primary.active {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
}
.btn-outline-primary.dropdown-toggle.show {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
}
.btn-outline-primary.disabled {
	color: #262B40;
	background-color: transparent;
}
.btn-outline-secondary {
	color: #F8BD7A;
	border-color: #F8BD7A;
	&:hover {
		color: #262B40;
		background-color: #F8BD7A;
		border-color: #F8BD7A;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #F8BD7A;
		border-color: #F8BD7A;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
		}
	}
	&:disabled {
		color: #F8BD7A;
		background-color: transparent;
	}
}
.btn-outline-secondary.active {
	color: #262B40;
	background-color: #F8BD7A;
	border-color: #F8BD7A;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
	}
}
.btn-outline-secondary.dropdown-toggle.show {
	color: #262B40;
	background-color: #F8BD7A;
	border-color: #F8BD7A;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(248, 189, 122, 0.5);
	}
}
.btn-outline-secondary.disabled {
	color: #F8BD7A;
	background-color: transparent;
}
.btn-outline-tertiary {
	color: #1B998B;
	border-color: #1B998B;
	&:hover {
		color: #ffffff;
		background-color: #1B998B;
		border-color: #1B998B;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #1B998B;
		border-color: #1B998B;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
		}
	}
	&:disabled {
		color: #1B998B;
		background-color: transparent;
	}
}
.btn-outline-tertiary.active {
	color: #ffffff;
	background-color: #1B998B;
	border-color: #1B998B;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
	}
}
.btn-outline-tertiary.dropdown-toggle.show {
	color: #ffffff;
	background-color: #1B998B;
	border-color: #1B998B;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
	}
}
.btn-outline-tertiary.disabled {
	color: #1B998B;
	background-color: transparent;
}
.btn-outline-quaternary {
	color: #0948B3;
	border-color: #0948B3;
	&:hover {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
		}
	}
	&:disabled {
		color: #0948B3;
		background-color: transparent;
	}
}
.btn-outline-quaternary.active {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-quaternary.dropdown-toggle.show {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-quaternary.disabled {
	color: #0948B3;
	background-color: transparent;
}
.btn-outline-primary-app {
	color: #EBF4F6;
	border-color: #EBF4F6;
	&:hover {
		color: #262B40;
		background-color: #EBF4F6;
		border-color: #EBF4F6;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #EBF4F6;
		border-color: #EBF4F6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
		}
	}
	&:disabled {
		color: #EBF4F6;
		background-color: transparent;
	}
}
.btn-outline-primary-app.active {
	color: #262B40;
	background-color: #EBF4F6;
	border-color: #EBF4F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
	}
}
.btn-outline-primary-app.dropdown-toggle.show {
	color: #262B40;
	background-color: #EBF4F6;
	border-color: #EBF4F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
	}
}
.btn-outline-primary-app.disabled {
	color: #EBF4F6;
	background-color: transparent;
}
.btn-outline-secondary-app {
	color: #424AA0;
	border-color: #424AA0;
	&:hover {
		color: #ffffff;
		background-color: #424AA0;
		border-color: #424AA0;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #424AA0;
		border-color: #424AA0;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
		}
	}
	&:disabled {
		color: #424AA0;
		background-color: transparent;
	}
}
.btn-outline-secondary-app.active {
	color: #ffffff;
	background-color: #424AA0;
	border-color: #424AA0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
	}
}
.btn-outline-secondary-app.dropdown-toggle.show {
	color: #ffffff;
	background-color: #424AA0;
	border-color: #424AA0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
	}
}
.btn-outline-secondary-app.disabled {
	color: #424AA0;
	background-color: transparent;
}
.btn-outline-light {
	color: #eaedf2;
	border-color: #eaedf2;
	&:hover {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
		}
	}
	&:disabled {
		color: #eaedf2;
		background-color: transparent;
	}
}
.btn-outline-light.active {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
}
.btn-outline-light.dropdown-toggle.show {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
}
.btn-outline-light.disabled {
	color: #eaedf2;
	background-color: transparent;
}
.btn-outline-lighten {
	color: #F0F3F6;
	border-color: #F0F3F6;
	&:hover {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
		}
	}
	&:disabled {
		color: #F0F3F6;
		background-color: transparent;
	}
}
.btn-outline-lighten.active {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
}
.btn-outline-lighten.dropdown-toggle.show {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
}
.btn-outline-lighten.disabled {
	color: #F0F3F6;
	background-color: transparent;
}
.btn-outline-success {
	color: #05A677;
	border-color: #05A677;
	&:hover {
		color: #ffffff;
		background-color: #05A677;
		border-color: #05A677;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #05A677;
		border-color: #05A677;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
		}
	}
	&:disabled {
		color: #05A677;
		background-color: transparent;
	}
}
.btn-outline-success.active {
	color: #ffffff;
	background-color: #05A677;
	border-color: #05A677;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
	}
}
.btn-outline-success.dropdown-toggle.show {
	color: #ffffff;
	background-color: #05A677;
	border-color: #05A677;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
	}
}
.btn-outline-success.disabled {
	color: #05A677;
	background-color: transparent;
}
.btn-outline-info {
	color: #0948B3;
	border-color: #0948B3;
	&:hover {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
		}
	}
	&:disabled {
		color: #0948B3;
		background-color: transparent;
	}
}
.btn-outline-info.active {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-info.dropdown-toggle.show {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-info.disabled {
	color: #0948B3;
	background-color: transparent;
}
.btn-outline-warning {
	color: #f5b759;
	border-color: #f5b759;
	&:hover {
		color: #262B40;
		background-color: #f5b759;
		border-color: #f5b759;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f5b759;
		border-color: #f5b759;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
		}
	}
	&:disabled {
		color: #f5b759;
		background-color: transparent;
	}
}
.btn-outline-warning.active {
	color: #262B40;
	background-color: #f5b759;
	border-color: #f5b759;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
	}
}
.btn-outline-warning.dropdown-toggle.show {
	color: #262B40;
	background-color: #f5b759;
	border-color: #f5b759;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
	}
}
.btn-outline-warning.disabled {
	color: #f5b759;
	background-color: transparent;
}
.btn-outline-danger {
	color: #FA5252;
	border-color: #FA5252;
	&:hover {
		color: #ffffff;
		background-color: #FA5252;
		border-color: #FA5252;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #FA5252;
		border-color: #FA5252;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
		}
	}
	&:disabled {
		color: #FA5252;
		background-color: transparent;
	}
}
.btn-outline-danger.active {
	color: #ffffff;
	background-color: #FA5252;
	border-color: #FA5252;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
	}
}
.btn-outline-danger.dropdown-toggle.show {
	color: #ffffff;
	background-color: #FA5252;
	border-color: #FA5252;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
	}
}
.btn-outline-danger.disabled {
	color: #FA5252;
	background-color: transparent;
}
.btn-outline-white {
	color: #ffffff;
	border-color: #ffffff;
	&:hover {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: transparent;
	}
}
.btn-outline-white.active {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
}
.btn-outline-white.dropdown-toggle.show {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
}
.btn-outline-white.disabled {
	color: #ffffff;
	background-color: transparent;
}
.btn-outline-gray {
	color: #4A5073;
	border-color: #4A5073;
	&:hover {
		color: #ffffff;
		background-color: #4A5073;
		border-color: #4A5073;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #4A5073;
		border-color: #4A5073;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
		}
	}
	&:disabled {
		color: #4A5073;
		background-color: transparent;
	}
}
.btn-outline-gray.active {
	color: #ffffff;
	background-color: #4A5073;
	border-color: #4A5073;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
	}
}
.btn-outline-gray.dropdown-toggle.show {
	color: #ffffff;
	background-color: #4A5073;
	border-color: #4A5073;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
	}
}
.btn-outline-gray.disabled {
	color: #4A5073;
	background-color: transparent;
}
.btn-outline-neutral {
	color: #ffffff;
	border-color: #ffffff;
	&:hover {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #ffffff;
		border-color: #ffffff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
		}
	}
	&:disabled {
		color: #ffffff;
		background-color: transparent;
	}
}
.btn-outline-neutral.active {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
}
.btn-outline-neutral.dropdown-toggle.show {
	color: #262B40;
	background-color: #ffffff;
	border-color: #ffffff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
	}
}
.btn-outline-neutral.disabled {
	color: #ffffff;
	background-color: transparent;
}
.btn-outline-dark {
	color: #262B40;
	border-color: #262B40;
	&:hover {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
		}
	}
	&:disabled {
		color: #262B40;
		background-color: transparent;
	}
}
.btn-outline-dark.active {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
}
.btn-outline-dark.dropdown-toggle.show {
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
	}
}
.btn-outline-dark.disabled {
	color: #262B40;
	background-color: transparent;
}
.btn-outline-soft {
	color: #f5f8fb;
	border-color: #f5f8fb;
	&:hover {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
		}
	}
	&:disabled {
		color: #f5f8fb;
		background-color: transparent;
	}
}
.btn-outline-soft.active {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
}
.btn-outline-soft.dropdown-toggle.show {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
}
.btn-outline-soft.disabled {
	color: #f5f8fb;
	background-color: transparent;
}
.btn-outline-black {
	color: #2e3650;
	border-color: #2e3650;
	&:hover {
		color: #ffffff;
		background-color: #2e3650;
		border-color: #2e3650;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #2e3650;
		border-color: #2e3650;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
		}
	}
	&:disabled {
		color: #2e3650;
		background-color: transparent;
	}
}
.btn-outline-black.active {
	color: #ffffff;
	background-color: #2e3650;
	border-color: #2e3650;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
	}
}
.btn-outline-black.dropdown-toggle.show {
	color: #ffffff;
	background-color: #2e3650;
	border-color: #2e3650;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
	}
}
.btn-outline-black.disabled {
	color: #2e3650;
	background-color: transparent;
}
.btn-outline-blue {
	color: #0948B3;
	border-color: #0948B3;
	&:hover {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0948B3;
		border-color: #0948B3;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
		}
	}
	&:disabled {
		color: #0948B3;
		background-color: transparent;
	}
}
.btn-outline-blue.active {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-blue.dropdown-toggle.show {
	color: #ffffff;
	background-color: #0948B3;
	border-color: #0948B3;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
	}
}
.btn-outline-blue.disabled {
	color: #0948B3;
	background-color: transparent;
}
.btn-outline-pink {
	color: #C96480;
	border-color: #C96480;
	&:hover {
		color: #ffffff;
		background-color: #C96480;
		border-color: #C96480;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #C96480;
		border-color: #C96480;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
		}
	}
	&:disabled {
		color: #C96480;
		background-color: transparent;
	}
}
.btn-outline-pink.active {
	color: #ffffff;
	background-color: #C96480;
	border-color: #C96480;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
	}
}
.btn-outline-pink.dropdown-toggle.show {
	color: #ffffff;
	background-color: #C96480;
	border-color: #C96480;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
	}
}
.btn-outline-pink.disabled {
	color: #C96480;
	background-color: transparent;
}
.btn-outline-cyan {
	color: #63b1bd;
	border-color: #63b1bd;
	&:hover {
		color: #262B40;
		background-color: #63b1bd;
		border-color: #63b1bd;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #63b1bd;
		border-color: #63b1bd;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
		}
	}
	&:disabled {
		color: #63b1bd;
		background-color: transparent;
	}
}
.btn-outline-cyan.active {
	color: #262B40;
	background-color: #63b1bd;
	border-color: #63b1bd;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
	}
}
.btn-outline-cyan.dropdown-toggle.show {
	color: #262B40;
	background-color: #63b1bd;
	border-color: #63b1bd;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
	}
}
.btn-outline-cyan.disabled {
	color: #63b1bd;
	background-color: transparent;
}
.btn-outline-purple {
	color: #8965e0;
	border-color: #8965e0;
	&:hover {
		color: #ffffff;
		background-color: #8965e0;
		border-color: #8965e0;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #8965e0;
		border-color: #8965e0;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
		}
	}
	&:disabled {
		color: #8965e0;
		background-color: transparent;
	}
}
.btn-outline-purple.active {
	color: #ffffff;
	background-color: #8965e0;
	border-color: #8965e0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
	}
}
.btn-outline-purple.dropdown-toggle.show {
	color: #ffffff;
	background-color: #8965e0;
	border-color: #8965e0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
	}
}
.btn-outline-purple.disabled {
	color: #8965e0;
	background-color: transparent;
}
.btn-outline-soft-indigo {
	color: #f5e8ff;
	border-color: #f5e8ff;
	&:hover {
		color: #262B40;
		background-color: #f5e8ff;
		border-color: #f5e8ff;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f5e8ff;
		border-color: #f5e8ff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
		}
	}
	&:disabled {
		color: #f5e8ff;
		background-color: transparent;
	}
}
.btn-outline-soft-indigo.active {
	color: #262B40;
	background-color: #f5e8ff;
	border-color: #f5e8ff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
	}
}
.btn-outline-soft-indigo.dropdown-toggle.show {
	color: #262B40;
	background-color: #f5e8ff;
	border-color: #f5e8ff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
	}
}
.btn-outline-soft-indigo.disabled {
	color: #f5e8ff;
	background-color: transparent;
}
.btn-outline-soft-green {
	color: #2CA58D;
	border-color: #2CA58D;
	&:hover {
		color: #ffffff;
		background-color: #2CA58D;
		border-color: #2CA58D;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #2CA58D;
		border-color: #2CA58D;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
		}
	}
	&:disabled {
		color: #2CA58D;
		background-color: transparent;
	}
}
.btn-outline-soft-green.active {
	color: #ffffff;
	background-color: #2CA58D;
	border-color: #2CA58D;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
	}
}
.btn-outline-soft-green.dropdown-toggle.show {
	color: #ffffff;
	background-color: #2CA58D;
	border-color: #2CA58D;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
	}
}
.btn-outline-soft-green.disabled {
	color: #2CA58D;
	background-color: transparent;
}
.btn-outline-gray-100 {
	color: #f3f7fa;
	border-color: #f3f7fa;
	&:hover {
		color: #262B40;
		background-color: #f3f7fa;
		border-color: #f3f7fa;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f3f7fa;
		border-color: #f3f7fa;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
		}
	}
	&:disabled {
		color: #f3f7fa;
		background-color: transparent;
	}
}
.btn-outline-gray-100.active {
	color: #262B40;
	background-color: #f3f7fa;
	border-color: #f3f7fa;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
	}
}
.btn-outline-gray-100.dropdown-toggle.show {
	color: #262B40;
	background-color: #f3f7fa;
	border-color: #f3f7fa;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
	}
}
.btn-outline-gray-100.disabled {
	color: #f3f7fa;
	background-color: transparent;
}
.btn-outline-gray-200 {
	color: #f5f8fb;
	border-color: #f5f8fb;
	&:hover {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #f5f8fb;
		border-color: #f5f8fb;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
		}
	}
	&:disabled {
		color: #f5f8fb;
		background-color: transparent;
	}
}
.btn-outline-gray-200.active {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
}
.btn-outline-gray-200.dropdown-toggle.show {
	color: #262B40;
	background-color: #f5f8fb;
	border-color: #f5f8fb;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
	}
}
.btn-outline-gray-200.disabled {
	color: #f5f8fb;
	background-color: transparent;
}
.btn-outline-gray-300 {
	color: #F0F3F6;
	border-color: #F0F3F6;
	&:hover {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #F0F3F6;
		border-color: #F0F3F6;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
		}
	}
	&:disabled {
		color: #F0F3F6;
		background-color: transparent;
	}
}
.btn-outline-gray-300.active {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
}
.btn-outline-gray-300.dropdown-toggle.show {
	color: #262B40;
	background-color: #F0F3F6;
	border-color: #F0F3F6;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
	}
}
.btn-outline-gray-300.disabled {
	color: #F0F3F6;
	background-color: transparent;
}
.btn-outline-gray-400 {
	color: #eaedf2;
	border-color: #eaedf2;
	&:hover {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #eaedf2;
		border-color: #eaedf2;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
		}
	}
	&:disabled {
		color: #eaedf2;
		background-color: transparent;
	}
}
.btn-outline-gray-400.active {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
}
.btn-outline-gray-400.dropdown-toggle.show {
	color: #262B40;
	background-color: #eaedf2;
	border-color: #eaedf2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
	}
}
.btn-outline-gray-400.disabled {
	color: #eaedf2;
	background-color: transparent;
}
.btn-outline-gray-500 {
	color: #d1d7e0;
	border-color: #d1d7e0;
	&:hover {
		color: #262B40;
		background-color: #d1d7e0;
		border-color: #d1d7e0;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #d1d7e0;
		border-color: #d1d7e0;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
		}
	}
	&:disabled {
		color: #d1d7e0;
		background-color: transparent;
	}
}
.btn-outline-gray-500.active {
	color: #262B40;
	background-color: #d1d7e0;
	border-color: #d1d7e0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
	}
}
.btn-outline-gray-500.dropdown-toggle.show {
	color: #262B40;
	background-color: #d1d7e0;
	border-color: #d1d7e0;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
	}
}
.btn-outline-gray-500.disabled {
	color: #d1d7e0;
	background-color: transparent;
}
.btn-outline-gray-600 {
	color: #93a5be;
	border-color: #93a5be;
	&:hover {
		color: #262B40;
		background-color: #93a5be;
		border-color: #93a5be;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #93a5be;
		border-color: #93a5be;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
		}
	}
	&:disabled {
		color: #93a5be;
		background-color: transparent;
	}
}
.btn-outline-gray-600.active {
	color: #262B40;
	background-color: #93a5be;
	border-color: #93a5be;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
	}
}
.btn-outline-gray-600.dropdown-toggle.show {
	color: #262B40;
	background-color: #93a5be;
	border-color: #93a5be;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
	}
}
.btn-outline-gray-600.disabled {
	color: #93a5be;
	background-color: transparent;
}
.btn-outline-gray-700 {
	color: #66799e;
	border-color: #66799e;
	&:hover {
		color: #ffffff;
		background-color: #66799e;
		border-color: #66799e;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #66799e;
		border-color: #66799e;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
		}
	}
	&:disabled {
		color: #66799e;
		background-color: transparent;
	}
}
.btn-outline-gray-700.active {
	color: #ffffff;
	background-color: #66799e;
	border-color: #66799e;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
	}
}
.btn-outline-gray-700.dropdown-toggle.show {
	color: #ffffff;
	background-color: #66799e;
	border-color: #66799e;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
	}
}
.btn-outline-gray-700.disabled {
	color: #66799e;
	background-color: transparent;
}
.btn-outline-gray-800 {
	color: #506690;
	border-color: #506690;
	&:hover {
		color: #ffffff;
		background-color: #506690;
		border-color: #506690;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #506690;
		border-color: #506690;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
		}
	}
	&:disabled {
		color: #506690;
		background-color: transparent;
	}
}
.btn-outline-gray-800.active {
	color: #ffffff;
	background-color: #506690;
	border-color: #506690;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
	}
}
.btn-outline-gray-800.dropdown-toggle.show {
	color: #ffffff;
	background-color: #506690;
	border-color: #506690;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
	}
}
.btn-outline-gray-800.disabled {
	color: #506690;
	background-color: transparent;
}
.btn-outline-facebook {
	color: #3b5999;
	border-color: #3b5999;
	&:hover {
		color: #ffffff;
		background-color: #3b5999;
		border-color: #3b5999;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #3b5999;
		border-color: #3b5999;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
		}
	}
	&:disabled {
		color: #3b5999;
		background-color: transparent;
	}
}
.btn-outline-facebook.active {
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
	}
}
.btn-outline-facebook.dropdown-toggle.show {
	color: #ffffff;
	background-color: #3b5999;
	border-color: #3b5999;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
	}
}
.btn-outline-facebook.disabled {
	color: #3b5999;
	background-color: transparent;
}
.btn-outline-twitter {
	color: #1da1f2;
	border-color: #1da1f2;
	&:hover {
		color: #262B40;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #1da1f2;
		border-color: #1da1f2;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
		}
	}
	&:disabled {
		color: #1da1f2;
		background-color: transparent;
	}
}
.btn-outline-twitter.active {
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
	}
}
.btn-outline-twitter.dropdown-toggle.show {
	color: #262B40;
	background-color: #1da1f2;
	border-color: #1da1f2;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
	}
}
.btn-outline-twitter.disabled {
	color: #1da1f2;
	background-color: transparent;
}
.btn-outline-google {
	color: #DB4337;
	border-color: #DB4337;
	&:hover {
		color: #ffffff;
		background-color: #DB4337;
		border-color: #DB4337;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #DB4337;
		border-color: #DB4337;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
		}
	}
	&:disabled {
		color: #DB4337;
		background-color: transparent;
	}
}
.btn-outline-google.active {
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
	}
}
.btn-outline-google.dropdown-toggle.show {
	color: #ffffff;
	background-color: #DB4337;
	border-color: #DB4337;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
	}
}
.btn-outline-google.disabled {
	color: #DB4337;
	background-color: transparent;
}
.btn-outline-instagram {
	color: #e4405f;
	border-color: #e4405f;
	&:hover {
		color: #ffffff;
		background-color: #e4405f;
		border-color: #e4405f;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #e4405f;
		border-color: #e4405f;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
		}
	}
	&:disabled {
		color: #e4405f;
		background-color: transparent;
	}
}
.btn-outline-instagram.active {
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
	}
}
.btn-outline-instagram.dropdown-toggle.show {
	color: #ffffff;
	background-color: #e4405f;
	border-color: #e4405f;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
	}
}
.btn-outline-instagram.disabled {
	color: #e4405f;
	background-color: transparent;
}
.btn-outline-pinterest {
	color: #bd081c;
	border-color: #bd081c;
	&:hover {
		color: #ffffff;
		background-color: #bd081c;
		border-color: #bd081c;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #bd081c;
		border-color: #bd081c;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
		}
	}
	&:disabled {
		color: #bd081c;
		background-color: transparent;
	}
}
.btn-outline-pinterest.active {
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
	}
}
.btn-outline-pinterest.dropdown-toggle.show {
	color: #ffffff;
	background-color: #bd081c;
	border-color: #bd081c;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
	}
}
.btn-outline-pinterest.disabled {
	color: #bd081c;
	background-color: transparent;
}
.btn-outline-youtube {
	color: #cd201f;
	border-color: #cd201f;
	&:hover {
		color: #ffffff;
		background-color: #cd201f;
		border-color: #cd201f;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #cd201f;
		border-color: #cd201f;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
		}
	}
	&:disabled {
		color: #cd201f;
		background-color: transparent;
	}
}
.btn-outline-youtube.active {
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
	}
}
.btn-outline-youtube.dropdown-toggle.show {
	color: #ffffff;
	background-color: #cd201f;
	border-color: #cd201f;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
	}
}
.btn-outline-youtube.disabled {
	color: #cd201f;
	background-color: transparent;
}
.btn-outline-slack {
	color: #3aaf85;
	border-color: #3aaf85;
	&:hover {
		color: #262B40;
		background-color: #3aaf85;
		border-color: #3aaf85;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #3aaf85;
		border-color: #3aaf85;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
		}
	}
	&:disabled {
		color: #3aaf85;
		background-color: transparent;
	}
}
.btn-outline-slack.active {
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
	}
}
.btn-outline-slack.dropdown-toggle.show {
	color: #262B40;
	background-color: #3aaf85;
	border-color: #3aaf85;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
	}
}
.btn-outline-slack.disabled {
	color: #3aaf85;
	background-color: transparent;
}
.btn-outline-dribbble {
	color: #ea4c89;
	border-color: #ea4c89;
	&:hover {
		color: #ffffff;
		background-color: #ea4c89;
		border-color: #ea4c89;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #ea4c89;
		border-color: #ea4c89;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
		}
	}
	&:disabled {
		color: #ea4c89;
		background-color: transparent;
	}
}
.btn-outline-dribbble.active {
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
	}
}
.btn-outline-dribbble.dropdown-toggle.show {
	color: #ffffff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
	}
}
.btn-outline-dribbble.disabled {
	color: #ea4c89;
	background-color: transparent;
}
.btn-outline-dropbox {
	color: #1E90FF;
	border-color: #1E90FF;
	&:hover {
		color: #ffffff;
		background-color: #1E90FF;
		border-color: #1E90FF;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #1E90FF;
		border-color: #1E90FF;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
		}
	}
	&:disabled {
		color: #1E90FF;
		background-color: transparent;
	}
}
.btn-outline-dropbox.active {
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
	}
}
.btn-outline-dropbox.dropdown-toggle.show {
	color: #ffffff;
	background-color: #1E90FF;
	border-color: #1E90FF;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
	}
}
.btn-outline-dropbox.disabled {
	color: #1E90FF;
	background-color: transparent;
}
.btn-outline-twitch {
	color: #4B367C;
	border-color: #4B367C;
	&:hover {
		color: #ffffff;
		background-color: #4B367C;
		border-color: #4B367C;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #4B367C;
		border-color: #4B367C;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
		}
	}
	&:disabled {
		color: #4B367C;
		background-color: transparent;
	}
}
.btn-outline-twitch.active {
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
	}
}
.btn-outline-twitch.dropdown-toggle.show {
	color: #ffffff;
	background-color: #4B367C;
	border-color: #4B367C;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
	}
}
.btn-outline-twitch.disabled {
	color: #4B367C;
	background-color: transparent;
}
.btn-outline-paypal {
	color: #ecb32c;
	border-color: #ecb32c;
	&:hover {
		color: #262B40;
		background-color: #ecb32c;
		border-color: #ecb32c;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
	}
	&:active {
		color: #262B40;
		background-color: #ecb32c;
		border-color: #ecb32c;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
		}
	}
	&:disabled {
		color: #ecb32c;
		background-color: transparent;
	}
}
.btn-outline-paypal.active {
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
	}
}
.btn-outline-paypal.dropdown-toggle.show {
	color: #262B40;
	background-color: #ecb32c;
	border-color: #ecb32c;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
	}
}
.btn-outline-paypal.disabled {
	color: #ecb32c;
	background-color: transparent;
}
.btn-outline-behance {
	color: #0057ff;
	border-color: #0057ff;
	&:hover {
		color: #ffffff;
		background-color: #0057ff;
		border-color: #0057ff;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #0057ff;
		border-color: #0057ff;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
		}
	}
	&:disabled {
		color: #0057ff;
		background-color: transparent;
	}
}
.btn-outline-behance.active {
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
	}
}
.btn-outline-behance.dropdown-toggle.show {
	color: #ffffff;
	background-color: #0057ff;
	border-color: #0057ff;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
	}
}
.btn-outline-behance.disabled {
	color: #0057ff;
	background-color: transparent;
}
.btn-outline-reddit {
	color: #E84422;
	border-color: #E84422;
	&:hover {
		color: #ffffff;
		background-color: #E84422;
		border-color: #E84422;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #E84422;
		border-color: #E84422;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
		}
	}
	&:disabled {
		color: #E84422;
		background-color: transparent;
	}
}
.btn-outline-reddit.active {
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
	}
}
.btn-outline-reddit.dropdown-toggle.show {
	color: #ffffff;
	background-color: #E84422;
	border-color: #E84422;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
	}
}
.btn-outline-reddit.disabled {
	color: #E84422;
	background-color: transparent;
}
.btn-outline-github {
	color: #222222;
	border-color: #222222;
	&:hover {
		color: #ffffff;
		background-color: #222222;
		border-color: #222222;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
	}
	&:active {
		color: #ffffff;
		background-color: #222222;
		border-color: #222222;
		&:focus {
			box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
		}
	}
	&:disabled {
		color: #222222;
		background-color: transparent;
	}
}
.btn-outline-github.active {
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
	}
}
.btn-outline-github.dropdown-toggle.show {
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
	&:focus {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
	}
}
.btn-outline-github.disabled {
	color: #222222;
	background-color: transparent;
}
.btn-link {
	font-weight: 400;
	color: #262B40;
	text-decoration: none;
	&:hover {
		color: #0a0b10;
	}
	&:disabled {
		color: #93a5be;
	}
}
.btn-link.disabled {
	color: #93a5be;
}
.btn-lg {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
	+ {
		.dropdown-toggle-split {
			padding-right: 0.75rem;
			padding-left: 0.75rem;
		}
	}
}
.btn-group-lg {
	>.btn {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		border-radius: 0.3rem;
		+ {
			.dropdown-toggle-split {
				padding-right: 0.75rem;
				padding-left: 0.75rem;
			}
		}
	}
}
.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.5rem;
	+ {
		.dropdown-toggle-split {
			padding-right: 0.375rem;
			padding-left: 0.375rem;
		}
	}
}
.btn-group-sm {
	>.btn {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		border-radius: 0.5rem;
		+ {
			.dropdown-toggle-split {
				padding-right: 0.375rem;
				padding-left: 0.375rem;
			}
		}
	}
	>.btn-icon-only.btn {
		width: 2rem;
		height: 2rem;
	}
}
.btn-block {
	display: block;
	width: 100%;
	+ {
		.btn-block {
			margin-top: 0.5rem;
		}
	}
}
.fade {
	transition: opacity 0.15s linear;
	&:not(.show) {
		opacity: 0;
	}
}
.collapse {
	&:not(.show) {
		display: none;
	}
}
.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
.dropup {
	position: relative;
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}
		&:empty {
			&::after {
				margin-left: 0;
			}
		}
		&:after {
			display: none;
		}
	}
	.dropdown-toggle-split {
		&::after {
			margin-left: 0;
		}
	}
}
.dropright {
	position: relative;
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
			vertical-align: 0;
		}
		&:empty {
			&::after {
				margin-left: 0;
			}
		}
		&:after {
			display: none;
		}
	}
	.dropdown-toggle-split {
		&::after {
			margin-left: 0;
		}
	}
}
.dropdown {
	position: relative;
}
.dropleft {
	position: relative;
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			display: none;
		}
		&::before {
			display: inline-block;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
			vertical-align: 0;
		}
		&:empty {
			&::after {
				margin-left: 0;
			}
		}
		&:before {
			display: none;
		}
	}
	.dropdown-toggle-split {
		&::before {
			margin-right: 0;
		}
	}
}
.dropdown-toggle {
	white-space: nowrap;
	&::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	&:empty {
		&::after {
			margin-left: 0;
		}
	}
	&:after {
		display: none;
	}
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #4A5073;
	text-align: left;
	list-style: none;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 0.0625rem solid #eaedf2;
	border-radius: 0.5rem;
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
	top: 100%;
	min-width: 12rem;
	span {
		width: auto;
	}
	.dropdown-header {
		padding: .5rem 1rem;
		font-size: 1rem;
		color: #4A5073;
		font-weight: 600;
	}
	.dropdown-item {
		padding: .5rem 1rem;
		font-size: 1rem;
		transition: all 0.2s ease;
		font-weight: 300;
	}
	.dropdown-itema {
		&:hover {
			color: #4A5073;
		}
	}
}
.dropdown-menu-left {
	right: auto;
	left: 0;
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropdown-menu[x-placement^="top"] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^="right"] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^="bottom"] {
	right: auto;
	bottom: auto;
}
.dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto;
}
.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #f5f8fb;
	margin: 0.375rem 0;
	border-color: #eaedf2;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #4A5073;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	&:hover {
		color: #404563;
		background-color: #f5f8fb;
	}
	&:focus {
		color: #404563;
		background-color: #f5f8fb;
	}
	&:active {
		color: #ffffff;
		text-decoration: none;
		background-color: #262B40;
	}
	&:disabled {
		color: #93a5be;
		pointer-events: none;
		background-color: transparent;
	}
}
.dropdown-item.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #262B40;
}
.dropdown-item.disabled {
	color: #93a5be;
	pointer-events: none;
	background-color: transparent;
}
.dropdown-menu.show {
	display: block;
}
.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #4A5073;
	white-space: nowrap;
}
.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #4A5073;
}
.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	>.btn {
		position: relative;
		flex: 1 1 auto;
		&:hover {
			z-index: 1;
		}
		&:focus {
			z-index: 1;
		}
		&:active {
			z-index: 1;
		}
		&:not(:first-child) {
			margin-left: -0.0625rem;
		}
		&:not(:last-child) {
			&:not(.dropdown-toggle) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:nth-child(n+3) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
	}
	>.btn-check {
		&:checked {
			+ {
				.btn {
					z-index: 1;
				}
			}
		}
		&:focus {
			+ {
				.btn {
					z-index: 1;
				}
			}
		}
	}
	>.btn.active {
		z-index: 1;
	}
	>.btn-group {
		&:not(:first-child) {
			margin-left: -0.0625rem;
			>.btn {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:last-child) {
			>.btn {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	> :not(.btn-check) + {
		.btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	>.btn {
		position: relative;
		flex: 1 1 auto;
		width: 100%;
		&:hover {
			z-index: 1;
		}
		&:focus {
			z-index: 1;
		}
		&:active {
			z-index: 1;
		}
		&:not(:first-child) {
			margin-top: -0.0625rem;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&:not(:last-child) {
			&:not(.dropdown-toggle) {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	>.btn-check {
		&:checked {
			+ {
				.btn {
					z-index: 1;
				}
			}
		}
		&:focus {
			+ {
				.btn {
					z-index: 1;
				}
			}
		}
	}
	>.btn.active {
		z-index: 1;
	}
	>.btn-group {
		width: 100%;
		&:not(:first-child) {
			margin-top: -0.0625rem;
			>.btn {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		&:not(:last-child) {
			>.btn {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}
.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	&::after {
		margin-left: 0;
	}
}
.btn-group.show {
	.dropdown-toggle {
		box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
	}
	.dropdown-toggle.btn-link {
		box-shadow: none;
	}
}
.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	gap: 0.3rem;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
	color: #506690;
	&:hover {
		color: #F8BD7A;
		img {
			opacity: inherit;
			transition: all 0.2s ease;
		}
	}
	span {
		position: relative;
	}
}
.nav-link.disabled {
	color: #93a5be;
	pointer-events: none;
	cursor: default;
}
.nav-tabs {
	border-bottom: 0.0625rem solid #eaedf2;
	.nav-link {
		margin-bottom: -0.0625rem;
		border: 0.0625rem solid transparent;
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
		border: 0;
		padding: 1rem 1rem;
		background-color: #ffffff;
		&:hover {
			border-color: #f5f8fb #f5f8fb #eaedf2;
		}
		&:focus {
			border-color: #f5f8fb #f5f8fb #eaedf2;
		}
	}
	.nav-link.disabled {
		color: #93a5be;
		background-color: transparent;
		border-color: transparent;
	}
	.nav-link.active {
		color: #66799e;
		background-color: #eaedf2;
		border-color: #eaedf2;
		color: #ffffff;
		background-color: #262B40;
	}
	.nav-item.show {
		.nav-link {
			color: #66799e;
			background-color: #eaedf2;
			border-color: #eaedf2;
		}
	}
	.dropdown-menu {
		margin-top: -0.0625rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.nav-item {
		margin-bottom: 0;
	}
}
.nav-pills {
	.nav-link {
		border-radius: 0.5rem;
		padding: 0.75rem 0.85rem;
		transition: all 0.2s ease;
		box-shadow: none;
		border: 1px solid #eaedf2;
		&:hover {
			color: #262B40;
		}
	}
	.nav-link.active {
		color: #262B40;
		background-color: #f8f8f8;
		color: #262B40;
		background-color: #f8f8f8;
		border-color: #262B40;
	}
	.show {
		>.nav-link {
			color: #262B40;
			background-color: #f8f8f8;
			color: #262B40;
			background-color: #f8f8f8;
			border-color: #262B40;
		}
	}
	.nav-item {
		&:not(:last-child) {
			padding-right: 1rem;
		}
		margin-bottom: 1rem;
	}
	.nav-link.avatar-link {
		border: 0;
	}
}
.nav-fill {
	>.nav-link {
		flex: 1 1 auto;
		text-align: center;
	}
	.nav-item {
		flex: 1 1 auto;
		text-align: center;
	}
}
.nav-justified {
	>.nav-link {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
	.nav-item {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
}
.tab-content {
	>.tab-pane {
		display: none;
		display: none;
		pre {
			padding: 0;
			margin: 0;
		}
	}
	>.active {
		display: block;
		display: block;
	}
}
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	>.container {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	>.container-fluid {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	>.container-sm {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	>.container-md {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	>.container-lg {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	>.container-xl {
		display: flex;
		flex-wrap: inherit;
		align-items: center;
		justify-content: space-between;
	}
	.navbar-nav {
		.nav-link {
			transition: all 0.8s all 0.2s ease;
		}
		.nav-item {
			.media {
				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}
			}
			&:focus {
				outline: none !important;
			}
			.nav-link {
				&:focus {
					outline: none !important;
				}
			}
		}
		.dropdown {
			.dropdown-menu {
				top: 100%;
				span {
					width: 25px;
				}
			}
			.dropdown-item {
				font-size: 0.875rem;
			}
		}
		.dropdown-megamenu {
			.megamenu-link {
				color: #4A5073;
				font-weight: 400;
				font-size: 0.875rem;
			}
			.megamenu-item {
				position: relative;
				&:hover {
					.megamenu-link {
						color: #262B40;
					}
				}
			}
		}
		.dropdown-megamenu-sm {
			.megamenu-link {
				color: #4A5073;
				font-weight: 400;
				font-size: 0.875rem;
			}
			.megamenu-item {
				position: relative;
				&:hover {
					.megamenu-link {
						color: #262B40;
					}
				}
			}
		}
		.dropdown-megamenu-md {
			.megamenu-link {
				color: #4A5073;
				font-weight: 400;
				font-size: 0.875rem;
			}
			.megamenu-item {
				position: relative;
				&:hover {
					.megamenu-link {
						color: #262B40;
					}
				}
			}
		}
		.dropdown-submenu {
			position: relative;
			.dropdown-menu {
				top: 0;
				left: calc(100% - 2px);
				margin-left: .1rem;
				margin-right: .1rem;
				opacity: 0;
				display: none;
			}
			.dropdown-menu.show {
				opacity: 1;
				pointer-events: all;
				display: block;
			}
		}
		.nav-link-arrow {
			transition: transform 0.2s ease;
		}
	}
}
.navbar-brand {
	padding-top: 0.8125rem;
	padding-bottom: 0.8125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-size: 0.875rem;
	font-weight: 600;
	text-transform: uppercase;
	img {
		height: 40px;
	}
}
.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	.nav-link {
		padding-right: 0;
		padding-left: 0;
	}
	.dropdown-menu {
		position: static;
	}
}
.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.navbar-collapse {
	align-items: center;
	width: 100%;
	width: auto;
}
.navbar-toggler {
	padding: 0.4rem 0.6rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 0.0625rem solid transparent;
	border-radius: 0.5rem;
	transition: box-shadow 0.15s ease-in-out;
	&:hover {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
		outline: 0;
		box-shadow: 0 0 0 0.2rem;
	}
}
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}
.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start;
	.navbar-nav {
		flex-direction: row;
		.dropdown-menu {
			position: absolute;
		}
		.nav-link {
			padding-right: 0.8rem;
			padding-left: 0.8rem;
		}
	}
	.navbar-collapse {
		display: flex !important;
	}
	.navbar-toggler {
		display: none;
	}
}
.navbar-light {
	.navbar-brand {
		color: #2e3650;
		color: #506690;
		&:hover {
			color: #2e3650;
		}
		&:focus {
			color: #2e3650;
		}
	}
	.navbar-nav {
		.nav-link {
			color: rgba(46, 54, 80, 0.9);
			&:hover {
				color: #2e3650;
			}
			&:focus {
				color: #2e3650;
			}
		}
		.nav-link.disabled {
			color: rgba(46, 54, 80, 0.9);
		}
		.show {
			>.nav-link {
				color: #2e3650;
			}
		}
		.nav-link.active {
			color: #2e3650;
		}
	}
	.navbar-toggler {
		color: rgba(46, 54, 80, 0.9);
		border-color: rgba(46, 54, 80, 0.1);
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2846, 54, 80, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(46, 54, 80, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
	}
	.navbar-text {
		color: rgba(46, 54, 80, 0.9);
		a {
			color: #2e3650;
			&:hover {
				color: #2e3650;
			}
			&:focus {
				color: #2e3650;
			}
		}
	}
	&:not(.headroom--not-top) {
		.btn-outline-soft {
			border-color: #262B40;
			color: #262B40;
			&:hover {
				background: #262B40;
				color: #ffffff;
			}
		}
	}
	.navbar-brand-dark {
		display: none;
	}
}
.navbar-dark {
	.navbar-brand {
		color: #ffffff;
		color: #ffffff;
		&:hover {
			color: #ffffff;
		}
		&:focus {
			color: #ffffff;
		}
	}
	.navbar-nav {
		.nav-link {
			color: rgba(255, 255, 255, 0.9);
			&:hover {
				color: white;
			}
			&:focus {
				color: white;
			}
		}
		.nav-link.disabled {
			color: rgba(255, 255, 255, 0.9);
		}
		.show {
			>.nav-link {
				color: #ffffff;
			}
		}
		.nav-link.active {
			color: #ffffff;
		}
	}
	.navbar-toggler {
		color: rgba(255, 255, 255, 0.9);
		border-color: rgba(255, 255, 255, 0.1);
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
	}
	.navbar-text {
		color: rgba(255, 255, 255, 0.9);
		a {
			color: #ffffff;
			&:hover {
				color: #ffffff;
			}
			&:focus {
				color: #ffffff;
			}
		}
	}
	.navbar-brand-light {
		display: none;
	}
	>.nav-item {
		&:hover {
			>.nav-link {
				color: #262B40;
			}
		}
	}
}
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #ffffff;
	background-clip: border-box;
	border: 0.0625rem solid rgba(46, 54, 80, 0.125);
	border-radius: 0.5rem;
	position: relative;
	>hr {
		margin-right: 0;
		margin-left: 0;
	}
	>.list-group {
		border-top: inherit;
		border-bottom: inherit;
		&:first-child {
			border-top-width: 0;
			border-top-left-radius: 0.4375rem;
			border-top-right-radius: 0.4375rem;
		}
		&:last-child {
			border-bottom-width: 0;
			border-bottom-right-radius: 0.4375rem;
			border-bottom-left-radius: 0.4375rem;
		}
		+ {
			.card-footer {
				border-top: 0;
			}
		}
	}
	>.card-header {
		+ {
			.list-group {
				border-top: 0;
			}
		}
	}
	.card-header {
		background: transparent;
	}
	.profile-cover {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		height: 200px;
	}
	.card-blockquote {
		position: relative;
		padding: 2rem;
		.svg-bg {
			display: block;
			position: absolute;
			width: 100%;
			height: 95px;
			top: -94px;
			left: 0;
		}
	}
	.table {
		margin-bottom: 0;
		td {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
		th {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}
.card-body {
	flex: 1 1 auto;
	padding: 1.25rem 1.5rem;
}
.card-title {
	margin-bottom: 0.875rem;
}
.card-subtitle {
	margin-top: -0.4375rem;
	margin-bottom: 0;
}
.card-text {
	&:last-child {
		margin-bottom: 0;
	}
}
.card-link {
	&:hover {
		text-decoration: none;
	}
	+ {
		.card-link {
			margin-left: 1.5rem;
		}
	}
}
.card-header {
	padding: 1.25rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(46, 54, 80, 0.03);
	border-bottom: 0.0625rem solid rgba(46, 54, 80, 0.125);
	&:first-child {
		border-radius: 0.4375rem 0.4375rem 0 0;
	}
}
.card-footer {
	padding: 1.25rem 1.5rem;
	background-color: rgba(46, 54, 80, 0.03);
	border-top: 0.0625rem solid rgba(46, 54, 80, 0.125);
	background-color: transparent;
	&:last-child {
		border-radius: 0 0 0.4375rem 0.4375rem;
	}
}
.card-header-tabs {
	margin-right: -0.75rem;
	margin-bottom: -1.25rem;
	margin-left: -0.75rem;
	border-bottom: 0;
	.nav-link.active {
		background-color: #ffffff;
		border-bottom-color: #ffffff;
	}
}
.card-header-pills {
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	border-radius: 0.4375rem;
	padding: 0;
	.card-footer {
		background: transparent;
		border-color: #66799e;
	}
	.card-header {
		background: transparent;
		border-color: #66799e;
	}
}
.card-img {
	width: 100%;
	border-top-left-radius: 0.4375rem;
	border-top-right-radius: 0.4375rem;
	border-bottom-right-radius: 0.4375rem;
	border-bottom-left-radius: 0.4375rem;
}
.card-img-top {
	width: 100%;
	border-top-left-radius: 0.4375rem;
	border-top-right-radius: 0.4375rem;
}
.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: 0.4375rem;
	border-bottom-left-radius: 0.4375rem;
}
.card-group {
	>.card {
		margin-bottom: 0.75rem;
	}
}
.accordion {
	>.card {
		overflow: hidden;
		&:not(:last-of-type) {
			border-bottom: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(:first-of-type) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		>.card-header {
			border-radius: 0;
			margin-bottom: -0.0625rem;
		}
	}
	.card {
		margin-bottom: 1.5rem;
		.card-header {
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			button {
				.icon {
					color: #262B40;
					transition: all 0.2s ease;
					span {
						font-size: 0.875rem;
					}
				}
				.icon-title {
					margin-right: 1rem;
					span {
						margin-right: 1rem;
					}
					+ {
						.icon {
							height: 1rem;
						}
					}
				}
				&:hover {
					>* {
						color: theme-color("dark");
						i {
							fill: theme-color("dark");
						}
					}
				}
			}
			button[aria-expanded="true"] {
				>.icon {
					transform: rotateZ(45deg);
				}
				>* {
					color: theme-color("dark");
					i {
						fill: theme-color("dark");
					}
				}
			}
			&:hover {
				cursor: pointer;
			}
			.btn {
				&:focus {
					outline: 0;
					box-shadow: none;
				}
			}
		}
		&:hover {
			background-color: #f5f8fb;
		}
	}
}
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #f5f8fb;
	border-radius: 0.5rem;
}
.breadcrumb-item {
	display: flex;
	font-size: 0.75rem;
	color: #66799e;
	font-weight: 400;
	font-size: 0.75rem;
	+ {
		.breadcrumb-item {
			padding-left: 0.5rem;
			&::before {
				display: inline-block;
				padding-right: 0.5rem;
				color: #66799e;
				content: "/";
			}
			&:before {
				content: "\f101";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				color: #66799e;
			}
		}
	}
	a {
		color: #66799e;
		font-weight: 400;
		font-size: 0.75rem;
	}
}
.breadcrumb-item.active {
	color: #ffffff;
	font-weight: 400;
}
.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
}
.page-link {
	position: relative;
	display: block;
	color: #262B40;
	background-color: #ffffff;
	border: 0.0625rem solid #eaedf2;
	padding: 0.375rem 0.75rem;
	&:hover {
		z-index: 2;
		color: #0a0b10;
		background-color: #f5f8fb;
		border-color: #F0F3F6;
	}
	&:focus {
		z-index: 3;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
	}
}
.page-item {
	&:not(:first-child) {
		.page-link {
			margin-left: -0.0625rem;
		}
	}
	&:first-child {
		.page-link {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}
	}
	&:last-child {
		.page-link {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
		}
	}
}
.page-item.active {
	.page-link {
		z-index: 3;
		color: #ffffff;
		background-color: #262B40;
		border-color: #262B40;
	}
}
.page-item.disabled {
	.page-link {
		color: #93a5be;
		pointer-events: none;
		background-color: #ffffff;
		border-color: #F0F3F6;
	}
}
.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.25rem;
	}
	.page-item {
		&:first-child {
			.page-link {
				border-top-left-radius: 0.3rem;
				border-bottom-left-radius: 0.3rem;
			}
		}
		&:last-child {
			.page-link {
				border-top-right-radius: 0.3rem;
				border-bottom-right-radius: 0.3rem;
			}
		}
	}
}
.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
	}
	.page-item {
		&:first-child {
			.page-link {
				border-top-left-radius: 0.1rem;
				border-bottom-left-radius: 0.1rem;
			}
		}
		&:last-child {
			.page-link {
				border-top-right-radius: 0.1rem;
				border-bottom-right-radius: 0.1rem;
			}
		}
	}
}
.badge {
	display: inline-block;
	padding: 0.1rem 0.4rem;
	font-size: 0.75em;
	font-weight: 700;
	line-height: 1;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.5rem;
	font-size: 0.75em;
	font-weight: 700;
	&:empty {
		display: none;
	}
	a {
		color: #ffffff;
	}
}
.alert {
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 0.0625rem solid transparent;
	border-radius: 0.5rem;
}
.alert-heading {
	color: inherit;
	font-weight: 600;
}
.alert-link {
	font-weight: 600;
}
.alert-dismissible {
	padding-right: 3.5rem;
	.close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem 1rem;
		color: inherit;
	}
}
.alert-primary {
	color: #2c344d;
	background-color: #d4d5d9;
	border-color: #c2c4ca;
	.alert-link {
		color: #191e2d;
	}
}
.alert-secondary {
	color: #565158;
	background-color: #fef2e4;
	border-color: #fdedda;
	.alert-link {
		color: #3c393d;
	}
}
.alert-tertiary {
	color: #2a4a5c;
	background-color: #d1ebe8;
	border-color: #bfe2df;
	.alert-link {
		color: #1a2e39;
	}
}
.alert-quaternary {
	color: #273a64;
	background-color: #cedaf0;
	border-color: #baccea;
	.alert-link {
		color: #19253f;
	}
}
.alert-primary-app {
	color: #545c71;
	background-color: #fbfdfd;
	border-color: #f9fcfc;
	.alert-link {
		color: #3e4454;
	}
}
.alert-secondary-app {
	color: #323a60;
	background-color: #d9dbec;
	border-color: #cacce4;
	.alert-link {
		color: #21263e;
	}
}
.alert-light {
	color: #545b70;
	background-color: #fbfbfc;
	border-color: #f9fafb;
	.alert-link {
		color: #3e4353;
	}
}
.alert-lighten {
	color: #555c71;
	background-color: #fcfdfd;
	border-color: #fbfcfc;
	.alert-link {
		color: #3f4454;
	}
}
.alert-success {
	color: #264c58;
	background-color: #cdede4;
	border-color: #b9e6d9;
	.alert-link {
		color: #172d34;
	}
}
.alert-info {
	color: #273a64;
	background-color: #cedaf0;
	border-color: #baccea;
	.alert-link {
		color: #19253f;
	}
}
.alert-warning {
	color: #565052;
	background-color: #fdf1de;
	border-color: #fcebd1;
	.alert-link {
		color: #3c3739;
	}
}
.alert-danger {
	color: #573c50;
	background-color: #fedcdc;
	border-color: #fecfcf;
	.alert-link {
		color: #392734;
	}
}
.alert-white {
	color: #585e73;
	background-color: white;
	border-color: white;
	.alert-link {
		color: #424656;
	}
}
.alert-gray {
	color: #343b57;
	background-color: #dbdce3;
	border-color: #ccced8;
	.alert-link {
		color: #212537;
	}
}
.alert-neutral {
	color: #585e73;
	background-color: white;
	border-color: white;
	.alert-link {
		color: #424656;
	}
}
.alert-dark {
	color: #2c344d;
	background-color: #d4d5d9;
	border-color: #c2c4ca;
	.alert-link {
		color: #191e2d;
	}
}
.alert-soft {
	color: #565d72;
	background-color: #fdfefe;
	border-color: #fcfdfe;
	.alert-link {
		color: #404555;
	}
}
.alert-black {
	color: #2e3650;
	background-color: #d5d7dc;
	border-color: #c4c7ce;
	.alert-link {
		color: #1b2030;
	}
}
.alert-blue {
	color: #273a64;
	background-color: #cedaf0;
	border-color: #baccea;
	.alert-link {
		color: #19253f;
	}
}
.alert-pink {
	color: #4d3f5a;
	background-color: #f4e0e6;
	border-color: #f0d4db;
	.alert-link {
		color: #332a3c;
	}
}
.alert-cyan {
	color: #394f66;
	background-color: #e0eff2;
	border-color: #d3e9ed;
	.alert-link {
		color: #273645;
	}
}
.alert-purple {
	color: #403f6d;
	background-color: #e7e0f9;
	border-color: #ded4f6;
	.alert-link {
		color: #2d2c4d;
	}
}
.alert-soft-indigo {
	color: #565a73;
	background-color: #fdfaff;
	border-color: #fcf9ff;
	.alert-link {
		color: #404356;
	}
}
.alert-soft-green {
	color: #2e4c5c;
	background-color: #d5ede8;
	border-color: #c4e6df;
	.alert-link {
		color: #1d303a;
	}
}
.alert-gray-100 {
	color: #555d72;
	background-color: #fdfdfe;
	border-color: #fcfdfe;
	.alert-link {
		color: #3f4555;
	}
}
.alert-gray-200 {
	color: #565d72;
	background-color: #fdfefe;
	border-color: #fcfdfe;
	.alert-link {
		color: #404555;
	}
}
.alert-gray-300 {
	color: #555c71;
	background-color: #fcfdfd;
	border-color: #fbfcfc;
	.alert-link {
		color: #3f4454;
	}
}
.alert-gray-400 {
	color: #545b70;
	background-color: #fbfbfc;
	border-color: #f9fafb;
	.alert-link {
		color: #3e4353;
	}
}
.alert-gray-500 {
	color: #4f566d;
	background-color: #f6f7f9;
	border-color: #f2f4f6;
	.alert-link {
		color: #3a3f4f;
	}
}
.alert-gray-600 {
	color: #424c66;
	background-color: #e9edf2;
	border-color: #e1e6ed;
	.alert-link {
		color: #2e3547;
	}
}
.alert-gray-700 {
	color: #394360;
	background-color: #e0e4ec;
	border-color: #d4d9e4;
	.alert-link {
		color: #262d40;
	}
}
.alert-gray-800 {
	color: #35405d;
	background-color: #dce0e9;
	border-color: #ced4e0;
	.alert-link {
		color: #222a3d;
	}
}
.alert-facebook {
	color: #313d5f;
	background-color: #d8deeb;
	border-color: #c8d1e2;
	.alert-link {
		color: #20273d;
	}
}
.alert-twitter {
	color: #2b4b70;
	background-color: #d2ecfc;
	border-color: #c0e5fb;
	.alert-link {
		color: #1d324b;
	}
}
.alert-google {
	color: #51394b;
	background-color: #f8d9d7;
	border-color: #f5cac7;
	.alert-link {
		color: #33242f;
	}
}
.alert-instagram {
	color: #523853;
	background-color: #fad9df;
	border-color: #f7cad2;
	.alert-link {
		color: #342335;
	}
}
.alert-pinterest {
	color: #4b2d46;
	background-color: #f2ced2;
	border-color: #edbabf;
	.alert-link {
		color: #2b1a28;
	}
}
.alert-youtube {
	color: #4e3246;
	background-color: #f5d2d2;
	border-color: #f1c1c0;
	.alert-link {
		color: #2f1e2a;
	}
}
.alert-slack {
	color: #304e5b;
	background-color: #d8efe7;
	border-color: #c8e9dd;
	.alert-link {
		color: #1e313a;
	}
}
.alert-dribbble {
	color: #543a5b;
	background-color: #fbdbe7;
	border-color: #f9cdde;
	.alert-link {
		color: #37263c;
	}
}
.alert-dropbox {
	color: #2b4873;
	background-color: #d2e9ff;
	border-color: #c0e0ff;
	.alert-link {
		color: #1d314e;
	}
}
.alert-twitch {
	color: #343659;
	background-color: #dbd7e5;
	border-color: #cdc7da;
	.alert-link {
		color: #212239;
	}
}
.alert-paypal {
	color: #544f49;
	background-color: #fbf0d5;
	border-color: #faeac4;
	.alert-link {
		color: #393531;
	}
}
.alert-behance {
	color: #253d73;
	background-color: #ccddff;
	border-color: #b8d0ff;
	.alert-link {
		color: #19294c;
	}
}
.alert-reddit {
	color: #533947;
	background-color: #fadad3;
	border-color: #f9cbc1;
	.alert-link {
		color: #35242d;
	}
}
.alert-github {
	color: #2c3247;
	background-color: lightgray;
	border-color: #c1c1c1;
	.alert-link {
		color: #181c28;
	}
}
.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #f5f8fb;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	height: 4px;
	margin-bottom: 1rem;
	overflow: hidden;
	border-radius: 0.1rem;
	background-color: #f5f8fb;
	font-size: 0.75rem;
	font-weight: 600;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	background-color: #262B40;
	transition: width 0.6s ease;
	box-shadow: none;
	border-radius: 0;
	height: auto;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite;
}
.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.5rem;
}
.list-group-item-action {
	width: 100%;
	color: #66799e;
	text-align: inherit;
	&:hover {
		z-index: 1;
		color: #66799e;
		text-decoration: none;
		background-color: #f3f7fa;
	}
	&:focus {
		z-index: 1;
		color: #66799e;
		text-decoration: none;
		background-color: #f3f7fa;
	}
	&:active {
		color: #4A5073;
		background-color: #f5f8fb;
	}
}
.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	background-color: #ffffff;
	border: 0.0625rem solid rgba(46, 54, 80, 0.125);
	border: 0;
	&:first-child {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
	&:last-child {
		border-bottom-right-radius: inherit;
		border-bottom-left-radius: inherit;
	}
	&:disabled {
		color: #93a5be;
		pointer-events: none;
		background-color: #ffffff;
	}
	+ {
		.list-group-item {
			border-top-width: 0;
		}
		.list-group-item.active {
			margin-top: -0.0625rem;
			border-top-width: 0.0625rem;
		}
	}
	i {
		width: 1rem;
	}
}
.list-group-item.disabled {
	color: #93a5be;
	pointer-events: none;
	background-color: #ffffff;
}
.list-group-item.active {
	z-index: 2;
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
	z-index: 2;
	color: #ffffff;
	background-color: #262B40;
	border-color: #262B40;
}
.list-group-horizontal {
	flex-direction: row;
	>.list-group-item {
		&:first-child {
			border-bottom-left-radius: 0.5rem;
			border-top-right-radius: 0;
		}
		&:last-child {
			border-top-right-radius: 0.5rem;
			border-bottom-left-radius: 0;
		}
		+ {
			.list-group-item {
				border-top-width: 0.0625rem;
				border-left-width: 0;
			}
			.list-group-item.active {
				margin-left: -0.0625rem;
				border-left-width: 0.0625rem;
			}
		}
	}
	>.list-group-item.active {
		margin-top: 0;
	}
}
.list-group-flush {
	border-radius: 0;
	>.list-group-item {
		border-width: 0 0 0.0625rem;
		&:last-child {
			border-bottom-width: 0;
		}
	}
}
.list-group-item-primary {
	color: #2a3048;
	background-color: #c2c4ca;
}
.list-group-item-primary.list-group-item-action {
	&:hover {
		color: #2a3048;
		background-color: #b4b7be;
	}
	&:focus {
		color: #2a3048;
		background-color: #b4b7be;
	}
}
.list-group-item-primary.list-group-item-action.active {
	color: #ffffff;
	background-color: #2a3048;
	border-color: #2a3048;
}
.list-group-item-secondary {
	color: #977c66;
	background-color: #fdedda;
}
.list-group-item-secondary.list-group-item-action {
	&:hover {
		color: #977c66;
		background-color: #fce1c2;
	}
	&:focus {
		color: #977c66;
		background-color: #fce1c2;
	}
}
.list-group-item-secondary.list-group-item-action.active {
	color: #ffffff;
	background-color: #977c66;
	border-color: #977c66;
}
.list-group-item-tertiary {
	color: #24696f;
	background-color: #bfe2df;
}
.list-group-item-tertiary.list-group-item-action {
	&:hover {
		color: #24696f;
		background-color: #addad6;
	}
	&:focus {
		color: #24696f;
		background-color: #addad6;
	}
}
.list-group-item-tertiary.list-group-item-action.active {
	color: #ffffff;
	background-color: #24696f;
	border-color: #24696f;
}
.list-group-item-quaternary {
	color: #1b3f83;
	background-color: #baccea;
}
.list-group-item-quaternary.list-group-item-action {
	&:hover {
		color: #1b3f83;
		background-color: #a6bee4;
	}
	&:focus {
		color: #1b3f83;
		background-color: #a6bee4;
	}
}
.list-group-item-quaternary.list-group-item-action.active {
	color: #ffffff;
	background-color: #1b3f83;
	border-color: #1b3f83;
}
.list-group-item-primary-app {
	color: #9099a6;
	background-color: #f9fcfc;
}
.list-group-item-primary-app.list-group-item-action {
	&:hover {
		color: #9099a6;
		background-color: #e8f4f4;
	}
	&:focus {
		color: #9099a6;
		background-color: #e8f4f4;
	}
}
.list-group-item-primary-app.list-group-item-action.active {
	color: #ffffff;
	background-color: #9099a6;
	border-color: #9099a6;
}
.list-group-item-secondary-app {
	color: #38407a;
	background-color: #cacce4;
}
.list-group-item-secondary-app.list-group-item-action {
	&:hover {
		color: #38407a;
		background-color: #b9bcdb;
	}
	&:focus {
		color: #38407a;
		background-color: #b9bcdb;
	}
}
.list-group-item-secondary-app.list-group-item-action.active {
	color: #ffffff;
	background-color: #38407a;
	border-color: #38407a;
}
.list-group-item-light {
	color: #9095a4;
	background-color: #f9fafb;
}
.list-group-item-light.list-group-item-action {
	&:hover {
		color: #9095a4;
		background-color: #eaedf1;
	}
	&:focus {
		color: #9095a4;
		background-color: #eaedf1;
	}
}
.list-group-item-light.list-group-item-action.active {
	color: #ffffff;
	background-color: #9095a4;
	border-color: #9095a4;
}
.list-group-item-lighten {
	color: #9398a6;
	background-color: #fbfcfc;
}
.list-group-item-lighten.list-group-item-action {
	&:hover {
		color: #9398a6;
		background-color: #ecf1f1;
	}
	&:focus {
		color: #9398a6;
		background-color: #ecf1f1;
	}
}
.list-group-item-lighten.list-group-item-action.active {
	color: #ffffff;
	background-color: #9398a6;
	border-color: #9398a6;
}
.list-group-item-success {
	color: #197064;
	background-color: #b9e6d9;
}
.list-group-item-success.list-group-item-action {
	&:hover {
		color: #197064;
		background-color: #a6dfcf;
	}
	&:focus {
		color: #197064;
		background-color: #a6dfcf;
	}
}
.list-group-item-success.list-group-item-action.active {
	color: #ffffff;
	background-color: #197064;
	border-color: #197064;
}
.list-group-item-info {
	color: #1b3f83;
	background-color: #baccea;
}
.list-group-item-info.list-group-item-action {
	&:hover {
		color: #1b3f83;
		background-color: #a6bee4;
	}
	&:focus {
		color: #1b3f83;
		background-color: #a6bee4;
	}
}
.list-group-item-info.list-group-item-action.active {
	color: #ffffff;
	background-color: #1b3f83;
	border-color: #1b3f83;
}
.list-group-item-warning {
	color: #957955;
	background-color: #fcebd1;
}
.list-group-item-warning.list-group-item-action {
	&:hover {
		color: #957955;
		background-color: #fae1b9;
	}
	&:focus {
		color: #957955;
		background-color: #fae1b9;
	}
}
.list-group-item-warning.list-group-item-action.active {
	color: #ffffff;
	background-color: #957955;
	border-color: #957955;
}
.list-group-item-danger {
	color: #984551;
	background-color: #fecfcf;
}
.list-group-item-danger.list-group-item-action {
	&:hover {
		color: #984551;
		background-color: #fdb6b6;
	}
	&:focus {
		color: #984551;
		background-color: #fdb6b6;
	}
}
.list-group-item-danger.list-group-item-action.active {
	color: #ffffff;
	background-color: #984551;
	border-color: #984551;
}
.list-group-item-white {
	color: #9b9fab;
	background-color: white;
}
.list-group-item-white.list-group-item-action {
	&:hover {
		color: #9b9fab;
		background-color: #f2f2f2;
	}
	&:focus {
		color: #9b9fab;
		background-color: #f2f2f2;
	}
}
.list-group-item-white.list-group-item-action.active {
	color: #ffffff;
	background-color: #9b9fab;
	border-color: #9b9fab;
}
.list-group-item-gray {
	color: #3d4462;
	background-color: #ccced8;
}
.list-group-item-gray.list-group-item-action {
	&:hover {
		color: #3d4462;
		background-color: #bec0cd;
	}
	&:focus {
		color: #3d4462;
		background-color: #bec0cd;
	}
}
.list-group-item-gray.list-group-item-action.active {
	color: #ffffff;
	background-color: #3d4462;
	border-color: #3d4462;
}
.list-group-item-neutral {
	color: #9b9fab;
	background-color: white;
}
.list-group-item-neutral.list-group-item-action {
	&:hover {
		color: #9b9fab;
		background-color: #f2f2f2;
	}
	&:focus {
		color: #9b9fab;
		background-color: #f2f2f2;
	}
}
.list-group-item-neutral.list-group-item-action.active {
	color: #ffffff;
	background-color: #9b9fab;
	border-color: #9b9fab;
}
.list-group-item-dark {
	color: #2a3048;
	background-color: #c2c4ca;
}
.list-group-item-dark.list-group-item-action {
	&:hover {
		color: #2a3048;
		background-color: #b4b7be;
	}
	&:focus {
		color: #2a3048;
		background-color: #b4b7be;
	}
}
.list-group-item-dark.list-group-item-action.active {
	color: #ffffff;
	background-color: #2a3048;
	border-color: #2a3048;
}
.list-group-item-soft {
	color: #959ba9;
	background-color: #fcfdfe;
}
.list-group-item-soft.list-group-item-action {
	&:hover {
		color: #959ba9;
		background-color: #e9f0f8;
	}
	&:focus {
		color: #959ba9;
		background-color: #e9f0f8;
	}
}
.list-group-item-soft.list-group-item-action.active {
	color: #ffffff;
	background-color: #959ba9;
	border-color: #959ba9;
}
.list-group-item-black {
	color: #2e3650;
	background-color: #c4c7ce;
}
.list-group-item-black.list-group-item-action {
	&:hover {
		color: #2e3650;
		background-color: #b6bac2;
	}
	&:focus {
		color: #2e3650;
		background-color: #b6bac2;
	}
}
.list-group-item-black.list-group-item-action.active {
	color: #ffffff;
	background-color: #2e3650;
	border-color: #2e3650;
}
.list-group-item-blue {
	color: #1b3f83;
	background-color: #baccea;
}
.list-group-item-blue.list-group-item-action {
	&:hover {
		color: #1b3f83;
		background-color: #a6bee4;
	}
	&:focus {
		color: #1b3f83;
		background-color: #a6bee4;
	}
}
.list-group-item-blue.list-group-item-action.active {
	color: #ffffff;
	background-color: #1b3f83;
	border-color: #1b3f83;
}
.list-group-item-pink {
	color: #7f4e69;
	background-color: #f0d4db;
}
.list-group-item-pink.list-group-item-action {
	&:hover {
		color: #7f4e69;
		background-color: #e9c1cb;
	}
	&:focus {
		color: #7f4e69;
		background-color: #e9c1cb;
	}
}
.list-group-item-pink.list-group-item-action.active {
	color: #ffffff;
	background-color: #7f4e69;
	border-color: #7f4e69;
}
.list-group-item-cyan {
	color: #4a7689;
	background-color: #d3e9ed;
}
.list-group-item-cyan.list-group-item-action {
	&:hover {
		color: #4a7689;
		background-color: #c1e0e6;
	}
	&:focus {
		color: #4a7689;
		background-color: #c1e0e6;
	}
}
.list-group-item-cyan.list-group-item-action.active {
	color: #ffffff;
	background-color: #4a7689;
	border-color: #4a7689;
}
.list-group-item-purple {
	color: #5d4e9b;
	background-color: #ded4f6;
}
.list-group-item-purple.list-group-item-action {
	&:hover {
		color: #5d4e9b;
		background-color: #cebff2;
	}
	&:focus {
		color: #5d4e9b;
		background-color: #cebff2;
	}
}
.list-group-item-purple.list-group-item-action.active {
	color: #ffffff;
	background-color: #5d4e9b;
	border-color: #5d4e9b;
}
.list-group-item-soft-indigo {
	color: #9593ab;
	background-color: #fcf9ff;
}
.list-group-item-soft-indigo.list-group-item-action {
	&:hover {
		color: #9593ab;
		background-color: #efe0ff;
	}
	&:focus {
		color: #9593ab;
		background-color: #efe0ff;
	}
}
.list-group-item-soft-indigo.list-group-item-action.active {
	color: #ffffff;
	background-color: #9593ab;
	border-color: #9593ab;
}
.list-group-item-soft-green {
	color: #2d7070;
	background-color: #c4e6df;
}
.list-group-item-soft-green.list-group-item-action {
	&:hover {
		color: #2d7070;
		background-color: #b2ded5;
	}
	&:focus {
		color: #2d7070;
		background-color: #b2ded5;
	}
}
.list-group-item-soft-green.list-group-item-action.active {
	color: #ffffff;
	background-color: #2d7070;
	border-color: #2d7070;
}
.list-group-item-gray-100 {
	color: #949aa8;
	background-color: #fcfdfe;
}
.list-group-item-gray-100.list-group-item-action {
	&:hover {
		color: #949aa8;
		background-color: #e9f0f8;
	}
	&:focus {
		color: #949aa8;
		background-color: #e9f0f8;
	}
}
.list-group-item-gray-100.list-group-item-action.active {
	color: #ffffff;
	background-color: #949aa8;
	border-color: #949aa8;
}
.list-group-item-gray-200 {
	color: #959ba9;
	background-color: #fcfdfe;
}
.list-group-item-gray-200.list-group-item-action {
	&:hover {
		color: #959ba9;
		background-color: #e9f0f8;
	}
	&:focus {
		color: #959ba9;
		background-color: #e9f0f8;
	}
}
.list-group-item-gray-200.list-group-item-action.active {
	color: #ffffff;
	background-color: #959ba9;
	border-color: #959ba9;
}
.list-group-item-gray-300 {
	color: #9398a6;
	background-color: #fbfcfc;
}
.list-group-item-gray-300.list-group-item-action {
	&:hover {
		color: #9398a6;
		background-color: #ecf1f1;
	}
	&:focus {
		color: #9398a6;
		background-color: #ecf1f1;
	}
}
.list-group-item-gray-300.list-group-item-action.active {
	color: #ffffff;
	background-color: #9398a6;
	border-color: #9398a6;
}
.list-group-item-gray-400 {
	color: #9095a4;
	background-color: #f9fafb;
}
.list-group-item-gray-400.list-group-item-action {
	&:hover {
		color: #9095a4;
		background-color: #eaedf1;
	}
	&:focus {
		color: #9095a4;
		background-color: #eaedf1;
	}
}
.list-group-item-gray-400.list-group-item-action.active {
	color: #ffffff;
	background-color: #9095a4;
	border-color: #9095a4;
}
.list-group-item-gray-500 {
	color: #838a9b;
	background-color: #f2f4f6;
}
.list-group-item-gray-500.list-group-item-action {
	&:hover {
		color: #838a9b;
		background-color: #e3e7ec;
	}
	&:focus {
		color: #838a9b;
		background-color: #e3e7ec;
	}
}
.list-group-item-gray-500.list-group-item-action.active {
	color: #ffffff;
	background-color: #838a9b;
	border-color: #838a9b;
}
.list-group-item-gray-600 {
	color: #637089;
	background-color: #e1e6ed;
}
.list-group-item-gray-600.list-group-item-action {
	&:hover {
		color: #637089;
		background-color: #d1d9e3;
	}
	&:focus {
		color: #637089;
		background-color: #d1d9e3;
	}
}
.list-group-item-gray-600.list-group-item-action.active {
	color: #ffffff;
	background-color: #637089;
	border-color: #637089;
}
.list-group-item-gray-700 {
	color: #4b5979;
	background-color: #d4d9e4;
}
.list-group-item-gray-700.list-group-item-action {
	&:hover {
		color: #4b5979;
		background-color: #c4cbda;
	}
	&:focus {
		color: #4b5979;
		background-color: #c4cbda;
	}
}
.list-group-item-gray-700.list-group-item-action.active {
	color: #ffffff;
	background-color: #4b5979;
	border-color: #4b5979;
}
.list-group-item-gray-800 {
	color: #404f71;
	background-color: #ced4e0;
}
.list-group-item-gray-800.list-group-item-action {
	&:hover {
		color: #404f71;
		background-color: #bec6d6;
	}
	&:focus {
		color: #404f71;
		background-color: #bec6d6;
	}
}
.list-group-item-gray-800.list-group-item-action.active {
	color: #ffffff;
	background-color: #404f71;
	border-color: #404f71;
}
.list-group-item-facebook {
	color: #354876;
	background-color: #c8d1e2;
}
.list-group-item-facebook.list-group-item-action {
	&:hover {
		color: #354876;
		background-color: #b7c3d9;
	}
	&:focus {
		color: #354876;
		background-color: #b7c3d9;
	}
}
.list-group-item-facebook.list-group-item-action.active {
	color: #ffffff;
	background-color: #354876;
	border-color: #354876;
}
.list-group-item-twitter {
	color: #256ea4;
	background-color: #c0e5fb;
}
.list-group-item-twitter.list-group-item-action {
	&:hover {
		color: #256ea4;
		background-color: #a8dbf9;
	}
	&:focus {
		color: #256ea4;
		background-color: #a8dbf9;
	}
}
.list-group-item-twitter.list-group-item-action.active {
	color: #ffffff;
	background-color: #256ea4;
	border-color: #256ea4;
}
.list-group-item-google {
	color: #883d43;
	background-color: #f5cac7;
}
.list-group-item-google.list-group-item-action {
	&:hover {
		color: #883d43;
		background-color: #f1b6b1;
	}
	&:focus {
		color: #883d43;
		background-color: #f1b6b1;
	}
}
.list-group-item-google.list-group-item-action.active {
	color: #ffffff;
	background-color: #883d43;
	border-color: #883d43;
}
.list-group-item-instagram {
	color: #8d3b58;
	background-color: #f7cad2;
}
.list-group-item-instagram.list-group-item-action {
	&:hover {
		color: #8d3b58;
		background-color: #f4b4bf;
	}
	&:focus {
		color: #8d3b58;
		background-color: #f4b4bf;
	}
}
.list-group-item-instagram.list-group-item-action.active {
	color: #ffffff;
	background-color: #8d3b58;
	border-color: #8d3b58;
}
.list-group-item-pinterest {
	color: #781e35;
	background-color: #edbabf;
}
.list-group-item-pinterest.list-group-item-action {
	&:hover {
		color: #781e35;
		background-color: #e8a6ac;
	}
	&:focus {
		color: #781e35;
		background-color: #e8a6ac;
	}
}
.list-group-item-pinterest.list-group-item-action.active {
	color: #ffffff;
	background-color: #781e35;
	border-color: #781e35;
}
.list-group-item-youtube {
	color: #812b37;
	background-color: #f1c1c0;
}
.list-group-item-youtube.list-group-item-action {
	&:hover {
		color: #812b37;
		background-color: #ecacab;
	}
	&:focus {
		color: #812b37;
		background-color: #ecacab;
	}
}
.list-group-item-youtube.list-group-item-action.active {
	color: #ffffff;
	background-color: #812b37;
	border-color: #812b37;
}
.list-group-item-slack {
	color: #34756c;
	background-color: #c8e9dd;
}
.list-group-item-slack.list-group-item-action {
	&:hover {
		color: #34756c;
		background-color: #b6e2d2;
	}
	&:focus {
		color: #34756c;
		background-color: #b6e2d2;
	}
}
.list-group-item-slack.list-group-item-action.active {
	color: #ffffff;
	background-color: #34756c;
	border-color: #34756c;
}
.list-group-item-dribbble {
	color: #90416e;
	background-color: #f9cdde;
}
.list-group-item-dribbble.list-group-item-action {
	&:hover {
		color: #90416e;
		background-color: #f6b6cf;
	}
	&:focus {
		color: #90416e;
		background-color: #f6b6cf;
	}
}
.list-group-item-dribbble.list-group-item-action.active {
	color: #ffffff;
	background-color: #90416e;
	border-color: #90416e;
}
.list-group-item-dropbox {
	color: #2665ab;
	background-color: #c0e0ff;
}
.list-group-item-dropbox.list-group-item-action {
	&:hover {
		color: #2665ab;
		background-color: #a7d3ff;
	}
	&:focus {
		color: #2665ab;
		background-color: #a7d3ff;
	}
}
.list-group-item-dropbox.list-group-item-action.active {
	color: #ffffff;
	background-color: #2665ab;
	border-color: #2665ab;
}
.list-group-item-twitch {
	color: #3d3667;
	background-color: #cdc7da;
}
.list-group-item-twitch.list-group-item-action {
	&:hover {
		color: #3d3667;
		background-color: #bfb8d0;
	}
	&:focus {
		color: #3d3667;
		background-color: #bfb8d0;
	}
}
.list-group-item-twitch.list-group-item-action.active {
	color: #ffffff;
	background-color: #3d3667;
	border-color: #3d3667;
}
.list-group-item-paypal {
	color: #91773d;
	background-color: #faeac4;
}
.list-group-item-paypal.list-group-item-action {
	&:hover {
		color: #91773d;
		background-color: #f8e2ac;
	}
	&:focus {
		color: #91773d;
		background-color: #f8e2ac;
	}
}
.list-group-item-paypal.list-group-item-action.active {
	color: #ffffff;
	background-color: #91773d;
	border-color: #91773d;
}
.list-group-item-behance {
	color: #1647ab;
	background-color: #b8d0ff;
}
.list-group-item-behance.list-group-item-action {
	&:hover {
		color: #1647ab;
		background-color: #9fbfff;
	}
	&:focus {
		color: #1647ab;
		background-color: #9fbfff;
	}
}
.list-group-item-behance.list-group-item-action.active {
	color: #ffffff;
	background-color: #1647ab;
	border-color: #1647ab;
}
.list-group-item-reddit {
	color: #8f3d38;
	background-color: #f9cbc1;
}
.list-group-item-reddit.list-group-item-action {
	&:hover {
		color: #8f3d38;
		background-color: #f7b8aa;
	}
	&:focus {
		color: #8f3d38;
		background-color: #f7b8aa;
	}
}
.list-group-item-reddit.list-group-item-action.active {
	color: #ffffff;
	background-color: #8f3d38;
	border-color: #8f3d38;
}
.list-group-item-github {
	color: #282c38;
	background-color: #c1c1c1;
}
.list-group-item-github.list-group-item-action {
	&:hover {
		color: #282c38;
		background-color: #b4b4b4;
	}
	&:focus {
		color: #282c38;
		background-color: #b4b4b4;
	}
}
.list-group-item-github.list-group-item-action.active {
	color: #ffffff;
	background-color: #282c38;
	border-color: #282c38;
}
.close {
	font-size: calc(1.275rem + 0.3vw);
	font-weight: 600;
	line-height: 1;
	color: #2e3650;
	text-shadow: 0 1px 0 #ffffff;
	opacity: .5;
	transition: all 0.2s ease;
	&:hover {
		color: #2e3650;
		text-decoration: none;
		opacity: .75;
		background-color: transparent;
		color: rgba(0, 0, 0, 0.9);
		outline: none;
		span {
			&:not(.sr-only) {
				background-color: transparent;
			}
		}
	}
	&:focus {
		opacity: .75;
		background-color: transparent;
		color: rgba(0, 0, 0, 0.9);
		outline: none;
		span {
			&:not(.sr-only) {
				background-color: transparent;
			}
		}
	}
	&:disabled {
		pointer-events: none;
	}
	>span {
		&:not(.sr-only) {
			display: block;
			height: 1.25rem;
			width: 1.25rem;
			background-color: transparent;
			color: #2e3650;
			line-height: 17px;
			border-radius: 50%;
			font-size: 1.25rem;
			transition: all 0.2s ease;
		}
	}
}
.close.disabled {
	pointer-events: none;
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
}
.toast {
	max-width: 350px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	opacity: 0;
	border-radius: 0.5rem;
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}
.toast.showing {
	opacity: 1;
}
.toast.show {
	display: block;
	opacity: 1;
}
.toast.hide {
	display: none;
}
.toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: #93a5be;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
	padding: 0.75rem;
}
.modal-open {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade {
	.modal-dialog {
		transition: transform 0.3s ease-out;
		transform: translate(0, -50px);
	}
}
.modal.show {
	.modal-dialog {
		transform: none;
	}
}
.modal.modal-static {
	.modal-dialog {
		transform: scale(1.02);
	}
}
.modal-dialog-scrollable {
	max-height: calc(100% - 1rem);
	.modal-content {
		overflow: hidden;
	}
	.modal-body {
		overflow-y: auto;
	}
}
.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}
.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 0.0625rem solid rgba(46, 54, 80, 0.2);
	border-radius: 0.3rem;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
	outline: 0;
	border: 0;
	border-radius: 0.3rem;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #2e3650;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: 0.5;
}
.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 0.0625rem solid #eaedf2;
	border-top-left-radius: 0.2375rem;
	border-top-right-radius: 0.2375rem;
	.close {
		padding: 1rem 1rem;
		margin: -1rem -1rem -1rem auto;
	}
}
.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}
.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}
.modal-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 0.0625rem solid #eaedf2;
	border-bottom-right-radius: 0.2375rem;
	border-bottom-left-radius: 0.2375rem;
	>* {
		margin: 0.25rem;
	}
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
	.modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-header {
		border-radius: 0;
	}
	.modal-body {
		overflow-y: auto;
	}
	.modal-footer {
		border-radius: 0;
	}
}
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
	.tooltip-arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;
		&::before {
			position: absolute;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.tooltip.show {
	opacity: 0.9;
}
.bs-tooltip-top {
	padding: 0.4rem 0;
	.tooltip-arrow {
		bottom: 0;
		&::before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #ffffff;
		}
	}
}
.bs-tooltip-auto[x-placement^="top"] {
	padding: 0.4rem 0;
	.tooltip-arrow {
		bottom: 0;
		&::before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #ffffff;
		}
	}
}
.bs-tooltip-right {
	padding: 0 0.4rem;
	.tooltip-arrow {
		left: 0;
		width: 0.4rem;
		height: 0.8rem;
		&::before {
			right: 0;
			border-width: 0.4rem 0.4rem 0.4rem 0;
			border-right-color: #ffffff;
		}
	}
}
.bs-tooltip-auto[x-placement^="right"] {
	padding: 0 0.4rem;
	.tooltip-arrow {
		left: 0;
		width: 0.4rem;
		height: 0.8rem;
		&::before {
			right: 0;
			border-width: 0.4rem 0.4rem 0.4rem 0;
			border-right-color: #ffffff;
		}
	}
}
.bs-tooltip-bottom {
	padding: 0.4rem 0;
	.tooltip-arrow {
		top: 0;
		&::before {
			bottom: 0;
			border-width: 0 0.4rem 0.4rem;
			border-bottom-color: #ffffff;
		}
	}
}
.bs-tooltip-auto[x-placement^="bottom"] {
	padding: 0.4rem 0;
	.tooltip-arrow {
		top: 0;
		&::before {
			bottom: 0;
			border-width: 0 0.4rem 0.4rem;
			border-bottom-color: #ffffff;
		}
	}
}
.bs-tooltip-left {
	padding: 0 0.4rem;
	.tooltip-arrow {
		right: 0;
		width: 0.4rem;
		height: 0.8rem;
		&::before {
			left: 0;
			border-width: 0.4rem 0 0.4rem 0.4rem;
			border-left-color: #ffffff;
		}
	}
}
.bs-tooltip-auto[x-placement^="left"] {
	padding: 0 0.4rem;
	.tooltip-arrow {
		right: 0;
		width: 0.4rem;
		height: 0.8rem;
		&::before {
			left: 0;
			border-width: 0.4rem 0 0.4rem 0.4rem;
			border-left-color: #ffffff;
		}
	}
}
.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #2e3650;
	text-align: center;
	background-color: #ffffff;
	border-radius: 0.5rem;
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}
.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 0.0625rem solid rgba(46, 54, 80, 0.2);
	border-radius: 0.3rem;
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
	border: 0;
	.popover-arrow {
		position: absolute;
		display: block;
		width: 1rem;
		height: 0.5rem;
		margin: 0 0.3rem;
		&::before {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
		&::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.bs-popover-top {
	margin-bottom: 0.5rem;
	>.popover-arrow {
		bottom: -0.5625rem;
		&::before {
			bottom: 0;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			bottom: 0.0625rem;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: #ffffff;
		}
	}
}
.bs-popover-auto[x-placement^="top"] {
	margin-bottom: 0.5rem;
	>.popover-arrow {
		bottom: -0.5625rem;
		&::before {
			bottom: 0;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			bottom: 0.0625rem;
			border-width: 0.5rem 0.5rem 0;
			border-top-color: #ffffff;
		}
	}
}
.bs-popover-right {
	margin-left: 0.5rem;
	>.popover-arrow {
		left: -0.5625rem;
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		&::before {
			left: 0;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			left: 0.0625rem;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: #ffffff;
		}
	}
}
.bs-popover-auto[x-placement^="right"] {
	margin-left: 0.5rem;
	>.popover-arrow {
		left: -0.5625rem;
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		&::before {
			left: 0;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			left: 0.0625rem;
			border-width: 0.5rem 0.5rem 0.5rem 0;
			border-right-color: #ffffff;
		}
	}
}
.bs-popover-bottom {
	margin-top: 0.5rem;
	>.popover-arrow {
		top: -0.5625rem;
		&::before {
			top: 0;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			top: 0.0625rem;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: #ffffff;
		}
	}
	.popover-header {
		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 1rem;
			margin-left: -0.5rem;
			content: "";
			border-bottom: 0.0625rem solid #f7f7f7;
		}
	}
}
.bs-popover-auto[x-placement^="bottom"] {
	margin-top: 0.5rem;
	>.popover-arrow {
		top: -0.5625rem;
		&::before {
			top: 0;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			top: 0.0625rem;
			border-width: 0 0.5rem 0.5rem 0.5rem;
			border-bottom-color: #ffffff;
		}
	}
	.popover-header {
		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 1rem;
			margin-left: -0.5rem;
			content: "";
			border-bottom: 0.0625rem solid #f7f7f7;
		}
	}
}
.bs-popover-left {
	margin-right: 0.5rem;
	>.popover-arrow {
		right: -0.5625rem;
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		&::before {
			right: 0;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			right: 0.0625rem;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: #ffffff;
		}
	}
}
.bs-popover-auto[x-placement^="left"] {
	margin-right: 0.5rem;
	>.popover-arrow {
		right: -0.5625rem;
		width: 0.5rem;
		height: 1rem;
		margin: 0.3rem 0;
		&::before {
			right: 0;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: rgba(46, 54, 80, 0.25);
		}
		&::after {
			right: 0.0625rem;
			border-width: 0.5rem 0 0.5rem 0.5rem;
			border-left-color: #ffffff;
		}
	}
}
.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	color: #262B40;
	background-color: #f7f7f7;
	border-bottom: 0.0625rem solid #ebebeb;
	border-top-left-radius: 0.2375rem;
	border-top-right-radius: 0.2375rem;
	font-weight: 600;
	&:empty {
		display: none;
	}
}
.popover-body {
	padding: 1rem 1rem;
	color: #4A5073;
}
.carousel {
	position: relative;
}
.carousel.pointer-event {
	touch-action: pan-y;
}
.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}
.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}
.carousel-item.active {
	display: block;
}
.carousel-item-next {
	display: block;
	&:not(.carousel-item-left) {
		transform: translateX(100%);
	}
}
.carousel-item-prev {
	display: block;
	&:not(.carousel-item-right) {
		transform: translateX(-100%);
	}
}
.active.carousel-item-right {
	transform: translateX(100%);
}
.active.carousel-item-left {
	transform: translateX(-100%);
}
.carousel-fade {
	.carousel-item {
		opacity: 0;
		transition-property: opacity;
		transform: none;
	}
	.carousel-item.active {
		z-index: 1;
		opacity: 1;
	}
	.carousel-item-next.carousel-item-left {
		z-index: 1;
		opacity: 1;
	}
	.carousel-item-prev.carousel-item-right {
		z-index: 1;
		opacity: 1;
	}
	.active.carousel-item-left {
		z-index: 0;
		opacity: 0;
		transition: opacity 0s 0.6s;
	}
	.active.carousel-item-right {
		z-index: 0;
		opacity: 0;
		transition: opacity 0s 0.6s;
	}
}
.carousel-control-prev {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #ffffff;
	text-align: center;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	left: 0;
	&:hover {
		color: #ffffff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
	&:focus {
		color: #ffffff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
}
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	color: #ffffff;
	text-align: center;
	opacity: 0.5;
	transition: opacity 0.15s ease;
	right: 0;
	&:hover {
		color: #ffffff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
	&:focus {
		color: #ffffff;
		text-decoration: none;
		outline: 0;
		opacity: 0.9;
	}
}
.carousel-control-prev-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
	width: auto;
	height: auto;
	font-weight: 900;
	background-image: none;
	&::before {
		content: '\f060';
		font-family: "Font Awesome 5 Free";
		font-size: 2rem;
	}
}
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
	width: auto;
	height: auto;
	font-weight: 900;
	background-image: none;
	&:before {
		font-family: "Font Awesome 5 Free";
		content: "\f061";
		font-size: 2rem;
	}
}
.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		box-sizing: content-box;
		flex: 0 1 auto;
		width: 30px;
		height: 3px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		cursor: pointer;
		background-color: #ffffff;
		background-clip: padding-box;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		opacity: 0.5;
		transition: opacity 0.6s ease;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: transparent;
		border: 2px solid #ffffff;
	}
	.active {
		opacity: 1;
		opacity: 1;
		background: #ffffff;
	}
}
.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #ffffff;
	text-align: center;
	color: #ffffff;
	h5 {
		color: #ffffff;
	}
	.h5 {
		color: #ffffff;
	}
}
.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
	width: 1.2rem;
	height: 1.2rem;
	border-width: 0.2em;
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
	width: 1.2rem;
	height: 1.2rem;
}
.clearfix {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}
.link-primary {
	color: #262B40;
	&:hover {
		color: #0a0b10;
	}
	&:focus {
		color: #0a0b10;
	}
}
.link-secondary {
	color: #F8BD7A;
	&:hover {
		color: #f49931;
	}
	&:focus {
		color: #f49931;
	}
}
.link-tertiary {
	color: #1B998B;
	&:hover {
		color: #105850;
	}
	&:focus {
		color: #105850;
	}
}
.link-quaternary {
	color: #0948B3;
	&:hover {
		color: #052b6a;
	}
	&:focus {
		color: #052b6a;
	}
}
.link-primary-app {
	color: #EBF4F6;
	&:hover {
		color: #b6d7de;
	}
	&:focus {
		color: #b6d7de;
	}
}
.link-secondary-app {
	color: #424AA0;
	&:hover {
		color: #2c316a;
	}
	&:focus {
		color: #2c316a;
	}
}
.link-light {
	color: #eaedf2;
	&:hover {
		color: #bbc5d5;
	}
	&:focus {
		color: #bbc5d5;
	}
}
.link-lighten {
	color: #F0F3F6;
	&:hover {
		color: #c0cdd9;
	}
	&:focus {
		color: #c0cdd9;
	}
}
.link-success {
	color: #05A677;
	&:hover {
		color: #035c42;
	}
	&:focus {
		color: #035c42;
	}
}
.link-info {
	color: #0948B3;
	&:hover {
		color: #052b6a;
	}
	&:focus {
		color: #052b6a;
	}
}
.link-warning {
	color: #f5b759;
	&:hover {
		color: #f19811;
	}
	&:focus {
		color: #f19811;
	}
}
.link-danger {
	color: #FA5252;
	&:hover {
		color: #f80808;
	}
	&:focus {
		color: #f80808;
	}
}
.link-white {
	color: #ffffff;
	&:hover {
		color: #d9d9d9;
	}
	&:focus {
		color: #d9d9d9;
	}
}
.link-gray {
	color: #4A5073;
	&:hover {
		color: #2c3044;
	}
	&:focus {
		color: #2c3044;
	}
}
.link-neutral {
	color: #ffffff;
	&:hover {
		color: #d9d9d9;
	}
	&:focus {
		color: #d9d9d9;
	}
}
.link-dark {
	color: #262B40;
	&:hover {
		color: #0a0b10;
	}
	&:focus {
		color: #0a0b10;
	}
}
.link-soft {
	color: #f5f8fb;
	&:hover {
		color: #bed2e5;
	}
	&:focus {
		color: #bed2e5;
	}
}
.link-black {
	color: #2e3650;
	&:hover {
		color: #12151f;
	}
	&:focus {
		color: #12151f;
	}
}
.link-blue {
	color: #0948B3;
	&:hover {
		color: #052b6a;
	}
	&:focus {
		color: #052b6a;
	}
}
.link-pink {
	color: #C96480;
	&:hover {
		color: #a63a58;
	}
	&:focus {
		color: #a63a58;
	}
}
.link-cyan {
	color: #63b1bd;
	&:hover {
		color: #3f8995;
	}
	&:focus {
		color: #3f8995;
	}
}
.link-purple {
	color: #8965e0;
	&:hover {
		color: #5a2acf;
	}
	&:focus {
		color: #5a2acf;
	}
}
.link-soft-indigo {
	color: #f5e8ff;
	&:hover {
		color: #d49cff;
	}
	&:focus {
		color: #d49cff;
	}
}
.link-soft-green {
	color: #2CA58D;
	&:hover {
		color: #1c6959;
	}
	&:focus {
		color: #1c6959;
	}
}
.link-gray-100 {
	color: #f3f7fa;
	&:hover {
		color: #bdd3e4;
	}
	&:focus {
		color: #bdd3e4;
	}
}
.link-gray-200 {
	color: #f5f8fb;
	&:hover {
		color: #bed2e5;
	}
	&:focus {
		color: #bed2e5;
	}
}
.link-gray-300 {
	color: #F0F3F6;
	&:hover {
		color: #c0cdd9;
	}
	&:focus {
		color: #c0cdd9;
	}
}
.link-gray-400 {
	color: #eaedf2;
	&:hover {
		color: #bbc5d5;
	}
	&:focus {
		color: #bbc5d5;
	}
}
.link-gray-500 {
	color: #d1d7e0;
	&:hover {
		color: #a3afc1;
	}
	&:focus {
		color: #a3afc1;
	}
}
.link-gray-600 {
	color: #93a5be;
	&:hover {
		color: #637da1;
	}
	&:focus {
		color: #637da1;
	}
}
.link-gray-700 {
	color: #66799e;
	&:hover {
		color: #475570;
	}
	&:focus {
		color: #475570;
	}
}
.link-gray-800 {
	color: #506690;
	&:hover {
		color: #35435f;
	}
	&:focus {
		color: #35435f;
	}
}
.link-facebook {
	color: #3b5999;
	&:hover {
		color: #263962;
	}
	&:focus {
		color: #263962;
	}
}
.link-twitter {
	color: #1da1f2;
	&:hover {
		color: #0b76b8;
	}
	&:focus {
		color: #0b76b8;
	}
}
.link-google {
	color: #DB4337;
	&:hover {
		color: #a7281e;
	}
	&:focus {
		color: #a7281e;
	}
}
.link-instagram {
	color: #e4405f;
	&:hover {
		color: #bd1b39;
	}
	&:focus {
		color: #bd1b39;
	}
}
.link-pinterest {
	color: #bd081c;
	&:hover {
		color: #740511;
	}
	&:focus {
		color: #740511;
	}
}
.link-youtube {
	color: #cd201f;
	&:hover {
		color: #8b1615;
	}
	&:focus {
		color: #8b1615;
	}
}
.link-slack {
	color: #3aaf85;
	&:hover {
		color: #277659;
	}
	&:focus {
		color: #277659;
	}
}
.link-dribbble {
	color: #ea4c89;
	&:hover {
		color: #d11960;
	}
	&:focus {
		color: #d11960;
	}
}
.link-dropbox {
	color: #1E90FF;
	&:hover {
		color: #006ad1;
	}
	&:focus {
		color: #006ad1;
	}
}
.link-twitch {
	color: #4B367C;
	&:hover {
		color: #2b1f47;
	}
	&:focus {
		color: #2b1f47;
	}
}
.link-paypal {
	color: #ecb32c;
	&:hover {
		color: #bb8811;
	}
	&:focus {
		color: #bb8811;
	}
}
.link-behance {
	color: #0057ff;
	&:hover {
		color: #003db3;
	}
	&:focus {
		color: #003db3;
	}
}
.link-reddit {
	color: #E84422;
	&:hover {
		color: #ac2c12;
	}
	&:focus {
		color: #ac2c12;
	}
}
.link-github {
	color: #222222;
	&:hover {
		color: black;
	}
	&:focus {
		color: black;
	}
}
.embed-responsive {
	position: relative;
	width: 100%;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	object {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.embed-responsive-21by9 {
	&::before {
		padding-top: 42.85714%;
	}
}
.embed-responsive-16by9 {
	&::before {
		padding-top: 56.25%;
	}
}
.embed-responsive-4by3 {
	&::before {
		padding-top: 75%;
	}
}
.embed-responsive-1by1 {
	&::before {
		padding-top: 100%;
	}
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}
.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1020;
}
.sr-only {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
.sr-only-focusable {
	&:not(:focus) {
		position: absolute !important;
		width: 1px !important;
		height: 1px !important;
		padding: 0 !important;
		margin: -1px !important;
		overflow: hidden !important;
		clip: rect(0, 0, 0, 0) !important;
		white-space: nowrap !important;
		border: 0 !important;
	}
}
.stretched-link {
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		content: "";
	}
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
.overflow-auto {
	overflow: auto !important;
}
.overflow-hidden {
	overflow: hidden !important;
}
.d-none {
	display: none !important;
}
.d-inline {
	display: inline !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-block {
	display: block !important;
}
.d-table {
	display: table !important;
}
.d-table-row {
	display: table-row !important;
}
.d-table-cell {
	display: table-cell !important;
}
.d-flex {
	display: flex !important;
}
.d-inline-flex {
	display: inline-flex !important;
}
.shadow {
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
}
.shadow-sm {
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
}
.shadow-none {
	box-shadow: none !important;
}
.position-static {
	position: static !important;
}
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.headroom--pinned {
	position: fixed !important;
	transform: translateY(0%);
}
.headroom--unpinned {
	position: fixed !important;
	transform: translateY(-100%);
}
.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}
.border {
	border: 0.0625rem solid #eaedf2 !important;
}
.border-0 {
	border: 0 !important;
}
.border-top {
	border-top: 0.0625rem solid #eaedf2 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-right {
	border-right: 0.0625rem solid #eaedf2 !important;
}
.border-right-0 {
	border-right: 0 !important;
}
.border-bottom {
	border-bottom: 0.0625rem solid #eaedf2 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-left {
	border-left: 0.0625rem solid #eaedf2 !important;
}
.border-left-0 {
	border-left: 0 !important;
}
.border-primary {
	border-color: #262B40 !important;
}
.border-secondary {
	border-color: #F8BD7A !important;
}
.border-tertiary {
	border-color: #1B998B !important;
}
.border-quaternary {
	border-color: #0948B3 !important;
}
.border-primary-app {
	border-color: #EBF4F6 !important;
}
.border-secondary-app {
	border-color: #424AA0 !important;
}
.border-light {
	border-color: #eaedf2 !important;
}
.border-lighten {
	border-color: #F0F3F6 !important;
}
.border-success {
	border-color: #05A677 !important;
}
.border-info {
	border-color: #0948B3 !important;
}
.border-warning {
	border-color: #f5b759 !important;
}
.border-danger {
	border-color: #FA5252 !important;
}
.border-white {
	border-color: #ffffff !important;
}
.border-gray {
	border-color: #4A5073 !important;
}
.border-neutral {
	border-color: #ffffff !important;
}
.border-dark {
	border-color: #262B40 !important;
}
.border-soft {
	border-color: #f5f8fb !important;
}
.border-black {
	border-color: #2e3650 !important;
}
.border-blue {
	border-color: #0948B3 !important;
}
.border-pink {
	border-color: #C96480 !important;
}
.border-cyan {
	border-color: #63b1bd !important;
}
.border-purple {
	border-color: #8965e0 !important;
}
.border-soft-indigo {
	border-color: #f5e8ff !important;
}
.border-soft-green {
	border-color: #2CA58D !important;
}
.border-gray-100 {
	border-color: #f3f7fa !important;
}
.border-gray-200 {
	border-color: #f5f8fb !important;
}
.border-gray-300 {
	border-color: #F0F3F6 !important;
}
.border-gray-400 {
	border-color: #eaedf2 !important;
}
.border-gray-500 {
	border-color: #d1d7e0 !important;
}
.border-gray-600 {
	border-color: #93a5be !important;
}
.border-gray-700 {
	border-color: #66799e !important;
}
.border-gray-800 {
	border-color: #506690 !important;
}
.border-facebook {
	border-color: #3b5999 !important;
}
.border-twitter {
	border-color: #1da1f2 !important;
}
.border-google {
	border-color: #DB4337 !important;
}
.border-instagram {
	border-color: #e4405f !important;
}
.border-pinterest {
	border-color: #bd081c !important;
}
.border-youtube {
	border-color: #cd201f !important;
}
.border-slack {
	border-color: #3aaf85 !important;
}
.border-dribbble {
	border-color: #ea4c89 !important;
}
.border-dropbox {
	border-color: #1E90FF !important;
}
.border-twitch {
	border-color: #4B367C !important;
}
.border-paypal {
	border-color: #ecb32c !important;
}
.border-behance {
	border-color: #0057ff !important;
}
.border-reddit {
	border-color: #E84422 !important;
}
.border-github {
	border-color: #222222 !important;
}
.w-25 {
	width: 25% !important;
}
.w-50 {
	width: 50% !important;
}
.w-75 {
	width: 75% !important;
}
.w-100 {
	width: 100% !important;
}
.w-auto {
	width: auto !important;
	width: auto !important;
}
.mw-100 {
	max-width: 100% !important;
}
.vw-100 {
	width: 100vw !important;
}
.min-vw-100 {
	min-width: 100vw !important;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.mh-100 {
	max-height: 100% !important;
}
.vh-100 {
	height: 100vh !important;
}
.min-vh-100 {
	min-height: 100vh !important;
}
.flex-fill {
	flex: 1 1 auto !important;
}
.flex-row {
	flex-direction: row !important;
}
.flex-column {
	flex-direction: column !important;
}
.flex-row-reverse {
	flex-direction: row-reverse !important;
}
.flex-column-reverse {
	flex-direction: column-reverse !important;
}
.flex-grow-0 {
	flex-grow: 0 !important;
}
.flex-grow-1 {
	flex-grow: 1 !important;
}
.flex-shrink-0 {
	flex-shrink: 0 !important;
}
.flex-shrink-1 {
	flex-shrink: 1 !important;
}
.flex-wrap {
	flex-wrap: wrap !important;
}
.flex-nowrap {
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}
.justify-content-start {
	justify-content: flex-start !important;
}
.justify-content-end {
	justify-content: flex-end !important;
}
.justify-content-center {
	justify-content: center !important;
}
.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-around {
	justify-content: space-around !important;
}
.justify-content-evenly {
	justify-content: space-evenly !important;
}
.align-items-start {
	align-items: flex-start !important;
}
.align-items-end {
	align-items: flex-end !important;
}
.align-items-center {
	align-items: center !important;
}
.align-items-baseline {
	align-items: baseline !important;
}
.align-items-stretch {
	align-items: stretch !important;
}
.align-content-start {
	align-content: flex-start !important;
}
.align-content-end {
	align-content: flex-end !important;
}
.align-content-center {
	align-content: center !important;
}
.align-content-between {
	align-content: space-between !important;
}
.align-content-around {
	align-content: space-around !important;
}
.align-content-stretch {
	align-content: stretch !important;
}
.align-self-auto {
	align-self: auto !important;
}
.align-self-start {
	align-self: flex-start !important;
}
.align-self-end {
	align-self: flex-end !important;
}
.align-self-center {
	align-self: center !important;
}
.align-self-baseline {
	align-self: baseline !important;
}
.align-self-stretch {
	align-self: stretch !important;
}
.order-first {
	order: -1 !important;
}
.order-0 {
	order: 0 !important;
}
.order-1 {
	order: 1 !important;
}
.order-2 {
	order: 2 !important;
}
.order-3 {
	order: 3 !important;
}
.order-4 {
	order: 4 !important;
}
.order-5 {
	order: 5 !important;
}
.order-last {
	order: 6 !important;
}
.m-0 {
	margin: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.m-4 {
	margin: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.m-6 {
	margin: 5rem !important;
}
.m-7 {
	margin: 8rem !important;
}
.m-8 {
	margin: 10rem !important;
}
.m-9 {
	margin: 11rem !important;
}
.m-10 {
	margin: 14rem !important;
}
.m-11 {
	margin: 16rem !important;
}
.m-12 {
	margin: 20rem !important;
}
.m-sm {
	margin: 1rem !important;
}
.m-md {
	margin: 2rem !important;
}
.m-lg {
	margin: 4rem !important;
}
.m-xl {
	margin: 8rem !important;
}
.m-auto {
	margin: auto !important;
}
.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}
.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}
.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}
.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}
.mx-6 {
	margin-right: 5rem !important;
	margin-left: 5rem !important;
}
.mx-7 {
	margin-right: 8rem !important;
	margin-left: 8rem !important;
}
.mx-8 {
	margin-right: 10rem !important;
	margin-left: 10rem !important;
}
.mx-9 {
	margin-right: 11rem !important;
	margin-left: 11rem !important;
}
.mx-10 {
	margin-right: 14rem !important;
	margin-left: 14rem !important;
}
.mx-11 {
	margin-right: 16rem !important;
	margin-left: 16rem !important;
}
.mx-12 {
	margin-right: 20rem !important;
	margin-left: 20rem !important;
}
.mx-sm {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}
.mx-md {
	margin-right: 2rem !important;
	margin-left: 2rem !important;
}
.mx-lg {
	margin-right: 4rem !important;
	margin-left: 4rem !important;
}
.mx-xl {
	margin-right: 8rem !important;
	margin-left: 8rem !important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}
.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}
.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}
.my-6 {
	margin-top: 5rem !important;
	margin-bottom: 5rem !important;
}
.my-7 {
	margin-top: 8rem !important;
	margin-bottom: 8rem !important;
}
.my-8 {
	margin-top: 10rem !important;
	margin-bottom: 10rem !important;
}
.my-9 {
	margin-top: 11rem !important;
	margin-bottom: 11rem !important;
}
.my-10 {
	margin-top: 14rem !important;
	margin-bottom: 14rem !important;
}
.my-11 {
	margin-top: 16rem !important;
	margin-bottom: 16rem !important;
}
.my-12 {
	margin-top: 20rem !important;
	margin-bottom: 20rem !important;
}
.my-sm {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.my-md {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}
.my-lg {
	margin-top: 4rem !important;
	margin-bottom: 4rem !important;
}
.my-xl {
	margin-top: 8rem !important;
	margin-bottom: 8rem !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: 0.25rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
.mt-5 {
	margin-top: 3rem !important;
}
.mt-6 {
	margin-top: 5rem !important;
}
.mt-7 {
	margin-top: 8rem !important;
}
.mt-8 {
	margin-top: 10rem !important;
}
.mt-9 {
	margin-top: 11rem !important;
}
.mt-10 {
	margin-top: 14rem !important;
}
.mt-11 {
	margin-top: 16rem !important;
}
.mt-12 {
	margin-top: 20rem !important;
}
.mt-sm {
	margin-top: 1rem !important;
}
.mt-md {
	margin-top: 2rem !important;
}
.mt-lg {
	margin-top: 4rem !important;
}
.mt-xl {
	margin-top: 8rem !important;
}
.mt-auto {
	margin-top: auto !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mr-1 {
	margin-right: 0.25rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mr-6 {
	margin-right: 5rem !important;
}
.mr-7 {
	margin-right: 8rem !important;
}
.mr-8 {
	margin-right: 10rem !important;
}
.mr-9 {
	margin-right: 11rem !important;
}
.mr-10 {
	margin-right: 14rem !important;
}
.mr-11 {
	margin-right: 16rem !important;
}
.mr-12 {
	margin-right: 20rem !important;
}
.mr-sm {
	margin-right: 1rem !important;
}
.mr-md {
	margin-right: 2rem !important;
}
.mr-lg {
	margin-right: 4rem !important;
}
.mr-xl {
	margin-right: 8rem !important;
}
.mr-auto {
	margin-right: auto !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-1 {
	margin-bottom: 0.25rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.mb-6 {
	margin-bottom: 5rem !important;
}
.mb-7 {
	margin-bottom: 8rem !important;
}
.mb-8 {
	margin-bottom: 10rem !important;
}
.mb-9 {
	margin-bottom: 11rem !important;
}
.mb-10 {
	margin-bottom: 14rem !important;
}
.mb-11 {
	margin-bottom: 16rem !important;
}
.mb-12 {
	margin-bottom: 20rem !important;
}
.mb-sm {
	margin-bottom: 1rem !important;
}
.mb-md {
	margin-bottom: 2rem !important;
}
.mb-lg {
	margin-bottom: 4rem !important;
}
.mb-xl {
	margin-bottom: 8rem !important;
}
.mb-auto {
	margin-bottom: auto !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.ml-1 {
	margin-left: 0.25rem !important;
}
.ml-2 {
	margin-left: 0.5rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.ml-6 {
	margin-left: 5rem !important;
}
.ml-7 {
	margin-left: 8rem !important;
}
.ml-8 {
	margin-left: 10rem !important;
}
.ml-9 {
	margin-left: 11rem !important;
}
.ml-10 {
	margin-left: 14rem !important;
}
.ml-11 {
	margin-left: 16rem !important;
}
.ml-12 {
	margin-left: 20rem !important;
}
.ml-sm {
	margin-left: 1rem !important;
}
.ml-md {
	margin-left: 2rem !important;
}
.ml-lg {
	margin-left: 4rem !important;
}
.ml-xl {
	margin-left: 8rem !important;
}
.ml-auto {
	margin-left: auto !important;
}
.m-n1 {
	margin: -0.25rem !important;
}
.m-n2 {
	margin: -0.5rem !important;
}
.m-n3 {
	margin: -1rem !important;
}
.m-n4 {
	margin: -1.5rem !important;
}
.m-n5 {
	margin: -3rem !important;
}
.m-n6 {
	margin: -5rem !important;
}
.m-n7 {
	margin: -8rem !important;
}
.m-n8 {
	margin: -10rem !important;
}
.m-n9 {
	margin: -11rem !important;
}
.m-n10 {
	margin: -14rem !important;
}
.m-n11 {
	margin: -16rem !important;
}
.m-n12 {
	margin: -20rem !important;
}
.m-nsm {
	margin: -1rem !important;
}
.m-nmd {
	margin: -2rem !important;
}
.m-nlg {
	margin: -4rem !important;
}
.m-nxl {
	margin: -8rem !important;
}
.mx-n1 {
	margin-right: -0.25rem !important;
	margin-left: -0.25rem !important;
}
.mx-n2 {
	margin-right: -0.5rem !important;
	margin-left: -0.5rem !important;
}
.mx-n3 {
	margin-right: -1rem !important;
	margin-left: -1rem !important;
}
.mx-n4 {
	margin-right: -1.5rem !important;
	margin-left: -1.5rem !important;
}
.mx-n5 {
	margin-right: -3rem !important;
	margin-left: -3rem !important;
}
.mx-n6 {
	margin-right: -5rem !important;
	margin-left: -5rem !important;
}
.mx-n7 {
	margin-right: -8rem !important;
	margin-left: -8rem !important;
}
.mx-n8 {
	margin-right: -10rem !important;
	margin-left: -10rem !important;
}
.mx-n9 {
	margin-right: -11rem !important;
	margin-left: -11rem !important;
}
.mx-n10 {
	margin-right: -14rem !important;
	margin-left: -14rem !important;
}
.mx-n11 {
	margin-right: -16rem !important;
	margin-left: -16rem !important;
}
.mx-n12 {
	margin-right: -20rem !important;
	margin-left: -20rem !important;
}
.mx-nsm {
	margin-right: -1rem !important;
	margin-left: -1rem !important;
}
.mx-nmd {
	margin-right: -2rem !important;
	margin-left: -2rem !important;
}
.mx-nlg {
	margin-right: -4rem !important;
	margin-left: -4rem !important;
}
.mx-nxl {
	margin-right: -8rem !important;
	margin-left: -8rem !important;
}
.my-n1 {
	margin-top: -0.25rem !important;
	margin-bottom: -0.25rem !important;
}
.my-n2 {
	margin-top: -0.5rem !important;
	margin-bottom: -0.5rem !important;
}
.my-n3 {
	margin-top: -1rem !important;
	margin-bottom: -1rem !important;
}
.my-n4 {
	margin-top: -1.5rem !important;
	margin-bottom: -1.5rem !important;
}
.my-n5 {
	margin-top: -3rem !important;
	margin-bottom: -3rem !important;
}
.my-n6 {
	margin-top: -5rem !important;
	margin-bottom: -5rem !important;
}
.my-n7 {
	margin-top: -8rem !important;
	margin-bottom: -8rem !important;
}
.my-n8 {
	margin-top: -10rem !important;
	margin-bottom: -10rem !important;
}
.my-n9 {
	margin-top: -11rem !important;
	margin-bottom: -11rem !important;
}
.my-n10 {
	margin-top: -14rem !important;
	margin-bottom: -14rem !important;
}
.my-n11 {
	margin-top: -16rem !important;
	margin-bottom: -16rem !important;
}
.my-n12 {
	margin-top: -20rem !important;
	margin-bottom: -20rem !important;
}
.my-nsm {
	margin-top: -1rem !important;
	margin-bottom: -1rem !important;
}
.my-nmd {
	margin-top: -2rem !important;
	margin-bottom: -2rem !important;
}
.my-nlg {
	margin-top: -4rem !important;
	margin-bottom: -4rem !important;
}
.my-nxl {
	margin-top: -8rem !important;
	margin-bottom: -8rem !important;
}
.mt-n1 {
	margin-top: -0.25rem !important;
}
.mt-n2 {
	margin-top: -0.5rem !important;
}
.mt-n3 {
	margin-top: -1rem !important;
}
.mt-n4 {
	margin-top: -1.5rem !important;
}
.mt-n5 {
	margin-top: -3rem !important;
}
.mt-n6 {
	margin-top: -5rem !important;
}
.mt-n7 {
	margin-top: -8rem !important;
}
.mt-n8 {
	margin-top: -10rem !important;
}
.mt-n9 {
	margin-top: -11rem !important;
}
.mt-n10 {
	margin-top: -14rem !important;
}
.mt-n11 {
	margin-top: -16rem !important;
}
.mt-n12 {
	margin-top: -20rem !important;
}
.mt-nsm {
	margin-top: -1rem !important;
}
.mt-nmd {
	margin-top: -2rem !important;
}
.mt-nlg {
	margin-top: -4rem !important;
}
.mt-nxl {
	margin-top: -8rem !important;
}
.mr-n1 {
	margin-right: -0.25rem !important;
}
.mr-n2 {
	margin-right: -0.5rem !important;
}
.mr-n3 {
	margin-right: -1rem !important;
}
.mr-n4 {
	margin-right: -1.5rem !important;
}
.mr-n5 {
	margin-right: -3rem !important;
}
.mr-n6 {
	margin-right: -5rem !important;
}
.mr-n7 {
	margin-right: -8rem !important;
}
.mr-n8 {
	margin-right: -10rem !important;
}
.mr-n9 {
	margin-right: -11rem !important;
}
.mr-n10 {
	margin-right: -14rem !important;
}
.mr-n11 {
	margin-right: -16rem !important;
}
.mr-n12 {
	margin-right: -20rem !important;
}
.mr-nsm {
	margin-right: -1rem !important;
}
.mr-nmd {
	margin-right: -2rem !important;
}
.mr-nlg {
	margin-right: -4rem !important;
}
.mr-nxl {
	margin-right: -8rem !important;
}
.mb-n1 {
	margin-bottom: -0.25rem !important;
}
.mb-n2 {
	margin-bottom: -0.5rem !important;
}
.mb-n3 {
	margin-bottom: -1rem !important;
}
.mb-n4 {
	margin-bottom: -1.5rem !important;
}
.mb-n5 {
	margin-bottom: -3rem !important;
}
.mb-n6 {
	margin-bottom: -5rem !important;
}
.mb-n7 {
	margin-bottom: -8rem !important;
}
.mb-n8 {
	margin-bottom: -10rem !important;
}
.mb-n9 {
	margin-bottom: -11rem !important;
}
.mb-n10 {
	margin-bottom: -14rem !important;
}
.mb-n11 {
	margin-bottom: -16rem !important;
}
.mb-n12 {
	margin-bottom: -20rem !important;
}
.mb-nsm {
	margin-bottom: -1rem !important;
}
.mb-nmd {
	margin-bottom: -2rem !important;
}
.mb-nlg {
	margin-bottom: -4rem !important;
}
.mb-nxl {
	margin-bottom: -8rem !important;
}
.ml-n1 {
	margin-left: -0.25rem !important;
}
.ml-n2 {
	margin-left: -0.5rem !important;
}
.ml-n3 {
	margin-left: -1rem !important;
}
.ml-n4 {
	margin-left: -1.5rem !important;
}
.ml-n5 {
	margin-left: -3rem !important;
}
.ml-n6 {
	margin-left: -5rem !important;
}
.ml-n7 {
	margin-left: -8rem !important;
}
.ml-n8 {
	margin-left: -10rem !important;
}
.ml-n9 {
	margin-left: -11rem !important;
}
.ml-n10 {
	margin-left: -14rem !important;
}
.ml-n11 {
	margin-left: -16rem !important;
}
.ml-n12 {
	margin-left: -20rem !important;
}
.ml-nsm {
	margin-left: -1rem !important;
}
.ml-nmd {
	margin-left: -2rem !important;
}
.ml-nlg {
	margin-left: -4rem !important;
}
.ml-nxl {
	margin-left: -8rem !important;
}
.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}
.p-6 {
	padding: 5rem !important;
}
.p-7 {
	padding: 8rem !important;
}
.p-8 {
	padding: 10rem !important;
}
.p-9 {
	padding: 11rem !important;
}
.p-10 {
	padding: 14rem !important;
}
.p-11 {
	padding: 16rem !important;
}
.p-12 {
	padding: 20rem !important;
}
.p-sm {
	padding: 1rem !important;
}
.p-md {
	padding: 2rem !important;
}
.p-lg {
	padding: 4rem !important;
}
.p-xl {
	padding: 8rem !important;
}
.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}
.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}
.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}
.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.px-6 {
	padding-right: 5rem !important;
	padding-left: 5rem !important;
}
.px-7 {
	padding-right: 8rem !important;
	padding-left: 8rem !important;
}
.px-8 {
	padding-right: 10rem !important;
	padding-left: 10rem !important;
}
.px-9 {
	padding-right: 11rem !important;
	padding-left: 11rem !important;
}
.px-10 {
	padding-right: 14rem !important;
	padding-left: 14rem !important;
}
.px-11 {
	padding-right: 16rem !important;
	padding-left: 16rem !important;
}
.px-12 {
	padding-right: 20rem !important;
	padding-left: 20rem !important;
}
.px-sm {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}
.px-md {
	padding-right: 2rem !important;
	padding-left: 2rem !important;
}
.px-lg {
	padding-right: 4rem !important;
	padding-left: 4rem !important;
}
.px-xl {
	padding-right: 8rem !important;
	padding-left: 8rem !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}
.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}
.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.py-6 {
	padding-top: 5rem !important;
	padding-bottom: 5rem !important;
}
.py-7 {
	padding-top: 8rem !important;
	padding-bottom: 8rem !important;
}
.py-8 {
	padding-top: 10rem !important;
	padding-bottom: 10rem !important;
}
.py-9 {
	padding-top: 11rem !important;
	padding-bottom: 11rem !important;
}
.py-10 {
	padding-top: 14rem !important;
	padding-bottom: 14rem !important;
}
.py-11 {
	padding-top: 16rem !important;
	padding-bottom: 16rem !important;
}
.py-12 {
	padding-top: 20rem !important;
	padding-bottom: 20rem !important;
}
.py-sm {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.py-md {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}
.py-lg {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important;
}
.py-xl {
	padding-top: 8rem !important;
	padding-bottom: 8rem !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.pt-1 {
	padding-top: 0.25rem !important;
}
.pt-2 {
	padding-top: 0.5rem !important;
}
.pt-3 {
	padding-top: 1rem !important;
}
.pt-4 {
	padding-top: 1.5rem !important;
}
.pt-5 {
	padding-top: 3rem !important;
}
.pt-6 {
	padding-top: 5rem !important;
}
.pt-7 {
	padding-top: 8rem !important;
}
.pt-8 {
	padding-top: 10rem !important;
}
.pt-9 {
	padding-top: 11rem !important;
}
.pt-10 {
	padding-top: 14rem !important;
}
.pt-11 {
	padding-top: 16rem !important;
}
.pt-12 {
	padding-top: 20rem !important;
}
.pt-sm {
	padding-top: 1rem !important;
}
.pt-md {
	padding-top: 2rem !important;
}
.pt-lg {
	padding-top: 4rem !important;
}
.pt-xl {
	padding-top: 8rem !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pr-1 {
	padding-right: 0.25rem !important;
}
.pr-2 {
	padding-right: 0.5rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.pr-6 {
	padding-right: 5rem !important;
}
.pr-7 {
	padding-right: 8rem !important;
}
.pr-8 {
	padding-right: 10rem !important;
}
.pr-9 {
	padding-right: 11rem !important;
}
.pr-10 {
	padding-right: 14rem !important;
}
.pr-11 {
	padding-right: 16rem !important;
}
.pr-12 {
	padding-right: 20rem !important;
}
.pr-sm {
	padding-right: 1rem !important;
}
.pr-md {
	padding-right: 2rem !important;
}
.pr-lg {
	padding-right: 4rem !important;
}
.pr-xl {
	padding-right: 8rem !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pb-1 {
	padding-bottom: 0.25rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pb-6 {
	padding-bottom: 5rem !important;
}
.pb-7 {
	padding-bottom: 8rem !important;
}
.pb-8 {
	padding-bottom: 10rem !important;
}
.pb-9 {
	padding-bottom: 11rem !important;
}
.pb-10 {
	padding-bottom: 14rem !important;
}
.pb-11 {
	padding-bottom: 16rem !important;
}
.pb-12 {
	padding-bottom: 20rem !important;
}
.pb-sm {
	padding-bottom: 1rem !important;
}
.pb-md {
	padding-bottom: 2rem !important;
}
.pb-lg {
	padding-bottom: 4rem !important;
}
.pb-xl {
	padding-bottom: 8rem !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.pl-1 {
	padding-left: 0.25rem !important;
}
.pl-2 {
	padding-left: 0.5rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.pl-6 {
	padding-left: 5rem !important;
}
.pl-7 {
	padding-left: 8rem !important;
}
.pl-8 {
	padding-left: 10rem !important;
}
.pl-9 {
	padding-left: 11rem !important;
}
.pl-10 {
	padding-left: 14rem !important;
}
.pl-11 {
	padding-left: 16rem !important;
}
.pl-12 {
	padding-left: 20rem !important;
}
.pl-sm {
	padding-left: 1rem !important;
}
.pl-md {
	padding-left: 2rem !important;
}
.pl-lg {
	padding-left: 4rem !important;
}
.pl-xl {
	padding-left: 8rem !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-lighter {
	font-weight: lighter !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-bold {
	font-weight: 600 !important;
}
.font-weight-bolder {
	font-weight: 700 !important;
}
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.text-left {
	text-align: left !important;
	text-align: left !important;
}
.text-right {
	text-align: right !important;
	text-align: right !important;
}
.text-center {
	text-align: center !important;
	text-align: center !important;
}
.text-primary {
	color: #262B40 !important;
	color: #262B40 !important;
	&:hover {
		color: #262B40 !important;
	}
	a {
		&:not(.btn) {
			color: #262B40;
		}
	}
	h1 {
		color: #262B40;
	}
	.h1 {
		color: #262B40;
		color: #262B40;
	}
	h2 {
		color: #262B40;
	}
	.h2 {
		color: #262B40;
		color: #262B40;
	}
	h3 {
		color: #262B40;
	}
	.h3 {
		color: #262B40;
		color: #262B40;
	}
	h4 {
		color: #262B40;
	}
	.h4 {
		color: #262B40;
		color: #262B40;
	}
	h5 {
		color: #262B40;
	}
	.h5 {
		color: #262B40;
		color: #262B40;
	}
	h6 {
		color: #262B40;
	}
	.h6 {
		color: #262B40;
		color: #262B40;
	}
	.display-1 {
		color: #262B40;
	}
	.display-2 {
		color: #262B40;
	}
	.display-3 {
		color: #262B40;
	}
	.display-4 {
		color: #262B40;
	}
	.text-muted {
		color: rgba(38, 43, 64, 0.7) !important;
	}
}
.text-secondary {
	color: #F8BD7A !important;
	color: #F8BD7A !important;
	&:hover {
		color: #F8BD7A !important;
	}
	a {
		&:not(.btn) {
			color: #F8BD7A;
		}
	}
	h1 {
		color: #F8BD7A;
	}
	.h1 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	h2 {
		color: #F8BD7A;
	}
	.h2 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	h3 {
		color: #F8BD7A;
	}
	.h3 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	h4 {
		color: #F8BD7A;
	}
	.h4 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	h5 {
		color: #F8BD7A;
	}
	.h5 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	h6 {
		color: #F8BD7A;
	}
	.h6 {
		color: #F8BD7A;
		color: #F8BD7A;
	}
	.display-1 {
		color: #F8BD7A;
	}
	.display-2 {
		color: #F8BD7A;
	}
	.display-3 {
		color: #F8BD7A;
	}
	.display-4 {
		color: #F8BD7A;
	}
	.text-muted {
		color: rgba(248, 189, 122, 0.7) !important;
	}
}
.text-tertiary {
	color: #1B998B !important;
	color: #1B998B !important;
	&:hover {
		color: #1B998B !important;
	}
	a {
		&:not(.btn) {
			color: #1B998B;
		}
	}
	h1 {
		color: #1B998B;
	}
	.h1 {
		color: #1B998B;
		color: #1B998B;
	}
	h2 {
		color: #1B998B;
	}
	.h2 {
		color: #1B998B;
		color: #1B998B;
	}
	h3 {
		color: #1B998B;
	}
	.h3 {
		color: #1B998B;
		color: #1B998B;
	}
	h4 {
		color: #1B998B;
	}
	.h4 {
		color: #1B998B;
		color: #1B998B;
	}
	h5 {
		color: #1B998B;
	}
	.h5 {
		color: #1B998B;
		color: #1B998B;
	}
	h6 {
		color: #1B998B;
	}
	.h6 {
		color: #1B998B;
		color: #1B998B;
	}
	.display-1 {
		color: #1B998B;
	}
	.display-2 {
		color: #1B998B;
	}
	.display-3 {
		color: #1B998B;
	}
	.display-4 {
		color: #1B998B;
	}
	.text-muted {
		color: rgba(27, 153, 139, 0.7) !important;
	}
}
.text-quaternary {
	color: #0948B3 !important;
	color: #0948B3 !important;
	&:hover {
		color: #0948B3 !important;
	}
	a {
		&:not(.btn) {
			color: #0948B3;
		}
	}
	h1 {
		color: #0948B3;
	}
	.h1 {
		color: #0948B3;
		color: #0948B3;
	}
	h2 {
		color: #0948B3;
	}
	.h2 {
		color: #0948B3;
		color: #0948B3;
	}
	h3 {
		color: #0948B3;
	}
	.h3 {
		color: #0948B3;
		color: #0948B3;
	}
	h4 {
		color: #0948B3;
	}
	.h4 {
		color: #0948B3;
		color: #0948B3;
	}
	h5 {
		color: #0948B3;
	}
	.h5 {
		color: #0948B3;
		color: #0948B3;
	}
	h6 {
		color: #0948B3;
	}
	.h6 {
		color: #0948B3;
		color: #0948B3;
	}
	.display-1 {
		color: #0948B3;
	}
	.display-2 {
		color: #0948B3;
	}
	.display-3 {
		color: #0948B3;
	}
	.display-4 {
		color: #0948B3;
	}
	.text-muted {
		color: rgba(9, 72, 179, 0.7) !important;
	}
}
.text-primary-app {
	color: #EBF4F6 !important;
	color: #EBF4F6 !important;
	&:hover {
		color: #EBF4F6 !important;
	}
	a {
		&:not(.btn) {
			color: #EBF4F6;
		}
	}
	h1 {
		color: #EBF4F6;
	}
	.h1 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	h2 {
		color: #EBF4F6;
	}
	.h2 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	h3 {
		color: #EBF4F6;
	}
	.h3 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	h4 {
		color: #EBF4F6;
	}
	.h4 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	h5 {
		color: #EBF4F6;
	}
	.h5 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	h6 {
		color: #EBF4F6;
	}
	.h6 {
		color: #EBF4F6;
		color: #EBF4F6;
	}
	.display-1 {
		color: #EBF4F6;
	}
	.display-2 {
		color: #EBF4F6;
	}
	.display-3 {
		color: #EBF4F6;
	}
	.display-4 {
		color: #EBF4F6;
	}
	.text-muted {
		color: rgba(235, 244, 246, 0.7) !important;
	}
}
.text-secondary-app {
	color: #424AA0 !important;
	color: #424AA0 !important;
	&:hover {
		color: #424AA0 !important;
	}
	a {
		&:not(.btn) {
			color: #424AA0;
		}
	}
	h1 {
		color: #424AA0;
	}
	.h1 {
		color: #424AA0;
		color: #424AA0;
	}
	h2 {
		color: #424AA0;
	}
	.h2 {
		color: #424AA0;
		color: #424AA0;
	}
	h3 {
		color: #424AA0;
	}
	.h3 {
		color: #424AA0;
		color: #424AA0;
	}
	h4 {
		color: #424AA0;
	}
	.h4 {
		color: #424AA0;
		color: #424AA0;
	}
	h5 {
		color: #424AA0;
	}
	.h5 {
		color: #424AA0;
		color: #424AA0;
	}
	h6 {
		color: #424AA0;
	}
	.h6 {
		color: #424AA0;
		color: #424AA0;
	}
	.display-1 {
		color: #424AA0;
	}
	.display-2 {
		color: #424AA0;
	}
	.display-3 {
		color: #424AA0;
	}
	.display-4 {
		color: #424AA0;
	}
	.text-muted {
		color: rgba(66, 74, 160, 0.7) !important;
	}
}
.text-light {
	color: #eaedf2 !important;
	color: #eaedf2 !important;
	&:hover {
		color: #eaedf2 !important;
	}
	a {
		&:not(.btn) {
			color: #eaedf2;
		}
	}
	h1 {
		color: #eaedf2;
	}
	.h1 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h2 {
		color: #eaedf2;
	}
	.h2 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h3 {
		color: #eaedf2;
	}
	.h3 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h4 {
		color: #eaedf2;
	}
	.h4 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h5 {
		color: #eaedf2;
	}
	.h5 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h6 {
		color: #eaedf2;
	}
	.h6 {
		color: #eaedf2;
		color: #eaedf2;
	}
	.display-1 {
		color: #eaedf2;
	}
	.display-2 {
		color: #eaedf2;
	}
	.display-3 {
		color: #eaedf2;
	}
	.display-4 {
		color: #eaedf2;
	}
	.text-muted {
		color: rgba(234, 237, 242, 0.7) !important;
	}
}
.text-lighten {
	color: #F0F3F6 !important;
	color: #F0F3F6 !important;
	&:hover {
		color: #F0F3F6 !important;
	}
	a {
		&:not(.btn) {
			color: #F0F3F6;
		}
	}
	h1 {
		color: #F0F3F6;
	}
	.h1 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h2 {
		color: #F0F3F6;
	}
	.h2 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h3 {
		color: #F0F3F6;
	}
	.h3 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h4 {
		color: #F0F3F6;
	}
	.h4 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h5 {
		color: #F0F3F6;
	}
	.h5 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h6 {
		color: #F0F3F6;
	}
	.h6 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	.display-1 {
		color: #F0F3F6;
	}
	.display-2 {
		color: #F0F3F6;
	}
	.display-3 {
		color: #F0F3F6;
	}
	.display-4 {
		color: #F0F3F6;
	}
	.text-muted {
		color: rgba(240, 243, 246, 0.7) !important;
	}
}
.text-success {
	color: #05A677 !important;
	color: #05A677 !important;
	&:hover {
		color: #05A677 !important;
	}
	a {
		&:not(.btn) {
			color: #05A677;
		}
	}
	h1 {
		color: #05A677;
	}
	.h1 {
		color: #05A677;
		color: #05A677;
	}
	h2 {
		color: #05A677;
	}
	.h2 {
		color: #05A677;
		color: #05A677;
	}
	h3 {
		color: #05A677;
	}
	.h3 {
		color: #05A677;
		color: #05A677;
	}
	h4 {
		color: #05A677;
	}
	.h4 {
		color: #05A677;
		color: #05A677;
	}
	h5 {
		color: #05A677;
	}
	.h5 {
		color: #05A677;
		color: #05A677;
	}
	h6 {
		color: #05A677;
	}
	.h6 {
		color: #05A677;
		color: #05A677;
	}
	.display-1 {
		color: #05A677;
	}
	.display-2 {
		color: #05A677;
	}
	.display-3 {
		color: #05A677;
	}
	.display-4 {
		color: #05A677;
	}
	.text-muted {
		color: rgba(5, 166, 119, 0.7) !important;
	}
}
.text-info {
	color: #0948B3 !important;
	color: #0948B3 !important;
	&:hover {
		color: #0948B3 !important;
	}
	a {
		&:not(.btn) {
			color: #0948B3;
		}
	}
	h1 {
		color: #0948B3;
	}
	.h1 {
		color: #0948B3;
		color: #0948B3;
	}
	h2 {
		color: #0948B3;
	}
	.h2 {
		color: #0948B3;
		color: #0948B3;
	}
	h3 {
		color: #0948B3;
	}
	.h3 {
		color: #0948B3;
		color: #0948B3;
	}
	h4 {
		color: #0948B3;
	}
	.h4 {
		color: #0948B3;
		color: #0948B3;
	}
	h5 {
		color: #0948B3;
	}
	.h5 {
		color: #0948B3;
		color: #0948B3;
	}
	h6 {
		color: #0948B3;
	}
	.h6 {
		color: #0948B3;
		color: #0948B3;
	}
	.display-1 {
		color: #0948B3;
	}
	.display-2 {
		color: #0948B3;
	}
	.display-3 {
		color: #0948B3;
	}
	.display-4 {
		color: #0948B3;
	}
	.text-muted {
		color: rgba(9, 72, 179, 0.7) !important;
	}
}
.text-warning {
	color: #f5b759 !important;
	color: #f5b759 !important;
	&:hover {
		color: #f5b759 !important;
	}
	a {
		&:not(.btn) {
			color: #f5b759;
		}
	}
	h1 {
		color: #f5b759;
	}
	.h1 {
		color: #f5b759;
		color: #f5b759;
	}
	h2 {
		color: #f5b759;
	}
	.h2 {
		color: #f5b759;
		color: #f5b759;
	}
	h3 {
		color: #f5b759;
	}
	.h3 {
		color: #f5b759;
		color: #f5b759;
	}
	h4 {
		color: #f5b759;
	}
	.h4 {
		color: #f5b759;
		color: #f5b759;
	}
	h5 {
		color: #f5b759;
	}
	.h5 {
		color: #f5b759;
		color: #f5b759;
	}
	h6 {
		color: #f5b759;
	}
	.h6 {
		color: #f5b759;
		color: #f5b759;
	}
	.display-1 {
		color: #f5b759;
	}
	.display-2 {
		color: #f5b759;
	}
	.display-3 {
		color: #f5b759;
	}
	.display-4 {
		color: #f5b759;
	}
	.text-muted {
		color: rgba(245, 183, 89, 0.7) !important;
	}
}
.text-danger {
	color: #FA5252 !important;
	color: #FA5252 !important;
	&:hover {
		color: #FA5252 !important;
	}
	a {
		&:not(.btn) {
			color: #FA5252;
		}
	}
	h1 {
		color: #FA5252;
	}
	.h1 {
		color: #FA5252;
		color: #FA5252;
	}
	h2 {
		color: #FA5252;
	}
	.h2 {
		color: #FA5252;
		color: #FA5252;
	}
	h3 {
		color: #FA5252;
	}
	.h3 {
		color: #FA5252;
		color: #FA5252;
	}
	h4 {
		color: #FA5252;
	}
	.h4 {
		color: #FA5252;
		color: #FA5252;
	}
	h5 {
		color: #FA5252;
	}
	.h5 {
		color: #FA5252;
		color: #FA5252;
	}
	h6 {
		color: #FA5252;
	}
	.h6 {
		color: #FA5252;
		color: #FA5252;
	}
	.display-1 {
		color: #FA5252;
	}
	.display-2 {
		color: #FA5252;
	}
	.display-3 {
		color: #FA5252;
	}
	.display-4 {
		color: #FA5252;
	}
	.text-muted {
		color: rgba(250, 82, 82, 0.7) !important;
	}
}
.text-white {
	color: #ffffff !important;
	color: #ffffff !important;
	&:hover {
		color: #ffffff !important;
	}
	a {
		&:not(.btn) {
			color: #ffffff;
		}
	}
	h1 {
		color: #ffffff;
	}
	.h1 {
		color: #ffffff;
		color: #ffffff;
	}
	h2 {
		color: #ffffff;
	}
	.h2 {
		color: #ffffff;
		color: #ffffff;
	}
	h3 {
		color: #ffffff;
	}
	.h3 {
		color: #ffffff;
		color: #ffffff;
	}
	h4 {
		color: #ffffff;
	}
	.h4 {
		color: #ffffff;
		color: #ffffff;
	}
	h5 {
		color: #ffffff;
	}
	.h5 {
		color: #ffffff;
		color: #ffffff;
	}
	h6 {
		color: #ffffff;
	}
	.h6 {
		color: #ffffff;
		color: #ffffff;
	}
	.display-1 {
		color: #ffffff;
	}
	.display-2 {
		color: #ffffff;
	}
	.display-3 {
		color: #ffffff;
	}
	.display-4 {
		color: #ffffff;
	}
	.text-muted {
		color: rgba(255, 255, 255, 0.7) !important;
	}
}
.text-gray {
	color: #4A5073 !important;
	color: #4A5073 !important;
	&:hover {
		color: #4A5073 !important;
	}
	a {
		&:not(.btn) {
			color: #4A5073;
		}
	}
	h1 {
		color: #4A5073;
	}
	.h1 {
		color: #4A5073;
		color: #4A5073;
	}
	h2 {
		color: #4A5073;
	}
	.h2 {
		color: #4A5073;
		color: #4A5073;
	}
	h3 {
		color: #4A5073;
	}
	.h3 {
		color: #4A5073;
		color: #4A5073;
	}
	h4 {
		color: #4A5073;
	}
	.h4 {
		color: #4A5073;
		color: #4A5073;
	}
	h5 {
		color: #4A5073;
	}
	.h5 {
		color: #4A5073;
		color: #4A5073;
	}
	h6 {
		color: #4A5073;
	}
	.h6 {
		color: #4A5073;
		color: #4A5073;
	}
	.display-1 {
		color: #4A5073;
	}
	.display-2 {
		color: #4A5073;
	}
	.display-3 {
		color: #4A5073;
	}
	.display-4 {
		color: #4A5073;
	}
	.text-muted {
		color: rgba(74, 80, 115, 0.7) !important;
	}
}
.text-neutral {
	color: #ffffff !important;
	color: #ffffff !important;
	&:hover {
		color: #ffffff !important;
	}
	a {
		&:not(.btn) {
			color: #ffffff;
		}
	}
	h1 {
		color: #ffffff;
	}
	.h1 {
		color: #ffffff;
		color: #ffffff;
	}
	h2 {
		color: #ffffff;
	}
	.h2 {
		color: #ffffff;
		color: #ffffff;
	}
	h3 {
		color: #ffffff;
	}
	.h3 {
		color: #ffffff;
		color: #ffffff;
	}
	h4 {
		color: #ffffff;
	}
	.h4 {
		color: #ffffff;
		color: #ffffff;
	}
	h5 {
		color: #ffffff;
	}
	.h5 {
		color: #ffffff;
		color: #ffffff;
	}
	h6 {
		color: #ffffff;
	}
	.h6 {
		color: #ffffff;
		color: #ffffff;
	}
	.display-1 {
		color: #ffffff;
	}
	.display-2 {
		color: #ffffff;
	}
	.display-3 {
		color: #ffffff;
	}
	.display-4 {
		color: #ffffff;
	}
	.text-muted {
		color: rgba(255, 255, 255, 0.7) !important;
	}
}
.text-dark {
	color: #262B40 !important;
	color: #262B40 !important;
	&:hover {
		color: #262B40 !important;
	}
	a {
		&:not(.btn) {
			color: #262B40;
		}
	}
	h1 {
		color: #262B40;
	}
	.h1 {
		color: #262B40;
		color: #262B40;
	}
	h2 {
		color: #262B40;
	}
	.h2 {
		color: #262B40;
		color: #262B40;
	}
	h3 {
		color: #262B40;
	}
	.h3 {
		color: #262B40;
		color: #262B40;
	}
	h4 {
		color: #262B40;
	}
	.h4 {
		color: #262B40;
		color: #262B40;
	}
	h5 {
		color: #262B40;
	}
	.h5 {
		color: #262B40;
		color: #262B40;
	}
	h6 {
		color: #262B40;
	}
	.h6 {
		color: #262B40;
		color: #262B40;
	}
	.display-1 {
		color: #262B40;
	}
	.display-2 {
		color: #262B40;
	}
	.display-3 {
		color: #262B40;
	}
	.display-4 {
		color: #262B40;
	}
	.text-muted {
		color: rgba(38, 43, 64, 0.7) !important;
	}
}
.text-soft {
	color: #f5f8fb !important;
	color: #f5f8fb !important;
	&:hover {
		color: #f5f8fb !important;
	}
	a {
		&:not(.btn) {
			color: #f5f8fb;
		}
	}
	h1 {
		color: #f5f8fb;
	}
	.h1 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h2 {
		color: #f5f8fb;
	}
	.h2 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h3 {
		color: #f5f8fb;
	}
	.h3 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h4 {
		color: #f5f8fb;
	}
	.h4 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h5 {
		color: #f5f8fb;
	}
	.h5 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h6 {
		color: #f5f8fb;
	}
	.h6 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	.display-1 {
		color: #f5f8fb;
	}
	.display-2 {
		color: #f5f8fb;
	}
	.display-3 {
		color: #f5f8fb;
	}
	.display-4 {
		color: #f5f8fb;
	}
	.text-muted {
		color: rgba(245, 248, 251, 0.7) !important;
	}
}
.text-black {
	color: #2e3650 !important;
	color: #2e3650 !important;
	&:hover {
		color: #2e3650 !important;
	}
	a {
		&:not(.btn) {
			color: #2e3650;
		}
	}
	h1 {
		color: #2e3650;
	}
	.h1 {
		color: #2e3650;
		color: #2e3650;
	}
	h2 {
		color: #2e3650;
	}
	.h2 {
		color: #2e3650;
		color: #2e3650;
	}
	h3 {
		color: #2e3650;
	}
	.h3 {
		color: #2e3650;
		color: #2e3650;
	}
	h4 {
		color: #2e3650;
	}
	.h4 {
		color: #2e3650;
		color: #2e3650;
	}
	h5 {
		color: #2e3650;
	}
	.h5 {
		color: #2e3650;
		color: #2e3650;
	}
	h6 {
		color: #2e3650;
	}
	.h6 {
		color: #2e3650;
		color: #2e3650;
	}
	.display-1 {
		color: #2e3650;
	}
	.display-2 {
		color: #2e3650;
	}
	.display-3 {
		color: #2e3650;
	}
	.display-4 {
		color: #2e3650;
	}
	.text-muted {
		color: rgba(46, 54, 80, 0.7) !important;
	}
}
.text-blue {
	color: #0948B3 !important;
	color: #0948B3 !important;
	&:hover {
		color: #0948B3 !important;
	}
	a {
		&:not(.btn) {
			color: #0948B3;
		}
	}
	h1 {
		color: #0948B3;
	}
	.h1 {
		color: #0948B3;
		color: #0948B3;
	}
	h2 {
		color: #0948B3;
	}
	.h2 {
		color: #0948B3;
		color: #0948B3;
	}
	h3 {
		color: #0948B3;
	}
	.h3 {
		color: #0948B3;
		color: #0948B3;
	}
	h4 {
		color: #0948B3;
	}
	.h4 {
		color: #0948B3;
		color: #0948B3;
	}
	h5 {
		color: #0948B3;
	}
	.h5 {
		color: #0948B3;
		color: #0948B3;
	}
	h6 {
		color: #0948B3;
	}
	.h6 {
		color: #0948B3;
		color: #0948B3;
	}
	.display-1 {
		color: #0948B3;
	}
	.display-2 {
		color: #0948B3;
	}
	.display-3 {
		color: #0948B3;
	}
	.display-4 {
		color: #0948B3;
	}
	.text-muted {
		color: rgba(9, 72, 179, 0.7) !important;
	}
}
.text-pink {
	color: #C96480 !important;
	color: #C96480 !important;
	&:hover {
		color: #C96480 !important;
	}
	a {
		&:not(.btn) {
			color: #C96480;
		}
	}
	h1 {
		color: #C96480;
	}
	.h1 {
		color: #C96480;
		color: #C96480;
	}
	h2 {
		color: #C96480;
	}
	.h2 {
		color: #C96480;
		color: #C96480;
	}
	h3 {
		color: #C96480;
	}
	.h3 {
		color: #C96480;
		color: #C96480;
	}
	h4 {
		color: #C96480;
	}
	.h4 {
		color: #C96480;
		color: #C96480;
	}
	h5 {
		color: #C96480;
	}
	.h5 {
		color: #C96480;
		color: #C96480;
	}
	h6 {
		color: #C96480;
	}
	.h6 {
		color: #C96480;
		color: #C96480;
	}
	.display-1 {
		color: #C96480;
	}
	.display-2 {
		color: #C96480;
	}
	.display-3 {
		color: #C96480;
	}
	.display-4 {
		color: #C96480;
	}
	.text-muted {
		color: rgba(201, 100, 128, 0.7) !important;
	}
}
.text-cyan {
	color: #63b1bd !important;
	color: #63b1bd !important;
	&:hover {
		color: #63b1bd !important;
	}
	a {
		&:not(.btn) {
			color: #63b1bd;
		}
	}
	h1 {
		color: #63b1bd;
	}
	.h1 {
		color: #63b1bd;
		color: #63b1bd;
	}
	h2 {
		color: #63b1bd;
	}
	.h2 {
		color: #63b1bd;
		color: #63b1bd;
	}
	h3 {
		color: #63b1bd;
	}
	.h3 {
		color: #63b1bd;
		color: #63b1bd;
	}
	h4 {
		color: #63b1bd;
	}
	.h4 {
		color: #63b1bd;
		color: #63b1bd;
	}
	h5 {
		color: #63b1bd;
	}
	.h5 {
		color: #63b1bd;
		color: #63b1bd;
	}
	h6 {
		color: #63b1bd;
	}
	.h6 {
		color: #63b1bd;
		color: #63b1bd;
	}
	.display-1 {
		color: #63b1bd;
	}
	.display-2 {
		color: #63b1bd;
	}
	.display-3 {
		color: #63b1bd;
	}
	.display-4 {
		color: #63b1bd;
	}
	.text-muted {
		color: rgba(99, 177, 189, 0.7) !important;
	}
}
.text-purple {
	color: #8965e0 !important;
	color: #8965e0 !important;
	&:hover {
		color: #8965e0 !important;
	}
	a {
		&:not(.btn) {
			color: #8965e0;
		}
	}
	h1 {
		color: #8965e0;
	}
	.h1 {
		color: #8965e0;
		color: #8965e0;
	}
	h2 {
		color: #8965e0;
	}
	.h2 {
		color: #8965e0;
		color: #8965e0;
	}
	h3 {
		color: #8965e0;
	}
	.h3 {
		color: #8965e0;
		color: #8965e0;
	}
	h4 {
		color: #8965e0;
	}
	.h4 {
		color: #8965e0;
		color: #8965e0;
	}
	h5 {
		color: #8965e0;
	}
	.h5 {
		color: #8965e0;
		color: #8965e0;
	}
	h6 {
		color: #8965e0;
	}
	.h6 {
		color: #8965e0;
		color: #8965e0;
	}
	.display-1 {
		color: #8965e0;
	}
	.display-2 {
		color: #8965e0;
	}
	.display-3 {
		color: #8965e0;
	}
	.display-4 {
		color: #8965e0;
	}
	.text-muted {
		color: rgba(137, 101, 224, 0.7) !important;
	}
}
.text-soft-indigo {
	color: #f5e8ff !important;
	color: #f5e8ff !important;
	&:hover {
		color: #f5e8ff !important;
	}
	a {
		&:not(.btn) {
			color: #f5e8ff;
		}
	}
	h1 {
		color: #f5e8ff;
	}
	.h1 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	h2 {
		color: #f5e8ff;
	}
	.h2 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	h3 {
		color: #f5e8ff;
	}
	.h3 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	h4 {
		color: #f5e8ff;
	}
	.h4 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	h5 {
		color: #f5e8ff;
	}
	.h5 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	h6 {
		color: #f5e8ff;
	}
	.h6 {
		color: #f5e8ff;
		color: #f5e8ff;
	}
	.display-1 {
		color: #f5e8ff;
	}
	.display-2 {
		color: #f5e8ff;
	}
	.display-3 {
		color: #f5e8ff;
	}
	.display-4 {
		color: #f5e8ff;
	}
	.text-muted {
		color: rgba(245, 232, 255, 0.7) !important;
	}
}
.text-soft-green {
	color: #2CA58D !important;
	color: #2CA58D !important;
	&:hover {
		color: #2CA58D !important;
	}
	a {
		&:not(.btn) {
			color: #2CA58D;
		}
	}
	h1 {
		color: #2CA58D;
	}
	.h1 {
		color: #2CA58D;
		color: #2CA58D;
	}
	h2 {
		color: #2CA58D;
	}
	.h2 {
		color: #2CA58D;
		color: #2CA58D;
	}
	h3 {
		color: #2CA58D;
	}
	.h3 {
		color: #2CA58D;
		color: #2CA58D;
	}
	h4 {
		color: #2CA58D;
	}
	.h4 {
		color: #2CA58D;
		color: #2CA58D;
	}
	h5 {
		color: #2CA58D;
	}
	.h5 {
		color: #2CA58D;
		color: #2CA58D;
	}
	h6 {
		color: #2CA58D;
	}
	.h6 {
		color: #2CA58D;
		color: #2CA58D;
	}
	.display-1 {
		color: #2CA58D;
	}
	.display-2 {
		color: #2CA58D;
	}
	.display-3 {
		color: #2CA58D;
	}
	.display-4 {
		color: #2CA58D;
	}
	.text-muted {
		color: rgba(44, 165, 141, 0.7) !important;
	}
}
.text-gray-100 {
	color: #f3f7fa !important;
	color: #f3f7fa !important;
	&:hover {
		color: #f3f7fa !important;
	}
	a {
		&:not(.btn) {
			color: #f3f7fa;
		}
	}
	h1 {
		color: #f3f7fa;
	}
	.h1 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	h2 {
		color: #f3f7fa;
	}
	.h2 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	h3 {
		color: #f3f7fa;
	}
	.h3 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	h4 {
		color: #f3f7fa;
	}
	.h4 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	h5 {
		color: #f3f7fa;
	}
	.h5 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	h6 {
		color: #f3f7fa;
	}
	.h6 {
		color: #f3f7fa;
		color: #f3f7fa;
	}
	.display-1 {
		color: #f3f7fa;
	}
	.display-2 {
		color: #f3f7fa;
	}
	.display-3 {
		color: #f3f7fa;
	}
	.display-4 {
		color: #f3f7fa;
	}
	.text-muted {
		color: rgba(243, 247, 250, 0.7) !important;
	}
}
.text-gray-200 {
	color: #f5f8fb !important;
	color: #f5f8fb !important;
	&:hover {
		color: #f5f8fb !important;
	}
	a {
		&:not(.btn) {
			color: #f5f8fb;
		}
	}
	h1 {
		color: #f5f8fb;
	}
	.h1 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h2 {
		color: #f5f8fb;
	}
	.h2 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h3 {
		color: #f5f8fb;
	}
	.h3 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h4 {
		color: #f5f8fb;
	}
	.h4 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h5 {
		color: #f5f8fb;
	}
	.h5 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	h6 {
		color: #f5f8fb;
	}
	.h6 {
		color: #f5f8fb;
		color: #f5f8fb;
	}
	.display-1 {
		color: #f5f8fb;
	}
	.display-2 {
		color: #f5f8fb;
	}
	.display-3 {
		color: #f5f8fb;
	}
	.display-4 {
		color: #f5f8fb;
	}
	.text-muted {
		color: rgba(245, 248, 251, 0.7) !important;
	}
}
.text-gray-300 {
	color: #F0F3F6 !important;
	color: #F0F3F6 !important;
	&:hover {
		color: #F0F3F6 !important;
	}
	a {
		&:not(.btn) {
			color: #F0F3F6;
		}
	}
	h1 {
		color: #F0F3F6;
	}
	.h1 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h2 {
		color: #F0F3F6;
	}
	.h2 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h3 {
		color: #F0F3F6;
	}
	.h3 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h4 {
		color: #F0F3F6;
	}
	.h4 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h5 {
		color: #F0F3F6;
	}
	.h5 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	h6 {
		color: #F0F3F6;
	}
	.h6 {
		color: #F0F3F6;
		color: #F0F3F6;
	}
	.display-1 {
		color: #F0F3F6;
	}
	.display-2 {
		color: #F0F3F6;
	}
	.display-3 {
		color: #F0F3F6;
	}
	.display-4 {
		color: #F0F3F6;
	}
	.text-muted {
		color: rgba(240, 243, 246, 0.7) !important;
	}
}
.text-gray-400 {
	color: #eaedf2 !important;
	color: #eaedf2 !important;
	&:hover {
		color: #eaedf2 !important;
	}
	a {
		&:not(.btn) {
			color: #eaedf2;
		}
	}
	h1 {
		color: #eaedf2;
	}
	.h1 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h2 {
		color: #eaedf2;
	}
	.h2 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h3 {
		color: #eaedf2;
	}
	.h3 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h4 {
		color: #eaedf2;
	}
	.h4 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h5 {
		color: #eaedf2;
	}
	.h5 {
		color: #eaedf2;
		color: #eaedf2;
	}
	h6 {
		color: #eaedf2;
	}
	.h6 {
		color: #eaedf2;
		color: #eaedf2;
	}
	.display-1 {
		color: #eaedf2;
	}
	.display-2 {
		color: #eaedf2;
	}
	.display-3 {
		color: #eaedf2;
	}
	.display-4 {
		color: #eaedf2;
	}
	.text-muted {
		color: rgba(234, 237, 242, 0.7) !important;
	}
}
.text-gray-500 {
	color: #d1d7e0 !important;
	color: #d1d7e0 !important;
	&:hover {
		color: #d1d7e0 !important;
	}
	a {
		&:not(.btn) {
			color: #d1d7e0;
		}
	}
	h1 {
		color: #d1d7e0;
	}
	.h1 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	h2 {
		color: #d1d7e0;
	}
	.h2 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	h3 {
		color: #d1d7e0;
	}
	.h3 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	h4 {
		color: #d1d7e0;
	}
	.h4 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	h5 {
		color: #d1d7e0;
	}
	.h5 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	h6 {
		color: #d1d7e0;
	}
	.h6 {
		color: #d1d7e0;
		color: #d1d7e0;
	}
	.display-1 {
		color: #d1d7e0;
	}
	.display-2 {
		color: #d1d7e0;
	}
	.display-3 {
		color: #d1d7e0;
	}
	.display-4 {
		color: #d1d7e0;
	}
	.text-muted {
		color: rgba(209, 215, 224, 0.7) !important;
	}
}
.text-gray-600 {
	color: #93a5be !important;
	color: #93a5be !important;
	&:hover {
		color: #93a5be !important;
	}
	a {
		&:not(.btn) {
			color: #93a5be;
		}
	}
	h1 {
		color: #93a5be;
	}
	.h1 {
		color: #93a5be;
		color: #93a5be;
	}
	h2 {
		color: #93a5be;
	}
	.h2 {
		color: #93a5be;
		color: #93a5be;
	}
	h3 {
		color: #93a5be;
	}
	.h3 {
		color: #93a5be;
		color: #93a5be;
	}
	h4 {
		color: #93a5be;
	}
	.h4 {
		color: #93a5be;
		color: #93a5be;
	}
	h5 {
		color: #93a5be;
	}
	.h5 {
		color: #93a5be;
		color: #93a5be;
	}
	h6 {
		color: #93a5be;
	}
	.h6 {
		color: #93a5be;
		color: #93a5be;
	}
	.display-1 {
		color: #93a5be;
	}
	.display-2 {
		color: #93a5be;
	}
	.display-3 {
		color: #93a5be;
	}
	.display-4 {
		color: #93a5be;
	}
	.text-muted {
		color: rgba(147, 165, 190, 0.7) !important;
	}
}
.text-gray-700 {
	color: #66799e !important;
	color: #66799e !important;
	&:hover {
		color: #66799e !important;
	}
	a {
		&:not(.btn) {
			color: #66799e;
		}
	}
	h1 {
		color: #66799e;
	}
	.h1 {
		color: #66799e;
		color: #66799e;
	}
	h2 {
		color: #66799e;
	}
	.h2 {
		color: #66799e;
		color: #66799e;
	}
	h3 {
		color: #66799e;
	}
	.h3 {
		color: #66799e;
		color: #66799e;
	}
	h4 {
		color: #66799e;
	}
	.h4 {
		color: #66799e;
		color: #66799e;
	}
	h5 {
		color: #66799e;
	}
	.h5 {
		color: #66799e;
		color: #66799e;
	}
	h6 {
		color: #66799e;
	}
	.h6 {
		color: #66799e;
		color: #66799e;
	}
	.display-1 {
		color: #66799e;
	}
	.display-2 {
		color: #66799e;
	}
	.display-3 {
		color: #66799e;
	}
	.display-4 {
		color: #66799e;
	}
	.text-muted {
		color: rgba(102, 121, 158, 0.7) !important;
	}
}
.text-gray-800 {
	color: #506690 !important;
	color: #506690 !important;
	&:hover {
		color: #506690 !important;
	}
	a {
		&:not(.btn) {
			color: #506690;
		}
	}
	h1 {
		color: #506690;
	}
	.h1 {
		color: #506690;
		color: #506690;
	}
	h2 {
		color: #506690;
	}
	.h2 {
		color: #506690;
		color: #506690;
	}
	h3 {
		color: #506690;
	}
	.h3 {
		color: #506690;
		color: #506690;
	}
	h4 {
		color: #506690;
	}
	.h4 {
		color: #506690;
		color: #506690;
	}
	h5 {
		color: #506690;
	}
	.h5 {
		color: #506690;
		color: #506690;
	}
	h6 {
		color: #506690;
	}
	.h6 {
		color: #506690;
		color: #506690;
	}
	.display-1 {
		color: #506690;
	}
	.display-2 {
		color: #506690;
	}
	.display-3 {
		color: #506690;
	}
	.display-4 {
		color: #506690;
	}
	.text-muted {
		color: rgba(80, 102, 144, 0.7) !important;
	}
}
.text-facebook {
	color: #3b5999 !important;
	color: #3b5999 !important;
	&:hover {
		color: #3b5999 !important;
	}
	a {
		&:not(.btn) {
			color: #3b5999;
		}
	}
	h1 {
		color: #3b5999;
	}
	.h1 {
		color: #3b5999;
		color: #3b5999;
	}
	h2 {
		color: #3b5999;
	}
	.h2 {
		color: #3b5999;
		color: #3b5999;
	}
	h3 {
		color: #3b5999;
	}
	.h3 {
		color: #3b5999;
		color: #3b5999;
	}
	h4 {
		color: #3b5999;
	}
	.h4 {
		color: #3b5999;
		color: #3b5999;
	}
	h5 {
		color: #3b5999;
	}
	.h5 {
		color: #3b5999;
		color: #3b5999;
	}
	h6 {
		color: #3b5999;
	}
	.h6 {
		color: #3b5999;
		color: #3b5999;
	}
	.display-1 {
		color: #3b5999;
	}
	.display-2 {
		color: #3b5999;
	}
	.display-3 {
		color: #3b5999;
	}
	.display-4 {
		color: #3b5999;
	}
	.text-muted {
		color: rgba(59, 89, 153, 0.7) !important;
	}
}
.text-twitter {
	color: #1da1f2 !important;
	color: #1da1f2 !important;
	&:hover {
		color: #1da1f2 !important;
	}
	a {
		&:not(.btn) {
			color: #1da1f2;
		}
	}
	h1 {
		color: #1da1f2;
	}
	.h1 {
		color: #1da1f2;
		color: #1da1f2;
	}
	h2 {
		color: #1da1f2;
	}
	.h2 {
		color: #1da1f2;
		color: #1da1f2;
	}
	h3 {
		color: #1da1f2;
	}
	.h3 {
		color: #1da1f2;
		color: #1da1f2;
	}
	h4 {
		color: #1da1f2;
	}
	.h4 {
		color: #1da1f2;
		color: #1da1f2;
	}
	h5 {
		color: #1da1f2;
	}
	.h5 {
		color: #1da1f2;
		color: #1da1f2;
	}
	h6 {
		color: #1da1f2;
	}
	.h6 {
		color: #1da1f2;
		color: #1da1f2;
	}
	.display-1 {
		color: #1da1f2;
	}
	.display-2 {
		color: #1da1f2;
	}
	.display-3 {
		color: #1da1f2;
	}
	.display-4 {
		color: #1da1f2;
	}
	.text-muted {
		color: rgba(29, 161, 242, 0.7) !important;
	}
}
.text-google {
	color: #DB4337 !important;
	color: #DB4337 !important;
	&:hover {
		color: #DB4337 !important;
	}
	a {
		&:not(.btn) {
			color: #DB4337;
		}
	}
	h1 {
		color: #DB4337;
	}
	.h1 {
		color: #DB4337;
		color: #DB4337;
	}
	h2 {
		color: #DB4337;
	}
	.h2 {
		color: #DB4337;
		color: #DB4337;
	}
	h3 {
		color: #DB4337;
	}
	.h3 {
		color: #DB4337;
		color: #DB4337;
	}
	h4 {
		color: #DB4337;
	}
	.h4 {
		color: #DB4337;
		color: #DB4337;
	}
	h5 {
		color: #DB4337;
	}
	.h5 {
		color: #DB4337;
		color: #DB4337;
	}
	h6 {
		color: #DB4337;
	}
	.h6 {
		color: #DB4337;
		color: #DB4337;
	}
	.display-1 {
		color: #DB4337;
	}
	.display-2 {
		color: #DB4337;
	}
	.display-3 {
		color: #DB4337;
	}
	.display-4 {
		color: #DB4337;
	}
	.text-muted {
		color: rgba(219, 67, 55, 0.7) !important;
	}
}
.text-instagram {
	color: #e4405f !important;
	color: #e4405f !important;
	&:hover {
		color: #e4405f !important;
	}
	a {
		&:not(.btn) {
			color: #e4405f;
		}
	}
	h1 {
		color: #e4405f;
	}
	.h1 {
		color: #e4405f;
		color: #e4405f;
	}
	h2 {
		color: #e4405f;
	}
	.h2 {
		color: #e4405f;
		color: #e4405f;
	}
	h3 {
		color: #e4405f;
	}
	.h3 {
		color: #e4405f;
		color: #e4405f;
	}
	h4 {
		color: #e4405f;
	}
	.h4 {
		color: #e4405f;
		color: #e4405f;
	}
	h5 {
		color: #e4405f;
	}
	.h5 {
		color: #e4405f;
		color: #e4405f;
	}
	h6 {
		color: #e4405f;
	}
	.h6 {
		color: #e4405f;
		color: #e4405f;
	}
	.display-1 {
		color: #e4405f;
	}
	.display-2 {
		color: #e4405f;
	}
	.display-3 {
		color: #e4405f;
	}
	.display-4 {
		color: #e4405f;
	}
	.text-muted {
		color: rgba(228, 64, 95, 0.7) !important;
	}
}
.text-pinterest {
	color: #bd081c !important;
	color: #bd081c !important;
	&:hover {
		color: #bd081c !important;
	}
	a {
		&:not(.btn) {
			color: #bd081c;
		}
	}
	h1 {
		color: #bd081c;
	}
	.h1 {
		color: #bd081c;
		color: #bd081c;
	}
	h2 {
		color: #bd081c;
	}
	.h2 {
		color: #bd081c;
		color: #bd081c;
	}
	h3 {
		color: #bd081c;
	}
	.h3 {
		color: #bd081c;
		color: #bd081c;
	}
	h4 {
		color: #bd081c;
	}
	.h4 {
		color: #bd081c;
		color: #bd081c;
	}
	h5 {
		color: #bd081c;
	}
	.h5 {
		color: #bd081c;
		color: #bd081c;
	}
	h6 {
		color: #bd081c;
	}
	.h6 {
		color: #bd081c;
		color: #bd081c;
	}
	.display-1 {
		color: #bd081c;
	}
	.display-2 {
		color: #bd081c;
	}
	.display-3 {
		color: #bd081c;
	}
	.display-4 {
		color: #bd081c;
	}
	.text-muted {
		color: rgba(189, 8, 28, 0.7) !important;
	}
}
.text-youtube {
	color: #cd201f !important;
	color: #cd201f !important;
	&:hover {
		color: #cd201f !important;
	}
	a {
		&:not(.btn) {
			color: #cd201f;
		}
	}
	h1 {
		color: #cd201f;
	}
	.h1 {
		color: #cd201f;
		color: #cd201f;
	}
	h2 {
		color: #cd201f;
	}
	.h2 {
		color: #cd201f;
		color: #cd201f;
	}
	h3 {
		color: #cd201f;
	}
	.h3 {
		color: #cd201f;
		color: #cd201f;
	}
	h4 {
		color: #cd201f;
	}
	.h4 {
		color: #cd201f;
		color: #cd201f;
	}
	h5 {
		color: #cd201f;
	}
	.h5 {
		color: #cd201f;
		color: #cd201f;
	}
	h6 {
		color: #cd201f;
	}
	.h6 {
		color: #cd201f;
		color: #cd201f;
	}
	.display-1 {
		color: #cd201f;
	}
	.display-2 {
		color: #cd201f;
	}
	.display-3 {
		color: #cd201f;
	}
	.display-4 {
		color: #cd201f;
	}
	.text-muted {
		color: rgba(205, 32, 31, 0.7) !important;
	}
}
.text-slack {
	color: #3aaf85 !important;
	color: #3aaf85 !important;
	&:hover {
		color: #3aaf85 !important;
	}
	a {
		&:not(.btn) {
			color: #3aaf85;
		}
	}
	h1 {
		color: #3aaf85;
	}
	.h1 {
		color: #3aaf85;
		color: #3aaf85;
	}
	h2 {
		color: #3aaf85;
	}
	.h2 {
		color: #3aaf85;
		color: #3aaf85;
	}
	h3 {
		color: #3aaf85;
	}
	.h3 {
		color: #3aaf85;
		color: #3aaf85;
	}
	h4 {
		color: #3aaf85;
	}
	.h4 {
		color: #3aaf85;
		color: #3aaf85;
	}
	h5 {
		color: #3aaf85;
	}
	.h5 {
		color: #3aaf85;
		color: #3aaf85;
	}
	h6 {
		color: #3aaf85;
	}
	.h6 {
		color: #3aaf85;
		color: #3aaf85;
	}
	.display-1 {
		color: #3aaf85;
	}
	.display-2 {
		color: #3aaf85;
	}
	.display-3 {
		color: #3aaf85;
	}
	.display-4 {
		color: #3aaf85;
	}
	.text-muted {
		color: rgba(58, 175, 133, 0.7) !important;
	}
}
.text-dribbble {
	color: #ea4c89 !important;
	color: #ea4c89 !important;
	&:hover {
		color: #ea4c89 !important;
	}
	a {
		&:not(.btn) {
			color: #ea4c89;
		}
	}
	h1 {
		color: #ea4c89;
	}
	.h1 {
		color: #ea4c89;
		color: #ea4c89;
	}
	h2 {
		color: #ea4c89;
	}
	.h2 {
		color: #ea4c89;
		color: #ea4c89;
	}
	h3 {
		color: #ea4c89;
	}
	.h3 {
		color: #ea4c89;
		color: #ea4c89;
	}
	h4 {
		color: #ea4c89;
	}
	.h4 {
		color: #ea4c89;
		color: #ea4c89;
	}
	h5 {
		color: #ea4c89;
	}
	.h5 {
		color: #ea4c89;
		color: #ea4c89;
	}
	h6 {
		color: #ea4c89;
	}
	.h6 {
		color: #ea4c89;
		color: #ea4c89;
	}
	.display-1 {
		color: #ea4c89;
	}
	.display-2 {
		color: #ea4c89;
	}
	.display-3 {
		color: #ea4c89;
	}
	.display-4 {
		color: #ea4c89;
	}
	.text-muted {
		color: rgba(234, 76, 137, 0.7) !important;
	}
}
.text-dropbox {
	color: #1E90FF !important;
	color: #1E90FF !important;
	&:hover {
		color: #1E90FF !important;
	}
	a {
		&:not(.btn) {
			color: #1E90FF;
		}
	}
	h1 {
		color: #1E90FF;
	}
	.h1 {
		color: #1E90FF;
		color: #1E90FF;
	}
	h2 {
		color: #1E90FF;
	}
	.h2 {
		color: #1E90FF;
		color: #1E90FF;
	}
	h3 {
		color: #1E90FF;
	}
	.h3 {
		color: #1E90FF;
		color: #1E90FF;
	}
	h4 {
		color: #1E90FF;
	}
	.h4 {
		color: #1E90FF;
		color: #1E90FF;
	}
	h5 {
		color: #1E90FF;
	}
	.h5 {
		color: #1E90FF;
		color: #1E90FF;
	}
	h6 {
		color: #1E90FF;
	}
	.h6 {
		color: #1E90FF;
		color: #1E90FF;
	}
	.display-1 {
		color: #1E90FF;
	}
	.display-2 {
		color: #1E90FF;
	}
	.display-3 {
		color: #1E90FF;
	}
	.display-4 {
		color: #1E90FF;
	}
	.text-muted {
		color: rgba(30, 144, 255, 0.7) !important;
	}
}
.text-twitch {
	color: #4B367C !important;
	color: #4B367C !important;
	&:hover {
		color: #4B367C !important;
	}
	a {
		&:not(.btn) {
			color: #4B367C;
		}
	}
	h1 {
		color: #4B367C;
	}
	.h1 {
		color: #4B367C;
		color: #4B367C;
	}
	h2 {
		color: #4B367C;
	}
	.h2 {
		color: #4B367C;
		color: #4B367C;
	}
	h3 {
		color: #4B367C;
	}
	.h3 {
		color: #4B367C;
		color: #4B367C;
	}
	h4 {
		color: #4B367C;
	}
	.h4 {
		color: #4B367C;
		color: #4B367C;
	}
	h5 {
		color: #4B367C;
	}
	.h5 {
		color: #4B367C;
		color: #4B367C;
	}
	h6 {
		color: #4B367C;
	}
	.h6 {
		color: #4B367C;
		color: #4B367C;
	}
	.display-1 {
		color: #4B367C;
	}
	.display-2 {
		color: #4B367C;
	}
	.display-3 {
		color: #4B367C;
	}
	.display-4 {
		color: #4B367C;
	}
	.text-muted {
		color: rgba(75, 54, 124, 0.7) !important;
	}
}
.text-paypal {
	color: #ecb32c !important;
	color: #ecb32c !important;
	&:hover {
		color: #ecb32c !important;
	}
	a {
		&:not(.btn) {
			color: #ecb32c;
		}
	}
	h1 {
		color: #ecb32c;
	}
	.h1 {
		color: #ecb32c;
		color: #ecb32c;
	}
	h2 {
		color: #ecb32c;
	}
	.h2 {
		color: #ecb32c;
		color: #ecb32c;
	}
	h3 {
		color: #ecb32c;
	}
	.h3 {
		color: #ecb32c;
		color: #ecb32c;
	}
	h4 {
		color: #ecb32c;
	}
	.h4 {
		color: #ecb32c;
		color: #ecb32c;
	}
	h5 {
		color: #ecb32c;
	}
	.h5 {
		color: #ecb32c;
		color: #ecb32c;
	}
	h6 {
		color: #ecb32c;
	}
	.h6 {
		color: #ecb32c;
		color: #ecb32c;
	}
	.display-1 {
		color: #ecb32c;
	}
	.display-2 {
		color: #ecb32c;
	}
	.display-3 {
		color: #ecb32c;
	}
	.display-4 {
		color: #ecb32c;
	}
	.text-muted {
		color: rgba(236, 179, 44, 0.7) !important;
	}
}
.text-behance {
	color: #0057ff !important;
	color: #0057ff !important;
	&:hover {
		color: #0057ff !important;
	}
	a {
		&:not(.btn) {
			color: #0057ff;
		}
	}
	h1 {
		color: #0057ff;
	}
	.h1 {
		color: #0057ff;
		color: #0057ff;
	}
	h2 {
		color: #0057ff;
	}
	.h2 {
		color: #0057ff;
		color: #0057ff;
	}
	h3 {
		color: #0057ff;
	}
	.h3 {
		color: #0057ff;
		color: #0057ff;
	}
	h4 {
		color: #0057ff;
	}
	.h4 {
		color: #0057ff;
		color: #0057ff;
	}
	h5 {
		color: #0057ff;
	}
	.h5 {
		color: #0057ff;
		color: #0057ff;
	}
	h6 {
		color: #0057ff;
	}
	.h6 {
		color: #0057ff;
		color: #0057ff;
	}
	.display-1 {
		color: #0057ff;
	}
	.display-2 {
		color: #0057ff;
	}
	.display-3 {
		color: #0057ff;
	}
	.display-4 {
		color: #0057ff;
	}
	.text-muted {
		color: rgba(0, 87, 255, 0.7) !important;
	}
}
.text-reddit {
	color: #E84422 !important;
	color: #E84422 !important;
	&:hover {
		color: #E84422 !important;
	}
	a {
		&:not(.btn) {
			color: #E84422;
		}
	}
	h1 {
		color: #E84422;
	}
	.h1 {
		color: #E84422;
		color: #E84422;
	}
	h2 {
		color: #E84422;
	}
	.h2 {
		color: #E84422;
		color: #E84422;
	}
	h3 {
		color: #E84422;
	}
	.h3 {
		color: #E84422;
		color: #E84422;
	}
	h4 {
		color: #E84422;
	}
	.h4 {
		color: #E84422;
		color: #E84422;
	}
	h5 {
		color: #E84422;
	}
	.h5 {
		color: #E84422;
		color: #E84422;
	}
	h6 {
		color: #E84422;
	}
	.h6 {
		color: #E84422;
		color: #E84422;
	}
	.display-1 {
		color: #E84422;
	}
	.display-2 {
		color: #E84422;
	}
	.display-3 {
		color: #E84422;
	}
	.display-4 {
		color: #E84422;
	}
	.text-muted {
		color: rgba(232, 68, 34, 0.7) !important;
	}
}
.text-github {
	color: #222222 !important;
	color: #222222 !important;
	&:hover {
		color: #222222 !important;
	}
	a {
		&:not(.btn) {
			color: #222222;
		}
	}
	h1 {
		color: #222222;
	}
	.h1 {
		color: #222222;
		color: #222222;
	}
	h2 {
		color: #222222;
	}
	.h2 {
		color: #222222;
		color: #222222;
	}
	h3 {
		color: #222222;
	}
	.h3 {
		color: #222222;
		color: #222222;
	}
	h4 {
		color: #222222;
	}
	.h4 {
		color: #222222;
		color: #222222;
	}
	h5 {
		color: #222222;
	}
	.h5 {
		color: #222222;
		color: #222222;
	}
	h6 {
		color: #222222;
	}
	.h6 {
		color: #222222;
		color: #222222;
	}
	.display-1 {
		color: #222222;
	}
	.display-2 {
		color: #222222;
	}
	.display-3 {
		color: #222222;
	}
	.display-4 {
		color: #222222;
	}
	.text-muted {
		color: rgba(34, 34, 34, 0.7) !important;
	}
}
.text-body {
	color: #4A5073 !important;
	color: #4A5073 !important;
}
.text-muted {
	color: #58677d !important;
}
.text-black-50 {
	color: rgba(46, 54, 80, 0.5) !important;
	color: rgba(46, 54, 80, 0.5) !important;
}
.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
	color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
	color: inherit !important;
}
.lh-1 {
	line-height: 1 !important;
}
.lh-sm {
	line-height: 1.25 !important;
}
.lh-base {
	line-height: 1.5 !important;
}
.lh-lg {
	line-height: 2 !important;
}
.bg-primary {
	background-color: #262B40 !important;
}
.bg-secondary {
	background-color: #F8BD7A !important;
}
.bg-tertiary {
	background-color: #1B998B !important;
}
.bg-quaternary {
	background-color: #0948B3 !important;
}
.bg-primary-app {
	background-color: #EBF4F6 !important;
}
.bg-secondary-app {
	background-color: #424AA0 !important;
}
.bg-light {
	background-color: #eaedf2 !important;
}
.bg-lighten {
	background-color: #F0F3F6 !important;
}
.bg-success {
	background-color: #05A677 !important;
}
.bg-info {
	background-color: #0948B3 !important;
}
.bg-warning {
	background-color: #f5b759 !important;
}
.bg-danger {
	background-color: #FA5252 !important;
}
.bg-white {
	background-color: #ffffff !important;
}
.bg-gray {
	background-color: #4A5073 !important;
}
.bg-neutral {
	background-color: #ffffff !important;
}
.bg-dark {
	background-color: #262B40 !important;
}
.bg-soft {
	background-color: #f5f8fb !important;
}
.bg-black {
	background-color: #2e3650 !important;
}
.bg-blue {
	background-color: #0948B3 !important;
}
.bg-pink {
	background-color: #C96480 !important;
}
.bg-cyan {
	background-color: #63b1bd !important;
}
.bg-purple {
	background-color: #8965e0 !important;
}
.bg-soft-indigo {
	background-color: #f5e8ff !important;
}
.bg-soft-green {
	background-color: #2CA58D !important;
}
.bg-gray-100 {
	background-color: #f3f7fa !important;
}
.bg-gray-200 {
	background-color: #f5f8fb !important;
}
.bg-gray-300 {
	background-color: #F0F3F6 !important;
}
.bg-gray-400 {
	background-color: #eaedf2 !important;
}
.bg-gray-500 {
	background-color: #d1d7e0 !important;
}
.bg-gray-600 {
	background-color: #93a5be !important;
}
.bg-gray-700 {
	background-color: #66799e !important;
}
.bg-gray-800 {
	background-color: #506690 !important;
}
.bg-facebook {
	background-color: #3b5999 !important;
}
.bg-twitter {
	background-color: #1da1f2 !important;
}
.bg-google {
	background-color: #DB4337 !important;
}
.bg-instagram {
	background-color: #e4405f !important;
}
.bg-pinterest {
	background-color: #bd081c !important;
}
.bg-youtube {
	background-color: #cd201f !important;
}
.bg-slack {
	background-color: #3aaf85 !important;
}
.bg-dribbble {
	background-color: #ea4c89 !important;
}
.bg-dropbox {
	background-color: #1E90FF !important;
}
.bg-twitch {
	background-color: #4B367C !important;
}
.bg-paypal {
	background-color: #ecb32c !important;
}
.bg-behance {
	background-color: #0057ff !important;
}
.bg-reddit {
	background-color: #E84422 !important;
}
.bg-github {
	background-color: #222222 !important;
}
.bg-body {
	background-color: #ffffff !important;
}
.bg-transparent {
	background-color: transparent !important;
}
.bg-gradient {
	background-image: var(--bs-gradient) !important;
}
.text-wrap {
	white-space: normal !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-decoration-none {
	text-decoration: none !important;
}
.text-decoration-underline {
	text-decoration: underline !important;
}
.text-decoration-line-through {
	text-decoration: line-through !important;
}
.font-italic {
	font-style: italic !important;
}
.font-normal {
	font-style: normal !important;
}
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}
.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}
.user-select-all {
	-webkit-user-select: all !important;
	-ms-user-select: all !important;
	user-select: all !important;
}
.user-select-auto {
	-webkit-user-select: auto !important;
	-ms-user-select: auto !important;
	user-select: auto !important;
}
.user-select-none {
	-webkit-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}
.pe-none {
	pointer-events: none !important;
}
.pe-auto {
	pointer-events: auto !important;
}
.rounded {
	border-radius: 0.5rem !important;
}
.rounded-sm {
	border-radius: 0.1rem !important;
}
.rounded-lg {
	border-radius: 0.3rem !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-pill {
	border-radius: 50rem !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.rounded-top {
	border-top-left-radius: 0.5rem !important;
	border-top-right-radius: 0.5rem !important;
}
.rounded-right {
	border-top-right-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
}
.rounded-bottom {
	border-bottom-right-radius: 0.5rem !important;
	border-bottom-left-radius: 0.5rem !important;
}
.rounded-left {
	border-bottom-left-radius: 0.5rem !important;
	border-top-left-radius: 0.5rem !important;
}
.visible {
	visibility: visible !important;
}
.invisible {
	visibility: hidden !important;
}
.headroom {
	will-change: transform;
	background-color: inherit;
	transition: all 0.2s ease;
}
.headroom--not-top {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.headroom--not-top.navbar-theme-primary {
	background-color: #262B40;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-secondary {
	background-color: #F8BD7A;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-tertiary {
	background-color: #1B998B;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-quaternary {
	background-color: #0948B3;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-primary-app {
	background-color: #EBF4F6;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-secondary-app {
	background-color: #424AA0;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-light {
	background-color: #eaedf2;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-lighten {
	background-color: #F0F3F6;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-success {
	background-color: #05A677;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-info {
	background-color: #0948B3;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-warning {
	background-color: #f5b759;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-danger {
	background-color: #FA5252;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-white {
	background-color: #ffffff;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray {
	background-color: #4A5073;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-neutral {
	background-color: #ffffff;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-dark {
	background-color: #262B40;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-soft {
	background-color: #f5f8fb;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-black {
	background-color: #2e3650;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-blue {
	background-color: #0948B3;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-pink {
	background-color: #C96480;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-cyan {
	background-color: #63b1bd;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-purple {
	background-color: #8965e0;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-soft-indigo {
	background-color: #f5e8ff;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-soft-green {
	background-color: #2CA58D;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-100 {
	background-color: #f3f7fa;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-200 {
	background-color: #f5f8fb;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-300 {
	background-color: #F0F3F6;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-400 {
	background-color: #eaedf2;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-500 {
	background-color: #d1d7e0;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-600 {
	background-color: #93a5be;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-700 {
	background-color: #66799e;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-gray-800 {
	background-color: #506690;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-facebook {
	background-color: #3b5999;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-twitter {
	background-color: #1da1f2;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-google {
	background-color: #DB4337;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-instagram {
	background-color: #e4405f;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-pinterest {
	background-color: #bd081c;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-youtube {
	background-color: #cd201f;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-slack {
	background-color: #3aaf85;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-dribbble {
	background-color: #ea4c89;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-dropbox {
	background-color: #1E90FF;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-twitch {
	background-color: #4B367C;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-paypal {
	background-color: #ecb32c;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-behance {
	background-color: #0057ff;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-reddit {
	background-color: #E84422;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-theme-github {
	background-color: #222222;
	.navbar-brand-light {
		display: none;
	}
	.navbar-brand-dark {
		display: block;
	}
	.nav-link {
		color: rgba(255, 255, 255, 0.9);
		&:hover {
			color: white;
		}
	}
}
.headroom--not-top.navbar-light {
	.navbar-nav {
		>.nav-item.show {
			>.nav-link {
				color: #ffffff;
			}
		}
		>.nav-item {
			>.nav-link {
				&:focus {
					color: #ffffff;
				}
			}
		}
	}
	.nav-item {
		.nav-link {
			>span {
				color: #ffffff;
			}
		}
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
	}
}
.noUi-target {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	touch-action: none;
	-ms-user-select: none;
	user-select: none;
	box-sizing: border-box;
	position: relative;
	direction: ltr;
	background: #eaedf2;
	border-radius: 5px;
	border: 0;
	box-shadow: inset 0 2px 18px rgba(0, 0, 0, 0.02);
	margin: 15px 0;
	cursor: pointer;
	* {
		-webkit-touch-callout: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-user-select: none;
		touch-action: none;
		-ms-user-select: none;
		user-select: none;
		box-sizing: border-box;
	}
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
.noUi-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	overflow: hidden;
	z-index: 0;
	border-radius: 3px;
}
.noUi-connect {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform-origin: 0 0;
	background: #262B40;
	background: #262B40;
	box-shadow: none;
}
.noUi-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform-origin: 0 0;
}
html {
	&:not([dir="rtl"]) {
		.noUi-horizontal {
			.noUi-origin {
				left: auto;
				right: 0;
			}
			.noUi-handle {
				right: -17px;
				left: auto;
				right: -10px;
			}
		}
	}
}
.noUi-vertical {
	.noUi-origin {
		width: 0;
	}
	width: 5px;
	height: 340px;
	width: 5px;
	.noUi-handle {
		width: 18px;
		height: 34px;
		left: -6px;
		top: -17px;
		border: 1px solid #66799e;
		border-radius: 3px;
		background: #ffffff;
		cursor: default;
		box-shadow: inset 0 0 1px #ffffff, inset 0 1px 7px #f3f7fa, 0 3px 6px -3px #f5f8fb;
		outline: none;
		&:hover {
			cursor: grab;
			cursor: -moz-grab;
			background-color: #eaedf2;
		}
		&:active {
			cursor: grabbing;
			cursor: -moz-grabbing;
			background-color: #eaedf2;
			box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
			background-color: #eaedf2;
		}
	}
	.noUi-draggable {
		cursor: ns-resize;
	}
	.noUi-tooltip {
		transform: translate(0, -50%);
		top: 50%;
		right: 120%;
	}
	.noUi-handle.noUi-active {
		transform: scale(1.2);
	}
}
.noUi-horizontal {
	.noUi-origin {
		height: 0;
	}
	height: 5px;
	height: 5px;
	.noUi-handle {
		left: -17px;
		top: -10px;
	}
	.noUi-tooltip {
		transform: translate(-50%, 0);
		left: 50%;
		bottom: 30px;
	}
	.noUi-handle.noUi-active {
		transform: scale(1.2);
	}
}
.noUi-handle {
	position: absolute;
	border: 1px solid #f3f7fa;
	border-radius: 3px;
	background: #ffffff;
	cursor: default;
	outline: none;
	position: absolute;
	border: 1px solid #262B40;
	border-radius: 50%;
	width: 23px;
	height: 23px;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
	background: #ffffff;
	transition: all 150ms cubic-bezier(0.17, 0.01, 0.28, 1.06);
	&:hover {
		cursor: grab;
		cursor: -moz-grab;
		background-color: #eaedf2;
	}
	&:active {
		cursor: grabbing;
		cursor: -moz-grabbing;
		background-color: #eaedf2;
		box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(69, 77, 103, 0.5);
		background-color: #eaedf2;
	}
}
.noUi-state-tap {
	.noUi-connect {
		transition: transform .3s;
	}
	.noUi-origin {
		transition: transform .3s;
	}
}
.noUi-state-drag {
	* {
		cursor: inherit !important;
	}
}
.noUi-draggable {
	cursor: ew-resize;
}
.noUi-active {
	outline: none;
}
[disabled] {
	.noUi-connect {
		background: #eaedf2;
		background: #f5f8fb;
	}
	.noUi-handle {
		cursor: not-allowed;
		cursor: not-allowed;
	}
}
[disabled].noUi-target {
	cursor: not-allowed;
}
[disabled].noUi-handle {
	cursor: not-allowed;
}
.noUi-pips {
	box-sizing: border-box;
	position: absolute;
	color: #506690;
	font-size: 0.75rem;
	* {
		box-sizing: border-box;
	}
}
.noUi-value {
	margin-top: 5px;
	position: absolute;
	white-space: nowrap;
	text-align: center;
}
.noUi-value-sub {
	color: #eaedf2;
	font-size: 0.75rem;
}
.noUi-marker {
	position: absolute;
	background: #506690;
}
.noUi-marker-sub {
	background: #506690;
}
.noUi-marker-large {
	background: #506690;
}
.noUi-pips-horizontal {
	padding: 12px 0;
	height: auto;
	top: 100%;
	left: 0;
	width: 100%;
}
.noUi-value-horizontal {
	transform: translate(-50%, 50%);
}
.noUi-rtl {
	.noUi-value-horizontal {
		transform: translate(50%, 50%);
	}
	.noUi-value-vertical {
		transform: translate(0, 50%);
	}
}
.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 12px;
}
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	transform: translate(0, -50%, 0);
	padding-left: 25px;
}
.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
.noUi-tooltip {
	background: #ffffff;
	color: #506690;
	font-size: 0.75rem;
	font-weight: 400;
	display: block;
	position: absolute;
	padding: 5px 10px;
	text-align: center;
	white-space: nowrap;
	border-radius: 0.5rem;
	border: 0.0625rem solid #eaedf2;
}
.input-slider--cyan {
	.noUi-connect {
		background: #63b1bd;
	}
}
.input-slider--cyan.noUi-horizontal {
	.noUi-handle {
		background-color: #63b1bd;
	}
}
.input-slider--cyan.noUi-vertical {
	.noUi-handle {
		background-color: #63b1bd;
	}
}
.input-slider--red {
	.noUi-connect {
		background: #FA5252;
	}
}
.input-slider--red.noUi-horizontal {
	.noUi-handle {
		background-color: #FA5252;
	}
}
.input-slider--red.noUi-vertical {
	.noUi-handle {
		background-color: #FA5252;
	}
}
.input-slider--green {
	.noUi-connect {
		background: #05A677;
	}
}
.input-slider--green.noUi-horizontal {
	.noUi-handle {
		background-color: #05A677;
	}
}
.input-slider--green.noUi-vertical {
	.noUi-handle {
		background-color: #05A677;
	}
}
.input-slider--yellow {
	.noUi-connect {
		background: #f5b759;
	}
}
.input-slider--yellow.noUi-horizontal {
	.noUi-handle {
		background-color: #f5b759;
	}
}
.input-slider--yellow.noUi-vertical {
	.noUi-handle {
		background-color: #f5b759;
	}
}
.input-slider--pink {
	.noUi-connect {
		background: #C96480;
	}
}
.input-slider--pink.noUi-horizontal {
	.noUi-handle {
		background-color: #C96480;
	}
}
.input-slider--pink.noUi-vertical {
	.noUi-handle {
		background-color: #C96480;
	}
}
[disabled].noUi-connect {
	background: #f5f8fb;
}
[disabled].noUi-origin {
	cursor: not-allowed;
}
.range-slider-value {
	font-size: 0.75rem;
	font-weight: 500;
	background-color: rgba(38, 43, 64, 0.7);
	color: color-yiq(#262B40);
	border-radius: 10px;
	padding: .4em .8em .3em .85em;
}
.range-slider-wrapper {
	.upper-info {
		font-weight: 400;
		margin-bottom: 5px;
	}
}
.input-slider-value-output {
	background: #262B40;
	color: #ffffff;
	padding: 4px 8px;
	position: relative;
	top: 12px;
	font-size: 11px;
	border-radius: 2px;
	&:after {
		bottom: 100%;
		left: 10px;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: #262B40;
		border-bottom-color: #333;
		border-width: 4px;
		margin-left: -4px;
	}
}
.input-slider-value-output.left {
	&:after {
		left: 10px;
		right: auto;
	}
}
.input-slider-value-output.right {
	&:after {
		right: 10px;
		left: auto;
	}
}
code[class*="language-"] {
	color: black;
	background: none;
	text-shadow: 0 1px white;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;
	-moz-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	text-shadow: none;
	font-family: var(--bs-font-sans-serif);
	font-weight: 600;
	&::selection {
		text-shadow: none;
		text-shadow: none;
		background: #b3d4fc;
		background: #b3d4fc;
	}
}
pre[class*="language-"] {
	color: black;
	background: none;
	text-shadow: 0 1px white;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;
	-moz-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	background: #f5f2f0;
	text-shadow: none;
	font-family: var(--bs-font-sans-serif);
	font-weight: 600;
	&::selection {
		text-shadow: none;
		text-shadow: none;
		background: #b3d4fc;
		background: #b3d4fc;
	}
}
:not(pre) {
	>code[class*="language-"] {
		background: #f5f2f0;
		padding: .1em;
		border-radius: .3em;
		white-space: normal;
	}
}
.token.comment {
	color: slategray;
}
.token.prolog {
	color: slategray;
}
.token.doctype {
	color: slategray;
}
.token.cdata {
	color: slategray;
}
.token.punctuation {
	color: #999;
}
.namespace {
	opacity: .7;
}
.token.property {
	color: #905;
}
.token.tag {
	color: #905;
}
.token.boolean {
	color: #905;
}
.token.number {
	color: #905;
}
.token.constant {
	color: #905;
}
.token.symbol {
	color: #905;
}
.token.deleted {
	color: #905;
}
.token.selector {
	color: #690;
}
.token.attr-name {
	color: #690;
}
.token.string {
	color: #690;
}
.token.char {
	color: #690;
}
.token.builtin {
	color: #690;
}
.token.inserted {
	color: #690;
}
.token.operator {
	color: #9a6e3a;
	background: rgba(255, 255, 255, 0.5);
}
.token.entity {
	color: #9a6e3a;
	background: rgba(255, 255, 255, 0.5);
	cursor: help;
}
.token.url {
	color: #9a6e3a;
	background: rgba(255, 255, 255, 0.5);
}
.language-css {
	.token.string {
		color: #9a6e3a;
		background: rgba(255, 255, 255, 0.5);
	}
}
.style {
	.token.string {
		color: #9a6e3a;
		background: rgba(255, 255, 255, 0.5);
	}
}
.token.atrule {
	color: #07a;
}
.token.attr-value {
	color: #07a;
}
.token.keyword {
	color: #07a;
}
.token.function {
	color: #DD4A68;
}
.token.class-name {
	color: #DD4A68;
}
.token.regex {
	color: #e90;
}
.token.important {
	color: #e90;
	font-weight: bold;
}
.token.variable {
	color: #e90;
}
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}
.ct-label {
	fill: #66799e;
	color: #66799e;
	font-size: 0.75rem;
	line-height: 1;
	font-weight: 400;
	fill: #4A5073;
	color: #4A5073;
	font-size: 0.75rem;
	margin-top: 6px;
	font-weight: 600;
}
.ct-chart-line {
	.ct-label {
		display: block;
		display: flex;
	}
}
.ct-chart-bar {
	.ct-label {
		display: block;
		display: flex;
	}
	.ct-label.ct-horizontal.ct-start {
		align-items: flex-end;
		justify-content: center;
		text-align: center;
		text-anchor: start;
	}
	.ct-label.ct-horizontal.ct-end {
		align-items: flex-start;
		justify-content: center;
		text-align: center;
		text-anchor: start;
	}
}
.ct-chart-pie {
	.ct-label {
		dominant-baseline: central;
		color: #ffffff;
		fill: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		font-size: 0.75rem;
		font-weight: 600;
		color: #ffffff;
		fill: #ffffff;
		font-size: 1rem;
		font-weight: 600;
	}
}
.ct-chart-donut {
	.ct-label {
		dominant-baseline: central;
		color: #ffffff;
		fill: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		font-size: 0.75rem;
		font-weight: 600;
		color: #ffffff;
		fill: #ffffff;
		font-size: 1rem;
		font-weight: 600;
	}
	.ct-series {
		stroke: #ffffff;
		stroke-width: 3px;
	}
}
.ct-label.ct-horizontal.ct-start {
	align-items: flex-end;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-horizontal.ct-end {
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-label.ct-vertical.ct-start {
	align-items: flex-end;
	justify-content: flex-end;
	text-align: right;
	text-anchor: end;
}
.ct-label.ct-vertical.ct-end {
	align-items: flex-end;
	justify-content: flex-start;
	text-align: left;
	text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars {
	.ct-label.ct-horizontal.ct-start {
		align-items: flex-end;
		justify-content: flex-start;
		text-align: left;
		text-anchor: start;
	}
	.ct-label.ct-horizontal.ct-end {
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		text-anchor: start;
	}
	.ct-label.ct-vertical.ct-start {
		align-items: center;
		justify-content: flex-end;
		text-align: right;
		text-anchor: end;
	}
	.ct-label.ct-vertical.ct-end {
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		text-anchor: end;
	}
}
.ct-grid {
	stroke: #686868;
	stroke-width: 1px;
	stroke-dasharray: 1px;
}
.ct-grid-background {
	fill: none;
}
.ct-point {
	stroke-width: 10px;
	stroke-linecap: round;
}
.ct-line {
	fill: none;
	stroke-width: 3px;
	pointer-events: none;
}
.ct-area {
	stroke: none;
	fill-opacity: 0.1;
	pointer-events: none;
}
.ct-bar {
	fill: none;
	stroke-width: 7px;
	stroke-linecap: round;
	stroke-width: 10px;
}
.ct-slice-donut {
	fill: none;
	stroke-width: 50px;
}
.ct-series-a {
	.ct-point {
		stroke: #0948B3;
	}
	.ct-line {
		stroke: #0948B3;
	}
	.ct-bar {
		stroke: #0948B3;
	}
	.ct-slice-donut {
		stroke: #0948B3;
	}
	.ct-slice-pie {
		fill: #0948B3;
	}
	.ct-slice-donut-solid {
		fill: #0948B3;
	}
	.ct-area {
		fill: #0948B3;
	}
}
.ct-series-b {
	.ct-point {
		stroke: #F8BD7A;
	}
	.ct-line {
		stroke: #F8BD7A;
	}
	.ct-bar {
		stroke: #F8BD7A;
	}
	.ct-slice-donut {
		stroke: #F8BD7A;
	}
	.ct-slice-pie {
		fill: #F8BD7A;
	}
	.ct-slice-donut-solid {
		fill: #F8BD7A;
	}
	.ct-area {
		fill: #F8BD7A;
	}
}
.ct-series-c {
	.ct-point {
		stroke: #1B998B;
	}
	.ct-line {
		stroke: #1B998B;
	}
	.ct-bar {
		stroke: #1B998B;
	}
	.ct-slice-donut {
		stroke: #1B998B;
	}
	.ct-slice-pie {
		fill: #1B998B;
	}
	.ct-slice-donut-solid {
		fill: #1B998B;
	}
	.ct-area {
		fill: #1B998B;
	}
}
.ct-series-d {
	.ct-point {
		stroke: #404E7C;
	}
	.ct-line {
		stroke: #404E7C;
	}
	.ct-bar {
		stroke: #404E7C;
	}
	.ct-slice-donut {
		stroke: #404E7C;
	}
	.ct-slice-pie {
		fill: #404E7C;
	}
	.ct-slice-donut-solid {
		fill: #404E7C;
	}
	.ct-area {
		fill: #404E7C;
	}
}
.ct-series-e {
	.ct-point {
		stroke: #C96480;
	}
	.ct-line {
		stroke: #C96480;
	}
	.ct-bar {
		stroke: #C96480;
	}
	.ct-slice-donut {
		stroke: #C96480;
	}
	.ct-slice-pie {
		fill: #C96480;
	}
	.ct-slice-donut-solid {
		fill: #C96480;
	}
	.ct-area {
		fill: #C96480;
	}
}
.ct-series-f {
	.ct-point {
		stroke: #ffffff;
	}
	.ct-line {
		stroke: #ffffff;
	}
	.ct-bar {
		stroke: #ffffff;
	}
	.ct-slice-donut {
		stroke: #ffffff;
	}
	.ct-slice-pie {
		fill: #ffffff;
	}
	.ct-slice-donut-solid {
		fill: #ffffff;
	}
	.ct-area {
		fill: #ffffff;
	}
}
.ct-series-g {
	.ct-point {
		stroke: #F8BD7A;
	}
	.ct-line {
		stroke: #F8BD7A;
	}
	.ct-bar {
		stroke: #F8BD7A;
	}
	.ct-slice-donut {
		stroke: #F8BD7A;
	}
	.ct-slice-pie {
		fill: #F8BD7A;
	}
	.ct-slice-donut-solid {
		fill: #F8BD7A;
	}
	.ct-area {
		fill: #F8BD7A;
	}
	.ct-grid {
		stroke: #F8BD7A;
		stroke-width: 2px;
		stroke-dasharray: 2px;
	}
	.ct-label.ct-horizontal.ct-end {
		margin-left: -9px;
		margin-top: 10px;
		color: #262B40;
	}
}
.ct-square {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 100%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-second {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 93.75%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-second {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 88.88889%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-third {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 83.33333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-third {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 80%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-perfect-fourth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 75%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-perfect-fifth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 66.66667%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-sixth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 62.5%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-golden-section {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 61.8047%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-sixth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 60%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-minor-seventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 56.25%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-seventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 53.33333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-octave {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 50%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-tenth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 40%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-eleventh {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 37.5%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-major-twelfth {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 33.33333%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.ct-double-octave {
	display: block;
	position: relative;
	width: 100%;
	&:before {
		display: block;
		float: left;
		content: "";
		width: 0;
		height: 0;
		padding-bottom: 25%;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.datepicker {
	display: none;
}
.datepicker.active {
	display: block;
}
.datepicker-dropdown {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;
	padding-top: 4px;
	z-index: 9999;
	.datepicker-picker {
		box-shadow: 0 2px 3px rgba(46, 54, 80, 0.1), 0 0 0 1px rgba(46, 54, 80, 0.1);
	}
}
.datepicker-dropdown.datepicker-orient-top {
	padding-top: 0;
	padding-bottom: 4px;
}
.datepicker-picker {
	display: inline-block;
	border-radius: 4px;
	background-color: #ffffff;
	span {
		display: block;
		flex: 1;
		border: 0;
		border-radius: 4px;
		cursor: default;
		text-align: center;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}
.datepicker-main {
	padding: 2px;
}
.datepicker-footer {
	box-shadow: inset 0 1px 1px rgba(46, 54, 80, 0.1);
	background-color: #eaedf2;
	.datepicker-controls {
		.button {
			margin: calc(0.375rem - 1px) 0.375rem;
			border-radius: 2px;
			width: 100%;
			font-size: 0.75rem;
		}
	}
}
.datepicker-controls {
	display: flex;
	.button {
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		margin: 0;
		border: 1px solid #cbd2df;
		border-radius: 4px;
		box-shadow: none;
		background-color: #ffffff;
		cursor: pointer;
		padding: calc(0.375em - 1px) 0.75em;
		height: 2.25em;
		vertical-align: top;
		text-align: center;
		line-height: 1.5;
		white-space: nowrap;
		color: #262B40;
		font-size: 1rem;
		&:focus {
			outline: none;
			border-color: #262B40;
			color: #262B40;
			&:not(:active) {
				box-shadow: 0 0 0 0.125em rgba(38, 43, 64, 0.25);
			}
		}
		&:active {
			outline: none;
			border-color: #5d6da1;
			color: #262B40;
		}
		&:hover {
			border-color: #9baac1;
			color: #262B40;
		}
	}
	.button[disabled] {
		cursor: not-allowed;
	}
	.view-switch {
		flex: auto;
	}
	.prev-btn {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
		width: 2.25rem;
	}
	.next-btn {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
		width: 2.25rem;
	}
	.prev-btn.disabled {
		visibility: hidden;
	}
	.next-btn.disabled {
		visibility: hidden;
	}
}
.datepicker-view {
	display: flex;
	.days-of-week {
		display: flex;
	}
	.dow {
		flex-basis: 14.28571%;
		height: 1.5rem;
		line-height: 1.5rem;
		font-size: 0.875rem;
		font-weight: 700;
	}
	.days {
		.datepicker-cell {
			flex-basis: 14.28571%;
		}
	}
	.week {
		height: 2.25rem;
		line-height: 2.25rem;
		width: 2.25rem;
		color: #9baac1;
		font-size: 0.75rem;
	}
}
.datepicker-grid {
	display: flex;
	flex-wrap: wrap;
	width: 15.75rem;
}
.datepicker-view.datepicker-grid {
	.datepicker-cell {
		flex-basis: 25%;
		height: 4.5rem;
		line-height: 4.5rem;
	}
}
.datepicker-cell {
	height: 2.25rem;
	line-height: 2.25rem;
	&:not(.disabled) {
		&:hover {
			background-color: #f9f9f9;
			cursor: pointer;
		}
	}
}
.datepicker-title {
	box-shadow: inset 0 -1px 1px rgba(46, 54, 80, 0.1);
	background-color: #eaedf2;
	padding: 0.375rem 0.75rem;
	text-align: center;
	font-weight: 700;
}
.datepicker-header {
	.datepicker-controls {
		padding: 2px 2px 0;
		.button {
			border-color: transparent;
			font-weight: bold;
			&:hover {
				background-color: #f9f9f9;
			}
			&:focus {
				&:not(:active) {
					box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
				}
			}
			&:active {
				background-color: #f2f2f2;
			}
		}
		.button[disabled] {
			box-shadow: none;
		}
	}
}
.datepicker-cell.focused {
	&:not(.selected) {
		background-color: #dae0e8;
	}
}
.datepicker-cell.selected {
	background-color: #262B40;
	color: #fff;
	font-weight: 600;
	&:hover {
		background-color: #262B40;
		color: #fff;
		font-weight: 600;
	}
}
.datepicker-cell.disabled {
	color: #cbd2df;
}
.datepicker-cell.prev {
	&:not(.disabled) {
		color: #7a7a7a;
	}
}
.datepicker-cell.next {
	&:not(.disabled) {
		color: #7a7a7a;
	}
}
.datepicker-cell.highlighted {
	&:not(.selected) {
		&:not(.range) {
			&:not(.today) {
				border-radius: 0;
				background-color: #eaedf2;
				&:not(.disabled) {
					&:hover {
						background-color: #e2e6ed;
					}
				}
			}
			&:not(.today).focused {
				background-color: #dae0e8;
			}
		}
	}
}
.datepicker-cell.today {
	&:not(.selected) {
		background-color: #00d1b2;
		&:not(.disabled) {
			color: #fff;
		}
	}
}
.datepicker-cell.today.focused {
	&:not(.selected) {
		background-color: #00c4a7;
	}
}
.datepicker-cell.range-start {
	&:not(.selected) {
		background-color: #9baac1;
		color: #fff;
	}
	border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end {
	&:not(.selected) {
		background-color: #9baac1;
		color: #fff;
	}
	border-radius: 0 4px 4px 0;
}
.datepicker-cell.range-start.focused {
	&:not(.selected) {
		background-color: #93a3bc;
	}
}
.datepicker-cell.range-end.focused {
	&:not(.selected) {
		background-color: #93a3bc;
	}
}
.datepicker-cell.range {
	border-radius: 0;
	background-color: #cbd2df;
	&:not(.disabled) {
		&:not(.focused) {
			&:not(.today) {
				&:hover {
					background-color: #c3cbda;
				}
			}
		}
	}
}
.datepicker-cell.range.disabled {
	color: #abb7cb;
}
.datepicker-cell.range.focused {
	background-color: #bbc5d5;
}
.datepicker-input.in-edit {
	border-color: #1d2030;
	&:focus {
		box-shadow: 0 0 0.25em 0.25em rgba(29, 32, 48, 0.2);
	}
	&:active {
		box-shadow: 0 0 0.25em 0.25em rgba(29, 32, 48, 0.2);
	}
}
figcaption {
	display: block;
	margin: 0;
}
main {
	display: block;
	margin: 0;
	overflow: hidden;
	min-height: 100vh;
}
.bg-gradient-primary {
	background: linear-gradient(87deg, #262B40 0, #212845 100%) !important;
}
.bg-gradient-secondary {
	background: linear-gradient(87deg, #F8BD7A 0, #ffbd73 100%) !important;
}
.bg-gradient-tertiary {
	background: linear-gradient(87deg, #1B998B 0, #12a292 100%) !important;
}
.bg-gradient-quaternary {
	background: linear-gradient(87deg, #0948B3 0, #0046bc 100%) !important;
}
.bg-gradient-primary-app {
	background: linear-gradient(87deg, #EBF4F6 0, #eaf5f7 100%) !important;
}
.bg-gradient-secondary-app {
	background: linear-gradient(87deg, #424AA0 0, #3741ab 100%) !important;
}
.bg-gradient-light {
	background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}
.bg-gradient-lighten {
	background: linear-gradient(87deg, #F0F3F6 0, #eff3f7 100%) !important;
}
.bg-gradient-success {
	background: linear-gradient(87deg, #05A677 0, #00ab79 100%) !important;
}
.bg-gradient-info {
	background: linear-gradient(87deg, #0948B3 0, #0046bc 100%) !important;
}
.bg-gradient-warning {
	background: linear-gradient(87deg, #f5b759 0, #feb950 100%) !important;
}
.bg-gradient-danger {
	background: linear-gradient(87deg, #FA5252 0, #ff4d4d 100%) !important;
}
.bg-gradient-white {
	background: linear-gradient(87deg, #ffffff 0, white 100%) !important;
}
.bg-gradient-gray {
	background: linear-gradient(87deg, #4A5073 0, #41497c 100%) !important;
}
.bg-gradient-neutral {
	background: linear-gradient(87deg, #ffffff 0, white 100%) !important;
}
.bg-gradient-dark {
	background: linear-gradient(87deg, #262B40 0, #212845 100%) !important;
}
.bg-gradient-soft {
	background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}
.bg-gradient-black {
	background: linear-gradient(87deg, #2e3650 0, #283356 100%) !important;
}
.bg-gradient-blue {
	background: linear-gradient(87deg, #0948B3 0, #0046bc 100%) !important;
}
.bg-gradient-pink {
	background: linear-gradient(87deg, #C96480 0, #d35a7b 100%) !important;
}
.bg-gradient-cyan {
	background: linear-gradient(87deg, #63b1bd 0, #58b9c8 100%) !important;
}
.bg-gradient-purple {
	background: linear-gradient(87deg, #8965e0 0, #855ce9 100%) !important;
}
.bg-gradient-soft-indigo {
	background: linear-gradient(87deg, #f5e8ff 0, #f5e8ff 100%) !important;
}
.bg-gradient-soft-green {
	background: linear-gradient(87deg, #2CA58D 0, #22af93 100%) !important;
}
.bg-gradient-gray-100 {
	background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}
.bg-gradient-gray-200 {
	background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}
.bg-gradient-gray-300 {
	background: linear-gradient(87deg, #F0F3F6 0, #eff3f7 100%) !important;
}
.bg-gradient-gray-400 {
	background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}
.bg-gradient-gray-500 {
	background: linear-gradient(87deg, #d1d7e0 0, #cdd6e4 100%) !important;
}
.bg-gradient-gray-600 {
	background: linear-gradient(87deg, #93a5be 0, #8aa4c7 100%) !important;
}
.bg-gradient-gray-700 {
	background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}
.bg-gradient-gray-800 {
	background: linear-gradient(87deg, #506690 0, #45639b 100%) !important;
}
.bg-gradient-facebook {
	background: linear-gradient(87deg, #3b5999 0, #3055a4 100%) !important;
}
.bg-gradient-twitter {
	background: linear-gradient(87deg, #1da1f2 0, #11a4fe 100%) !important;
}
.bg-gradient-google {
	background: linear-gradient(87deg, #DB4337 0, #e7392b 100%) !important;
}
.bg-gradient-instagram {
	background: linear-gradient(87deg, #e4405f 0, #ef3558 100%) !important;
}
.bg-gradient-pinterest {
	background: linear-gradient(87deg, #bd081c 0, #c50016 100%) !important;
}
.bg-gradient-youtube {
	background: linear-gradient(87deg, #cd201f 0, #d91413 100%) !important;
}
.bg-gradient-slack {
	background: linear-gradient(87deg, #3aaf85 0, #2ebb88 100%) !important;
}
.bg-gradient-dribbble {
	background: linear-gradient(87deg, #ea4c89 0, #f44287 100%) !important;
}
.bg-gradient-dropbox {
	background: linear-gradient(87deg, #1E90FF 0, dodgerblue 100%) !important;
}
.bg-gradient-twitch {
	background: linear-gradient(87deg, #4B367C 0, #472d85 100%) !important;
}
.bg-gradient-paypal {
	background: linear-gradient(87deg, #ecb32c 0, #f8b821 100%) !important;
}
.bg-gradient-behance {
	background: linear-gradient(87deg, #0057ff 0, #0057ff 100%) !important;
}
.bg-gradient-reddit {
	background: linear-gradient(87deg, #E84422 0, #f43c16 100%) !important;
}
.bg-gradient-github {
	background: linear-gradient(87deg, #222222 0, #251f1f 100%) !important;
}
.overlay-primary {
	&:before {
		position: absolute;
		content: "";
		background: #262B40;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-secondary {
	&:before {
		position: absolute;
		content: "";
		background: #F8BD7A;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-tertiary {
	&:before {
		position: absolute;
		content: "";
		background: #1B998B;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-quaternary {
	&:before {
		position: absolute;
		content: "";
		background: #0948B3;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-primary-app {
	&:before {
		position: absolute;
		content: "";
		background: #EBF4F6;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-secondary-app {
	&:before {
		position: absolute;
		content: "";
		background: #424AA0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-light {
	&:before {
		position: absolute;
		content: "";
		background: #eaedf2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-lighten {
	&:before {
		position: absolute;
		content: "";
		background: #F0F3F6;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-success {
	&:before {
		position: absolute;
		content: "";
		background: #05A677;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-info {
	&:before {
		position: absolute;
		content: "";
		background: #0948B3;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-warning {
	&:before {
		position: absolute;
		content: "";
		background: #f5b759;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-danger {
	&:before {
		position: absolute;
		content: "";
		background: #FA5252;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-white {
	&:before {
		position: absolute;
		content: "";
		background: #ffffff;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray {
	&:before {
		position: absolute;
		content: "";
		background: #4A5073;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-neutral {
	&:before {
		position: absolute;
		content: "";
		background: #ffffff;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-dark {
	&:before {
		position: absolute;
		content: "";
		background: #262B40;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-soft {
	&:before {
		position: absolute;
		content: "";
		background: #f5f8fb;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-black {
	&:before {
		position: absolute;
		content: "";
		background: #2e3650;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-blue {
	&:before {
		position: absolute;
		content: "";
		background: #0948B3;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-pink {
	&:before {
		position: absolute;
		content: "";
		background: #C96480;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-cyan {
	&:before {
		position: absolute;
		content: "";
		background: #63b1bd;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-purple {
	&:before {
		position: absolute;
		content: "";
		background: #8965e0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-soft-indigo {
	&:before {
		position: absolute;
		content: "";
		background: #f5e8ff;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-soft-green {
	&:before {
		position: absolute;
		content: "";
		background: #2CA58D;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-100 {
	&:before {
		position: absolute;
		content: "";
		background: #f3f7fa;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-200 {
	&:before {
		position: absolute;
		content: "";
		background: #f5f8fb;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-300 {
	&:before {
		position: absolute;
		content: "";
		background: #F0F3F6;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-400 {
	&:before {
		position: absolute;
		content: "";
		background: #eaedf2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-500 {
	&:before {
		position: absolute;
		content: "";
		background: #d1d7e0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-600 {
	&:before {
		position: absolute;
		content: "";
		background: #93a5be;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-700 {
	&:before {
		position: absolute;
		content: "";
		background: #66799e;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-gray-800 {
	&:before {
		position: absolute;
		content: "";
		background: #506690;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-facebook {
	&:before {
		position: absolute;
		content: "";
		background: #3b5999;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-twitter {
	&:before {
		position: absolute;
		content: "";
		background: #1da1f2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-google {
	&:before {
		position: absolute;
		content: "";
		background: #DB4337;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-instagram {
	&:before {
		position: absolute;
		content: "";
		background: #e4405f;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-pinterest {
	&:before {
		position: absolute;
		content: "";
		background: #bd081c;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-youtube {
	&:before {
		position: absolute;
		content: "";
		background: #cd201f;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-slack {
	&:before {
		position: absolute;
		content: "";
		background: #3aaf85;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-dribbble {
	&:before {
		position: absolute;
		content: "";
		background: #ea4c89;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-dropbox {
	&:before {
		position: absolute;
		content: "";
		background: #1E90FF;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-twitch {
	&:before {
		position: absolute;
		content: "";
		background: #4B367C;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-paypal {
	&:before {
		position: absolute;
		content: "";
		background: #ecb32c;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-behance {
	&:before {
		position: absolute;
		content: "";
		background: #0057ff;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-reddit {
	&:before {
		position: absolute;
		content: "";
		background: #E84422;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.overlay-github {
	&:before {
		position: absolute;
		content: "";
		background: #222222;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.8;
		z-index: 0;
	}
}
.section-image {
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}
.bg-yellow-alt {
	background-color: #fce1c3;
}
.form-bg-image {
	background-repeat: no-repeat !important;
	background-position: center !important;
}
.floating-xs {
	animation: floating-xs 3s ease infinite;
	will-change: transform;
	&:hover {
		animation-play-state: paused;
	}
}
.overflow-visible {
	overflow: visible !important;
}
.opacity-0 {
	opacity: 0 !important;
}
.opacity-1 {
	opacity: 0.1 !important;
}
.opacity-2 {
	opacity: 0.2 !important;
}
.opacity-3 {
	opacity: 0.3 !important;
}
.opacity-4 {
	opacity: 0.4 !important;
}
.opacity-5 {
	opacity: 0.5 !important;
}
.opacity-6 {
	opacity: 0.6 !important;
}
.opacity-7 {
	opacity: 0.7 !important;
}
.opacity-8 {
	opacity: 0.8 !important;
}
.opacity-9 {
	opacity: 0.9 !important;
}
.fill-opacity-0 {
	fill-opacity: 0 !important;
}
.fill-opacity-1 {
	fill-opacity: 0.1 !important;
}
.fill-opacity-2 {
	fill-opacity: 0.2 !important;
}
.fill-opacity-3 {
	fill-opacity: 0.3 !important;
}
.fill-opacity-4 {
	fill-opacity: 0.4 !important;
}
.fill-opacity-5 {
	fill-opacity: 0.5 !important;
}
.fill-opacity-6 {
	fill-opacity: 0.6 !important;
}
.fill-opacity-7 {
	fill-opacity: 0.7 !important;
}
.fill-opacity-8 {
	fill-opacity: 0.8 !important;
}
.fill-opacity-9 {
	fill-opacity: 0.9 !important;
}
.z-0 {
	position: relative;
	z-index: 0 !important;
}
.z-1 {
	position: relative;
	z-index: 1 !important;
}
.z-2 {
	position: relative;
	z-index: 2 !important;
}
.z-3 {
	position: relative;
	z-index: 3 !important;
}
.z-4 {
	position: relative;
	z-index: 4 !important;
}
.z-5 {
	position: relative;
	z-index: 5 !important;
}
.z-6 {
	position: relative;
	z-index: 6 !important;
}
.z-7 {
	position: relative;
	z-index: 7 !important;
}
.z-8 {
	position: relative;
	z-index: 8 !important;
}
.z-9 {
	position: relative;
	z-index: 9 !important;
}
.bw-md {
	border-width: 0.125rem !important;
}
.bw-lg {
	border-width: 0.25rem !important;
}
.bw-xl {
	border-width: 0.375rem !important;
}
.border-top-left-radius-0 {
	border-top-left-radius: 0 !important;
}
.border-top-right-radius-0 {
	border-top-right-radius: 0 !important;
}
.border-bottom-left-radius-0 {
	border-bottom-left-radius: 0 !important;
}
.border-bottom-right-radius-0 {
	border-bottom-right-radius: 0 !important;
}
.rounded-xl {
	border-radius: 0.875rem !important;
}
.top-0 {
	top: 0;
}
.right-0 {
	right: 0;
}
.bottom-0 {
	bottom: 0;
}
.left-0 {
	left: 0;
}
.top-1 {
	top: 0.25rem;
}
.right-1 {
	right: 0.25rem;
}
.bottom-1 {
	bottom: 0.25rem;
}
.left-1 {
	left: 0.25rem;
}
.top-2 {
	top: 0.5rem;
}
.right-2 {
	right: 0.5rem;
}
.bottom-2 {
	bottom: 0.5rem;
}
.left-2 {
	left: 0.5rem;
}
.top-3 {
	top: 1rem;
}
.right-3 {
	right: 1rem;
}
.bottom-3 {
	bottom: 1rem;
}
.left-3 {
	left: 1rem;
}
.top-4 {
	top: 1.5rem;
}
.right-4 {
	right: 1.5rem;
}
.bottom-4 {
	bottom: 1.5rem;
}
.left-4 {
	left: 1.5rem;
}
.top-5 {
	top: 3rem;
}
.right-5 {
	right: 3rem;
}
.bottom-5 {
	bottom: 3rem;
}
.left-5 {
	left: 3rem;
}
.top-6 {
	top: 5rem;
}
.right-6 {
	right: 5rem;
}
.bottom-6 {
	bottom: 5rem;
}
.left-6 {
	left: 5rem;
}
.top-7 {
	top: 8rem;
}
.right-7 {
	right: 8rem;
}
.bottom-7 {
	bottom: 8rem;
}
.left-7 {
	left: 8rem;
}
.top-8 {
	top: 10rem;
}
.right-8 {
	right: 10rem;
}
.bottom-8 {
	bottom: 10rem;
}
.left-8 {
	left: 10rem;
}
.top-9 {
	top: 11rem;
}
.right-9 {
	right: 11rem;
}
.bottom-9 {
	bottom: 11rem;
}
.left-9 {
	left: 11rem;
}
.top-10 {
	top: 14rem;
}
.right-10 {
	right: 14rem;
}
.bottom-10 {
	bottom: 14rem;
}
.left-10 {
	left: 14rem;
}
.top-11 {
	top: 16rem;
}
.right-11 {
	right: 16rem;
}
.bottom-11 {
	bottom: 16rem;
}
.left-11 {
	left: 16rem;
}
.top-12 {
	top: 20rem;
}
.right-12 {
	right: 20rem;
}
.bottom-12 {
	bottom: 20rem;
}
.left-12 {
	left: 20rem;
}
.top-sm {
	top: 1rem;
}
.right-sm {
	right: 1rem;
}
.bottom-sm {
	bottom: 1rem;
}
.left-sm {
	left: 1rem;
}
.top-md {
	top: 2rem;
}
.right-md {
	right: 2rem;
}
.bottom-md {
	bottom: 2rem;
}
.left-md {
	left: 2rem;
}
.top-lg {
	top: 4rem;
}
.right-lg {
	right: 4rem;
}
.bottom-lg {
	bottom: 4rem;
}
.left-lg {
	left: 4rem;
}
.top-xl {
	top: 8rem;
}
.right-xl {
	right: 8rem;
}
.bottom-xl {
	bottom: 8rem;
}
.left-xl {
	left: 8rem;
}
.center-vertical-absolute {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.center-horizontal-absolute {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}
.h-100vh {
	height: 100vh !important;
}
.fh-50 {
	height: 50px !important;
}
.fh-100 {
	height: 100px !important;
}
.fh-150 {
	height: 150px !important;
}
.fh-200 {
	height: 200px !important;
}
.fh-250 {
	height: 250px !important;
}
.fh-300 {
	height: 300px !important;
}
.fh-350 {
	height: 350px !important;
}
.fh-400 {
	height: 400px !important;
}
.fh-450 {
	height: 450px !important;
}
.fh-500 {
	height: 500px !important;
}
.fh-550 {
	height: 550px !important;
}
.fh-600 {
	height: 600px !important;
}
.fh-650 {
	height: 650px !important;
}
.fh-700 {
	height: 700px !important;
}
.fh-750 {
	height: 750px !important;
}
.fh-800 {
	height: 800px !important;
}
.fh-850 {
	height: 850px !important;
}
.fh-900 {
	height: 900px !important;
}
.fh-950 {
	height: 950px !important;
}
.fh-1000 {
	height: 1000px !important;
}
.fh-1050 {
	height: 1050px !important;
}
.fh-1100 {
	height: 1100px !important;
}
.fh-1150 {
	height: 1150px !important;
}
.fh-1200 {
	height: 1200px !important;
}
.fh-1250 {
	height: 1250px !important;
}
.fh-1300 {
	height: 1300px !important;
}
.fh-1350 {
	height: 1350px !important;
}
.fh-1400 {
	height: 1400px !important;
}
.fh-1450 {
	height: 1450px !important;
}
.fh-1500 {
	height: 1500px !important;
}
.fh-1550 {
	height: 1550px !important;
}
.fh-1600 {
	height: 1600px !important;
}
.fh-1650 {
	height: 1650px !important;
}
.fh-1700 {
	height: 1700px !important;
}
.fh-1750 {
	height: 1750px !important;
}
.fh-1800 {
	height: 1800px !important;
}
.fh-1850 {
	height: 1850px !important;
}
.fh-1900 {
	height: 1900px !important;
}
.fh-1950 {
	height: 1950px !important;
}
.fw-50 {
	width: 50px !important;
}
.fw-100 {
	width: 100px !important;
}
.fw-150 {
	width: 150px !important;
}
.fw-200 {
	width: 200px !important;
}
.fw-250 {
	width: 250px !important;
}
.fw-300 {
	width: 300px !important;
}
.fw-350 {
	width: 350px !important;
}
.fw-400 {
	width: 400px !important;
}
.fw-450 {
	width: 450px !important;
}
.fw-500 {
	width: 500px !important;
}
.fw-550 {
	width: 550px !important;
}
.fw-600 {
	width: 600px !important;
}
.fw-650 {
	width: 650px !important;
}
.fw-700 {
	width: 700px !important;
}
.fw-750 {
	width: 750px !important;
}
.fw-800 {
	width: 800px !important;
}
.fw-850 {
	width: 850px !important;
}
.fw-900 {
	width: 900px !important;
}
.fw-950 {
	width: 950px !important;
}
.fw-1000 {
	width: 1000px !important;
}
.fw-1050 {
	width: 1050px !important;
}
.fw-1100 {
	width: 1100px !important;
}
.fw-1150 {
	width: 1150px !important;
}
.fw-1200 {
	width: 1200px !important;
}
.fw-1250 {
	width: 1250px !important;
}
.fw-1300 {
	width: 1300px !important;
}
.fw-1350 {
	width: 1350px !important;
}
.fw-1400 {
	width: 1400px !important;
}
.fw-1450 {
	width: 1450px !important;
}
.fw-1500 {
	width: 1500px !important;
}
.fw-1550 {
	width: 1550px !important;
}
.fw-1600 {
	width: 1600px !important;
}
.fw-1650 {
	width: 1650px !important;
}
.fw-1700 {
	width: 1700px !important;
}
.fw-1750 {
	width: 1750px !important;
}
.fw-1800 {
	width: 1800px !important;
}
.fw-1850 {
	width: 1850px !important;
}
.fw-1900 {
	width: 1900px !important;
}
.fw-1950 {
	width: 1950px !important;
}
.fmh-50 {
	min-height: 50px !important;
}
.fmh-100 {
	min-height: 100px !important;
}
.fmh-150 {
	min-height: 150px !important;
}
.fmh-200 {
	min-height: 200px !important;
}
.fmh-250 {
	min-height: 250px !important;
}
.fmh-300 {
	min-height: 300px !important;
}
.fmh-350 {
	min-height: 350px !important;
}
.fmh-400 {
	min-height: 400px !important;
}
.fmh-450 {
	min-height: 450px !important;
}
.fmh-500 {
	min-height: 500px !important;
}
.fmh-550 {
	min-height: 550px !important;
}
.fmh-600 {
	min-height: 600px !important;
}
.fmh-650 {
	min-height: 650px !important;
}
.fmh-700 {
	min-height: 700px !important;
}
.fmh-750 {
	min-height: 750px !important;
}
.fmh-800 {
	min-height: 800px !important;
}
.fmh-850 {
	min-height: 850px !important;
}
.fmh-900 {
	min-height: 900px !important;
}
.fmh-950 {
	min-height: 950px !important;
}
.fmh-1000 {
	min-height: 1000px !important;
}
.fmh-1050 {
	min-height: 1050px !important;
}
.fmh-1100 {
	min-height: 1100px !important;
}
.fmh-1150 {
	min-height: 1150px !important;
}
.fmh-1200 {
	min-height: 1200px !important;
}
.fmh-1250 {
	min-height: 1250px !important;
}
.fmh-1300 {
	min-height: 1300px !important;
}
.fmh-1350 {
	min-height: 1350px !important;
}
.fmh-1400 {
	min-height: 1400px !important;
}
.fmh-1450 {
	min-height: 1450px !important;
}
.fmh-1500 {
	min-height: 1500px !important;
}
.fmh-1550 {
	min-height: 1550px !important;
}
.fmh-1600 {
	min-height: 1600px !important;
}
.fmh-1650 {
	min-height: 1650px !important;
}
.fmh-1700 {
	min-height: 1700px !important;
}
.fmh-1750 {
	min-height: 1750px !important;
}
.fmh-1800 {
	min-height: 1800px !important;
}
.fmh-1850 {
	min-height: 1850px !important;
}
.fmh-1900 {
	min-height: 1900px !important;
}
.fmh-1950 {
	min-height: 1950px !important;
}
.fmw-50 {
	min-width: 50px !important;
}
.fmw-100 {
	min-width: 100px !important;
}
.fmw-150 {
	min-width: 150px !important;
}
.fmw-200 {
	min-width: 200px !important;
}
.fmw-250 {
	min-width: 250px !important;
}
.fmw-300 {
	min-width: 300px !important;
}
.fmw-350 {
	min-width: 350px !important;
}
.fmw-400 {
	min-width: 400px !important;
}
.fmw-450 {
	min-width: 450px !important;
}
.fmw-500 {
	min-width: 500px !important;
}
.fmw-550 {
	min-width: 550px !important;
}
.fmw-600 {
	min-width: 600px !important;
}
.fmw-650 {
	min-width: 650px !important;
}
.fmw-700 {
	min-width: 700px !important;
}
.fmw-750 {
	min-width: 750px !important;
}
.fmw-800 {
	min-width: 800px !important;
}
.fmw-850 {
	min-width: 850px !important;
}
.fmw-900 {
	min-width: 900px !important;
}
.fmw-950 {
	min-width: 950px !important;
}
.fmw-1000 {
	min-width: 1000px !important;
}
.fmw-1050 {
	min-width: 1050px !important;
}
.fmw-1100 {
	min-width: 1100px !important;
}
.fmw-1150 {
	min-width: 1150px !important;
}
.fmw-1200 {
	min-width: 1200px !important;
}
.fmw-1250 {
	min-width: 1250px !important;
}
.fmw-1300 {
	min-width: 1300px !important;
}
.fmw-1350 {
	min-width: 1350px !important;
}
.fmw-1400 {
	min-width: 1400px !important;
}
.fmw-1450 {
	min-width: 1450px !important;
}
.fmw-1500 {
	min-width: 1500px !important;
}
.fmw-1550 {
	min-width: 1550px !important;
}
.fmw-1600 {
	min-width: 1600px !important;
}
.fmw-1650 {
	min-width: 1650px !important;
}
.fmw-1700 {
	min-width: 1700px !important;
}
.fmw-1750 {
	min-width: 1750px !important;
}
.fmw-1800 {
	min-width: 1800px !important;
}
.fmw-1850 {
	min-width: 1850px !important;
}
.fmw-1900 {
	min-width: 1900px !important;
}
.fmw-1950 {
	min-width: 1950px !important;
}
.fmxh-50 {
	max-height: 50px !important;
}
.fmxh-100 {
	max-height: 100px !important;
}
.fmxh-150 {
	max-height: 150px !important;
}
.fmxh-200 {
	max-height: 200px !important;
}
.fmxh-250 {
	max-height: 250px !important;
}
.fmxh-300 {
	max-height: 300px !important;
}
.fmxh-350 {
	max-height: 350px !important;
}
.fmxh-400 {
	max-height: 400px !important;
}
.fmxh-450 {
	max-height: 450px !important;
}
.fmxh-500 {
	max-height: 500px !important;
}
.fmxh-550 {
	max-height: 550px !important;
}
.fmxh-600 {
	max-height: 600px !important;
}
.fmxh-650 {
	max-height: 650px !important;
}
.fmxh-700 {
	max-height: 700px !important;
}
.fmxh-750 {
	max-height: 750px !important;
}
.fmxh-800 {
	max-height: 800px !important;
}
.fmxh-850 {
	max-height: 850px !important;
}
.fmxh-900 {
	max-height: 900px !important;
}
.fmxh-950 {
	max-height: 950px !important;
}
.fmxh-1000 {
	max-height: 1000px !important;
}
.fmxh-1050 {
	max-height: 1050px !important;
}
.fmxh-1100 {
	max-height: 1100px !important;
}
.fmxh-1150 {
	max-height: 1150px !important;
}
.fmxh-1200 {
	max-height: 1200px !important;
}
.fmxh-1250 {
	max-height: 1250px !important;
}
.fmxh-1300 {
	max-height: 1300px !important;
}
.fmxh-1350 {
	max-height: 1350px !important;
}
.fmxh-1400 {
	max-height: 1400px !important;
}
.fmxh-1450 {
	max-height: 1450px !important;
}
.fmxh-1500 {
	max-height: 1500px !important;
}
.fmxh-1550 {
	max-height: 1550px !important;
}
.fmxh-1600 {
	max-height: 1600px !important;
}
.fmxh-1650 {
	max-height: 1650px !important;
}
.fmxh-1700 {
	max-height: 1700px !important;
}
.fmxh-1750 {
	max-height: 1750px !important;
}
.fmxh-1800 {
	max-height: 1800px !important;
}
.fmxh-1850 {
	max-height: 1850px !important;
}
.fmxh-1900 {
	max-height: 1900px !important;
}
.fmxh-1950 {
	max-height: 1950px !important;
}
.fmxw-50 {
	max-width: 50px !important;
}
.fmxw-100 {
	max-width: 100px !important;
}
.fmxw-150 {
	max-width: 150px !important;
}
.fmxw-200 {
	max-width: 200px !important;
}
.fmxw-250 {
	max-width: 250px !important;
}
.fmxw-300 {
	max-width: 300px !important;
}
.fmxw-350 {
	max-width: 350px !important;
}
.fmxw-400 {
	max-width: 400px !important;
}
.fmxw-450 {
	max-width: 450px !important;
}
.fmxw-500 {
	max-width: 500px !important;
}
.fmxw-550 {
	max-width: 550px !important;
}
.fmxw-600 {
	max-width: 600px !important;
}
.fmxw-650 {
	max-width: 650px !important;
}
.fmxw-700 {
	max-width: 700px !important;
}
.fmxw-750 {
	max-width: 750px !important;
}
.fmxw-800 {
	max-width: 800px !important;
}
.fmxw-850 {
	max-width: 850px !important;
}
.fmxw-900 {
	max-width: 900px !important;
}
.fmxw-950 {
	max-width: 950px !important;
}
.fmxw-1000 {
	max-width: 1000px !important;
}
.fmxw-1050 {
	max-width: 1050px !important;
}
.fmxw-1100 {
	max-width: 1100px !important;
}
.fmxw-1150 {
	max-width: 1150px !important;
}
.fmxw-1200 {
	max-width: 1200px !important;
}
.fmxw-1250 {
	max-width: 1250px !important;
}
.fmxw-1300 {
	max-width: 1300px !important;
}
.fmxw-1350 {
	max-width: 1350px !important;
}
.fmxw-1400 {
	max-width: 1400px !important;
}
.fmxw-1450 {
	max-width: 1450px !important;
}
.fmxw-1500 {
	max-width: 1500px !important;
}
.fmxw-1550 {
	max-width: 1550px !important;
}
.fmxw-1600 {
	max-width: 1600px !important;
}
.fmxw-1650 {
	max-width: 1650px !important;
}
.fmxw-1700 {
	max-width: 1700px !important;
}
.fmxw-1750 {
	max-width: 1750px !important;
}
.fmxw-1800 {
	max-width: 1800px !important;
}
.fmxw-1850 {
	max-width: 1850px !important;
}
.fmxw-1900 {
	max-width: 1900px !important;
}
.fmxw-1950 {
	max-width: 1950px !important;
}
[class*="shadow"] {
	transition: all 0.2s ease;
}
.shadow-soft {
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}
.shadow-hover {
	&:hover {
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
	}
}
.shadow-sm-hover {
	&:hover {
		box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
	}
}
.shadow-lg-hover {
	&:hover {
		box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
	}
}
.shadow-none-hover {
	&:hover {
		box-shadow: none !important;
	}
}
.text-underline {
	text-decoration: underline !important;
}
.text-through {
	text-decoration: line-through !important;
}
.display-1-xs {
	font-size: 5rem;
}
.display-2-xs {
	font-size: 3.5rem;
}
.display-3-xs {
	font-size: 2.5rem;
}
.display-4-xs {
	font-size: 1.875rem;
}
.lh-100 {
	line-height: 1;
}
.lh-110 {
	line-height: 1.1;
}
.lh-120 {
	line-height: 1.2;
}
.lh-130 {
	line-height: 1.3;
}
.lh-140 {
	line-height: 1.4;
}
.lh-150 {
	line-height: 1.5;
}
.lh-160 {
	line-height: 1.6;
}
.lh-170 {
	line-height: 1.7;
}
.lh-180 {
	line-height: 1.8;
}
.lh-190 {
	line-height: 1.9;
}
.lh-200 {
	line-height: 2;
}
.lh-210 {
	line-height: 2.1;
}
.lh-220 {
	line-height: 2.2;
}
.lh-230 {
	line-height: 2.3;
}
.lh-240 {
	line-height: 2.4;
}
.lh-250 {
	line-height: 2.5;
}
.lh-260 {
	line-height: 2.6;
}
.lh-270 {
	line-height: 2.7;
}
.lh-280 {
	line-height: 2.8;
}
.lh-290 {
	line-height: 2.9;
}
.lh-300 {
	line-height: 3;
}
.ls-1 {
	letter-spacing: .0625rem;
}
.ls-2 {
	letter-spacing: .09375rem;
}
.ls-3 {
	letter-spacing: 0.125rem;
}
.list-style-none {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
.t-none {
	transform: none !important;
}
.animate-up-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -1px);
	}
}
.animate-right-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(1px, 0);
	}
}
.animate-down-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 1px);
	}
}
.animate-left-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-1px, 0);
	}
}
.scale-up-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(0.55556);
	}
}
.scale-down-1 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(0.4);
	}
}
.scale-up-hover-1 {
	&:hover {
		.scale {
			transform: scale(0.55556);
		}
	}
}
.animate-hover {
	&:hover {
		.animate-up-1 {
			transform: translate(0, -1px);
		}
		.animate-right-1 {
			transform: translate(1px, 0);
		}
		.animate-down-1 {
			transform: translate(0, 1px);
		}
		.animate-left-1 {
			transform: translate(-1px, 0);
		}
		.animate-up-2 {
			transform: translate(0, -2px);
		}
		.animate-right-2 {
			transform: translate(2px, 0);
		}
		.animate-down-2 {
			transform: translate(0, 2px);
		}
		.animate-left-2 {
			transform: translate(-2px, 0);
		}
		.animate-up-3 {
			transform: translate(0, -3px);
		}
		.animate-right-3 {
			transform: translate(3px, 0);
		}
		.animate-down-3 {
			transform: translate(0, 3px);
		}
		.animate-left-3 {
			transform: translate(-3px, 0);
		}
		.animate-up-4 {
			transform: translate(0, -4px);
		}
		.animate-right-4 {
			transform: translate(4px, 0);
		}
		.animate-down-4 {
			transform: translate(0, 4px);
		}
		.animate-left-4 {
			transform: translate(-4px, 0);
		}
		.animate-up-5 {
			transform: translate(0, -5px);
		}
		.animate-right-5 {
			transform: translate(5px, 0);
		}
		.animate-down-5 {
			transform: translate(0, 5px);
		}
		.animate-left-5 {
			transform: translate(-5px, 0);
		}
		.animate-up-6 {
			transform: translate(0, -6px);
		}
		.animate-right-6 {
			transform: translate(6px, 0);
		}
		.animate-down-6 {
			transform: translate(0, 6px);
		}
		.animate-left-6 {
			transform: translate(-6px, 0);
		}
		.animate-up-7 {
			transform: translate(0, -7px);
		}
		.animate-right-7 {
			transform: translate(7px, 0);
		}
		.animate-down-7 {
			transform: translate(0, 7px);
		}
		.animate-left-7 {
			transform: translate(-7px, 0);
		}
		.animate-up-8 {
			transform: translate(0, -8px);
		}
		.animate-right-8 {
			transform: translate(8px, 0);
		}
		.animate-down-8 {
			transform: translate(0, 8px);
		}
		.animate-left-8 {
			transform: translate(-8px, 0);
		}
		.animate-up-9 {
			transform: translate(0, -9px);
		}
		.animate-right-9 {
			transform: translate(9px, 0);
		}
		.animate-down-9 {
			transform: translate(0, 9px);
		}
		.animate-left-9 {
			transform: translate(-9px, 0);
		}
	}
}
.animate-up-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -2px);
	}
}
.animate-right-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(2px, 0);
	}
}
.animate-down-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 2px);
	}
}
.animate-left-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-2px, 0);
	}
}
.scale-up-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(1.11111);
	}
}
.scale-down-2 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(0.8);
	}
}
.scale-up-hover-2 {
	&:hover {
		.scale {
			transform: scale(1.11111);
		}
	}
}
.animate-up-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -3px);
	}
}
.animate-right-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(3px, 0);
	}
}
.animate-down-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 3px);
	}
}
.animate-left-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-3px, 0);
	}
}
.scale-up-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(1.66667);
	}
}
.scale-down-3 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(1.2);
	}
}
.scale-up-hover-3 {
	&:hover {
		.scale {
			transform: scale(1.66667);
		}
	}
}
.animate-up-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -4px);
	}
}
.animate-right-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(4px, 0);
	}
}
.animate-down-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 4px);
	}
}
.animate-left-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-4px, 0);
	}
}
.scale-up-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(2.22222);
	}
}
.scale-down-4 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(1.6);
	}
}
.scale-up-hover-4 {
	&:hover {
		.scale {
			transform: scale(2.22222);
		}
	}
}
.animate-up-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -5px);
	}
}
.animate-right-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(5px, 0);
	}
}
.animate-down-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 5px);
	}
}
.animate-left-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-5px, 0);
	}
}
.scale-up-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(2.77778);
	}
}
.scale-down-5 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(2);
	}
}
.scale-up-hover-5 {
	&:hover {
		.scale {
			transform: scale(2.77778);
		}
	}
}
.animate-up-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -6px);
	}
}
.animate-right-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(6px, 0);
	}
}
.animate-down-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 6px);
	}
}
.animate-left-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-6px, 0);
	}
}
.scale-up-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(3.33333);
	}
}
.scale-down-6 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(2.4);
	}
}
.scale-up-hover-6 {
	&:hover {
		.scale {
			transform: scale(3.33333);
		}
	}
}
.animate-up-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -7px);
	}
}
.animate-right-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(7px, 0);
	}
}
.animate-down-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 7px);
	}
}
.animate-left-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-7px, 0);
	}
}
.scale-up-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(3.88889);
	}
}
.scale-down-7 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(2.8);
	}
}
.scale-up-hover-7 {
	&:hover {
		.scale {
			transform: scale(3.88889);
		}
	}
}
.animate-up-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -8px);
	}
}
.animate-right-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(8px, 0);
	}
}
.animate-down-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 8px);
	}
}
.animate-left-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-8px, 0);
	}
}
.scale-up-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(4.44444);
	}
}
.scale-down-8 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(3.2);
	}
}
.scale-up-hover-8 {
	&:hover {
		.scale {
			transform: scale(4.44444);
		}
	}
}
.animate-up-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, -9px);
	}
}
.animate-right-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(9px, 0);
	}
}
.animate-down-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(0, 9px);
	}
}
.animate-left-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: translate(-9px, 0);
	}
}
.scale-up-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(5);
	}
}
.scale-down-9 {
	transition: all 0.2s ease;
	&:hover {
		transform: scale(3.6);
	}
}
.scale-up-hover-9 {
	&:hover {
		.scale {
			transform: scale(5);
		}
	}
}
.bell-shake.shaking {
	animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-origin: top right;
}
.animate__animated {
	animation-duration: 1s;
	animation-duration: var(--animate-duration);
	animation-fill-mode: both;
}
.animate__jackInTheBox {
	animation-name: jackInTheBox;
	animation-name: jackInTheBox;
}
.navbar-main {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
	}
}
.navbar-theme-primary {
	&:not(.headroom) {
		background-color: #262B40;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-primary.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-secondary {
	&:not(.headroom) {
		background-color: #F8BD7A;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-secondary.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-tertiary {
	&:not(.headroom) {
		background-color: #1B998B;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-tertiary.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-quaternary {
	&:not(.headroom) {
		background-color: #0948B3;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-quaternary.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-primary-app {
	&:not(.headroom) {
		background-color: #EBF4F6;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-primary-app.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-secondary-app {
	&:not(.headroom) {
		background-color: #424AA0;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-secondary-app.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-light {
	&:not(.headroom) {
		background-color: #eaedf2;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-light.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-lighten {
	&:not(.headroom) {
		background-color: #F0F3F6;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-lighten.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-success {
	&:not(.headroom) {
		background-color: #05A677;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-success.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-info {
	&:not(.headroom) {
		background-color: #0948B3;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-info.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-warning {
	&:not(.headroom) {
		background-color: #f5b759;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-warning.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-danger {
	&:not(.headroom) {
		background-color: #FA5252;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-danger.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-white {
	&:not(.headroom) {
		background-color: #ffffff;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-white.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray {
	&:not(.headroom) {
		background-color: #4A5073;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-neutral {
	&:not(.headroom) {
		background-color: #ffffff;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-neutral.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-dark {
	&:not(.headroom) {
		background-color: #262B40;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-dark.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-soft {
	&:not(.headroom) {
		background-color: #f5f8fb;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-soft.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-black {
	&:not(.headroom) {
		background-color: #2e3650;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-black.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-blue {
	&:not(.headroom) {
		background-color: #0948B3;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-blue.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-pink {
	&:not(.headroom) {
		background-color: #C96480;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-pink.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-cyan {
	&:not(.headroom) {
		background-color: #63b1bd;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-cyan.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-purple {
	&:not(.headroom) {
		background-color: #8965e0;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-purple.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-soft-indigo {
	&:not(.headroom) {
		background-color: #f5e8ff;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-soft-indigo.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-soft-green {
	&:not(.headroom) {
		background-color: #2CA58D;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-soft-green.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-100 {
	&:not(.headroom) {
		background-color: #f3f7fa;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-100.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-200 {
	&:not(.headroom) {
		background-color: #f5f8fb;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-200.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-300 {
	&:not(.headroom) {
		background-color: #F0F3F6;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-300.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-400 {
	&:not(.headroom) {
		background-color: #eaedf2;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-400.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-500 {
	&:not(.headroom) {
		background-color: #d1d7e0;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-500.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-600 {
	&:not(.headroom) {
		background-color: #93a5be;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-600.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-700 {
	&:not(.headroom) {
		background-color: #66799e;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-700.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-gray-800 {
	&:not(.headroom) {
		background-color: #506690;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-gray-800.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-facebook {
	&:not(.headroom) {
		background-color: #3b5999;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-facebook.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-twitter {
	&:not(.headroom) {
		background-color: #1da1f2;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-twitter.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-google {
	&:not(.headroom) {
		background-color: #DB4337;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-google.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-instagram {
	&:not(.headroom) {
		background-color: #e4405f;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-instagram.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-pinterest {
	&:not(.headroom) {
		background-color: #bd081c;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-pinterest.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-youtube {
	&:not(.headroom) {
		background-color: #cd201f;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-youtube.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-slack {
	&:not(.headroom) {
		background-color: #3aaf85;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-slack.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-dribbble {
	&:not(.headroom) {
		background-color: #ea4c89;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-dribbble.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-dropbox {
	&:not(.headroom) {
		background-color: #1E90FF;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-dropbox.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-twitch {
	&:not(.headroom) {
		background-color: #4B367C;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-twitch.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-paypal {
	&:not(.headroom) {
		background-color: #ecb32c;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-paypal.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-behance {
	&:not(.headroom) {
		background-color: #0057ff;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-behance.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-reddit {
	&:not(.headroom) {
		background-color: #E84422;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-reddit.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-theme-github {
	&:not(.headroom) {
		background-color: #222222;
	}
	.navbar-nav {
		.dropdown-item.active {
			color: #262B40;
		}
		.dropdown-item {
			&:hover {
				color: #262B40;
			}
		}
		.list-group-item.active {
			color: #262B40;
		}
		.list-group-item {
			&:hover {
				color: #262B40;
			}
		}
	}
}
.navbar-theme-github.navbar-light {
	&:not(.headroom) {
		.navbar-nav {
			.dropdown-item.active {
				color: #262B40;
			}
			.dropdown-item {
				&:hover {
					color: #262B40;
				}
			}
			.list-group-item.active {
				color: #262B40;
			}
			.list-group-item {
				&:hover {
					color: #262B40;
				}
			}
		}
	}
}
.navbar-transparent {
	background-color: transparent;
	border: 0;
	box-shadow: none;
}
.navbar-collapse-header {
	display: none;
}
.navbar-dashboard {
	border-radius: 0.5rem;
}
.icon-notifications {
	.unread-notifications {
		position: absolute;
		right: -5px;
		top: -5px;
	}
}
.section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.section-header {
	position: relative;
	padding-top: 9rem;
	padding-bottom: 3rem;
}
.line-bottom {
	&::after {
		content: '';
		display: block;
		width: 80%;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -40%;
		height: 1px;
		background: radial-gradient(ellipse at center, #eaedf2 0, rgba(255, 255, 255, 0) 80%);
	}
}
.section-profile-cover {
	height: 580px;
	background-size: cover;
	background-position: center center;
}
.components-section {
	>.form-control {
		+ {
			.form-control {
				margin-top: .5rem;
			}
		}
	}
	>.nav {
		+ {
			.nav {
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
		}
	}
	>.alert {
		+ {
			.alert {
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
		}
	}
	>.navbar {
		+ {
			.navbar {
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
		}
	}
	>.progress {
		+ {
			.progress {
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
			.btn {
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
		}
	}
	.badge {
		margin-top: .5rem;
		margin-bottom: .5rem;
		margin-right: .5rem;
	}
	.btn {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}
	.btn-group {
		margin-top: .5rem;
		margin-bottom: .5rem;
		.btn {
			margin: 0;
		}
	}
	.alert {
		margin: 0;
		+ {
			.alert {
				margin-top: 1.25rem;
			}
		}
	}
	.modal-footer {
		.btn {
			margin: 0;
		}
	}
}
.presentation-box {
	position: relative;
	.gadget {
		height: 500px;
		max-width: 100%;
	}
	.pricing-card {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 15%;
		top: 47%;
		height: 125px;
	}
	.dropdown-btn-img {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 25%;
		top: 10%;
		height: 25px;
	}
	.blog-card {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 2%;
		top: 20%;
		height: 60px;
	}
	.profile-card {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 67%;
		top: 19%;
		height: 100px;
	}
	.navbar-img {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 50%;
		top: 56%;
		height: 65px;
	}
	.modal-img {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 38%;
		top: 80%;
		height: 100px;
	}
	.social-btn-img {
		position: absolute;
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		border-radius: 0.5rem;
		left: 51%;
		top: 78%;
		height: 25px;
	}
}
.card-box {
	-o-perspective: 900px;
	perspective: 900px;
	width: 250px;
	position: relative;
	.card-component {
		transform-style: preserve-3d;
		position: relative;
		height: 300px;
		.front {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
			box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
			border-radius: 0.5rem;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			transform: rotateY(24deg);
			&:hover {
				cursor: pointer;
			}
		}
	}
	.card-component.card-component-lg {
		height: auto;
		max-height: 800px;
	}
	.card-component.card-component-xs {
		height: auto;
		max-height: 230px;
	}
	.page-card {
		box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
		border-radius: 0.5rem;
		&:hover {
			cursor: pointer;
		}
	}
}
.copy-docs {
	position: absolute;
	top: 5px;
	right: 5px;
	transition: all 0.2s ease;
	&:hover {
		cursor: pointer;
	}
}
.copy-docs.copied {
	background: #05A677;
}
.index-icon {
	position: absolute;
	font-size: 38px;
	color: #dcdcdc;
	transition: 0.3s all ease;
}
.index-icon-javascript {
	bottom: 80px;
	left: 40px;
	&:hover {
		color: #f1da1c;
	}
}
.index-icon-bootstrap {
	bottom: -150px;
	right: -7%;
	&:hover {
		color: #553d7c;
	}
}
.icon-sass {
	&:hover {
		color: #CD6799;
	}
}
.index-icon-code {
	bottom: 180px;
	left: -80px;
	&:hover {
		color: #ff7f66;
	}
}
.index-icon-gulp {
	&:hover {
		color: #e34a4f;
	}
	left: 250px;
	bottom: -10px;
}
.index-icon-html5 {
	right: 30px;
	bottom: 150px;
	&:hover {
		color: #e54b20;
	}
}
.index-icon-css3 {
	right: 40px;
	bottom: -20px;
	&:hover {
		color: #e54b20;
	}
}
.index-icon-npm {
	right: 180px;
	bottom: 20px;
	&:hover {
		color: #cc3f3d;
	}
}
.index-icon-fontawesome {
	right: 340px;
	bottom: -40px;
	&:hover {
		color: #3499EF;
	}
}
.index-icon-illustrations {
	left: 20px;
	bottom: -40px;
	&:hover {
		color: #F8BD7A;
	}
}
.footer {
	display: flex;
	position: relative;
	ul {
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			a {
				text-decoration: none;
				position: relative;
				display: block;
				color: #66799e;
				&:hover {
					color: #262B40;
				}
			}
			.btn {
				margin: 0;
			}
		}
	}
	ul.links-horizontal {
		&:first-child {
			a {
				padding-left: 0;
			}
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
	}
	ul.links-vertical {
		li {
			display: block;
			margin-left: -5px;
			margin-right: -5px;
			a {
				padding: 5px;
				&:hover {
					color: inherit !important;
				}
			}
		}
	}
	ul.icon-box {
		i {
			line-height: 1.7;
		}
	}
	.social-buttons {
		a {
			margin-top: 5px;
			margin-bottom: 5px;
			padding: 0.125rem 0.375rem;
		}
		.btn {
			margin-top: 5px;
			margin-bottom: 5px;
			padding: 0.125rem 0.375rem;
		}
	}
	.footer-brand {
		font-size: 1.25rem;
		font-weight: 600;
		img.common {
			height: 40px;
		}
		img {
			height: 40px;
			width: 40px;
		}
		&:hover {
			color: #2e3650;
		}
		&:focus {
			color: #2e3650;
		}
	}
	.footer-language-link {
		font-size: 0.875rem;
		i {
			font-size: 0.75rem;
		}
	}
	.copyright {
		font-size: 0.875rem;
	}
	.pull-center {
		display: inline-block;
		float: none;
	}
}
#doc-index {
	&:not(.collapse.show) {
		display: none;
	}
}
.doc-sidebar {
	display: none;
}
.sidebar {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	max-height: 100%;
	overflow-y: hidden;
	width: 100%;
	.nav-item {
		.nav-link {
			color: white;
			.sidebar-icon {
				text-align: center;
			}
			// border-left: 3px solid transparent;
			&:hover {
				color: #eaedf2;
				background-color: #2e3650;
				border-radius: 0.5rem;
				// border-left: 3px solid #eaedf2;
			}
		}
	}
	.nav-item.active:not(.no-active) {
		>.nav-link {
			color: #eaedf2;
			background-color: #424960;
			border-radius: 0.5rem;
			// border-left: 3px solid #10b981;
		}
	}
	.nav-link {
		+ {
			.multi-level {
				margin-top: .2rem;
			}
		}
		font-size: 1rem;
		vertical-align: middle;
		padding: 0.5rem 0.8rem;
		.sidebar-icon {
			color: #ffffff;
			text-align: center;
			svg {
				min-width: 22px;
			}
		}
		.sidebar-text {
			margin-left: .5rem;
		}
		.link-arrow {
			font-size: 0.875rem;
		}
	}
	.nav-link[data-toggle=collapse][aria-expanded=true] {
		.link-arrow {
			transform: rotate(90deg);
			transition: all 0.2s ease;
		}
	}
	.nav-link.active:not(.no-active) {
		color: #262B40;
	}
	.multi-level {
		.nav-link {
			padding-left: 45px;
		}
	}
}
.sidebar-inner {
	position: relative;
	overflow-y: hidden;
}
.sidebar-heading {
	font-size: .75rem;
	text-transform: uppercase;
}
.user-card {
	border-bottom: 0.0625rem solid #2e3650;
}
.content {
	overflow: visible;
}
.alert-icon {
	margin-bottom: .5rem;
	span {
		font-size: 2rem;
	}
}
.avatar {
	+ {
		.avatar-content {
			display: inline-block;
			margin-left: .75rem;
		}
	}
}
.avatar-link {
	img {
		width: 4rem;
		height: 4rem;
	}
}
.author-image {
	img {
		width: 3rem;
		height: 3rem;
	}
}
.user-avatar {
	height: 2.5rem;
	width: 2.5rem;
	min-height: 2.5rem;
	min-width: 2.5rem;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	border-radius: 50%;
	.md-avatar {
		height: 2rem;
		width: 2rem;
		min-height: 2rem;
		min-width: 2rem;
	}
}
.user-avatar.xs-avatar {
	height: 1.5rem;
	width: 1.5rem;
	img {
		height: 1.5rem;
		width: 1.5rem;
	}
}
.user-avatar.lg-avatar {
	height: 3.5rem;
	width: 3.5rem;
	min-height: 3.5rem;
	min-width: 3.5rem;
	font-size: 0.75rem;
}
.user-avatar.xl-avatar {
	height: 4.5rem;
	width: 4.5rem;
	min-height: 4.5rem;
	min-width: 4.5rem;
}
.user-avatar.large-avatar {
	border: 2px solid #ffffff;
	height: 10rem;
	width: 10rem;
	min-height: 10rem;
	min-width: 10rem;
}
.avatar-group {
	.avatar {
		position: relative;
		z-index: 2;
		border: 2px solid #ffffff;
		&:hover {
			z-index: 3;
		}
		+ {
			.avatar {
				margin-left: -1rem;
			}
		}
	}
}
.badge.super-badge {
	vertical-align: super;
}
.badge-pill {
	padding-right: 0.875em;
	padding-left: 0.875em;
}
.badge-inline {
	margin-right: .625rem;
	+ {
		span {
			top: 2px;
			position: relative;
			>a {
				text-decoration: underline;
			}
		}
	}
}
.badge-md {
	padding: .25rem .4rem;
}
.badge-lg {
	padding: .35rem .85rem;
}
.badge-xl {
	font-size: 1.25rem;
	padding: .35rem .85rem;
}
.wi-tags {
	a {
		display: inline-block;
		padding: 0.125rem 0.875rem;
		margin: 0.25rem;
		line-height: 2;
		font-size: 0.875rem;
		box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
		background-color: #ffffff;
	}
}
.pixel-pro-badge {
	position: relative;
	font-size: 1.25rem;
	text-transform: uppercase;
	font-weight: 600;
	right: -20px;
	padding: 5px 14px;
	top: -46px;
	background: #ffffff;
	border-radius: 0.5rem;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}
.subscription-badge {
	top: -19px;
	right: -12px;
	font-size: .75rem;
}
.btn.btn-circle {
	border-radius: 50%;
}
.btn.btn-md {
	padding: 0.65rem 1.25rem;
}
.btn.btn-xs {
	padding: 0.175rem 0.45rem;
	font-size: 0.7rem;
}
.btn-icon-only {
	width: 2.575rem;
	height: 2.575rem;
	padding: 0;
}
.btn-icon-onlya {
	line-height: 2.5;
}
.btn-icon-only.btn-xs {
	width: 1.7rem;
	height: 1.7rem;
}
.btn-icon-only.btn-sm {
	width: 2rem;
	height: 2rem;
}
.btn-facebook.btn-link {
	color: #3b5999;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #3b5999;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #3b5999;
	}
}
.btn-facebook.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #3b5999;
}
.btn-twitter.btn-link {
	color: #1da1f2;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #1da1f2;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #1da1f2;
	}
}
.btn-twitter.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #1da1f2;
}
.btn-google.btn-link {
	color: #DB4337;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #DB4337;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #DB4337;
	}
}
.btn-google.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #DB4337;
}
.btn-instagram.btn-link {
	color: #e4405f;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #e4405f;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #e4405f;
	}
}
.btn-instagram.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #e4405f;
}
.btn-pinterest.btn-link {
	color: #bd081c;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #bd081c;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #bd081c;
	}
}
.btn-pinterest.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #bd081c;
}
.btn-youtube.btn-link {
	color: #cd201f;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #cd201f;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #cd201f;
	}
}
.btn-youtube.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #cd201f;
}
.btn-slack.btn-link {
	color: #3aaf85;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #3aaf85;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #3aaf85;
	}
}
.btn-slack.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #3aaf85;
}
.btn-dribbble.btn-link {
	color: #ea4c89;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #ea4c89;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #ea4c89;
	}
}
.btn-dribbble.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #ea4c89;
}
.btn-dropbox.btn-link {
	color: #1E90FF;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #1E90FF;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #1E90FF;
	}
}
.btn-dropbox.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #1E90FF;
}
.btn-twitch.btn-link {
	color: #4B367C;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #4B367C;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #4B367C;
	}
}
.btn-twitch.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #4B367C;
}
.btn-paypal.btn-link {
	color: #ecb32c;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #ecb32c;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #ecb32c;
	}
}
.btn-paypal.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #ecb32c;
}
.btn-behance.btn-link {
	color: #0057ff;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #0057ff;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #0057ff;
	}
}
.btn-behance.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #0057ff;
}
.btn-reddit.btn-link {
	color: #E84422;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #E84422;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #E84422;
	}
}
.btn-reddit.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #E84422;
}
.btn-github.btn-link {
	color: #222222;
	background: transparent;
	box-shadow: none;
	border: 0;
	border-style: none;
	&:hover {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #222222;
	}
	&:focus {
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		color: #222222;
	}
}
.btn-github.btn-link.active {
	background-color: transparent !important;
	box-shadow: none;
	border: 0;
	color: #222222;
}
.breadcrumb-transparent {
	background: transparent;
	padding: 0;
}
.breadcrumb-primary {
	background: #262B40;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-primary.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #262B40;
	}
}
.breadcrumb-secondary {
	background: #F8BD7A;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-secondary.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #F8BD7A;
	}
}
.breadcrumb-tertiary {
	background: #1B998B;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-tertiary.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #1B998B;
	}
}
.breadcrumb-quaternary {
	background: #0948B3;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-quaternary.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #0948B3;
	}
}
.breadcrumb-primary-app {
	background: #EBF4F6;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-primary-app.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #EBF4F6;
	}
}
.breadcrumb-secondary-app {
	background: #424AA0;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-secondary-app.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #424AA0;
	}
}
.breadcrumb-light {
	background: #eaedf2;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-light.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #eaedf2;
	}
}
.breadcrumb-lighten {
	background: #F0F3F6;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-lighten.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #F0F3F6;
	}
}
.breadcrumb-success {
	background: #05A677;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-success.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #05A677;
	}
}
.breadcrumb-info {
	background: #0948B3;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-info.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #0948B3;
	}
}
.breadcrumb-warning {
	background: #f5b759;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-warning.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #f5b759;
	}
}
.breadcrumb-danger {
	background: #FA5252;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-danger.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #FA5252;
	}
}
.breadcrumb-white {
	background: #ffffff;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-white.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray {
	background: #4A5073;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #4A5073;
	}
}
.breadcrumb-neutral {
	background: #ffffff;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-neutral.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-dark {
	background: #262B40;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-dark.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #262B40;
	}
}
.breadcrumb-soft {
	background: #f5f8fb;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-soft.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #f5f8fb;
	}
}
.breadcrumb-black {
	background: #2e3650;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-black.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #2e3650;
	}
}
.breadcrumb-blue {
	background: #0948B3;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-blue.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #0948B3;
	}
}
.breadcrumb-pink {
	background: #C96480;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-pink.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #C96480;
	}
}
.breadcrumb-cyan {
	background: #63b1bd;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-cyan.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #63b1bd;
	}
}
.breadcrumb-purple {
	background: #8965e0;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-purple.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #8965e0;
	}
}
.breadcrumb-soft-indigo {
	background: #f5e8ff;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-soft-indigo.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #f5e8ff;
	}
}
.breadcrumb-soft-green {
	background: #2CA58D;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-soft-green.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #2CA58D;
	}
}
.breadcrumb-gray-100 {
	background: #f3f7fa;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-100.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #f3f7fa;
	}
}
.breadcrumb-gray-200 {
	background: #f5f8fb;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-200.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #f5f8fb;
	}
}
.breadcrumb-gray-300 {
	background: #F0F3F6;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-300.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #F0F3F6;
	}
}
.breadcrumb-gray-400 {
	background: #eaedf2;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-400.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #eaedf2;
	}
}
.breadcrumb-gray-500 {
	background: #d1d7e0;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-500.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #d1d7e0;
	}
}
.breadcrumb-gray-600 {
	background: #93a5be;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-600.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #93a5be;
	}
}
.breadcrumb-gray-700 {
	background: #66799e;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-700.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #66799e;
	}
}
.breadcrumb-gray-800 {
	background: #506690;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-gray-800.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #506690;
	}
}
.breadcrumb-facebook {
	background: #3b5999;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-facebook.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #3b5999;
	}
}
.breadcrumb-twitter {
	background: #1da1f2;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-twitter.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #1da1f2;
	}
}
.breadcrumb-google {
	background: #DB4337;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-google.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #DB4337;
	}
}
.breadcrumb-instagram {
	background: #e4405f;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-instagram.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #e4405f;
	}
}
.breadcrumb-pinterest {
	background: #bd081c;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-pinterest.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #bd081c;
	}
}
.breadcrumb-youtube {
	background: #cd201f;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-youtube.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #cd201f;
	}
}
.breadcrumb-slack {
	background: #3aaf85;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-slack.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #3aaf85;
	}
}
.breadcrumb-dribbble {
	background: #ea4c89;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-dribbble.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #ea4c89;
	}
}
.breadcrumb-dropbox {
	background: #1E90FF;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-dropbox.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #1E90FF;
	}
}
.breadcrumb-twitch {
	background: #4B367C;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-twitch.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #4B367C;
	}
}
.breadcrumb-paypal {
	background: #ecb32c;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-paypal.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #ecb32c;
	}
}
.breadcrumb-behance {
	background: #0057ff;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-behance.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #0057ff;
	}
}
.breadcrumb-reddit {
	background: #E84422;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-reddit.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #E84422;
	}
}
.breadcrumb-github {
	background: #222222;
	.breadcrumb-item.active {
		color: #ffffff;
	}
}
.breadcrumb-github.breadcrumb-transparent {
	background: transparent;
	.breadcrumb-item.active {
		color: #222222;
	}
}
.breadcrumb-text-light {
	.breadcrumb-item {
		color: #ffffff;
		a {
			color: #ffffff;
		}
		&:before {
			color: #ffffff;
		}
	}
}
.blog-card {
	margin-bottom: 2rem;
	.card.img-card {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
	}
	.card-header {
		background: transparent;
	}
	.card-body {
		.bottom-content {
			position: absolute;
			bottom: 10px;
		}
	}
	.card-footer {
		background: transparent;
		padding: 1rem 1rem;
	}
	.avatar-sm-custom {
		img {
			border: 0.125rem solid #F8BD7A;
			padding: .25rem;
			width: 50px;
			height: 50px;
		}
	}
	.card-img-top {
		object-fit: cover;
	}
	.card-article-wide {
		img {
			height: 100%;
		}
	}
}
.card.hover-state {
	&:hover {
		background-color: #f5f8fb;
	}
}
.message-wrapper {
	.card {
		border: 0;
	}
}
.task-wrapper {
	.card {
		border: 0;
	}
}
.customer-testimonial {
	.content-wrapper {
		&:before {
			content: "";
			position: absolute;
			top: 25px;
			left: -1px;
			margin-left: .75rem;
			border: 8px solid #2e3650;
			border-color: transparent #e0e6ec #e0e6ec transparent;
			box-sizing: border-box;
			transform-origin: 0 0;
			transform: rotate(135deg);
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		}
	}
}
.profile-page {
	.card-profile {
		margin-top: -150px;
		.card-profile-image {
			position: relative;
			img {
				max-width: 180px;
				border-radius: 0.5rem;
				transform: translate(-50%, -30%);
				position: absolute;
				left: 50%;
				transition: all 0.2s ease;
				&:hover {
					transform: translate(-50%, -33%);
				}
			}
		}
		.card-profile-stats {
			padding: 1rem 0;
			>div {
				text-align: center;
				margin-right: 1rem;
				padding: .875rem;
				&:last-child {
					margin-right: 0;
				}
				.heading {
					display: block;
					font-size: 1.1rem;
					font-weight: bold;
				}
				.description {
					font-size: .875rem;
					color: #d1d7e0;
				}
			}
		}
		.card-profile-actions {
			padding: .875rem;
			margin-top: 110px;
		}
	}
}
.card-stats {
	padding-left: 1.9rem;
}
.card-lift-hover {
	&:hover {
		transform: translateY(-20px);
		transition: all 0.2s ease;
	}
}
.page-preview {
	display: block;
	position: relative;
	.show-on-hover {
		position: absolute;
		bottom: -25px;
		background: rgba(38, 43, 64, 0.85);
		padding: 10px 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		width: calc(100% + 28px);
		left: -14px;
		opacity: 0;
		transition: 0.2s;
	}
	&:hover {
		.show-on-hover {
			z-index: 99;
			opacity: 1;
		}
	}
}
.page-preview.page-preview-lg {
	.show-on-hover {
		left: -30px;
		width: calc(100% + 60px);
		bottom: -20px;
	}
}
.counter-alternate {
	&::after {
		content: '\f067';
		font-family: "Font Awesome 5 Free";
		position: absolute;
		font-size: 2.25rem;
		color: #262B40;
		font-weight: 900;
	}
}
.custom-control-label {
	&:before {
		box-shadow: none;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	span {
		position: relative;
		top: 2px;
	}
	margin-bottom: 0;
}
.custom-control-input {
	&:active {
		~ {
			.custom-control-label {
				&::before {
					border-color: #262B40;
				}
			}
		}
	}
}
.custom-file-label {
	background-color: #f3f3f5;
	&::after {
		background-color: #f3f3f5;
	}
}
.custom-select {
	font-size: 1rem;
	box-shadow: none;
	&:hover {
		cursor: pointer;
	}
}
.custom-select.custom-select-shadow {
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	transition: box-shadow .15s ease;
	&:focus {
		box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
	}
}
.rating-star.star-lg {
	label {
		margin-bottom: .5rem;
		&::before {
			font-size: 1.5rem;
		}
	}
}
.rating-star {
	label {
		position: relative;
		margin-bottom: 0;
		cursor: pointer;
		color: #eaedf2;
		&:before {
			content: "\f005";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			transition: all .2s ease;
			font-size: 1.0625rem;
		}
	}
	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	>input {
		&:checked {
			~ {
				label {
					color: #f5b759;
				}
			}
		}
	}
	>label {
		&:hover {
			color: #f5b759;
			~ {
				label {
					color: #f5b759;
				}
			}
		}
	}
}
.ct-slice-pie {
	stroke: #ffffff;
	stroke-width: 3px;
}
.small-chart {
	margin-left: -1rem;
}
.dropdown-menu.dropdown-menu-xs {
	min-width: 120px;
	max-width: 120px;
	border: 0.0625rem solid #eaedf2;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}
[data-toggle] {
	&:hover {
		cursor: pointer;
	}
}
.dropdown-menu-sm {
	min-width: 100px;
	border: 0.3rem;
}
.dropdown-menu-md {
	min-width: 250px;
	border: 0.3rem;
}
.dropdown-menu-lg {
	min-width: 350px;
	border-radius: 0.3rem;
}
.dropdown-menu-xl {
	min-width: 450px;
	border-radius: 0.3rem;
}
.dropzone {
	min-height: 150px;
	border: 2px dashed #93a5be;
	background: #ffffff;
	&:hover {
		background-color: #f5f8fb;
	}
	.dz-message {
		.dz-button {
			font-size: 1.25rem;
			font-weight: 600;
		}
	}
}
.form-control.form-control-lg {
	padding: 1rem 0.75rem;
}
.form-check-input.round-check[type="checkbox"] {
	border-radius: 50%;
}
.search-bar {
	.form-control {
		width: 200px;
		transition: width .2s ease-in-out;
		&:focus {
			width: 300px;
		}
	}
}
.focused {
	.form-control {
		border-color: #566190;
		background-color: #ffffff;
	}
	.input-group-text {
		color: #66799e;
		background-color: #ffffff;
	}
	.input-group-shadow {
		box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
	}
}
.file-field {
	input[type="file"] {
		position: absolute;
		cursor: pointer;
		filter: alpha(opacity=0);
		opacity: 0;
		padding-bottom: 30px;
	}
	span {
		cursor: pointer;
	}
}
.icon-box {
	padding: 1rem;
	position: relative;
	.icon-box-body {
		position: relative;
	}
}
.icon {
	text-align: center;
	display: inline-flex;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-ms-flex-pack: center;
	span {
		font-size: 2rem;
	}
	svg {
		font-size: 2rem;
	}
	.organic-shape {
		margin-left: .5rem;
	}
}
.icon.icon-small {
	span {
		font-size: 0.675rem;
	}
	svg {
		font-size: 0.675rem;
	}
}
.icon.icon-xs {
	span {
		font-size: 0.875rem;
	}
	svg {
		font-size: 0.875rem;
	}
}
.icon.icon-sm {
	span {
		font-size: 1.25rem;
	}
	svg {
		font-size: 1.25rem;
	}
}
.icon.icon-lg {
	span {
		font-size: 2.75rem;
	}
	svg {
		font-size: 2.75rem;
		height: 2rem;
	}
}
.icon.icon-xl {
	span {
		font-size: 4.5rem;
	}
	svg {
		height: 3.5rem;
	}
}
.icon.w-20 {
	width: 20px;
}
.icon.w-30 {
	width: 30px;
}
.icon.rounded-circle {
	.icon-bordered {
		border-radius: 50%;
	}
}
.icon.organic-radius {
	.icon-bordered {
		border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
	}
}
.icon-shape {
	width: 4.5rem;
	height: 4.5rem;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 2rem;
	}
	svg {
		font-size: 2rem;
	}
}
.icon-shape.icon-xs {
	width: 2rem;
	height: 2rem;
	span {
		font-size: 0.875rem;
	}
	svg {
		font-size: 0.875rem;
	}
}
.icon-shape.icon-sm {
	width: 3rem;
	height: 3rem;
	span {
		font-size: 1.25rem;
	}
	svg {
		font-size: 1.25rem;
	}
}
.icon-shape.icon-lg {
	width: 5.5rem;
	height: 5.5rem;
	span {
		font-size: 1.875rem;
	}
	svg {
		font-size: 1.875rem;
	}
}
.image-shape {
	width: 5.5rem;
	height: 5.5rem;
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	span {
		font-size: 2rem;
		z-index: 1;
	}
}
.image-shape.icon-parallax {
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	overflow: hidden;
	background-repeat: no-repeat;
}
.list-icon {
	width: 45px;
}
.icon-shape-primary {
	color: #262B40;
	background-color: rgba(38, 43, 64, 0.3);
}
.icon-primary {
	span {
		color: #262B40;
	}
}
.fill-primary {
	fill: #262B40;
	fill: #262B40;
}
.icon-shape-secondary {
	color: #F8BD7A;
	background-color: rgba(248, 189, 122, 0.3);
}
.icon-secondary {
	span {
		color: #F8BD7A;
	}
}
.fill-secondary {
	fill: #F8BD7A;
	fill: #F8BD7A;
}
.icon-shape-tertiary {
	color: #1B998B;
	background-color: rgba(27, 153, 139, 0.3);
}
.icon-tertiary {
	span {
		color: #1B998B;
	}
}
.fill-tertiary {
	fill: #1B998B;
	fill: #1B998B;
}
.icon-shape-quaternary {
	color: #0948B3;
	background-color: rgba(9, 72, 179, 0.3);
}
.icon-quaternary {
	span {
		color: #0948B3;
	}
}
.fill-quaternary {
	fill: #0948B3;
	fill: #0948B3;
}
.icon-shape-primary-app {
	color: #EBF4F6;
	background-color: rgba(235, 244, 246, 0.3);
}
.icon-primary-app {
	span {
		color: #EBF4F6;
	}
}
.fill-primary-app {
	fill: #EBF4F6;
	fill: #EBF4F6;
}
.icon-shape-secondary-app {
	color: #424AA0;
	background-color: rgba(66, 74, 160, 0.3);
}
.icon-secondary-app {
	span {
		color: #424AA0;
	}
}
.fill-secondary-app {
	fill: #424AA0;
	fill: #424AA0;
}
.icon-shape-light {
	color: #eaedf2;
	background-color: rgba(234, 237, 242, 0.3);
}
.icon-light {
	span {
		color: #eaedf2;
	}
}
.fill-light {
	fill: #eaedf2;
	fill: #eaedf2;
}
.icon-shape-lighten {
	color: #F0F3F6;
	background-color: rgba(240, 243, 246, 0.3);
}
.icon-lighten {
	span {
		color: #F0F3F6;
	}
}
.fill-lighten {
	fill: #F0F3F6;
	fill: #F0F3F6;
}
.icon-shape-success {
	color: #05A677;
	background-color: rgba(5, 166, 119, 0.3);
}
.icon-success {
	span {
		color: #05A677;
	}
}
.fill-success {
	fill: #05A677;
	fill: #05A677;
}
.icon-shape-info {
	color: #0948B3;
	background-color: rgba(9, 72, 179, 0.3);
}
.icon-info {
	span {
		color: #0948B3;
	}
}
.fill-info {
	fill: #0948B3;
	fill: #0948B3;
}
.icon-shape-warning {
	color: #f5b759;
	background-color: rgba(245, 183, 89, 0.3);
}
.icon-warning {
	span {
		color: #f5b759;
	}
}
.fill-warning {
	fill: #f5b759;
	fill: #f5b759;
}
.icon-shape-danger {
	color: #FA5252;
	background-color: rgba(250, 82, 82, 0.3);
}
.icon-danger {
	span {
		color: #FA5252;
	}
}
.fill-danger {
	fill: #FA5252;
	fill: #FA5252;
}
.icon-shape-white {
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.3);
}
.icon-white {
	span {
		color: #ffffff;
	}
}
.fill-white {
	fill: #ffffff;
	fill: #ffffff;
}
.icon-shape-gray {
	color: #4A5073;
	background-color: rgba(74, 80, 115, 0.3);
}
.icon-gray {
	span {
		color: #4A5073;
	}
}
.fill-gray {
	fill: #4A5073;
	fill: #4A5073;
}
.icon-shape-neutral {
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.3);
}
.icon-neutral {
	span {
		color: #ffffff;
	}
}
.fill-neutral {
	fill: #ffffff;
	fill: #ffffff;
}
.icon-shape-dark {
	color: #262B40;
	background-color: rgba(38, 43, 64, 0.3);
}
.icon-dark {
	span {
		color: #262B40;
	}
}
.fill-dark {
	fill: #262B40;
	fill: #262B40;
}
.icon-shape-soft {
	color: #f5f8fb;
	background-color: rgba(245, 248, 251, 0.3);
}
.icon-soft {
	span {
		color: #f5f8fb;
	}
}
.fill-soft {
	fill: #f5f8fb;
	fill: #f5f8fb;
}
.icon-shape-black {
	color: #2e3650;
	background-color: rgba(46, 54, 80, 0.3);
}
.icon-black {
	span {
		color: #2e3650;
	}
}
.fill-black {
	fill: #2e3650;
	fill: #2e3650;
}
.icon-shape-blue {
	color: #0948B3;
	background-color: rgba(9, 72, 179, 0.3);
}
.icon-blue {
	span {
		color: #0948B3;
	}
}
.fill-blue {
	fill: #0948B3;
	fill: #0948B3;
}
.icon-shape-pink {
	color: #C96480;
	background-color: rgba(201, 100, 128, 0.3);
}
.icon-pink {
	span {
		color: #C96480;
	}
}
.fill-pink {
	fill: #C96480;
	fill: #C96480;
}
.icon-shape-cyan {
	color: #63b1bd;
	background-color: rgba(99, 177, 189, 0.3);
}
.icon-cyan {
	span {
		color: #63b1bd;
	}
}
.fill-cyan {
	fill: #63b1bd;
	fill: #63b1bd;
}
.icon-shape-purple {
	color: #8965e0;
	background-color: rgba(137, 101, 224, 0.3);
}
.icon-purple {
	span {
		color: #8965e0;
	}
}
.fill-purple {
	fill: #8965e0;
	fill: #8965e0;
}
.icon-shape-soft-indigo {
	color: #f5e8ff;
	background-color: rgba(245, 232, 255, 0.3);
}
.icon-soft-indigo {
	span {
		color: #f5e8ff;
	}
}
.fill-soft-indigo {
	fill: #f5e8ff;
	fill: #f5e8ff;
}
.icon-shape-soft-green {
	color: #2CA58D;
	background-color: rgba(44, 165, 141, 0.3);
}
.icon-soft-green {
	span {
		color: #2CA58D;
	}
}
.fill-soft-green {
	fill: #2CA58D;
	fill: #2CA58D;
}
.icon-shape-gray-100 {
	color: #f3f7fa;
	background-color: rgba(243, 247, 250, 0.3);
}
.icon-gray-100 {
	span {
		color: #f3f7fa;
	}
}
.fill-gray-100 {
	fill: #f3f7fa;
	fill: #f3f7fa;
}
.icon-shape-gray-200 {
	color: #f5f8fb;
	background-color: rgba(245, 248, 251, 0.3);
}
.icon-gray-200 {
	span {
		color: #f5f8fb;
	}
}
.fill-gray-200 {
	fill: #f5f8fb;
	fill: #f5f8fb;
}
.icon-shape-gray-300 {
	color: #F0F3F6;
	background-color: rgba(240, 243, 246, 0.3);
}
.icon-gray-300 {
	span {
		color: #F0F3F6;
	}
}
.fill-gray-300 {
	fill: #F0F3F6;
	fill: #F0F3F6;
}
.icon-shape-gray-400 {
	color: #eaedf2;
	background-color: rgba(234, 237, 242, 0.3);
}
.icon-gray-400 {
	span {
		color: #eaedf2;
	}
}
.fill-gray-400 {
	fill: #eaedf2;
	fill: #eaedf2;
}
.icon-shape-gray-500 {
	color: #d1d7e0;
	background-color: rgba(209, 215, 224, 0.3);
}
.icon-gray-500 {
	span {
		color: #d1d7e0;
	}
}
.fill-gray-500 {
	fill: #d1d7e0;
	fill: #d1d7e0;
}
.icon-shape-gray-600 {
	color: #93a5be;
	background-color: rgba(147, 165, 190, 0.3);
}
.icon-gray-600 {
	span {
		color: #93a5be;
	}
}
.fill-gray-600 {
	fill: #93a5be;
	fill: #93a5be;
}
.icon-shape-gray-700 {
	color: #66799e;
	background-color: rgba(102, 121, 158, 0.3);
}
.icon-gray-700 {
	span {
		color: #66799e;
	}
}
.fill-gray-700 {
	fill: #66799e;
	fill: #66799e;
}
.icon-shape-gray-800 {
	color: #506690;
	background-color: rgba(80, 102, 144, 0.3);
}
.icon-gray-800 {
	span {
		color: #506690;
	}
}
.fill-gray-800 {
	fill: #506690;
	fill: #506690;
}
.icon-shape-facebook {
	color: #3b5999;
	background-color: rgba(59, 89, 153, 0.3);
}
.icon-facebook {
	span {
		color: #3b5999;
	}
}
.fill-facebook {
	fill: #3b5999;
	fill: #3b5999;
}
.icon-shape-twitter {
	color: #1da1f2;
	background-color: rgba(29, 161, 242, 0.3);
}
.icon-twitter {
	span {
		color: #1da1f2;
	}
}
.fill-twitter {
	fill: #1da1f2;
	fill: #1da1f2;
}
.icon-shape-google {
	color: #DB4337;
	background-color: rgba(219, 67, 55, 0.3);
}
.icon-google {
	span {
		color: #DB4337;
	}
}
.fill-google {
	fill: #DB4337;
	fill: #DB4337;
}
.icon-shape-instagram {
	color: #e4405f;
	background-color: rgba(228, 64, 95, 0.3);
}
.icon-instagram {
	span {
		color: #e4405f;
	}
}
.fill-instagram {
	fill: #e4405f;
	fill: #e4405f;
}
.icon-shape-pinterest {
	color: #bd081c;
	background-color: rgba(189, 8, 28, 0.3);
}
.icon-pinterest {
	span {
		color: #bd081c;
	}
}
.fill-pinterest {
	fill: #bd081c;
	fill: #bd081c;
}
.icon-shape-youtube {
	color: #cd201f;
	background-color: rgba(205, 32, 31, 0.3);
}
.icon-youtube {
	span {
		color: #cd201f;
	}
}
.fill-youtube {
	fill: #cd201f;
	fill: #cd201f;
}
.icon-shape-slack {
	color: #3aaf85;
	background-color: rgba(58, 175, 133, 0.3);
}
.icon-slack {
	span {
		color: #3aaf85;
	}
}
.fill-slack {
	fill: #3aaf85;
	fill: #3aaf85;
}
.icon-shape-dribbble {
	color: #ea4c89;
	background-color: rgba(234, 76, 137, 0.3);
}
.icon-dribbble {
	span {
		color: #ea4c89;
	}
}
.fill-dribbble {
	fill: #ea4c89;
	fill: #ea4c89;
}
.icon-shape-dropbox {
	color: #1E90FF;
	background-color: rgba(30, 144, 255, 0.3);
}
.icon-dropbox {
	span {
		color: #1E90FF;
	}
}
.fill-dropbox {
	fill: #1E90FF;
	fill: #1E90FF;
}
.icon-shape-twitch {
	color: #4B367C;
	background-color: rgba(75, 54, 124, 0.3);
}
.icon-twitch {
	span {
		color: #4B367C;
	}
}
.fill-twitch {
	fill: #4B367C;
	fill: #4B367C;
}
.icon-shape-paypal {
	color: #ecb32c;
	background-color: rgba(236, 179, 44, 0.3);
}
.icon-paypal {
	span {
		color: #ecb32c;
	}
}
.fill-paypal {
	fill: #ecb32c;
	fill: #ecb32c;
}
.icon-shape-behance {
	color: #0057ff;
	background-color: rgba(0, 87, 255, 0.3);
}
.icon-behance {
	span {
		color: #0057ff;
	}
}
.fill-behance {
	fill: #0057ff;
	fill: #0057ff;
}
.icon-shape-reddit {
	color: #E84422;
	background-color: rgba(232, 68, 34, 0.3);
}
.icon-reddit {
	span {
		color: #E84422;
	}
}
.fill-reddit {
	fill: #E84422;
	fill: #E84422;
}
.icon-shape-github {
	color: #222222;
	background-color: rgba(34, 34, 34, 0.3);
}
.icon-github {
	span {
		color: #222222;
	}
}
.fill-github {
	fill: #222222;
	fill: #222222;
}
.shape-xs {
	width: 10px;
	height: 10px;
}
.calendar {
	width: 3.125rem;
	line-height: 1;
	flex-direction: column;
	text-align: center;
	border-radius: .375rem;
	.calendar-month {
		background-color: #FA5252;
		color: #ffffff;
		border-radius: .375rem .375rem 0 0;
		padding: .25rem;
		font-weight: 600;
	}
	.calendar-day {
		font-size: 1.728rem;
		padding: .25rem;
		border: 0.125rem solid #262B40;
		border-top: 0;
		border-radius: 0 0 .375rem .375rem;
		color: #262B40;
		font-weight: 600;
	}
}
.icon-badge {
	display: inline-block;
	position: absolute;
	top: -11px;
	right: 6px;
	height: 7px;
	width: 7px;
	background-color: #FA5252;
}
.github-big-icon {
	position: absolute;
	right: -35px;
	top: 165px;
	span {
		font-size: 800px;
		opacity: .1;
	}
}
.image-xl {
	height: 20rem;
	img {
		height: 20rem;
	}
}
.image-lg {
	height: 12rem;
	img {
		height: 12rem;
	}
}
.image-md {
	height: 5.5rem;
	img {
		height: 5.5rem;
	}
}
.image-sm {
	height: 3rem;
	img {
		height: 3rem;
	}
}
.image-xs {
	height: 1.5rem;
	img {
		height: 1.5rem;
	}
}
.image-small {
	height: 1rem;
	img {
		height: 1rem;
	}
}
.full-image {
	height: 100%;
}
.gallery-feed {
	img {
		width: 20%;
		margin-right: .5rem;
		margin-bottom: .5rem;
		float: left;
	}
}
.language-flag {
	width: auto;
	height: 1rem;
	margin-right: 0.4rem;
	position: relative;
	top: -2px;
}
.input-group-prepend {
	margin-right: -2px;
}
.list-group-space {
	.list-group-item {
		margin-bottom: 1.5rem;
		border-radius: 0.5rem;
	}
}
.list-group-img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	vertical-align: top;
	margin: -.1rem 1.2rem 0 -.2rem;
}
.list-group-content {
	flex: 1;
	min-width: 0;
	>p {
		color: #d1d7e0;
		line-height: 1.5;
		margin: .2rem 0 0;
	}
}
.list-group-heading {
	font-size: 1rem;
	color: #506690;
	>small {
		float: right;
		color: #d1d7e0;
		font-weight: 500;
	}
	>.small {
		float: right;
		color: #d1d7e0;
		font-weight: 500;
	}
}
.list-group.simple-list {
	.list-group-item {
		background: transparent;
		border: none;
		padding: 0.375rem 0.125rem;
		i {
			vertical-align: middle;
			width: 35px;
			display: inline-block;
		}
	}
}
.news-list {
	.row {
		>[class*='col'] {
			padding: 0 0.25rem;
		}
	}
	>li {
		&:not(:last-child) {
			margin-bottom: .75rem;
		}
	}
	img {
		min-height: 100%;
		object-fit: cover;
	}
}
.map {
	height: 400px;
	width: 100%;
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
}
.mapouter {
	position: relative;
	text-align: right;
	height: 500px;
	width: 100%;
}
.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 500px;
	width: 100%;
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	#gmap_canvas {
		width: 100%;
		height: 500px;
	}
}
#mapbox {
	width: 100%;
	height: 600px;
	z-index: 1;
}
.leaflet-popup-content-wrapper {
	padding: 8px 8px 8px 0;
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}
.leaflet-popup-tip {
	box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}
.leaflet-container {
	a.leaflet-popup-close-button {
		padding: 7px 15px 0 0;
	}
}
.logo-map {
	position: absolute;
	right: 0;
	z-index: 3;
}
.search-map {
	position: absolute;
	z-index: 3;
	bottom: 30px;
	width: 100%;
}
.svgMap-map-wrapper {
	position: relative;
	width: 100%;
	padding-top: 50%;
	overflow: hidden;
	background: #ffffff;
	color: #262B40;
	* {
		box-sizing: border-box;
		outline: none;
	}
	.svgMap-map-image {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
	.svgMap-map-controls-wrapper {
		position: absolute;
		bottom: 10px;
		left: 10px;
		z-index: 1;
		display: flex;
		overflow: hidden;
		border-radius: 0.5rem;
		box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
	}
	.svgMap-map-controls-zoom {
		display: flex;
		margin-right: 5px;
		overflow: hidden;
		background: #fff;
		&:last-child {
			margin-right: 0;
		}
	}
	.svgMap-map-controls-move {
		display: flex;
		margin-right: 5px;
		overflow: hidden;
		background: #fff;
		&:last-child {
			margin-right: 0;
		}
	}
	.svgMap-control-button {
		cursor: pointer;
		width: 30px;
		height: 30px;
		position: relative;
	}
	.svgMap-control-button.svgMap-zoom-button {
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #f5f8fb;
			transition: background-color .2s;
			width: 11px;
			height: 3px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #f5f8fb;
			transition: background-color .2s;
		}
		&:hover {
			&:before {
				background: #111;
			}
			&:after {
				background: #111;
			}
		}
	}
	.svgMap-control-button.svgMap-zoom-button.svgMap-disabled {
		&:before {
			background: #ccc;
		}
		&:after {
			background: #ccc;
		}
	}
	.svgMap-control-button.svgMap-zoom-in-button {
		&:after {
			width: 3px;
			height: 11px;
		}
	}
	.svgMap-country {
		cursor: pointer;
		stroke: #fff;
		stroke-width: 1;
		stroke-linejoin: round;
		vector-effect: non-scaling-stroke;
		transition: fill .2s, stroke .2s;
		&:hover {
			stroke: #f5f8fb;
		}
	}
	.svgMap-country.svgMap-active {
		stroke: #f5f8fb;
		stroke-width: 1.5;
	}
}
.svgMap-tooltip {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	position: absolute;
	z-index: 2;
	border-radius: 2px;
	background: #fff;
	transform: translate(-50%, -100%);
	border-bottom: 1px solid #f5f8fb;
	display: none;
	pointer-events: none;
	min-width: 60px;
	.svgMap-tooltip-content-container {
		position: relative;
		padding: 10px 20px;
		.svgMap-tooltip-flag-container {
			text-align: center;
			margin: 2px 0 5px;
			.svgMap-tooltip-flag {
				display: block;
				margin: auto;
				width: auto;
				height: 32px;
				padding: 2px;
				background: rgba(0, 0, 0, 0.15);
				border-radius: 2px;
			}
		}
		.svgMap-tooltip-flag-container.svgMap-tooltip-flag-container-emoji {
			font-size: 50px;
			line-height: 0;
			padding: 25px 0 15px;
		}
	}
	.svgMap-tooltip-title {
		white-space: nowrap;
		font-size: 18px;
		line-height: 28px;
		padding: 0 0 8px;
		text-align: center;
	}
	.svgMap-tooltip-content {
		white-space: nowrap;
		text-align: center;
		font-size: 14px;
		color: #777;
		margin: -5px 0 0;
		table {
			padding: 0;
			border-spacing: 0px;
			margin: auto;
			td {
				padding: 2px 0;
				text-align: left;
				span {
					color: #111;
				}
				&:first-child {
					padding-right: 10px;
					text-align: right;
				}
				sup {
					vertical-align: baseline;
					position: relative;
					top: -5px;
				}
			}
		}
		.svgMap-tooltip-no-data {
			padding: 2px 0;
			color: #777;
			font-style: italic;
		}
	}
	.svgMap-tooltip-pointer {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;
		height: 10px;
		width: 30px;
		&:after {
			content: '';
			width: 20px;
			height: 20px;
			background: #fff;
			border: 1px solid #f5f8fb;
			position: absolute;
			bottom: 6px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
		}
	}
}
.svgMap-tooltip.svgMap-tooltip-flipped {
	transform: translate(-50%, 0);
	border-bottom: 0;
	border-top: 1px solid #f5f8fb;
	.svgMap-tooltip-pointer {
		bottom: auto;
		top: -10px;
		transform: translateX(-50%) scaleY(-1);
	}
}
.svgMap-tooltip.svgMap-active {
	display: block;
}
.modal.static-example {
	position: relative;
	display: block;
}
.modal-fluid {
	.modal-dialog {
		margin-top: 0;
		margin-bottom: 0;
	}
	.modal-content {
		border-radius: 0;
	}
}
.modal-primary {
	.modal-title {
		color: color-yiq(#262B40);
	}
	.modal-header {
		border-color: rgba(38, 43, 64, 0.075);
	}
	.modal-footer {
		border-color: rgba(38, 43, 64, 0.075);
	}
	.modal-content {
		background-color: #262B40;
		color: color-yiq(#262B40);
		.heading {
			color: color-yiq(#262B40);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-secondary {
	.modal-title {
		color: color-yiq(#F8BD7A);
	}
	.modal-header {
		border-color: rgba(248, 189, 122, 0.075);
	}
	.modal-footer {
		border-color: rgba(248, 189, 122, 0.075);
	}
	.modal-content {
		background-color: #F8BD7A;
		color: color-yiq(#F8BD7A);
		.heading {
			color: color-yiq(#F8BD7A);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-tertiary {
	.modal-title {
		color: color-yiq(#1B998B);
	}
	.modal-header {
		border-color: rgba(27, 153, 139, 0.075);
	}
	.modal-footer {
		border-color: rgba(27, 153, 139, 0.075);
	}
	.modal-content {
		background-color: #1B998B;
		color: color-yiq(#1B998B);
		.heading {
			color: color-yiq(#1B998B);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-quaternary {
	.modal-title {
		color: color-yiq(#0948B3);
	}
	.modal-header {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-footer {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-content {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		.heading {
			color: color-yiq(#0948B3);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-primary-app {
	.modal-title {
		color: color-yiq(#EBF4F6);
	}
	.modal-header {
		border-color: rgba(235, 244, 246, 0.075);
	}
	.modal-footer {
		border-color: rgba(235, 244, 246, 0.075);
	}
	.modal-content {
		background-color: #EBF4F6;
		color: color-yiq(#EBF4F6);
		.heading {
			color: color-yiq(#EBF4F6);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-secondary-app {
	.modal-title {
		color: color-yiq(#424AA0);
	}
	.modal-header {
		border-color: rgba(66, 74, 160, 0.075);
	}
	.modal-footer {
		border-color: rgba(66, 74, 160, 0.075);
	}
	.modal-content {
		background-color: #424AA0;
		color: color-yiq(#424AA0);
		.heading {
			color: color-yiq(#424AA0);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-light {
	.modal-title {
		color: color-yiq(#eaedf2);
	}
	.modal-header {
		border-color: rgba(234, 237, 242, 0.075);
	}
	.modal-footer {
		border-color: rgba(234, 237, 242, 0.075);
	}
	.modal-content {
		background-color: #eaedf2;
		color: color-yiq(#eaedf2);
		.heading {
			color: color-yiq(#eaedf2);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-lighten {
	.modal-title {
		color: color-yiq(#F0F3F6);
	}
	.modal-header {
		border-color: rgba(240, 243, 246, 0.075);
	}
	.modal-footer {
		border-color: rgba(240, 243, 246, 0.075);
	}
	.modal-content {
		background-color: #F0F3F6;
		color: color-yiq(#F0F3F6);
		.heading {
			color: color-yiq(#F0F3F6);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-success {
	.modal-title {
		color: color-yiq(#05A677);
	}
	.modal-header {
		border-color: rgba(5, 166, 119, 0.075);
	}
	.modal-footer {
		border-color: rgba(5, 166, 119, 0.075);
	}
	.modal-content {
		background-color: #05A677;
		color: color-yiq(#05A677);
		.heading {
			color: color-yiq(#05A677);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-info {
	.modal-title {
		color: color-yiq(#0948B3);
	}
	.modal-header {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-footer {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-content {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		.heading {
			color: color-yiq(#0948B3);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-warning {
	.modal-title {
		color: color-yiq(#f5b759);
	}
	.modal-header {
		border-color: rgba(245, 183, 89, 0.075);
	}
	.modal-footer {
		border-color: rgba(245, 183, 89, 0.075);
	}
	.modal-content {
		background-color: #f5b759;
		color: color-yiq(#f5b759);
		.heading {
			color: color-yiq(#f5b759);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-danger {
	.modal-title {
		color: color-yiq(#FA5252);
	}
	.modal-header {
		border-color: rgba(250, 82, 82, 0.075);
	}
	.modal-footer {
		border-color: rgba(250, 82, 82, 0.075);
	}
	.modal-content {
		background-color: #FA5252;
		color: color-yiq(#FA5252);
		.heading {
			color: color-yiq(#FA5252);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-white {
	.modal-title {
		color: color-yiq(#ffffff);
	}
	.modal-header {
		border-color: rgba(255, 255, 255, 0.075);
	}
	.modal-footer {
		border-color: rgba(255, 255, 255, 0.075);
	}
	.modal-content {
		background-color: #ffffff;
		color: color-yiq(#ffffff);
		.heading {
			color: color-yiq(#ffffff);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray {
	.modal-title {
		color: color-yiq(#4A5073);
	}
	.modal-header {
		border-color: rgba(74, 80, 115, 0.075);
	}
	.modal-footer {
		border-color: rgba(74, 80, 115, 0.075);
	}
	.modal-content {
		background-color: #4A5073;
		color: color-yiq(#4A5073);
		.heading {
			color: color-yiq(#4A5073);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-neutral {
	.modal-title {
		color: color-yiq(#ffffff);
	}
	.modal-header {
		border-color: rgba(255, 255, 255, 0.075);
	}
	.modal-footer {
		border-color: rgba(255, 255, 255, 0.075);
	}
	.modal-content {
		background-color: #ffffff;
		color: color-yiq(#ffffff);
		.heading {
			color: color-yiq(#ffffff);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-dark {
	.modal-title {
		color: color-yiq(#262B40);
	}
	.modal-header {
		border-color: rgba(38, 43, 64, 0.075);
	}
	.modal-footer {
		border-color: rgba(38, 43, 64, 0.075);
	}
	.modal-content {
		background-color: #262B40;
		color: color-yiq(#262B40);
		.heading {
			color: color-yiq(#262B40);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-soft {
	.modal-title {
		color: color-yiq(#f5f8fb);
	}
	.modal-header {
		border-color: rgba(245, 248, 251, 0.075);
	}
	.modal-footer {
		border-color: rgba(245, 248, 251, 0.075);
	}
	.modal-content {
		background-color: #f5f8fb;
		color: color-yiq(#f5f8fb);
		.heading {
			color: color-yiq(#f5f8fb);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-black {
	.modal-title {
		color: color-yiq(#2e3650);
	}
	.modal-header {
		border-color: rgba(46, 54, 80, 0.075);
	}
	.modal-footer {
		border-color: rgba(46, 54, 80, 0.075);
	}
	.modal-content {
		background-color: #2e3650;
		color: color-yiq(#2e3650);
		.heading {
			color: color-yiq(#2e3650);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-blue {
	.modal-title {
		color: color-yiq(#0948B3);
	}
	.modal-header {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-footer {
		border-color: rgba(9, 72, 179, 0.075);
	}
	.modal-content {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		.heading {
			color: color-yiq(#0948B3);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-pink {
	.modal-title {
		color: color-yiq(#C96480);
	}
	.modal-header {
		border-color: rgba(201, 100, 128, 0.075);
	}
	.modal-footer {
		border-color: rgba(201, 100, 128, 0.075);
	}
	.modal-content {
		background-color: #C96480;
		color: color-yiq(#C96480);
		.heading {
			color: color-yiq(#C96480);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-cyan {
	.modal-title {
		color: color-yiq(#63b1bd);
	}
	.modal-header {
		border-color: rgba(99, 177, 189, 0.075);
	}
	.modal-footer {
		border-color: rgba(99, 177, 189, 0.075);
	}
	.modal-content {
		background-color: #63b1bd;
		color: color-yiq(#63b1bd);
		.heading {
			color: color-yiq(#63b1bd);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-purple {
	.modal-title {
		color: color-yiq(#8965e0);
	}
	.modal-header {
		border-color: rgba(137, 101, 224, 0.075);
	}
	.modal-footer {
		border-color: rgba(137, 101, 224, 0.075);
	}
	.modal-content {
		background-color: #8965e0;
		color: color-yiq(#8965e0);
		.heading {
			color: color-yiq(#8965e0);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-soft-indigo {
	.modal-title {
		color: color-yiq(#f5e8ff);
	}
	.modal-header {
		border-color: rgba(245, 232, 255, 0.075);
	}
	.modal-footer {
		border-color: rgba(245, 232, 255, 0.075);
	}
	.modal-content {
		background-color: #f5e8ff;
		color: color-yiq(#f5e8ff);
		.heading {
			color: color-yiq(#f5e8ff);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-soft-green {
	.modal-title {
		color: color-yiq(#2CA58D);
	}
	.modal-header {
		border-color: rgba(44, 165, 141, 0.075);
	}
	.modal-footer {
		border-color: rgba(44, 165, 141, 0.075);
	}
	.modal-content {
		background-color: #2CA58D;
		color: color-yiq(#2CA58D);
		.heading {
			color: color-yiq(#2CA58D);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-100 {
	.modal-title {
		color: color-yiq(#f3f7fa);
	}
	.modal-header {
		border-color: rgba(243, 247, 250, 0.075);
	}
	.modal-footer {
		border-color: rgba(243, 247, 250, 0.075);
	}
	.modal-content {
		background-color: #f3f7fa;
		color: color-yiq(#f3f7fa);
		.heading {
			color: color-yiq(#f3f7fa);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-200 {
	.modal-title {
		color: color-yiq(#f5f8fb);
	}
	.modal-header {
		border-color: rgba(245, 248, 251, 0.075);
	}
	.modal-footer {
		border-color: rgba(245, 248, 251, 0.075);
	}
	.modal-content {
		background-color: #f5f8fb;
		color: color-yiq(#f5f8fb);
		.heading {
			color: color-yiq(#f5f8fb);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-300 {
	.modal-title {
		color: color-yiq(#F0F3F6);
	}
	.modal-header {
		border-color: rgba(240, 243, 246, 0.075);
	}
	.modal-footer {
		border-color: rgba(240, 243, 246, 0.075);
	}
	.modal-content {
		background-color: #F0F3F6;
		color: color-yiq(#F0F3F6);
		.heading {
			color: color-yiq(#F0F3F6);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-400 {
	.modal-title {
		color: color-yiq(#eaedf2);
	}
	.modal-header {
		border-color: rgba(234, 237, 242, 0.075);
	}
	.modal-footer {
		border-color: rgba(234, 237, 242, 0.075);
	}
	.modal-content {
		background-color: #eaedf2;
		color: color-yiq(#eaedf2);
		.heading {
			color: color-yiq(#eaedf2);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-500 {
	.modal-title {
		color: color-yiq(#d1d7e0);
	}
	.modal-header {
		border-color: rgba(209, 215, 224, 0.075);
	}
	.modal-footer {
		border-color: rgba(209, 215, 224, 0.075);
	}
	.modal-content {
		background-color: #d1d7e0;
		color: color-yiq(#d1d7e0);
		.heading {
			color: color-yiq(#d1d7e0);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-600 {
	.modal-title {
		color: color-yiq(#93a5be);
	}
	.modal-header {
		border-color: rgba(147, 165, 190, 0.075);
	}
	.modal-footer {
		border-color: rgba(147, 165, 190, 0.075);
	}
	.modal-content {
		background-color: #93a5be;
		color: color-yiq(#93a5be);
		.heading {
			color: color-yiq(#93a5be);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-700 {
	.modal-title {
		color: color-yiq(#66799e);
	}
	.modal-header {
		border-color: rgba(102, 121, 158, 0.075);
	}
	.modal-footer {
		border-color: rgba(102, 121, 158, 0.075);
	}
	.modal-content {
		background-color: #66799e;
		color: color-yiq(#66799e);
		.heading {
			color: color-yiq(#66799e);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-gray-800 {
	.modal-title {
		color: color-yiq(#506690);
	}
	.modal-header {
		border-color: rgba(80, 102, 144, 0.075);
	}
	.modal-footer {
		border-color: rgba(80, 102, 144, 0.075);
	}
	.modal-content {
		background-color: #506690;
		color: color-yiq(#506690);
		.heading {
			color: color-yiq(#506690);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-facebook {
	.modal-title {
		color: color-yiq(#3b5999);
	}
	.modal-header {
		border-color: rgba(59, 89, 153, 0.075);
	}
	.modal-footer {
		border-color: rgba(59, 89, 153, 0.075);
	}
	.modal-content {
		background-color: #3b5999;
		color: color-yiq(#3b5999);
		.heading {
			color: color-yiq(#3b5999);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-twitter {
	.modal-title {
		color: color-yiq(#1da1f2);
	}
	.modal-header {
		border-color: rgba(29, 161, 242, 0.075);
	}
	.modal-footer {
		border-color: rgba(29, 161, 242, 0.075);
	}
	.modal-content {
		background-color: #1da1f2;
		color: color-yiq(#1da1f2);
		.heading {
			color: color-yiq(#1da1f2);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-google {
	.modal-title {
		color: color-yiq(#DB4337);
	}
	.modal-header {
		border-color: rgba(219, 67, 55, 0.075);
	}
	.modal-footer {
		border-color: rgba(219, 67, 55, 0.075);
	}
	.modal-content {
		background-color: #DB4337;
		color: color-yiq(#DB4337);
		.heading {
			color: color-yiq(#DB4337);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-instagram {
	.modal-title {
		color: color-yiq(#e4405f);
	}
	.modal-header {
		border-color: rgba(228, 64, 95, 0.075);
	}
	.modal-footer {
		border-color: rgba(228, 64, 95, 0.075);
	}
	.modal-content {
		background-color: #e4405f;
		color: color-yiq(#e4405f);
		.heading {
			color: color-yiq(#e4405f);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-pinterest {
	.modal-title {
		color: color-yiq(#bd081c);
	}
	.modal-header {
		border-color: rgba(189, 8, 28, 0.075);
	}
	.modal-footer {
		border-color: rgba(189, 8, 28, 0.075);
	}
	.modal-content {
		background-color: #bd081c;
		color: color-yiq(#bd081c);
		.heading {
			color: color-yiq(#bd081c);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-youtube {
	.modal-title {
		color: color-yiq(#cd201f);
	}
	.modal-header {
		border-color: rgba(205, 32, 31, 0.075);
	}
	.modal-footer {
		border-color: rgba(205, 32, 31, 0.075);
	}
	.modal-content {
		background-color: #cd201f;
		color: color-yiq(#cd201f);
		.heading {
			color: color-yiq(#cd201f);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-slack {
	.modal-title {
		color: color-yiq(#3aaf85);
	}
	.modal-header {
		border-color: rgba(58, 175, 133, 0.075);
	}
	.modal-footer {
		border-color: rgba(58, 175, 133, 0.075);
	}
	.modal-content {
		background-color: #3aaf85;
		color: color-yiq(#3aaf85);
		.heading {
			color: color-yiq(#3aaf85);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-dribbble {
	.modal-title {
		color: color-yiq(#ea4c89);
	}
	.modal-header {
		border-color: rgba(234, 76, 137, 0.075);
	}
	.modal-footer {
		border-color: rgba(234, 76, 137, 0.075);
	}
	.modal-content {
		background-color: #ea4c89;
		color: color-yiq(#ea4c89);
		.heading {
			color: color-yiq(#ea4c89);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-dropbox {
	.modal-title {
		color: color-yiq(#1E90FF);
	}
	.modal-header {
		border-color: rgba(30, 144, 255, 0.075);
	}
	.modal-footer {
		border-color: rgba(30, 144, 255, 0.075);
	}
	.modal-content {
		background-color: #1E90FF;
		color: color-yiq(#1E90FF);
		.heading {
			color: color-yiq(#1E90FF);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-twitch {
	.modal-title {
		color: color-yiq(#4B367C);
	}
	.modal-header {
		border-color: rgba(75, 54, 124, 0.075);
	}
	.modal-footer {
		border-color: rgba(75, 54, 124, 0.075);
	}
	.modal-content {
		background-color: #4B367C;
		color: color-yiq(#4B367C);
		.heading {
			color: color-yiq(#4B367C);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-paypal {
	.modal-title {
		color: color-yiq(#ecb32c);
	}
	.modal-header {
		border-color: rgba(236, 179, 44, 0.075);
	}
	.modal-footer {
		border-color: rgba(236, 179, 44, 0.075);
	}
	.modal-content {
		background-color: #ecb32c;
		color: color-yiq(#ecb32c);
		.heading {
			color: color-yiq(#ecb32c);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-behance {
	.modal-title {
		color: color-yiq(#0057ff);
	}
	.modal-header {
		border-color: rgba(0, 87, 255, 0.075);
	}
	.modal-footer {
		border-color: rgba(0, 87, 255, 0.075);
	}
	.modal-content {
		background-color: #0057ff;
		color: color-yiq(#0057ff);
		.heading {
			color: color-yiq(#0057ff);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-reddit {
	.modal-title {
		color: color-yiq(#E84422);
	}
	.modal-header {
		border-color: rgba(232, 68, 34, 0.075);
	}
	.modal-footer {
		border-color: rgba(232, 68, 34, 0.075);
	}
	.modal-content {
		background-color: #E84422;
		color: color-yiq(#E84422);
		.heading {
			color: color-yiq(#E84422);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.modal-github {
	.modal-title {
		color: color-yiq(#222222);
	}
	.modal-header {
		border-color: rgba(34, 34, 34, 0.075);
	}
	.modal-footer {
		border-color: rgba(34, 34, 34, 0.075);
	}
	.modal-content {
		background-color: #222222;
		color: color-yiq(#222222);
		.heading {
			color: color-yiq(#222222);
		}
	}
	.close {
		>span {
			&:not(.sr-only) {
				color: #ffffff;
			}
		}
	}
}
.nav-link.active {
	color: #F8BD7A;
	img {
		opacity: inherit;
		transition: all 0.2s ease;
	}
}
.nav-tabs.nav-dark {
	.nav-link.active {
		color: #ffffff;
		background-color: #12358a;
	}
}
.nav-tabs.nav-light {
	.nav-link.active {
		color: #2e3650;
		background-color: #eaedf2;
	}
	.nav-link {
		&:hover {
			color: #2e3650;
		}
	}
}
.nav-pills.rounded {
	.nav-link {
		border-radius: 30px;
	}
}
.nav-pills.rounded.vertical-tab {
	.nav-link {
		margin-bottom: .625rem;
		min-width: 100px;
	}
	.nav-item {
		&:not(:last-child) {
			padding-right: 0;
		}
	}
}
.nav-pills.bordered-pill-md {
	.nav-link {
		border: 0.125rem solid #f5f8fb;
		font-weight: 600;
	}
}
.nav-pills.vertical-tab {
	.nav-link {
		margin-bottom: .625rem;
	}
	.nav-item {
		&:not(:last-child) {
			padding-right: 0;
		}
	}
}
.nav-pill-circle {
	.nav-link {
		display: flex;
		text-align: center;
		height: 80px;
		width: 80px;
		padding: 0;
		box-shadow: none;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
	}
	.nav-link.avatar-link {
		box-shadow: none;
	}
	.nav-link-icon {
		i {
			font-size: 1.25rem;
			margin: 0;
			display: block;
		}
		svg {
			font-size: 1.25rem;
			margin: 0;
			display: block;
		}
	}
}
.nav-pill-circle.vertical-tab {
	.nav-link-icon {
		i {
			font-size: 1.5rem;
		}
		svg {
			font-size: 1.5rem;
		}
	}
	.nav-item {
		&:not(:last-child) {
			padding-right: 0;
		}
	}
}
.nav-pill-square {
	.nav-link {
		text-align: center;
		min-width: 80px;
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.nav-link-icon {
		i {
			font-size: 1.25rem;
			margin: 0;
			display: block;
			line-height: 50px;
		}
		svg {
			font-size: 1.25rem;
			margin: 0;
			display: block;
			line-height: 50px;
		}
	}
}
.nav-pill-square.vertical-tab {
	.nav-link {
		margin-bottom: .625rem;
		min-width: 100px;
	}
	.nav-item {
		&:not(:last-child) {
			padding-right: 0;
		}
	}
}
.nav-wrapper {
	padding: 1rem 0;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	+ {
		.card {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}
	}
}
.pixel-tab {
	.col-lg-2 {
		padding-right: 0;
		position: relative;
		z-index: 1;
	}
	.col-lg-10 {
		padding-left: 0;
	}
	.nav-pills {
		border-right: 0;
		.nav-link {
			padding: 30px 25px;
			margin-bottom: .625rem;
			box-shadow: none;
			border-right: 0;
			h2 {
				color: #262B40;
			}
			.h2 {
				color: #262B40;
			}
		}
		.nav-link.active {
			background: #ffffff;
			border-left: 0.25rem solid #262B40;
			border-radius: 0;
			box-shadow: none;
			h2 {
				color: #262B40;
			}
			.h2 {
				color: #262B40;
			}
		}
	}
	.tab-content {
		background-color: #ffffff;
		box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
		.pricing-box {
			padding: 50px 70px;
			z-index: 2;
			position: relative;
		}
		.list-inline {
			margin-top: 20px;
			li {
				padding: 10px 0;
				font-weight: 400;
				span {
					display: inline-block;
					vertical-align: middle;
				}
				i {
					font-size: 1.5rem;
					color: #262B40;
					vertical-align: middle;
					width: 50px;
					overflow: hidden;
					display: inline-block;
				}
			}
		}
		.bg-image {
			background-size: cover;
			position: relative;
			display: flex;
			.description-box {
				z-index: 1;
			}
		}
	}
}
.circle-pagination {
	.page-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		padding: 0;
		border-radius: 50%;
	}
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		padding: 0;
		border-radius: 50%;
	}
}
.pricing-card {
	.card-header {
		background: transparent;
		z-index: 2;
	}
	.card-body {
		z-index: 2;
	}
	.pricing-value {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		border-width: 0.375rem;
		border-style: double;
		margin: 0 auto;
		span {
			line-height: 140px;
		}
	}
	.list-group-item {
		background: transparent;
		border: none;
		padding: 0.375rem 0.125rem;
		i {
			display: inline-block;
			vertical-align: middle;
			width: 35px;
		}
	}
}
.profile-card {
	.card-image {
		.card-title {
			position: absolute;
			left: 1.25rem;
			top: 1.25rem;
		}
		.dropdown {
			position: absolute;
			right: 1rem;
			top: 1rem;
		}
	}
	.profile-cover {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		height: 175px;
	}
	.profile-image {
		width: 10rem;
		height: 10rem;
		margin: 0 auto;
		transform: translateY(-3rem);
	}
	.profile-image-small {
		width: 8rem;
		height: 8rem;
		margin: 0 auto;
		img {
			border: 0.25rem solid #ffffff;
		}
	}
	.card-link {
		font-size: 0.875rem;
	}
	.card-footer {
		background-color: #ffffff;
	}
	.social-buttons {
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			a.btn {
				padding: 0.25rem 0.375rem;
			}
		}
	}
}
.rotating-card-container {
	.card-rotate {
		background: transparent;
		box-shadow: none;
		&:after {
			display: none;
		}
	}
	.card {
		transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
		transform-style: preserve-3d;
		position: relative;
		.back {
			display: flex;
			-moz-flex-direction: column;
			flex-direction: column;
			-ms-align-content: center;
			align-content: center;
			-moz-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			-ms-flex-pack: center;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			position: absolute;
			border-radius: 0.5rem;
			top: 0;
			left: 0;
			transform: rotateY(180deg);
			z-index: 5;
			text-align: center;
			width: 100%;
			height: 100%;
			.card-body {
				justify-content: center;
				align-content: center;
				display: flex;
				-moz-flex-direction: column;
				flex-direction: column;
				padding-left: 15px;
				padding-right: 15px;
				.card-subtitle {
					color: #262B40;
				}
				.social-buttons {
					margin-bottom: 0;
					padding: 0;
					list-style: none;
					li {
						display: inline-block;
						a.btn {
							padding: 0.25rem 0.375rem;
						}
					}
				}
			}
			.card-footer {
				.btn {
					margin: 0;
				}
			}
		}
		.front {
			display: flex;
			-moz-flex-direction: column;
			flex-direction: column;
			-ms-align-content: center;
			align-content: center;
			-moz-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			-ms-flex-pack: center;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			position: absolute;
			border-radius: 0.5rem;
			top: 0;
			left: 0;
			z-index: 2;
			position: relative;
			.card-body {
				justify-content: center;
				align-content: center;
				display: flex;
				-moz-flex-direction: column;
				flex-direction: column;
				.card-subtitle {
					color: #262B40;
				}
				.social-buttons {
					margin-bottom: 0;
					padding: 0;
					list-style: none;
					li {
						display: inline-block;
						a.btn {
							padding: 0.25rem 0.375rem;
						}
					}
				}
			}
			.profile-image {
				width: 9rem;
				height: 9rem;
				margin: 0 auto;
				img {
					border-width: 0.125rem;
					border-style: solid;
					padding: .25rem;
				}
			}
		}
		.back.back-background {
			&:after {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				display: block;
				left: 0;
				top: 0;
				content: "";
				background-color: rgba(0, 0, 0, 0.56);
				border-radius: 0.5rem;
			}
			.card-body {
				position: relative;
				z-index: 2;
			}
		}
	}
	&:not(.manual-flip) {
		&:hover {
			.card {
				transform: rotateY(180deg);
			}
		}
	}
}
.rotating-card-container.hover.manual-flip {
	.card {
		transform: rotateY(180deg);
	}
}
.card-profile {
	.rotating-card-container {
		.front {
			text-align: left;
		}
	}
}
.back-background {
	.card-body {
		min-height: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	background-position: center center;
	background-size: cover;
}
.front-background {
	background-position: center center;
	background-size: cover;
}
.profile-card-image {
	text-align: center;
	&:hover {
		.card {
			&:after {
				background: #ffffff;
			}
		}
		.card-body {
			opacity: 1 !important;
			transform: translate(0, -50%);
		}
		.social-buttons {
			opacity: 1 !important;
			transform: translate(0, -50%);
		}
	}
	.card {
		position: relative;
		overflow: hidden;
		&:after {
			display: block;
			background: transparent;
			position: absolute;
			content: " ";
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
		.card-body {
			transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
			opacity: 0;
			z-index: 2;
			bottom: 0;
			position: absolute;
			h6 {
				color: #F8BD7A;
			}
			.h6 {
				color: #F8BD7A;
			}
		}
		.social-buttons {
			transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
			opacity: 0;
			z-index: 2;
			bottom: 0;
			margin-bottom: 0;
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				a.btn {
					padding: 0.25rem 0.375rem;
				}
			}
		}
	}
}
.profile-card-alternative {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 1.5rem;
	transition: all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
	img {
		width: 100%;
		display: block;
	}
	&:hover {
		.team-mask {
			opacity: .85;
			transform: scale(1);
		}
		h5 {
			opacity: 1;
			transition: all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
		.h5 {
			opacity: 1;
			transition: all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
		p {
			opacity: 1;
			transition: all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
		.social-buttons {
			opacity: 1;
			transition: all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
	}
	h4 {
		width: 100%;
		transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
	}
	.h4 {
		width: 100%;
		transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
	}
	.card-subtitle {
		position: absolute;
		color: #4A5073;
		font-size: 0.875rem;
		margin-top: -1rem;
		left: 0;
		width: 100%;
		transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
	}
	.social-buttons {
		margin-top: 2.75rem;
		padding: 0;
		list-style: none;
		position: absolute;
		z-index: 1;
		width: 100%;
		transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
		opacity: 0;
		li {
			display: inline-block;
			position: relative;
			a.btn {
				padding: 0.25rem 0.375rem;
			}
		}
	}
}
.profile-card-alternative.over-hide {
	overflow: hidden;
}
.profile-card-alternative.organic-radius {
	.team-mask {
		border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
	}
}
.profile-card-alternative.rounded-circle {
	.team-mask {
		border-radius: 50%;
	}
}
.team-mask {
	position: absolute;
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	left: .25rem;
	top: .25rem;
	z-index: 1;
	display: block;
	opacity: 0;
	transition: all 0.2s linear;
}
.popover-primary {
	background-color: #262B40;
	.popover-header {
		background-color: #262B40;
		color: color-yiq(#262B40);
		border-color: rgba(38, 43, 64, 0.2);
	}
	.popover-body {
		color: color-yiq(#262B40);
	}
}
.popover-primary.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #262B40;
		}
	}
}
.popover-primary.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #262B40;
		}
	}
}
.popover-secondary {
	background-color: #F8BD7A;
	.popover-header {
		background-color: #F8BD7A;
		color: color-yiq(#F8BD7A);
		border-color: rgba(248, 189, 122, 0.2);
	}
	.popover-body {
		color: color-yiq(#F8BD7A);
	}
}
.popover-secondary.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #F8BD7A;
		}
	}
}
.popover-secondary.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #F8BD7A;
		}
	}
}
.popover-tertiary {
	background-color: #1B998B;
	.popover-header {
		background-color: #1B998B;
		color: color-yiq(#1B998B);
		border-color: rgba(27, 153, 139, 0.2);
	}
	.popover-body {
		color: color-yiq(#1B998B);
	}
}
.popover-tertiary.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #1B998B;
		}
	}
}
.popover-tertiary.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #1B998B;
		}
	}
}
.popover-quaternary {
	background-color: #0948B3;
	.popover-header {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		border-color: rgba(9, 72, 179, 0.2);
	}
	.popover-body {
		color: color-yiq(#0948B3);
	}
}
.popover-quaternary.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-quaternary.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-primary-app {
	background-color: #EBF4F6;
	.popover-header {
		background-color: #EBF4F6;
		color: color-yiq(#EBF4F6);
		border-color: rgba(235, 244, 246, 0.2);
	}
	.popover-body {
		color: color-yiq(#EBF4F6);
	}
}
.popover-primary-app.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #EBF4F6;
		}
	}
}
.popover-primary-app.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #EBF4F6;
		}
	}
}
.popover-secondary-app {
	background-color: #424AA0;
	.popover-header {
		background-color: #424AA0;
		color: color-yiq(#424AA0);
		border-color: rgba(66, 74, 160, 0.2);
	}
	.popover-body {
		color: color-yiq(#424AA0);
	}
}
.popover-secondary-app.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #424AA0;
		}
	}
}
.popover-secondary-app.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #424AA0;
		}
	}
}
.popover-light {
	background-color: #eaedf2;
	.popover-header {
		background-color: #eaedf2;
		color: color-yiq(#eaedf2);
		border-color: rgba(234, 237, 242, 0.2);
	}
	.popover-body {
		color: color-yiq(#eaedf2);
	}
}
.popover-light.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #eaedf2;
		}
	}
}
.popover-light.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #eaedf2;
		}
	}
}
.popover-lighten {
	background-color: #F0F3F6;
	.popover-header {
		background-color: #F0F3F6;
		color: color-yiq(#F0F3F6);
		border-color: rgba(240, 243, 246, 0.2);
	}
	.popover-body {
		color: color-yiq(#F0F3F6);
	}
}
.popover-lighten.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #F0F3F6;
		}
	}
}
.popover-lighten.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #F0F3F6;
		}
	}
}
.popover-success {
	background-color: #05A677;
	.popover-header {
		background-color: #05A677;
		color: color-yiq(#05A677);
		border-color: rgba(5, 166, 119, 0.2);
	}
	.popover-body {
		color: color-yiq(#05A677);
	}
}
.popover-success.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #05A677;
		}
	}
}
.popover-success.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #05A677;
		}
	}
}
.popover-success.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #05A677;
		}
	}
}
.popover-success.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #05A677;
		}
	}
}
.popover-success.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #05A677;
		}
	}
}
.popover-success.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #05A677;
		}
	}
}
.popover-success.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #05A677;
		}
	}
}
.popover-success.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #05A677;
		}
	}
}
.popover-info {
	background-color: #0948B3;
	.popover-header {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		border-color: rgba(9, 72, 179, 0.2);
	}
	.popover-body {
		color: color-yiq(#0948B3);
	}
}
.popover-info.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-info.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-warning {
	background-color: #f5b759;
	.popover-header {
		background-color: #f5b759;
		color: color-yiq(#f5b759);
		border-color: rgba(245, 183, 89, 0.2);
	}
	.popover-body {
		color: color-yiq(#f5b759);
	}
}
.popover-warning.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #f5b759;
		}
	}
}
.popover-warning.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #f5b759;
		}
	}
}
.popover-danger {
	background-color: #FA5252;
	.popover-header {
		background-color: #FA5252;
		color: color-yiq(#FA5252);
		border-color: rgba(250, 82, 82, 0.2);
	}
	.popover-body {
		color: color-yiq(#FA5252);
	}
}
.popover-danger.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #FA5252;
		}
	}
}
.popover-danger.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #FA5252;
		}
	}
}
.popover-white {
	background-color: #ffffff;
	.popover-header {
		background-color: #ffffff;
		color: color-yiq(#ffffff);
		border-color: rgba(255, 255, 255, 0.2);
	}
	.popover-body {
		color: color-yiq(#ffffff);
	}
}
.popover-white.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #ffffff;
		}
	}
}
.popover-white.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #ffffff;
		}
	}
}
.popover-gray {
	background-color: #4A5073;
	.popover-header {
		background-color: #4A5073;
		color: color-yiq(#4A5073);
		border-color: rgba(74, 80, 115, 0.2);
	}
	.popover-body {
		color: color-yiq(#4A5073);
	}
}
.popover-gray.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #4A5073;
		}
	}
}
.popover-gray.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #4A5073;
		}
	}
}
.popover-neutral {
	background-color: #ffffff;
	.popover-header {
		background-color: #ffffff;
		color: color-yiq(#ffffff);
		border-color: rgba(255, 255, 255, 0.2);
	}
	.popover-body {
		color: color-yiq(#ffffff);
	}
}
.popover-neutral.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #ffffff;
		}
	}
}
.popover-neutral.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #ffffff;
		}
	}
}
.popover-dark {
	background-color: #262B40;
	.popover-header {
		background-color: #262B40;
		color: color-yiq(#262B40);
		border-color: rgba(38, 43, 64, 0.2);
	}
	.popover-body {
		color: color-yiq(#262B40);
	}
}
.popover-dark.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #262B40;
		}
	}
}
.popover-dark.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #262B40;
		}
	}
}
.popover-soft {
	background-color: #f5f8fb;
	.popover-header {
		background-color: #f5f8fb;
		color: color-yiq(#f5f8fb);
		border-color: rgba(245, 248, 251, 0.2);
	}
	.popover-body {
		color: color-yiq(#f5f8fb);
	}
}
.popover-soft.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #f5f8fb;
		}
	}
}
.popover-soft.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #f5f8fb;
		}
	}
}
.popover-black {
	background-color: #2e3650;
	.popover-header {
		background-color: #2e3650;
		color: color-yiq(#2e3650);
		border-color: rgba(46, 54, 80, 0.2);
	}
	.popover-body {
		color: color-yiq(#2e3650);
	}
}
.popover-black.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #2e3650;
		}
	}
}
.popover-black.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #2e3650;
		}
	}
}
.popover-blue {
	background-color: #0948B3;
	.popover-header {
		background-color: #0948B3;
		color: color-yiq(#0948B3);
		border-color: rgba(9, 72, 179, 0.2);
	}
	.popover-body {
		color: color-yiq(#0948B3);
	}
}
.popover-blue.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-blue.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #0948B3;
		}
	}
}
.popover-pink {
	background-color: #C96480;
	.popover-header {
		background-color: #C96480;
		color: color-yiq(#C96480);
		border-color: rgba(201, 100, 128, 0.2);
	}
	.popover-body {
		color: color-yiq(#C96480);
	}
}
.popover-pink.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #C96480;
		}
	}
}
.popover-pink.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #C96480;
		}
	}
}
.popover-cyan {
	background-color: #63b1bd;
	.popover-header {
		background-color: #63b1bd;
		color: color-yiq(#63b1bd);
		border-color: rgba(99, 177, 189, 0.2);
	}
	.popover-body {
		color: color-yiq(#63b1bd);
	}
}
.popover-cyan.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #63b1bd;
		}
	}
}
.popover-cyan.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #63b1bd;
		}
	}
}
.popover-purple {
	background-color: #8965e0;
	.popover-header {
		background-color: #8965e0;
		color: color-yiq(#8965e0);
		border-color: rgba(137, 101, 224, 0.2);
	}
	.popover-body {
		color: color-yiq(#8965e0);
	}
}
.popover-purple.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #8965e0;
		}
	}
}
.popover-purple.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #8965e0;
		}
	}
}
.popover-soft-indigo {
	background-color: #f5e8ff;
	.popover-header {
		background-color: #f5e8ff;
		color: color-yiq(#f5e8ff);
		border-color: rgba(245, 232, 255, 0.2);
	}
	.popover-body {
		color: color-yiq(#f5e8ff);
	}
}
.popover-soft-indigo.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #f5e8ff;
		}
	}
}
.popover-soft-indigo.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #f5e8ff;
		}
	}
}
.popover-soft-green {
	background-color: #2CA58D;
	.popover-header {
		background-color: #2CA58D;
		color: color-yiq(#2CA58D);
		border-color: rgba(44, 165, 141, 0.2);
	}
	.popover-body {
		color: color-yiq(#2CA58D);
	}
}
.popover-soft-green.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #2CA58D;
		}
	}
}
.popover-soft-green.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #2CA58D;
		}
	}
}
.popover-gray-100 {
	background-color: #f3f7fa;
	.popover-header {
		background-color: #f3f7fa;
		color: color-yiq(#f3f7fa);
		border-color: rgba(243, 247, 250, 0.2);
	}
	.popover-body {
		color: color-yiq(#f3f7fa);
	}
}
.popover-gray-100.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #f3f7fa;
		}
	}
}
.popover-gray-100.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #f3f7fa;
		}
	}
}
.popover-gray-200 {
	background-color: #f5f8fb;
	.popover-header {
		background-color: #f5f8fb;
		color: color-yiq(#f5f8fb);
		border-color: rgba(245, 248, 251, 0.2);
	}
	.popover-body {
		color: color-yiq(#f5f8fb);
	}
}
.popover-gray-200.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #f5f8fb;
		}
	}
}
.popover-gray-200.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #f5f8fb;
		}
	}
}
.popover-gray-300 {
	background-color: #F0F3F6;
	.popover-header {
		background-color: #F0F3F6;
		color: color-yiq(#F0F3F6);
		border-color: rgba(240, 243, 246, 0.2);
	}
	.popover-body {
		color: color-yiq(#F0F3F6);
	}
}
.popover-gray-300.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #F0F3F6;
		}
	}
}
.popover-gray-300.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #F0F3F6;
		}
	}
}
.popover-gray-400 {
	background-color: #eaedf2;
	.popover-header {
		background-color: #eaedf2;
		color: color-yiq(#eaedf2);
		border-color: rgba(234, 237, 242, 0.2);
	}
	.popover-body {
		color: color-yiq(#eaedf2);
	}
}
.popover-gray-400.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #eaedf2;
		}
	}
}
.popover-gray-400.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #eaedf2;
		}
	}
}
.popover-gray-500 {
	background-color: #d1d7e0;
	.popover-header {
		background-color: #d1d7e0;
		color: color-yiq(#d1d7e0);
		border-color: rgba(209, 215, 224, 0.2);
	}
	.popover-body {
		color: color-yiq(#d1d7e0);
	}
}
.popover-gray-500.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #d1d7e0;
		}
	}
}
.popover-gray-500.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #d1d7e0;
		}
	}
}
.popover-gray-600 {
	background-color: #93a5be;
	.popover-header {
		background-color: #93a5be;
		color: color-yiq(#93a5be);
		border-color: rgba(147, 165, 190, 0.2);
	}
	.popover-body {
		color: color-yiq(#93a5be);
	}
}
.popover-gray-600.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #93a5be;
		}
	}
}
.popover-gray-600.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #93a5be;
		}
	}
}
.popover-gray-700 {
	background-color: #66799e;
	.popover-header {
		background-color: #66799e;
		color: color-yiq(#66799e);
		border-color: rgba(102, 121, 158, 0.2);
	}
	.popover-body {
		color: color-yiq(#66799e);
	}
}
.popover-gray-700.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #66799e;
		}
	}
}
.popover-gray-700.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #66799e;
		}
	}
}
.popover-gray-800 {
	background-color: #506690;
	.popover-header {
		background-color: #506690;
		color: color-yiq(#506690);
		border-color: rgba(80, 102, 144, 0.2);
	}
	.popover-body {
		color: color-yiq(#506690);
	}
}
.popover-gray-800.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #506690;
		}
	}
}
.popover-gray-800.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #506690;
		}
	}
}
.popover-facebook {
	background-color: #3b5999;
	.popover-header {
		background-color: #3b5999;
		color: color-yiq(#3b5999);
		border-color: rgba(59, 89, 153, 0.2);
	}
	.popover-body {
		color: color-yiq(#3b5999);
	}
}
.popover-facebook.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #3b5999;
		}
	}
}
.popover-facebook.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #3b5999;
		}
	}
}
.popover-twitter {
	background-color: #1da1f2;
	.popover-header {
		background-color: #1da1f2;
		color: color-yiq(#1da1f2);
		border-color: rgba(29, 161, 242, 0.2);
	}
	.popover-body {
		color: color-yiq(#1da1f2);
	}
}
.popover-twitter.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #1da1f2;
		}
	}
}
.popover-twitter.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #1da1f2;
		}
	}
}
.popover-google {
	background-color: #DB4337;
	.popover-header {
		background-color: #DB4337;
		color: color-yiq(#DB4337);
		border-color: rgba(219, 67, 55, 0.2);
	}
	.popover-body {
		color: color-yiq(#DB4337);
	}
}
.popover-google.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #DB4337;
		}
	}
}
.popover-google.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #DB4337;
		}
	}
}
.popover-instagram {
	background-color: #e4405f;
	.popover-header {
		background-color: #e4405f;
		color: color-yiq(#e4405f);
		border-color: rgba(228, 64, 95, 0.2);
	}
	.popover-body {
		color: color-yiq(#e4405f);
	}
}
.popover-instagram.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #e4405f;
		}
	}
}
.popover-instagram.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #e4405f;
		}
	}
}
.popover-pinterest {
	background-color: #bd081c;
	.popover-header {
		background-color: #bd081c;
		color: color-yiq(#bd081c);
		border-color: rgba(189, 8, 28, 0.2);
	}
	.popover-body {
		color: color-yiq(#bd081c);
	}
}
.popover-pinterest.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #bd081c;
		}
	}
}
.popover-pinterest.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #bd081c;
		}
	}
}
.popover-youtube {
	background-color: #cd201f;
	.popover-header {
		background-color: #cd201f;
		color: color-yiq(#cd201f);
		border-color: rgba(205, 32, 31, 0.2);
	}
	.popover-body {
		color: color-yiq(#cd201f);
	}
}
.popover-youtube.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #cd201f;
		}
	}
}
.popover-youtube.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #cd201f;
		}
	}
}
.popover-slack {
	background-color: #3aaf85;
	.popover-header {
		background-color: #3aaf85;
		color: color-yiq(#3aaf85);
		border-color: rgba(58, 175, 133, 0.2);
	}
	.popover-body {
		color: color-yiq(#3aaf85);
	}
}
.popover-slack.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #3aaf85;
		}
	}
}
.popover-slack.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #3aaf85;
		}
	}
}
.popover-dribbble {
	background-color: #ea4c89;
	.popover-header {
		background-color: #ea4c89;
		color: color-yiq(#ea4c89);
		border-color: rgba(234, 76, 137, 0.2);
	}
	.popover-body {
		color: color-yiq(#ea4c89);
	}
}
.popover-dribbble.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #ea4c89;
		}
	}
}
.popover-dribbble.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #ea4c89;
		}
	}
}
.popover-dropbox {
	background-color: #1E90FF;
	.popover-header {
		background-color: #1E90FF;
		color: color-yiq(#1E90FF);
		border-color: rgba(30, 144, 255, 0.2);
	}
	.popover-body {
		color: color-yiq(#1E90FF);
	}
}
.popover-dropbox.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #1E90FF;
		}
	}
}
.popover-dropbox.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #1E90FF;
		}
	}
}
.popover-twitch {
	background-color: #4B367C;
	.popover-header {
		background-color: #4B367C;
		color: color-yiq(#4B367C);
		border-color: rgba(75, 54, 124, 0.2);
	}
	.popover-body {
		color: color-yiq(#4B367C);
	}
}
.popover-twitch.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #4B367C;
		}
	}
}
.popover-twitch.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #4B367C;
		}
	}
}
.popover-paypal {
	background-color: #ecb32c;
	.popover-header {
		background-color: #ecb32c;
		color: color-yiq(#ecb32c);
		border-color: rgba(236, 179, 44, 0.2);
	}
	.popover-body {
		color: color-yiq(#ecb32c);
	}
}
.popover-paypal.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #ecb32c;
		}
	}
}
.popover-paypal.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #ecb32c;
		}
	}
}
.popover-behance {
	background-color: #0057ff;
	.popover-header {
		background-color: #0057ff;
		color: color-yiq(#0057ff);
		border-color: rgba(0, 87, 255, 0.2);
	}
	.popover-body {
		color: color-yiq(#0057ff);
	}
}
.popover-behance.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #0057ff;
		}
	}
}
.popover-behance.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #0057ff;
		}
	}
}
.popover-reddit {
	background-color: #E84422;
	.popover-header {
		background-color: #E84422;
		color: color-yiq(#E84422);
		border-color: rgba(232, 68, 34, 0.2);
	}
	.popover-body {
		color: color-yiq(#E84422);
	}
}
.popover-reddit.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #E84422;
		}
	}
}
.popover-reddit.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #E84422;
		}
	}
}
.popover-github {
	background-color: #222222;
	.popover-header {
		background-color: #222222;
		color: color-yiq(#222222);
		border-color: rgba(34, 34, 34, 0.2);
	}
	.popover-body {
		color: color-yiq(#222222);
	}
}
.popover-github.bs-popover-top {
	.arrow {
		&::after {
			border-top-color: #222222;
		}
	}
}
.popover-github.bs-popover-auto[x-placement^="top"] {
	.arrow {
		&::after {
			border-top-color: #222222;
		}
	}
}
.popover-github.bs-popover-right {
	.arrow {
		&::after {
			border-right-color: #222222;
		}
	}
}
.popover-github.bs-popover-auto[x-placement^="right"] {
	.arrow {
		&::after {
			border-right-color: #222222;
		}
	}
}
.popover-github.bs-popover-bottom {
	.arrow {
		&::after {
			border-bottom-color: #222222;
		}
	}
}
.popover-github.bs-popover-auto[x-placement^="bottom"] {
	.arrow {
		&::after {
			border-bottom-color: #222222;
		}
	}
}
.popover-github.bs-popover-left {
	.arrow {
		&::after {
			border-left-color: #222222;
		}
	}
}
.popover-github.bs-popover-auto[x-placement^="left"] {
	.arrow {
		&::after {
			border-left-color: #222222;
		}
	}
}
.progress-wrapper {
	position: relative;
}
.progress-xl {
	height: 0.8rem;
}
.progress-lg {
	height: 0.6rem;
}
.progress-sm {
	height: 0.2rem;
}
.progress-info {
	display: flex;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: .5rem;
	.progress-label {
		span {
			display: inline-block;
			color: #262B40;
			font-size: 0.875rem;
			font-weight: 600;
		}
	}
	.progress-percentage {
		text-align: right;
		span {
			display: inline-block;
			color: #d1d7e0;
			font-size: 0.75rem;
			font-weight: 600;
		}
	}
	.bg-primary {
		&::after {
			border-top-color: #262B40;
		}
	}
	.bg-secondary {
		&::after {
			border-top-color: #F8BD7A;
		}
	}
	.bg-tertiary {
		&::after {
			border-top-color: #1B998B;
		}
	}
	.bg-quaternary {
		&::after {
			border-top-color: #0948B3;
		}
	}
	.bg-primary-app {
		&::after {
			border-top-color: #EBF4F6;
		}
	}
	.bg-secondary-app {
		&::after {
			border-top-color: #424AA0;
		}
	}
	.bg-light {
		&::after {
			border-top-color: #eaedf2;
		}
	}
	.bg-lighten {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
	.bg-success {
		&::after {
			border-top-color: #05A677;
		}
	}
	.bg-info {
		&::after {
			border-top-color: #0948B3;
		}
	}
	.bg-warning {
		&::after {
			border-top-color: #f5b759;
		}
	}
	.bg-danger {
		&::after {
			border-top-color: #FA5252;
		}
	}
	.bg-white {
		&::after {
			border-top-color: #ffffff;
		}
	}
	.bg-gray {
		&::after {
			border-top-color: #4A5073;
		}
	}
	.bg-neutral {
		&::after {
			border-top-color: #ffffff;
		}
	}
	.bg-dark {
		&::after {
			border-top-color: #262B40;
		}
	}
	.bg-soft {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
	.bg-black {
		&::after {
			border-top-color: #2e3650;
		}
	}
	.bg-blue {
		&::after {
			border-top-color: #0948B3;
		}
	}
	.bg-pink {
		&::after {
			border-top-color: #C96480;
		}
	}
	.bg-cyan {
		&::after {
			border-top-color: #63b1bd;
		}
	}
	.bg-purple {
		&::after {
			border-top-color: #8965e0;
		}
	}
	.bg-soft-indigo {
		&::after {
			border-top-color: #f5e8ff;
		}
	}
	.bg-soft-green {
		&::after {
			border-top-color: #2CA58D;
		}
	}
	.bg-gray-100 {
		&::after {
			border-top-color: #f3f7fa;
		}
	}
	.bg-gray-200 {
		&::after {
			border-top-color: #f5f8fb;
		}
	}
	.bg-gray-300 {
		&::after {
			border-top-color: #F0F3F6;
		}
	}
	.bg-gray-400 {
		&::after {
			border-top-color: #eaedf2;
		}
	}
	.bg-gray-500 {
		&::after {
			border-top-color: #d1d7e0;
		}
	}
	.bg-gray-600 {
		&::after {
			border-top-color: #93a5be;
		}
	}
	.bg-gray-700 {
		&::after {
			border-top-color: #66799e;
		}
	}
	.bg-gray-800 {
		&::after {
			border-top-color: #506690;
		}
	}
	.bg-facebook {
		&::after {
			border-top-color: #3b5999;
		}
	}
	.bg-twitter {
		&::after {
			border-top-color: #1da1f2;
		}
	}
	.bg-google {
		&::after {
			border-top-color: #DB4337;
		}
	}
	.bg-instagram {
		&::after {
			border-top-color: #e4405f;
		}
	}
	.bg-pinterest {
		&::after {
			border-top-color: #bd081c;
		}
	}
	.bg-youtube {
		&::after {
			border-top-color: #cd201f;
		}
	}
	.bg-slack {
		&::after {
			border-top-color: #3aaf85;
		}
	}
	.bg-dribbble {
		&::after {
			border-top-color: #ea4c89;
		}
	}
	.bg-dropbox {
		&::after {
			border-top-color: #1E90FF;
		}
	}
	.bg-twitch {
		&::after {
			border-top-color: #4B367C;
		}
	}
	.bg-paypal {
		&::after {
			border-top-color: #ecb32c;
		}
	}
	.bg-behance {
		&::after {
			border-top-color: #0057ff;
		}
	}
	.bg-reddit {
		&::after {
			border-top-color: #E84422;
		}
	}
	.bg-github {
		&::after {
			border-top-color: #222222;
		}
	}
}
.info-xl {
	.progress-label {
		span {
			font-size: 0.875rem;
		}
	}
	.progress-percentage {
		span {
			font-size: 0.875rem;
		}
		text-align: right;
	}
}
.progress-tooltip {
	background: #262B40;
	color: #ffffff;
	font-weight: 600;
	padding: .25rem .375rem;
	line-height: 1;
	font-size: 0.75rem;
	position: relative;
	border-radius: 0.1rem;
	&:after {
		content: " ";
		position: absolute;
		top: 100%;
		left: 10px;
		border: solid transparent;
		height: 0;
		width: 0;
		pointer-events: none;
		border-top-color: #4A5073;
		border-width: 6px;
	}
}
.choices__inner {
	background-color: #ffffff;
	border-color: #d1d7e0;
	border-radius: 0.5rem;
}
.choices__list.choices__list--dropdown {
	border-color: #d1d7e0;
	margin-top: .5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}
.choices.is-focused {
	.choices__inner {
		background-color: #ffffff;
		border-color: #d1d7e0;
		border-radius: 0.5rem;
	}
}
.choices {
	.is-open {
		.choices__inner {
			background-color: #ffffff;
			border-color: #d1d7e0;
			border-radius: 0.5rem;
		}
	}
}
.choices__list--multiple {
	.choices__item.is-highlighted {
		background-color: #eaedf2;
		border-color: #d1d7e0;
	}
	.choices__item {
		background-color: #262B40;
		border: 0;
		border-radius: 0.5rem;
	}
}
.is-disabled {
	.choices__list--multiple {
		.choices__item {
			background-color: #eaedf2;
			border-color: #d1d7e0;
		}
	}
}
.choices__list--dropdown {
	.choices__item--selectable.is-highlighted {
		background-color: #eaedf2;
	}
}
.choices__input {
	background-color: transparent;
}
.pattern {
	position: absolute;
	position: absolute;
	bottom: 0;
	bottom: 0;
	width: 100%;
	width: 100%;
	background-repeat: repeat-x;
	background-repeat: repeat-x;
	background-position: bottom;
	background-position: bottom;
	background-size: 1450px 160px;
	background-size: 1450px 160px;
	height: 0;
	height: 0;
	padding: 0;
	padding: 0;
	padding-bottom: 140px;
	padding-bottom: 140px;
}
.pattern.bottom {
	bottom: -10px;
	bottom: -10px;
	transform: rotate(180deg);
	transform: rotate(180deg);
}
.pattern.top {
	top: -10px;
	top: -10px;
}
.organic-shape {
	&:not(.position-relative) {
		position: absolute;
	}
	svg {
		background: none !important;
	}
}
.organic-shape.top {
	top: 0;
}
.organic-shape.left {
	left: 0;
}
.organic-shape.right {
	right: 0;
}
.organic-shape.middle-y {
	top: 50%;
}
.organic-shape.middle-x {
	left: 50%;
}
.organic-shape.bottom {
	bottom: 0;
}
.organic-shape.scale-2 {
	svg {
		transform: scale(2);
	}
}
.organic-shape.scale-3 {
	svg {
		transform: scale(3);
	}
}
.organic-shape.scale-4 {
	svg {
		transform: scale(4);
	}
}
.organic-shape.scale-5 {
	svg {
		transform: scale(5);
	}
}
.organic-shape-primary {
	fill: rgba(38, 43, 64, 0.3);
}
.organic-shape-secondary {
	fill: rgba(248, 189, 122, 0.3);
}
.organic-shape-tertiary {
	fill: rgba(27, 153, 139, 0.3);
}
.organic-shape-quaternary {
	fill: rgba(9, 72, 179, 0.3);
}
.organic-shape-primary-app {
	fill: rgba(235, 244, 246, 0.3);
}
.organic-shape-secondary-app {
	fill: rgba(66, 74, 160, 0.3);
}
.organic-shape-light {
	fill: rgba(234, 237, 242, 0.3);
}
.organic-shape-lighten {
	fill: rgba(240, 243, 246, 0.3);
}
.organic-shape-success {
	fill: rgba(5, 166, 119, 0.3);
}
.organic-shape-info {
	fill: rgba(9, 72, 179, 0.3);
}
.organic-shape-warning {
	fill: rgba(245, 183, 89, 0.3);
}
.organic-shape-danger {
	fill: rgba(250, 82, 82, 0.3);
}
.organic-shape-white {
	fill: rgba(255, 255, 255, 0.3);
}
.organic-shape-gray {
	fill: rgba(74, 80, 115, 0.3);
}
.organic-shape-neutral {
	fill: rgba(255, 255, 255, 0.3);
}
.organic-shape-dark {
	fill: rgba(38, 43, 64, 0.3);
}
.organic-shape-soft {
	fill: rgba(245, 248, 251, 0.3);
}
.organic-shape-black {
	fill: rgba(46, 54, 80, 0.3);
}
.organic-shape-blue {
	fill: rgba(9, 72, 179, 0.3);
}
.organic-shape-pink {
	fill: rgba(201, 100, 128, 0.3);
}
.organic-shape-cyan {
	fill: rgba(99, 177, 189, 0.3);
}
.organic-shape-purple {
	fill: rgba(137, 101, 224, 0.3);
}
.organic-shape-soft-indigo {
	fill: rgba(245, 232, 255, 0.3);
}
.organic-shape-soft-green {
	fill: rgba(44, 165, 141, 0.3);
}
.organic-shape-gray-100 {
	fill: rgba(243, 247, 250, 0.3);
}
.organic-shape-gray-200 {
	fill: rgba(245, 248, 251, 0.3);
}
.organic-shape-gray-300 {
	fill: rgba(240, 243, 246, 0.3);
}
.organic-shape-gray-400 {
	fill: rgba(234, 237, 242, 0.3);
}
.organic-shape-gray-500 {
	fill: rgba(209, 215, 224, 0.3);
}
.organic-shape-gray-600 {
	fill: rgba(147, 165, 190, 0.3);
}
.organic-shape-gray-700 {
	fill: rgba(102, 121, 158, 0.3);
}
.organic-shape-gray-800 {
	fill: rgba(80, 102, 144, 0.3);
}
.organic-shape-facebook {
	fill: rgba(59, 89, 153, 0.3);
}
.organic-shape-twitter {
	fill: rgba(29, 161, 242, 0.3);
}
.organic-shape-google {
	fill: rgba(219, 67, 55, 0.3);
}
.organic-shape-instagram {
	fill: rgba(228, 64, 95, 0.3);
}
.organic-shape-pinterest {
	fill: rgba(189, 8, 28, 0.3);
}
.organic-shape-youtube {
	fill: rgba(205, 32, 31, 0.3);
}
.organic-shape-slack {
	fill: rgba(58, 175, 133, 0.3);
}
.organic-shape-dribbble {
	fill: rgba(234, 76, 137, 0.3);
}
.organic-shape-dropbox {
	fill: rgba(30, 144, 255, 0.3);
}
.organic-shape-twitch {
	fill: rgba(75, 54, 124, 0.3);
}
.organic-shape-paypal {
	fill: rgba(236, 179, 44, 0.3);
}
.organic-shape-behance {
	fill: rgba(0, 87, 255, 0.3);
}
.organic-shape-reddit {
	fill: rgba(232, 68, 34, 0.3);
}
.organic-shape-github {
	fill: rgba(34, 34, 34, 0.3);
}
.stroke-primary {
	stroke: #262B40;
}
.stroke-secondary {
	stroke: #F8BD7A;
}
.stroke-tertiary {
	stroke: #1B998B;
}
.stroke-quaternary {
	stroke: #0948B3;
}
.stroke-primary-app {
	stroke: #EBF4F6;
}
.stroke-secondary-app {
	stroke: #424AA0;
}
.stroke-light {
	stroke: #eaedf2;
}
.stroke-lighten {
	stroke: #F0F3F6;
}
.stroke-success {
	stroke: #05A677;
}
.stroke-info {
	stroke: #0948B3;
}
.stroke-warning {
	stroke: #f5b759;
}
.stroke-danger {
	stroke: #FA5252;
}
.stroke-white {
	stroke: #ffffff;
}
.stroke-gray {
	stroke: #4A5073;
}
.stroke-neutral {
	stroke: #ffffff;
}
.stroke-dark {
	stroke: #262B40;
}
.stroke-soft {
	stroke: #f5f8fb;
}
.stroke-black {
	stroke: #2e3650;
}
.stroke-blue {
	stroke: #0948B3;
}
.stroke-pink {
	stroke: #C96480;
}
.stroke-cyan {
	stroke: #63b1bd;
}
.stroke-purple {
	stroke: #8965e0;
}
.stroke-soft-indigo {
	stroke: #f5e8ff;
}
.stroke-soft-green {
	stroke: #2CA58D;
}
.stroke-gray-100 {
	stroke: #f3f7fa;
}
.stroke-gray-200 {
	stroke: #f5f8fb;
}
.stroke-gray-300 {
	stroke: #F0F3F6;
}
.stroke-gray-400 {
	stroke: #eaedf2;
}
.stroke-gray-500 {
	stroke: #d1d7e0;
}
.stroke-gray-600 {
	stroke: #93a5be;
}
.stroke-gray-700 {
	stroke: #66799e;
}
.stroke-gray-800 {
	stroke: #506690;
}
.stroke-facebook {
	stroke: #3b5999;
}
.stroke-twitter {
	stroke: #1da1f2;
}
.stroke-google {
	stroke: #DB4337;
}
.stroke-instagram {
	stroke: #e4405f;
}
.stroke-pinterest {
	stroke: #bd081c;
}
.stroke-youtube {
	stroke: #cd201f;
}
.stroke-slack {
	stroke: #3aaf85;
}
.stroke-dribbble {
	stroke: #ea4c89;
}
.stroke-dropbox {
	stroke: #1E90FF;
}
.stroke-twitch {
	stroke: #4B367C;
}
.stroke-paypal {
	stroke: #ecb32c;
}
.stroke-behance {
	stroke: #0057ff;
}
.stroke-reddit {
	stroke: #E84422;
}
.stroke-github {
	stroke: #222222;
}
.shape-primary {
	background-color: #262B40;
	.step-number {
		background-color: #262B40;
	}
}
.shape-secondary {
	background-color: #F8BD7A;
	.step-number {
		background-color: #F8BD7A;
	}
}
.shape-tertiary {
	background-color: #1B998B;
	.step-number {
		background-color: #1B998B;
	}
}
.shape-quaternary {
	background-color: #0948B3;
	.step-number {
		background-color: #0948B3;
	}
}
.shape-primary-app {
	background-color: #EBF4F6;
	.step-number {
		background-color: #EBF4F6;
	}
}
.shape-secondary-app {
	background-color: #424AA0;
	.step-number {
		background-color: #424AA0;
	}
}
.shape-light {
	background-color: #eaedf2;
	.step-number {
		background-color: #eaedf2;
	}
}
.shape-lighten {
	background-color: #F0F3F6;
	.step-number {
		background-color: #F0F3F6;
	}
}
.shape-success {
	background-color: #05A677;
	.step-number {
		background-color: #05A677;
	}
}
.shape-info {
	background-color: #0948B3;
	.step-number {
		background-color: #0948B3;
	}
}
.shape-warning {
	background-color: #f5b759;
	.step-number {
		background-color: #f5b759;
	}
}
.shape-danger {
	background-color: #FA5252;
	.step-number {
		background-color: #FA5252;
	}
}
.shape-white {
	background-color: #ffffff;
	.step-number {
		background-color: #ffffff;
	}
}
.shape-gray {
	background-color: #4A5073;
	.step-number {
		background-color: #4A5073;
	}
}
.shape-neutral {
	background-color: #ffffff;
	.step-number {
		background-color: #ffffff;
	}
}
.shape-dark {
	background-color: #262B40;
	.step-number {
		background-color: #262B40;
	}
}
.shape-soft {
	background-color: #f5f8fb;
	.step-number {
		background-color: #f5f8fb;
	}
}
.shape-black {
	background-color: #2e3650;
	.step-number {
		background-color: #2e3650;
	}
}
.shape-blue {
	background-color: #0948B3;
	.step-number {
		background-color: #0948B3;
	}
}
.shape-pink {
	background-color: #C96480;
	.step-number {
		background-color: #C96480;
	}
}
.shape-cyan {
	background-color: #63b1bd;
	.step-number {
		background-color: #63b1bd;
	}
}
.shape-purple {
	background-color: #8965e0;
	.step-number {
		background-color: #8965e0;
	}
}
.shape-soft-indigo {
	background-color: #f5e8ff;
	.step-number {
		background-color: #f5e8ff;
	}
}
.shape-soft-green {
	background-color: #2CA58D;
	.step-number {
		background-color: #2CA58D;
	}
}
.shape-gray-100 {
	background-color: #f3f7fa;
	.step-number {
		background-color: #f3f7fa;
	}
}
.shape-gray-200 {
	background-color: #f5f8fb;
	.step-number {
		background-color: #f5f8fb;
	}
}
.shape-gray-300 {
	background-color: #F0F3F6;
	.step-number {
		background-color: #F0F3F6;
	}
}
.shape-gray-400 {
	background-color: #eaedf2;
	.step-number {
		background-color: #eaedf2;
	}
}
.shape-gray-500 {
	background-color: #d1d7e0;
	.step-number {
		background-color: #d1d7e0;
	}
}
.shape-gray-600 {
	background-color: #93a5be;
	.step-number {
		background-color: #93a5be;
	}
}
.shape-gray-700 {
	background-color: #66799e;
	.step-number {
		background-color: #66799e;
	}
}
.shape-gray-800 {
	background-color: #506690;
	.step-number {
		background-color: #506690;
	}
}
.shape-facebook {
	background-color: #3b5999;
	.step-number {
		background-color: #3b5999;
	}
}
.shape-twitter {
	background-color: #1da1f2;
	.step-number {
		background-color: #1da1f2;
	}
}
.shape-google {
	background-color: #DB4337;
	.step-number {
		background-color: #DB4337;
	}
}
.shape-instagram {
	background-color: #e4405f;
	.step-number {
		background-color: #e4405f;
	}
}
.shape-pinterest {
	background-color: #bd081c;
	.step-number {
		background-color: #bd081c;
	}
}
.shape-youtube {
	background-color: #cd201f;
	.step-number {
		background-color: #cd201f;
	}
}
.shape-slack {
	background-color: #3aaf85;
	.step-number {
		background-color: #3aaf85;
	}
}
.shape-dribbble {
	background-color: #ea4c89;
	.step-number {
		background-color: #ea4c89;
	}
}
.shape-dropbox {
	background-color: #1E90FF;
	.step-number {
		background-color: #1E90FF;
	}
}
.shape-twitch {
	background-color: #4B367C;
	.step-number {
		background-color: #4B367C;
	}
}
.shape-paypal {
	background-color: #ecb32c;
	.step-number {
		background-color: #ecb32c;
	}
}
.shape-behance {
	background-color: #0057ff;
	.step-number {
		background-color: #0057ff;
	}
}
.shape-reddit {
	background-color: #E84422;
	.step-number {
		background-color: #E84422;
	}
}
.shape-github {
	background-color: #222222;
	.step-number {
		background-color: #222222;
	}
}
.organic-radius {
	border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;
}
.avatar-sm {
	width: 1.5rem;
	height: 1.5rem;
}
.avatar-md {
	width: 2rem;
	height: 2rem;
}
.avatar-lg {
	width: 3rem;
	height: 3rem;
}
.color-shape {
	width: 7rem;
	height: 7rem;
}
.step {
	position: relative;
	margin-bottom: 4rem;
	border-radius: 6px;
	.step-shape {
		display: inline-flex;
		width: 7rem;
		height: 7rem;
		text-align: center;
		-moz-align-items: center;
		-ms-align-items: center;
		align-items: center;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		-ms-flex-pack: center;
		i {
			font-size: 2rem;
		}
		svg {
			font-size: 2rem;
		}
		.step-number {
			position: absolute;
			font-weight: 600;
			top: -10px;
			width: 32px;
			height: 32px;
			border-width: 0.125rem;
			border-style: solid;
			line-height: 1.7;
		}
	}
	.step-shape.bordered {
		border-width: 0.125rem;
		border-style: solid;
		.step-number {
			background-color: #ffffff;
			top: -12px;
		}
	}
	.step-shape.rounded-circle {
		.step-number {
			margin-right: 1.275rem;
		}
	}
	.step-shape.organic-radius {
		.step-number {
			margin-right: 1.25rem;
			border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
		}
	}
	.icon-lg {
		font-size: 4.5rem;
	}
	.back-layer {
		display: flex;
		position: absolute;
		font-size: 12rem;
		z-index: 0;
		top: 0;
	}
}
.layer-primary {
	color: rgba(38, 43, 64, 0.15);
}
.layer-secondary {
	color: rgba(248, 189, 122, 0.15);
}
.layer-tertiary {
	color: rgba(27, 153, 139, 0.15);
}
.layer-quaternary {
	color: rgba(9, 72, 179, 0.15);
}
.layer-primary-app {
	color: rgba(235, 244, 246, 0.15);
}
.layer-secondary-app {
	color: rgba(66, 74, 160, 0.15);
}
.layer-light {
	color: rgba(234, 237, 242, 0.15);
}
.layer-lighten {
	color: rgba(240, 243, 246, 0.15);
}
.layer-success {
	color: rgba(5, 166, 119, 0.15);
}
.layer-info {
	color: rgba(9, 72, 179, 0.15);
}
.layer-warning {
	color: rgba(245, 183, 89, 0.15);
}
.layer-danger {
	color: rgba(250, 82, 82, 0.15);
}
.layer-white {
	color: rgba(255, 255, 255, 0.15);
}
.layer-gray {
	color: rgba(74, 80, 115, 0.15);
}
.layer-neutral {
	color: rgba(255, 255, 255, 0.15);
}
.layer-dark {
	color: rgba(38, 43, 64, 0.15);
}
.layer-soft {
	color: rgba(245, 248, 251, 0.15);
}
.layer-black {
	color: rgba(46, 54, 80, 0.15);
}
.layer-blue {
	color: rgba(9, 72, 179, 0.15);
}
.layer-pink {
	color: rgba(201, 100, 128, 0.15);
}
.layer-cyan {
	color: rgba(99, 177, 189, 0.15);
}
.layer-purple {
	color: rgba(137, 101, 224, 0.15);
}
.layer-soft-indigo {
	color: rgba(245, 232, 255, 0.15);
}
.layer-soft-green {
	color: rgba(44, 165, 141, 0.15);
}
.layer-gray-100 {
	color: rgba(243, 247, 250, 0.15);
}
.layer-gray-200 {
	color: rgba(245, 248, 251, 0.15);
}
.layer-gray-300 {
	color: rgba(240, 243, 246, 0.15);
}
.layer-gray-400 {
	color: rgba(234, 237, 242, 0.15);
}
.layer-gray-500 {
	color: rgba(209, 215, 224, 0.15);
}
.layer-gray-600 {
	color: rgba(147, 165, 190, 0.15);
}
.layer-gray-700 {
	color: rgba(102, 121, 158, 0.15);
}
.layer-gray-800 {
	color: rgba(80, 102, 144, 0.15);
}
.layer-facebook {
	color: rgba(59, 89, 153, 0.15);
}
.layer-twitter {
	color: rgba(29, 161, 242, 0.15);
}
.layer-google {
	color: rgba(219, 67, 55, 0.15);
}
.layer-instagram {
	color: rgba(228, 64, 95, 0.15);
}
.layer-pinterest {
	color: rgba(189, 8, 28, 0.15);
}
.layer-youtube {
	color: rgba(205, 32, 31, 0.15);
}
.layer-slack {
	color: rgba(58, 175, 133, 0.15);
}
.layer-dribbble {
	color: rgba(234, 76, 137, 0.15);
}
.layer-dropbox {
	color: rgba(30, 144, 255, 0.15);
}
.layer-twitch {
	color: rgba(75, 54, 124, 0.15);
}
.layer-paypal {
	color: rgba(236, 179, 44, 0.15);
}
.layer-behance {
	color: rgba(0, 87, 255, 0.15);
}
.layer-reddit {
	color: rgba(232, 68, 34, 0.15);
}
.layer-github {
	color: rgba(34, 34, 34, 0.15);
}
.table.user-table {
	thead {
		th {
			border-top: 0;
		}
	}
}
.table.align-items-center {
	td {
		vertical-align: middle;
	}
	th {
		vertical-align: middle;
	}
}
.table-flush {
	td {
		border-left: 0;
		border-right: 0;
	}
	th {
		border-left: 0;
		border-right: 0;
	}
	tbody {
		tr {
			&:first-child {
				td {
					border-top: 0;
				}
				th {
					border-top: 0;
				}
			}
			&:last-child {
				td {
					border-bottom: 0;
				}
				th {
					border-bottom: 0;
				}
			}
		}
	}
}
.dataTables_wrapper {
	.dataTables_info {
		padding-left: 1.5rem;
	}
	.dataTables_paginate {
		padding-right: 1.5rem;
	}
	.dataTables_length {
		label {
			padding-left: 1.5rem;
		}
	}
	.dataTables_filter {
		padding-right: 1.5rem;
	}
}
.dataTable-top {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0 1.5rem 1rem 1.5rem;
}
.dataTable-bottom {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 1.5rem 1.5rem 0 1.5rem;
}
.dataTable-pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	a {
		padding: 0.375rem 0.75rem;
	}
	li {
		&:first-child {
			a {
				border-top-left-radius: 0.5rem;
				border-bottom-left-radius: 0.5rem;
			}
		}
		&:last-child {
			a {
				border-top-right-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
			}
		}
	}
	>li {
		&:not(:first-child) {
			a {
				margin-left: -0.0625rem;
			}
		}
		a {
			position: relative;
			display: block;
			color: #262B40;
			background-color: #ffffff;
			border: 0.0625rem solid #eaedf2;
			&:hover {
				z-index: 2;
				color: #0a0b10;
				background-color: #f5f8fb;
				border-color: #F0F3F6;
			}
			&:focus {
				z-index: 3;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
			}
		}
	}
	>li.active {
		a {
			z-index: 3;
			color: #ffffff;
			background-color: #262B40;
			border-color: #262B40;
		}
	}
	>li.disabled {
		a {
			color: #93a5be;
			pointer-events: none;
			background-color: #ffffff;
			border-color: #F0F3F6;
		}
	}
}
.dataTable-selector {
	display: inline-block;
	width: auto;
	height: calc(1.5em + 1.225rem);
	padding: 0.55rem 1.75rem 0.55rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #66799e;
	vertical-align: middle;
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 0.0625rem solid #d1d7e0;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		border-color: #566190;
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
		&::-ms-value {
			color: #66799e;
			background-color: #ffffff;
		}
	}
	&:disabled {
		color: #93a5be;
		background-color: #f5f8fb;
	}
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #66799e;
	}
}
.dataTable-selector[multiple] {
	height: auto;
	padding-right: 0.75rem;
	background-image: none;
}
.dataTable-selector[size] {
	&:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}
}
.dataTable-input {
	display: block;
	width: 100%;
	min-height: calc(1.5em + 1.225rem);
	padding: 0.55rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #66799e;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 0.0625rem solid #d1d7e0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.5rem;
	box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:focus {
		color: #66799e;
		background-color: #ffffff;
		border-color: #566190;
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075), 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
	}
	&:-ms-input-placeholder {
		color: #506690;
		opacity: 1;
	}
	&::placeholder {
		color: #506690;
		opacity: 1;
	}
	&:disabled {
		background-color: #f5f8fb;
		opacity: 1;
	}
}
.dataTable-input[readonly] {
	background-color: #f5f8fb;
	opacity: 1;
}
.p {
	font-family: var(--bs-font-sans-serif);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.6;
}
.text-action {
	color: #262B40;
	transition: all 0.2s ease;
	&:hover {
		color: #0a0b10;
		cursor: pointer;
	}
}
.line-through {
	-webkit-text-decoration: line-through;
	text-decoration: line-through;
}
.text-italic {
	font-style: italic !important;
}
.description {
	font-size: 0.875rem;
}
.heading {
	font-size: 2rem;
	font-weight: 600;
}
.font-small {
	font-size: 0.875rem;
	font-weight: 300;
}
.font-base {
	font-size: 1rem;
	font-weight: 300;
}
.font-medium {
	font-size: 1.25rem;
	font-weight: 300;
}
article {
	img {
		margin: 40px 0;
	}
	p {
		margin-bottom: 20px;
		font-size: 1.2rem;
	}
	ul {
		margin-bottom: 20px;
		li {
			font-size: 1.2rem;
		}
	}
	ol {
		margin-bottom: 20px;
		li {
			font-size: 1.2rem;
		}
	}
	blockquote {
		margin-bottom: 20px;
	}
	h1 {
		margin-bottom: 30px;
	}
	.h1 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
	h2 {
		margin-bottom: 30px;
	}
	.h2 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 30px;
	}
	.h3 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
	h4 {
		margin-bottom: 30px;
	}
	.h4 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
	h5 {
		margin-bottom: 30px;
	}
	.h5 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
	h6 {
		margin-bottom: 30px;
	}
	.h6 {
		margin-bottom: 30px;
		margin-bottom: 30px;
	}
}
.folder-structure {
	li {
		font-size: 1.2rem;
	}
}
.truncate-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.timeline {
	width: 100%;
}
.timeline.timeline-one {
	.timeline-item {
		position: relative;
		width: 50%;
		margin-left: 50%;
		padding-bottom: 2.75rem;
		padding-left: 2.75rem;
		text-align: left;
		img {
			margin-bottom: 1.25rem;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 16px;
			height: 16px;
			margin-top: 0.425rem;
			margin-left: -0.5rem;
			border: 0.0625rem solid #eaedf2;
			border-radius: 50%;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: -0.1875rem;
			width: 0.0625rem;
			margin-top: 1.875rem;
			margin-left: -0.0625rem;
			background-color: #eaedf2;
		}
		&:nth-child(2n+1) {
			margin-left: 0;
			padding-right: 2.75rem;
			padding-left: 0;
			text-align: right;
			&:before {
				right: 0;
				left: auto;
				margin-right: -0.5rem;
			}
			&:after {
				right: 0;
				left: auto;
				margin-right: -0.0625rem;
			}
		}
	}
}
.timeline.timeline-two {
	.timeline-item {
		position: relative;
		background-color: #ffffff;
		padding: 2rem;
		text-align: left;
		margin-bottom: 2.75rem;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			border: 7px double #2e3650;
			content: "";
			left: auto;
			width: 16px;
			height: 16px;
			margin-right: -2rem;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			background-color: #2e3650;
			width: 0.0625rem;
			height: 100%;
			margin-top: 2rem;
			left: auto;
			margin-right: -1.575rem;
		}
		.post-meta {
			a {
				i {
					font-size: 0.875rem;
					padding-right: .125rem;
				}
			}
			span {
				i {
					font-size: 0.875rem;
					padding-right: .125rem;
				}
			}
		}
	}
}
.timeline.timeline-four {
	margin: 1.25rem 0;
	position: relative;
	&::before {
		content: "";
		display: block;
		width: 0.0625rem;
		height: 100%;
		background: #eaedf2;
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	.timeline-item {
		margin-bottom: 2.75rem;
		position: relative;
		&::after {
			content: "";
			display: block;
			clear: both;
		}
		&:hover {
			.date-outer {
				&::before {
					left: 6px;
				}
				&::after {
					left: -6px;
				}
			}
		}
		&:nth-child(2n) {
			.date-content {
				float: right;
			}
			.timeline-content {
				padding: 0.625rem 3.125rem 0.625rem 0;
				text-align: right;
			}
		}
	}
	.icon {
		width: 16px;
		height: 16px;
		margin: auto;
		position: absolute;
		background-color: #ffffff;
		border-radius: 50%;
		border: 0.0625rem solid #eaedf2;
		margin-top: 1rem;
		top: 0;
		left: 0;
		right: 0;
	}
	.date-content {
		width: 50%;
		float: left;
		position: relative;
	}
	.date-outer {
		width: 150px;
		height: 150px;
		text-align: center;
		margin: auto;
		z-index: 1;
		&::before {
			content: "";
			width: 150px;
			height: 150px;
			margin: 0 auto;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: 0;
			background: #ffffff;
			border: 0.0625rem solid #1B998B;
			left: -6px;
			transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
		&::after {
			content: "";
			width: 150px;
			height: 150px;
			margin: 0 auto;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: 0;
			border: 0.0625rem solid #262B40;
			left: 6px;
			transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
		}
	}
	.date {
		width: 100%;
		margin: auto;
		position: absolute;
		top: 33%;
		left: 0;
	}
	.year {
		display: block;
		font-weight: 700;
		font-size: 0.875rem;
	}
	i {
		font-size: 1.5rem;
	}
	.timeline-content {
		width: 50%;
		padding: 0.625rem 0 0.625rem 3.125rem;
		float: right;
	}
}
.timeline.timeline-five {
	.row {
		.avatar-separator {
			width: 30px;
			height: 30px;
		}
		.col.middle-line {
			border-right: 0.0625rem solid #eaedf2;
		}
	}
	.card {
		.card-body {
			.post-meta {
				i {
					padding-right: .25rem;
				}
			}
		}
	}
}
.timeline.timeline-six {
	.row {
		.col.middle-line {
			border-right: 0.0625rem solid #eaedf2;
		}
		.dot-separator {
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}
		.avatar-separator {
			img {
				border: 0.0625rem solid #eaedf2;
				padding: .125rem;
			}
		}
	}
}
.vertical-timeline {
	.row {
		padding-top: 1rem;
		padding-bottom: 1rem;
		.col-md-2 {
			display: inline-flex;
			align-self: stretch;
			align-items: center;
			justify-content: center;
			&:after {
				content: '';
				position: absolute;
				border-left: 0.0625rem solid #eaedf2;
				z-index: 1;
			}
		}
		.col-md-2.bottom {
			&:after {
				height: 50%;
				top: 50%;
			}
		}
		.col-md-2.full {
			&:after {
				height: 100%;
				left: calc(50% - 1px);
			}
		}
		.col-md-2.top {
			&:after {
				height: 50%;
				left: 50%;
				top: 0;
			}
		}
		.shape {
			background-color: #eaedf2;
			width: 50px;
			height: 50px;
			z-index: 2;
		}
		.right {
			margin-right: 0.1875rem;
		}
	}
	.row.timeline-inner {
		div {
			padding: 0;
			height: 38px;
		}
		hr {
			border-top: 0.0625rem solid #eaedf2;
			margin: 0;
			top: 1.0625rem;
			position: relative;
		}
		.col-md-2 {
			display: flex;
			overflow: hidden;
		}
		.corner {
			border: 0.0625rem solid #eaedf2;
			width: 100%;
			position: relative;
		}
		.top-right {
			left: calc(50% - 1px);
			top: -50%;
		}
		.left-bottom {
			left: -50%;
			top: calc(50% - 2px);
		}
		.top-left {
			left: -50%;
			top: -50%;
		}
		.right-bottom {
			left: 50%;
			top: calc(50% - 2px);
		}
	}
}
.list-group-timeline {
	.list-group-item {
		position: relative;
		.col-auto {
			z-index: 999;
		}
		&:not(:last-child) {
			&::before {
				content: '';
				position: absolute;
				top: 1rem;
				left: 2.5rem;
				height: 100%;
				border-left: 1px solid #eaedf2;
			}
		}
	}
}
.chartist-tooltip {
	position: absolute;
	display: inline-block;
	opacity: 0;
	min-width: 5em;
	padding: .5em;
	background: #f5f8fb;
	border: 1px solid #eaedf2;
	border-radius: 0.5rem;
	color: #262B40;
	font-weight: 600;
	text-align: center;
	pointer-events: none;
	z-index: 1;
	transition: all 0.2s ease;
	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -15px;
		border: 15px solid transparent;
		border-top-color: #f5f8fb;
	}
}
.chartist-tooltip.tooltip-show {
	opacity: 1;
}
.preloader {
	display: flex;
	align-items: center;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	transition: height .2s linear;
	width: 100%;
	z-index: 9999;
	font-size: 50px;
	.loader-element {
		font-size: 50px;
		color: var(--bs-secondary);
		&.hide {
			display: none !important;
		}
	}
	&.show {
		height: 0;
	}
	.fc-h-event {
		border: 0;
		padding: 3px;
		display: inline-flex;
	}
}

.fc-daygrid-day-frame {
	padding: 5px;
	border: 1px solid #f5f8fb;
}
.fc-daygrid-day {
	transition: all 0.2s ease;
	&:hover {
		cursor: pointer;
		background: rgba(248, 189, 122, 0.2);
	}
}
.fc {
	.fc-scrollgrid {
		border-color: #ffffff;
		table {
			border-color: #ffffff;
		}
	}
	.fc-highlight {
		background-color: rgba(248, 189, 122, 0.2);
	}
	.fc-col-header-cell-cushion {
		&::before {
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			font-size: 0.875rem;
			content: "\f783";
			margin-right: 10px;
		}
		padding: 6px 8px;
	}
}
.fc-daygrid-event {
	font-weight: 600;
	&::before {
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		content: "\f274";
		color: #ffffff;
		margin-right: 4px;
	}
}
.fc-direction-ltr {
	.fc-daygrid-event {
		padding-right: 10px;
		padding-left: 10px;
	}
}
.fc-daygrid-day-number {
	font-weight: 600;
}
[data-simplebar] {
	position: fixed;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
}
.simplebar-wrapper {
	overflow: hidden;
	width: inherit;
	height: inherit;
	max-width: inherit;
	max-height: inherit;
}
.simplebar-mask {
	direction: inherit;
	position: absolute;
	overflow: hidden;
	padding: 0;
	margin: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: auto !important;
	height: auto !important;
	z-index: 0;
}
.simplebar-offset {
	direction: inherit !important;
	box-sizing: inherit !important;
	resize: none !important;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	margin: 0;
	-webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
	direction: inherit;
	box-sizing: border-box !important;
	position: relative;
	display: block;
	height: 100%;
	width: auto;
	max-width: 100%;
	max-height: 100%;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}
.simplebar-hide-scrollbar {
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	position: fixed;
	left: 0;
	visibility: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.simplebar-content {
	&:before {
		content: ' ';
		display: table;
	}
	&:after {
		content: ' ';
		display: table;
	}
}
.simplebar-placeholder {
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
	box-sizing: inherit !important;
	height: 100%;
	width: 100%;
	max-width: 1px;
	position: relative;
	float: left;
	max-height: 1px;
	overflow: hidden;
	z-index: -1;
	padding: 0;
	margin: 0;
	pointer-events: none;
	flex-grow: inherit;
	flex-shrink: 0;
	flex-basis: 0;
}
.simplebar-height-auto-observer {
	box-sizing: inherit;
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 1000%;
	width: 1000%;
	min-height: 1px;
	min-width: 1px;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;
}
.simplebar-track {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;
}
[data-simplebar].simplebar-dragging {
	.simplebar-content {
		pointer-events: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-user-select: none;
	}
	.simplebar-track {
		pointer-events: all;
	}
}
.simplebar-scrollbar {
	position: absolute;
	left: 0;
	right: 0;
	min-height: 10px;
	&:before {
		position: absolute;
		content: '';
		background: black;
		border-radius: 7px;
		left: 2px;
		right: 2px;
		opacity: 0;
		transition: opacity 0.2s 0.5s linear;
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
	}
	&::before {
		background-color: #131620;
	}
}
.simplebar-scrolling {
	.simplebar-scrollbar {
		&:before {
			opacity: 0.5;
			transition-delay: 0s;
			transition-duration: 0s;
		}
	}
}
.simplebar-hover {
	.simplebar-scrollbar {
		&:before {
			opacity: 0.5;
			transition-delay: 0s;
			transition-duration: 0s;
		}
	}
}
.simplebar-mouse-entered {
	.simplebar-scrollbar {
		&:before {
			opacity: 0.5;
			transition-delay: 0s;
			transition-duration: 0s;
		}
	}
}
.simplebar-scrollbar.simplebar-visible {
	&:before {
		opacity: 0.5;
		transition-delay: 0s;
		transition-duration: 0s;
	}
}
.simplebar-track.simplebar-vertical {
	top: 0;
	width: 11px;
}
.simplebar-track.simplebar-horizontal {
	left: 0;
	height: 11px;
	.simplebar-scrollbar {
		right: auto;
		left: 0;
		top: 0;
		bottom: 0;
		min-height: 0;
		min-width: 10px;
		width: auto;
	}
}
[data-simplebar-direction='rtl'] {
	.simplebar-track.simplebar-vertical {
		right: auto;
		left: 0;
	}
}
.simplebar-dummy-scrollbar-size {
	direction: rtl;
	position: fixed;
	opacity: 0;
	visibility: hidden;
	height: 500px;
	width: 500px;
	overflow-y: hidden;
	overflow-x: scroll;
	-ms-overflow-style: scrollbar !important;
	>div {
		width: 200%;
		height: 200%;
		margin: 10px 0;
	}
}
.suggestions-wrapper {
	svg {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	h1 {
		font-size: 2.5rem;
	}
	.h1 {
		font-size: 2.5rem;
	}
	h2 {
		font-size: 2rem;
	}
	.h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.75rem;
	}
	.h3 {
		font-size: 1.75rem;
	}
	h4 {
		font-size: 1.5rem;
	}
	.h4 {
		font-size: 1.5rem;
	}
	legend {
		font-size: 1.5rem;
	}
	.display-1 {
		font-size: 5rem;
	}
	.display-2 {
		font-size: 3.5rem;
	}
	.display-3 {
		font-size: 2.5rem;
	}
	.display-4 {
		font-size: 1.875rem;
	}
	.container {
		max-width: 1140px;
	}
	.container-sm {
		max-width: 1140px;
	}
	.container-md {
		max-width: 1140px;
	}
	.container-lg {
		max-width: 1140px;
	}
	.container-xl {
		max-width: 1140px;
	}
	.col-xl {
		flex: 1 0 0%;
	}
	.row-cols-xl-auto {
		>* {
			flex: 0 0 auto;
			width: auto;
		}
	}
	.row-cols-xl-1 {
		>* {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	.row-cols-xl-2 {
		>* {
			flex: 0 0 auto;
			width: 50%;
		}
	}
	.row-cols-xl-3 {
		>* {
			flex: 0 0 auto;
			width: 33.33333%;
		}
	}
	.row-cols-xl-4 {
		>* {
			flex: 0 0 auto;
			width: 25%;
		}
	}
	.row-cols-xl-5 {
		>* {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.row-cols-xl-6 {
		>* {
			flex: 0 0 auto;
			width: 16.66667%;
		}
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.33333%;
	}
	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.66667%;
	}
	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.33333%;
	}
	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.66667%;
	}
	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.33333%;
	}
	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.66667%;
	}
	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.33333%;
	}
	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.66667%;
	}
	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.33333%;
	}
	.offset-xl-2 {
		margin-left: 16.66667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.33333%;
	}
	.offset-xl-5 {
		margin-left: 41.66667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.33333%;
	}
	.offset-xl-8 {
		margin-left: 66.66667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.33333%;
	}
	.offset-xl-11 {
		margin-left: 91.66667%;
	}
	.g-xl-0 {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.gx-xl-0 {
		--bs-gutter-x: 0;
	}
	.gy-xl-0 {
		--bs-gutter-y: 0;
	}
	.g-xl-1 {
		--bs-gutter-x: 0.25rem;
		--bs-gutter-y: 0.25rem;
	}
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xl-2 {
		--bs-gutter-x: 0.5rem;
		--bs-gutter-y: 0.5rem;
	}
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xl-3 {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-xl-3 {
		--bs-gutter-x: 1rem;
	}
	.gy-xl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xl-4 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 1.5rem;
	}
	.gx-xl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.gy-xl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xl-5 {
		--bs-gutter-x: 3rem;
		--bs-gutter-y: 3rem;
	}
	.gx-xl-5 {
		--bs-gutter-x: 3rem;
	}
	.gy-xl-5 {
		--bs-gutter-y: 3rem;
	}
	.g-xl-6 {
		--bs-gutter-x: 5rem;
		--bs-gutter-y: 5rem;
	}
	.gx-xl-6 {
		--bs-gutter-x: 5rem;
	}
	.gy-xl-6 {
		--bs-gutter-y: 5rem;
	}
	.g-xl-7 {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-xl-7 {
		--bs-gutter-x: 8rem;
	}
	.gy-xl-7 {
		--bs-gutter-y: 8rem;
	}
	.g-xl-8 {
		--bs-gutter-x: 10rem;
		--bs-gutter-y: 10rem;
	}
	.gx-xl-8 {
		--bs-gutter-x: 10rem;
	}
	.gy-xl-8 {
		--bs-gutter-y: 10rem;
	}
	.g-xl-9 {
		--bs-gutter-x: 11rem;
		--bs-gutter-y: 11rem;
	}
	.gx-xl-9 {
		--bs-gutter-x: 11rem;
	}
	.gy-xl-9 {
		--bs-gutter-y: 11rem;
	}
	.g-xl-10 {
		--bs-gutter-x: 14rem;
		--bs-gutter-y: 14rem;
	}
	.gx-xl-10 {
		--bs-gutter-x: 14rem;
	}
	.gy-xl-10 {
		--bs-gutter-y: 14rem;
	}
	.g-xl-11 {
		--bs-gutter-x: 16rem;
		--bs-gutter-y: 16rem;
	}
	.gx-xl-11 {
		--bs-gutter-x: 16rem;
	}
	.gy-xl-11 {
		--bs-gutter-y: 16rem;
	}
	.g-xl-12 {
		--bs-gutter-x: 20rem;
		--bs-gutter-y: 20rem;
	}
	.gx-xl-12 {
		--bs-gutter-x: 20rem;
	}
	.gy-xl-12 {
		--bs-gutter-y: 20rem;
	}
	.g-xl-sm {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-xl-sm {
		--bs-gutter-x: 1rem;
	}
	.gy-xl-sm {
		--bs-gutter-y: 1rem;
	}
	.g-xl-md {
		--bs-gutter-x: 2rem;
		--bs-gutter-y: 2rem;
	}
	.gx-xl-md {
		--bs-gutter-x: 2rem;
	}
	.gy-xl-md {
		--bs-gutter-y: 2rem;
	}
	.g-xl-lg {
		--bs-gutter-x: 4rem;
		--bs-gutter-y: 4rem;
	}
	.gx-xl-lg {
		--bs-gutter-x: 4rem;
	}
	.gy-xl-lg {
		--bs-gutter-y: 4rem;
	}
	.g-xl-xl {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-xl-xl {
		--bs-gutter-x: 8rem;
	}
	.gy-xl-xl {
		--bs-gutter-y: 8rem;
	}
	.dropdown-menu-xl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-xl {
		flex-wrap: nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.8rem;
				padding-left: 0.8rem;
			}
		}
		.navbar-collapse {
			display: flex !important;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-xl {
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 0.0625rem;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -0.0625rem;
					border-left-width: 0.0625rem;
				}
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
	}
	.close {
		font-size: 1.5rem;
	}
	.modal-xl {
		max-width: 1140px;
	}
	.sticky-xl-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.justify-content-xl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
	.order-xl-first {
		order: -1 !important;
	}
	.order-xl-0 {
		order: 0 !important;
	}
	.order-xl-1 {
		order: 1 !important;
	}
	.order-xl-2 {
		order: 2 !important;
	}
	.order-xl-3 {
		order: 3 !important;
	}
	.order-xl-4 {
		order: 4 !important;
	}
	.order-xl-5 {
		order: 5 !important;
	}
	.order-xl-last {
		order: 6 !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.m-xl-6 {
		margin: 5rem !important;
	}
	.m-xl-7 {
		margin: 8rem !important;
	}
	.m-xl-8 {
		margin: 10rem !important;
	}
	.m-xl-9 {
		margin: 11rem !important;
	}
	.m-xl-10 {
		margin: 14rem !important;
	}
	.m-xl-11 {
		margin: 16rem !important;
	}
	.m-xl-12 {
		margin: 20rem !important;
	}
	.m-xl-sm {
		margin: 1rem !important;
	}
	.m-xl-md {
		margin: 2rem !important;
	}
	.m-xl-lg {
		margin: 4rem !important;
	}
	.m-xl-xl {
		margin: 8rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xl-6 {
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}
	.mx-xl-7 {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-xl-8 {
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.mx-xl-9 {
		margin-right: 11rem !important;
		margin-left: 11rem !important;
	}
	.mx-xl-10 {
		margin-right: 14rem !important;
		margin-left: 14rem !important;
	}
	.mx-xl-11 {
		margin-right: 16rem !important;
		margin-left: 16rem !important;
	}
	.mx-xl-12 {
		margin-right: 20rem !important;
		margin-left: 20rem !important;
	}
	.mx-xl-sm {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-md {
		margin-right: 2rem !important;
		margin-left: 2rem !important;
	}
	.mx-xl-lg {
		margin-right: 4rem !important;
		margin-left: 4rem !important;
	}
	.mx-xl-xl {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xl-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.my-xl-7 {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-xl-8 {
		margin-top: 10rem !important;
		margin-bottom: 10rem !important;
	}
	.my-xl-9 {
		margin-top: 11rem !important;
		margin-bottom: 11rem !important;
	}
	.my-xl-10 {
		margin-top: 14rem !important;
		margin-bottom: 14rem !important;
	}
	.my-xl-11 {
		margin-top: 16rem !important;
		margin-bottom: 16rem !important;
	}
	.my-xl-12 {
		margin-top: 20rem !important;
		margin-bottom: 20rem !important;
	}
	.my-xl-sm {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-md {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.my-xl-lg {
		margin-top: 4rem !important;
		margin-bottom: 4rem !important;
	}
	.my-xl-xl {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mt-xl-6 {
		margin-top: 5rem !important;
	}
	.mt-xl-7 {
		margin-top: 8rem !important;
	}
	.mt-xl-8 {
		margin-top: 10rem !important;
	}
	.mt-xl-9 {
		margin-top: 11rem !important;
	}
	.mt-xl-10 {
		margin-top: 14rem !important;
	}
	.mt-xl-11 {
		margin-top: 16rem !important;
	}
	.mt-xl-12 {
		margin-top: 20rem !important;
	}
	.mt-xl-sm {
		margin-top: 1rem !important;
	}
	.mt-xl-md {
		margin-top: 2rem !important;
	}
	.mt-xl-lg {
		margin-top: 4rem !important;
	}
	.mt-xl-xl {
		margin-top: 8rem !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.mr-xl-0 {
		margin-right: 0 !important;
	}
	.mr-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mr-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mr-xl-3 {
		margin-right: 1rem !important;
	}
	.mr-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mr-xl-5 {
		margin-right: 3rem !important;
	}
	.mr-xl-6 {
		margin-right: 5rem !important;
	}
	.mr-xl-7 {
		margin-right: 8rem !important;
	}
	.mr-xl-8 {
		margin-right: 10rem !important;
	}
	.mr-xl-9 {
		margin-right: 11rem !important;
	}
	.mr-xl-10 {
		margin-right: 14rem !important;
	}
	.mr-xl-11 {
		margin-right: 16rem !important;
	}
	.mr-xl-12 {
		margin-right: 20rem !important;
	}
	.mr-xl-sm {
		margin-right: 1rem !important;
	}
	.mr-xl-md {
		margin-right: 2rem !important;
	}
	.mr-xl-lg {
		margin-right: 4rem !important;
	}
	.mr-xl-xl {
		margin-right: 8rem !important;
	}
	.mr-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xl-6 {
		margin-bottom: 5rem !important;
	}
	.mb-xl-7 {
		margin-bottom: 8rem !important;
	}
	.mb-xl-8 {
		margin-bottom: 10rem !important;
	}
	.mb-xl-9 {
		margin-bottom: 11rem !important;
	}
	.mb-xl-10 {
		margin-bottom: 14rem !important;
	}
	.mb-xl-11 {
		margin-bottom: 16rem !important;
	}
	.mb-xl-12 {
		margin-bottom: 20rem !important;
	}
	.mb-xl-sm {
		margin-bottom: 1rem !important;
	}
	.mb-xl-md {
		margin-bottom: 2rem !important;
	}
	.mb-xl-lg {
		margin-bottom: 4rem !important;
	}
	.mb-xl-xl {
		margin-bottom: 8rem !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-0 {
		margin-left: 0 !important;
	}
	.ml-xl-1 {
		margin-left: 0.25rem !important;
	}
	.ml-xl-2 {
		margin-left: 0.5rem !important;
	}
	.ml-xl-3 {
		margin-left: 1rem !important;
	}
	.ml-xl-4 {
		margin-left: 1.5rem !important;
	}
	.ml-xl-5 {
		margin-left: 3rem !important;
	}
	.ml-xl-6 {
		margin-left: 5rem !important;
	}
	.ml-xl-7 {
		margin-left: 8rem !important;
	}
	.ml-xl-8 {
		margin-left: 10rem !important;
	}
	.ml-xl-9 {
		margin-left: 11rem !important;
	}
	.ml-xl-10 {
		margin-left: 14rem !important;
	}
	.ml-xl-11 {
		margin-left: 16rem !important;
	}
	.ml-xl-12 {
		margin-left: 20rem !important;
	}
	.ml-xl-sm {
		margin-left: 1rem !important;
	}
	.ml-xl-md {
		margin-left: 2rem !important;
	}
	.ml-xl-lg {
		margin-left: 4rem !important;
	}
	.ml-xl-xl {
		margin-left: 8rem !important;
	}
	.ml-xl-auto {
		margin-left: auto !important;
	}
	.m-xl-n1 {
		margin: -0.25rem !important;
	}
	.m-xl-n2 {
		margin: -0.5rem !important;
	}
	.m-xl-n3 {
		margin: -1rem !important;
	}
	.m-xl-n4 {
		margin: -1.5rem !important;
	}
	.m-xl-n5 {
		margin: -3rem !important;
	}
	.m-xl-n6 {
		margin: -5rem !important;
	}
	.m-xl-n7 {
		margin: -8rem !important;
	}
	.m-xl-n8 {
		margin: -10rem !important;
	}
	.m-xl-n9 {
		margin: -11rem !important;
	}
	.m-xl-n10 {
		margin: -14rem !important;
	}
	.m-xl-n11 {
		margin: -16rem !important;
	}
	.m-xl-n12 {
		margin: -20rem !important;
	}
	.m-xl-nsm {
		margin: -1rem !important;
	}
	.m-xl-nmd {
		margin: -2rem !important;
	}
	.m-xl-nlg {
		margin: -4rem !important;
	}
	.m-xl-nxl {
		margin: -8rem !important;
	}
	.mx-xl-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mx-xl-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mx-xl-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-xl-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mx-xl-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mx-xl-n6 {
		margin-right: -5rem !important;
		margin-left: -5rem !important;
	}
	.mx-xl-n7 {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.mx-xl-n8 {
		margin-right: -10rem !important;
		margin-left: -10rem !important;
	}
	.mx-xl-n9 {
		margin-right: -11rem !important;
		margin-left: -11rem !important;
	}
	.mx-xl-n10 {
		margin-right: -14rem !important;
		margin-left: -14rem !important;
	}
	.mx-xl-n11 {
		margin-right: -16rem !important;
		margin-left: -16rem !important;
	}
	.mx-xl-n12 {
		margin-right: -20rem !important;
		margin-left: -20rem !important;
	}
	.mx-xl-nsm {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-xl-nmd {
		margin-right: -2rem !important;
		margin-left: -2rem !important;
	}
	.mx-xl-nlg {
		margin-right: -4rem !important;
		margin-left: -4rem !important;
	}
	.mx-xl-nxl {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.my-xl-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.my-xl-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.my-xl-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-xl-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.my-xl-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.my-xl-n6 {
		margin-top: -5rem !important;
		margin-bottom: -5rem !important;
	}
	.my-xl-n7 {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.my-xl-n8 {
		margin-top: -10rem !important;
		margin-bottom: -10rem !important;
	}
	.my-xl-n9 {
		margin-top: -11rem !important;
		margin-bottom: -11rem !important;
	}
	.my-xl-n10 {
		margin-top: -14rem !important;
		margin-bottom: -14rem !important;
	}
	.my-xl-n11 {
		margin-top: -16rem !important;
		margin-bottom: -16rem !important;
	}
	.my-xl-n12 {
		margin-top: -20rem !important;
		margin-bottom: -20rem !important;
	}
	.my-xl-nsm {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-xl-nmd {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.my-xl-nlg {
		margin-top: -4rem !important;
		margin-bottom: -4rem !important;
	}
	.my-xl-nxl {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.mt-xl-n1 {
		margin-top: -0.25rem !important;
	}
	.mt-xl-n2 {
		margin-top: -0.5rem !important;
	}
	.mt-xl-n3 {
		margin-top: -1rem !important;
	}
	.mt-xl-n4 {
		margin-top: -1.5rem !important;
	}
	.mt-xl-n5 {
		margin-top: -3rem !important;
	}
	.mt-xl-n6 {
		margin-top: -5rem !important;
	}
	.mt-xl-n7 {
		margin-top: -8rem !important;
	}
	.mt-xl-n8 {
		margin-top: -10rem !important;
	}
	.mt-xl-n9 {
		margin-top: -11rem !important;
	}
	.mt-xl-n10 {
		margin-top: -14rem !important;
	}
	.mt-xl-n11 {
		margin-top: -16rem !important;
	}
	.mt-xl-n12 {
		margin-top: -20rem !important;
	}
	.mt-xl-nsm {
		margin-top: -1rem !important;
	}
	.mt-xl-nmd {
		margin-top: -2rem !important;
	}
	.mt-xl-nlg {
		margin-top: -4rem !important;
	}
	.mt-xl-nxl {
		margin-top: -8rem !important;
	}
	.mr-xl-n1 {
		margin-right: -0.25rem !important;
	}
	.mr-xl-n2 {
		margin-right: -0.5rem !important;
	}
	.mr-xl-n3 {
		margin-right: -1rem !important;
	}
	.mr-xl-n4 {
		margin-right: -1.5rem !important;
	}
	.mr-xl-n5 {
		margin-right: -3rem !important;
	}
	.mr-xl-n6 {
		margin-right: -5rem !important;
	}
	.mr-xl-n7 {
		margin-right: -8rem !important;
	}
	.mr-xl-n8 {
		margin-right: -10rem !important;
	}
	.mr-xl-n9 {
		margin-right: -11rem !important;
	}
	.mr-xl-n10 {
		margin-right: -14rem !important;
	}
	.mr-xl-n11 {
		margin-right: -16rem !important;
	}
	.mr-xl-n12 {
		margin-right: -20rem !important;
	}
	.mr-xl-nsm {
		margin-right: -1rem !important;
	}
	.mr-xl-nmd {
		margin-right: -2rem !important;
	}
	.mr-xl-nlg {
		margin-right: -4rem !important;
	}
	.mr-xl-nxl {
		margin-right: -8rem !important;
	}
	.mb-xl-n1 {
		margin-bottom: -0.25rem !important;
	}
	.mb-xl-n2 {
		margin-bottom: -0.5rem !important;
	}
	.mb-xl-n3 {
		margin-bottom: -1rem !important;
	}
	.mb-xl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.mb-xl-n5 {
		margin-bottom: -3rem !important;
	}
	.mb-xl-n6 {
		margin-bottom: -5rem !important;
	}
	.mb-xl-n7 {
		margin-bottom: -8rem !important;
	}
	.mb-xl-n8 {
		margin-bottom: -10rem !important;
	}
	.mb-xl-n9 {
		margin-bottom: -11rem !important;
	}
	.mb-xl-n10 {
		margin-bottom: -14rem !important;
	}
	.mb-xl-n11 {
		margin-bottom: -16rem !important;
	}
	.mb-xl-n12 {
		margin-bottom: -20rem !important;
	}
	.mb-xl-nsm {
		margin-bottom: -1rem !important;
	}
	.mb-xl-nmd {
		margin-bottom: -2rem !important;
	}
	.mb-xl-nlg {
		margin-bottom: -4rem !important;
	}
	.mb-xl-nxl {
		margin-bottom: -8rem !important;
	}
	.ml-xl-n1 {
		margin-left: -0.25rem !important;
	}
	.ml-xl-n2 {
		margin-left: -0.5rem !important;
	}
	.ml-xl-n3 {
		margin-left: -1rem !important;
	}
	.ml-xl-n4 {
		margin-left: -1.5rem !important;
	}
	.ml-xl-n5 {
		margin-left: -3rem !important;
	}
	.ml-xl-n6 {
		margin-left: -5rem !important;
	}
	.ml-xl-n7 {
		margin-left: -8rem !important;
	}
	.ml-xl-n8 {
		margin-left: -10rem !important;
	}
	.ml-xl-n9 {
		margin-left: -11rem !important;
	}
	.ml-xl-n10 {
		margin-left: -14rem !important;
	}
	.ml-xl-n11 {
		margin-left: -16rem !important;
	}
	.ml-xl-n12 {
		margin-left: -20rem !important;
	}
	.ml-xl-nsm {
		margin-left: -1rem !important;
	}
	.ml-xl-nmd {
		margin-left: -2rem !important;
	}
	.ml-xl-nlg {
		margin-left: -4rem !important;
	}
	.ml-xl-nxl {
		margin-left: -8rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.p-xl-6 {
		padding: 5rem !important;
	}
	.p-xl-7 {
		padding: 8rem !important;
	}
	.p-xl-8 {
		padding: 10rem !important;
	}
	.p-xl-9 {
		padding: 11rem !important;
	}
	.p-xl-10 {
		padding: 14rem !important;
	}
	.p-xl-11 {
		padding: 16rem !important;
	}
	.p-xl-12 {
		padding: 20rem !important;
	}
	.p-xl-sm {
		padding: 1rem !important;
	}
	.p-xl-md {
		padding: 2rem !important;
	}
	.p-xl-lg {
		padding: 4rem !important;
	}
	.p-xl-xl {
		padding: 8rem !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.px-xl-6 {
		padding-right: 5rem !important;
		padding-left: 5rem !important;
	}
	.px-xl-7 {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.px-xl-8 {
		padding-right: 10rem !important;
		padding-left: 10rem !important;
	}
	.px-xl-9 {
		padding-right: 11rem !important;
		padding-left: 11rem !important;
	}
	.px-xl-10 {
		padding-right: 14rem !important;
		padding-left: 14rem !important;
	}
	.px-xl-11 {
		padding-right: 16rem !important;
		padding-left: 16rem !important;
	}
	.px-xl-12 {
		padding-right: 20rem !important;
		padding-left: 20rem !important;
	}
	.px-xl-sm {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-md {
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}
	.px-xl-lg {
		padding-right: 4rem !important;
		padding-left: 4rem !important;
	}
	.px-xl-xl {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.py-xl-6 {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
	.py-xl-7 {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.py-xl-8 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.py-xl-9 {
		padding-top: 11rem !important;
		padding-bottom: 11rem !important;
	}
	.py-xl-10 {
		padding-top: 14rem !important;
		padding-bottom: 14rem !important;
	}
	.py-xl-11 {
		padding-top: 16rem !important;
		padding-bottom: 16rem !important;
	}
	.py-xl-12 {
		padding-top: 20rem !important;
		padding-bottom: 20rem !important;
	}
	.py-xl-sm {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-md {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
	.py-xl-lg {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
	.py-xl-xl {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pt-xl-6 {
		padding-top: 5rem !important;
	}
	.pt-xl-7 {
		padding-top: 8rem !important;
	}
	.pt-xl-8 {
		padding-top: 10rem !important;
	}
	.pt-xl-9 {
		padding-top: 11rem !important;
	}
	.pt-xl-10 {
		padding-top: 14rem !important;
	}
	.pt-xl-11 {
		padding-top: 16rem !important;
	}
	.pt-xl-12 {
		padding-top: 20rem !important;
	}
	.pt-xl-sm {
		padding-top: 1rem !important;
	}
	.pt-xl-md {
		padding-top: 2rem !important;
	}
	.pt-xl-lg {
		padding-top: 4rem !important;
	}
	.pt-xl-xl {
		padding-top: 8rem !important;
	}
	.pr-xl-0 {
		padding-right: 0 !important;
	}
	.pr-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pr-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pr-xl-3 {
		padding-right: 1rem !important;
	}
	.pr-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pr-xl-5 {
		padding-right: 3rem !important;
	}
	.pr-xl-6 {
		padding-right: 5rem !important;
	}
	.pr-xl-7 {
		padding-right: 8rem !important;
	}
	.pr-xl-8 {
		padding-right: 10rem !important;
	}
	.pr-xl-9 {
		padding-right: 11rem !important;
	}
	.pr-xl-10 {
		padding-right: 14rem !important;
	}
	.pr-xl-11 {
		padding-right: 16rem !important;
	}
	.pr-xl-12 {
		padding-right: 20rem !important;
	}
	.pr-xl-sm {
		padding-right: 1rem !important;
	}
	.pr-xl-md {
		padding-right: 2rem !important;
	}
	.pr-xl-lg {
		padding-right: 4rem !important;
	}
	.pr-xl-xl {
		padding-right: 8rem !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pb-xl-6 {
		padding-bottom: 5rem !important;
	}
	.pb-xl-7 {
		padding-bottom: 8rem !important;
	}
	.pb-xl-8 {
		padding-bottom: 10rem !important;
	}
	.pb-xl-9 {
		padding-bottom: 11rem !important;
	}
	.pb-xl-10 {
		padding-bottom: 14rem !important;
	}
	.pb-xl-11 {
		padding-bottom: 16rem !important;
	}
	.pb-xl-12 {
		padding-bottom: 20rem !important;
	}
	.pb-xl-sm {
		padding-bottom: 1rem !important;
	}
	.pb-xl-md {
		padding-bottom: 2rem !important;
	}
	.pb-xl-lg {
		padding-bottom: 4rem !important;
	}
	.pb-xl-xl {
		padding-bottom: 8rem !important;
	}
	.pl-xl-0 {
		padding-left: 0 !important;
	}
	.pl-xl-1 {
		padding-left: 0.25rem !important;
	}
	.pl-xl-2 {
		padding-left: 0.5rem !important;
	}
	.pl-xl-3 {
		padding-left: 1rem !important;
	}
	.pl-xl-4 {
		padding-left: 1.5rem !important;
	}
	.pl-xl-5 {
		padding-left: 3rem !important;
	}
	.pl-xl-6 {
		padding-left: 5rem !important;
	}
	.pl-xl-7 {
		padding-left: 8rem !important;
	}
	.pl-xl-8 {
		padding-left: 10rem !important;
	}
	.pl-xl-9 {
		padding-left: 11rem !important;
	}
	.pl-xl-10 {
		padding-left: 14rem !important;
	}
	.pl-xl-11 {
		padding-left: 16rem !important;
	}
	.pl-xl-12 {
		padding-left: 20rem !important;
	}
	.pl-xl-sm {
		padding-left: 1rem !important;
	}
	.pl-xl-md {
		padding-left: 2rem !important;
	}
	.pl-xl-lg {
		padding-left: 4rem !important;
	}
	.pl-xl-xl {
		padding-left: 8rem !important;
	}
	.text-xl-left {
		text-align: left !important;
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
		text-align: center !important;
	}
	.floating-xl {
		animation: floating-xl 3s ease infinite;
		will-change: transform;
		&:hover {
			animation-play-state: paused;
		}
	}
	.display-1-xl {
		font-size: 5rem;
	}
	.display-2-xl {
		font-size: 3.5rem;
	}
	.display-3-xl {
		font-size: 2.5rem;
	}
	.display-4-xl {
		font-size: 1.875rem;
	}
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
	.container-sm {
		max-width: 540px;
	}
	.col-sm {
		flex: 1 0 0%;
	}
	.row-cols-sm-auto {
		>* {
			flex: 0 0 auto;
			width: auto;
		}
	}
	.row-cols-sm-1 {
		>* {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	.row-cols-sm-2 {
		>* {
			flex: 0 0 auto;
			width: 50%;
		}
	}
	.row-cols-sm-3 {
		>* {
			flex: 0 0 auto;
			width: 33.33333%;
		}
	}
	.row-cols-sm-4 {
		>* {
			flex: 0 0 auto;
			width: 25%;
		}
	}
	.row-cols-sm-5 {
		>* {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.row-cols-sm-6 {
		>* {
			flex: 0 0 auto;
			width: 16.66667%;
		}
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.33333%;
	}
	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.66667%;
	}
	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.33333%;
	}
	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.66667%;
	}
	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.33333%;
	}
	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.66667%;
	}
	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.33333%;
	}
	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.66667%;
	}
	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.33333%;
	}
	.offset-sm-2 {
		margin-left: 16.66667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.33333%;
	}
	.offset-sm-5 {
		margin-left: 41.66667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.33333%;
	}
	.offset-sm-8 {
		margin-left: 66.66667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.33333%;
	}
	.offset-sm-11 {
		margin-left: 91.66667%;
	}
	.g-sm-0 {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.gx-sm-0 {
		--bs-gutter-x: 0;
	}
	.gy-sm-0 {
		--bs-gutter-y: 0;
	}
	.g-sm-1 {
		--bs-gutter-x: 0.25rem;
		--bs-gutter-y: 0.25rem;
	}
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem;
	}
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-sm-2 {
		--bs-gutter-x: 0.5rem;
		--bs-gutter-y: 0.5rem;
	}
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem;
	}
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-sm-3 {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-sm-3 {
		--bs-gutter-x: 1rem;
	}
	.gy-sm-3 {
		--bs-gutter-y: 1rem;
	}
	.g-sm-4 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 1.5rem;
	}
	.gx-sm-4 {
		--bs-gutter-x: 1.5rem;
	}
	.gy-sm-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-sm-5 {
		--bs-gutter-x: 3rem;
		--bs-gutter-y: 3rem;
	}
	.gx-sm-5 {
		--bs-gutter-x: 3rem;
	}
	.gy-sm-5 {
		--bs-gutter-y: 3rem;
	}
	.g-sm-6 {
		--bs-gutter-x: 5rem;
		--bs-gutter-y: 5rem;
	}
	.gx-sm-6 {
		--bs-gutter-x: 5rem;
	}
	.gy-sm-6 {
		--bs-gutter-y: 5rem;
	}
	.g-sm-7 {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-sm-7 {
		--bs-gutter-x: 8rem;
	}
	.gy-sm-7 {
		--bs-gutter-y: 8rem;
	}
	.g-sm-8 {
		--bs-gutter-x: 10rem;
		--bs-gutter-y: 10rem;
	}
	.gx-sm-8 {
		--bs-gutter-x: 10rem;
	}
	.gy-sm-8 {
		--bs-gutter-y: 10rem;
	}
	.g-sm-9 {
		--bs-gutter-x: 11rem;
		--bs-gutter-y: 11rem;
	}
	.gx-sm-9 {
		--bs-gutter-x: 11rem;
	}
	.gy-sm-9 {
		--bs-gutter-y: 11rem;
	}
	.g-sm-10 {
		--bs-gutter-x: 14rem;
		--bs-gutter-y: 14rem;
	}
	.gx-sm-10 {
		--bs-gutter-x: 14rem;
	}
	.gy-sm-10 {
		--bs-gutter-y: 14rem;
	}
	.g-sm-11 {
		--bs-gutter-x: 16rem;
		--bs-gutter-y: 16rem;
	}
	.gx-sm-11 {
		--bs-gutter-x: 16rem;
	}
	.gy-sm-11 {
		--bs-gutter-y: 16rem;
	}
	.g-sm-12 {
		--bs-gutter-x: 20rem;
		--bs-gutter-y: 20rem;
	}
	.gx-sm-12 {
		--bs-gutter-x: 20rem;
	}
	.gy-sm-12 {
		--bs-gutter-y: 20rem;
	}
	.g-sm-sm {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-sm-sm {
		--bs-gutter-x: 1rem;
	}
	.gy-sm-sm {
		--bs-gutter-y: 1rem;
	}
	.g-sm-md {
		--bs-gutter-x: 2rem;
		--bs-gutter-y: 2rem;
	}
	.gx-sm-md {
		--bs-gutter-x: 2rem;
	}
	.gy-sm-md {
		--bs-gutter-y: 2rem;
	}
	.g-sm-lg {
		--bs-gutter-x: 4rem;
		--bs-gutter-y: 4rem;
	}
	.gx-sm-lg {
		--bs-gutter-x: 4rem;
	}
	.gy-sm-lg {
		--bs-gutter-y: 4rem;
	}
	.g-sm-xl {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-sm-xl {
		--bs-gutter-x: 8rem;
	}
	.gy-sm-xl {
		--bs-gutter-y: 8rem;
	}
	.dropdown-menu-sm-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-sm-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-sm {
		flex-wrap: nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.8rem;
				padding-left: 0.8rem;
			}
		}
		.navbar-collapse {
			display: flex !important;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.card-group {
		display: flex;
		flex-flow: row wrap;
		>.card {
			flex: 1 0 0%;
			margin-bottom: 0;
			+ {
				.card {
					margin-left: 0;
					border-left: 0;
				}
			}
			&:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				.card-img-top {
					border-top-right-radius: 0;
				}
				.card-header {
					border-top-right-radius: 0;
				}
				.card-img-bottom {
					border-bottom-right-radius: 0;
				}
				.card-footer {
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				.card-img-top {
					border-top-left-radius: 0;
				}
				.card-header {
					border-top-left-radius: 0;
				}
				.card-img-bottom {
					border-bottom-left-radius: 0;
				}
				.card-footer {
					border-bottom-left-radius: 0;
				}
			}
		}
	}
	.list-group-horizontal-sm {
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 0.0625rem;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -0.0625rem;
					border-left-width: 0.0625rem;
				}
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
	}
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}
	.modal-content {
		box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
	}
	.modal-sm {
		max-width: 300px;
	}
	.sticky-sm-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
	.order-sm-first {
		order: -1 !important;
	}
	.order-sm-0 {
		order: 0 !important;
	}
	.order-sm-1 {
		order: 1 !important;
	}
	.order-sm-2 {
		order: 2 !important;
	}
	.order-sm-3 {
		order: 3 !important;
	}
	.order-sm-4 {
		order: 4 !important;
	}
	.order-sm-5 {
		order: 5 !important;
	}
	.order-sm-last {
		order: 6 !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.m-sm-6 {
		margin: 5rem !important;
	}
	.m-sm-7 {
		margin: 8rem !important;
	}
	.m-sm-8 {
		margin: 10rem !important;
	}
	.m-sm-9 {
		margin: 11rem !important;
	}
	.m-sm-10 {
		margin: 14rem !important;
	}
	.m-sm-11 {
		margin: 16rem !important;
	}
	.m-sm-12 {
		margin: 20rem !important;
	}
	.m-sm-sm {
		margin: 1rem !important;
	}
	.m-sm-md {
		margin: 2rem !important;
	}
	.m-sm-lg {
		margin: 4rem !important;
	}
	.m-sm-xl {
		margin: 8rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-sm-6 {
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}
	.mx-sm-7 {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-sm-8 {
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.mx-sm-9 {
		margin-right: 11rem !important;
		margin-left: 11rem !important;
	}
	.mx-sm-10 {
		margin-right: 14rem !important;
		margin-left: 14rem !important;
	}
	.mx-sm-11 {
		margin-right: 16rem !important;
		margin-left: 16rem !important;
	}
	.mx-sm-12 {
		margin-right: 20rem !important;
		margin-left: 20rem !important;
	}
	.mx-sm-sm {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-md {
		margin-right: 2rem !important;
		margin-left: 2rem !important;
	}
	.mx-sm-lg {
		margin-right: 4rem !important;
		margin-left: 4rem !important;
	}
	.mx-sm-xl {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-sm-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.my-sm-7 {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-sm-8 {
		margin-top: 10rem !important;
		margin-bottom: 10rem !important;
	}
	.my-sm-9 {
		margin-top: 11rem !important;
		margin-bottom: 11rem !important;
	}
	.my-sm-10 {
		margin-top: 14rem !important;
		margin-bottom: 14rem !important;
	}
	.my-sm-11 {
		margin-top: 16rem !important;
		margin-bottom: 16rem !important;
	}
	.my-sm-12 {
		margin-top: 20rem !important;
		margin-bottom: 20rem !important;
	}
	.my-sm-sm {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-md {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.my-sm-lg {
		margin-top: 4rem !important;
		margin-bottom: 4rem !important;
	}
	.my-sm-xl {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mt-sm-6 {
		margin-top: 5rem !important;
	}
	.mt-sm-7 {
		margin-top: 8rem !important;
	}
	.mt-sm-8 {
		margin-top: 10rem !important;
	}
	.mt-sm-9 {
		margin-top: 11rem !important;
	}
	.mt-sm-10 {
		margin-top: 14rem !important;
	}
	.mt-sm-11 {
		margin-top: 16rem !important;
	}
	.mt-sm-12 {
		margin-top: 20rem !important;
	}
	.mt-sm-sm {
		margin-top: 1rem !important;
	}
	.mt-sm-md {
		margin-top: 2rem !important;
	}
	.mt-sm-lg {
		margin-top: 4rem !important;
	}
	.mt-sm-xl {
		margin-top: 8rem !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.mr-sm-0 {
		margin-right: 0 !important;
	}
	.mr-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mr-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mr-sm-3 {
		margin-right: 1rem !important;
	}
	.mr-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mr-sm-5 {
		margin-right: 3rem !important;
	}
	.mr-sm-6 {
		margin-right: 5rem !important;
	}
	.mr-sm-7 {
		margin-right: 8rem !important;
	}
	.mr-sm-8 {
		margin-right: 10rem !important;
	}
	.mr-sm-9 {
		margin-right: 11rem !important;
	}
	.mr-sm-10 {
		margin-right: 14rem !important;
	}
	.mr-sm-11 {
		margin-right: 16rem !important;
	}
	.mr-sm-12 {
		margin-right: 20rem !important;
	}
	.mr-sm-sm {
		margin-right: 1rem !important;
	}
	.mr-sm-md {
		margin-right: 2rem !important;
	}
	.mr-sm-lg {
		margin-right: 4rem !important;
	}
	.mr-sm-xl {
		margin-right: 8rem !important;
	}
	.mr-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.mb-sm-6 {
		margin-bottom: 5rem !important;
	}
	.mb-sm-7 {
		margin-bottom: 8rem !important;
	}
	.mb-sm-8 {
		margin-bottom: 10rem !important;
	}
	.mb-sm-9 {
		margin-bottom: 11rem !important;
	}
	.mb-sm-10 {
		margin-bottom: 14rem !important;
	}
	.mb-sm-11 {
		margin-bottom: 16rem !important;
	}
	.mb-sm-12 {
		margin-bottom: 20rem !important;
	}
	.mb-sm-sm {
		margin-bottom: 1rem !important;
	}
	.mb-sm-md {
		margin-bottom: 2rem !important;
	}
	.mb-sm-lg {
		margin-bottom: 4rem !important;
	}
	.mb-sm-xl {
		margin-bottom: 8rem !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-0 {
		margin-left: 0 !important;
	}
	.ml-sm-1 {
		margin-left: 0.25rem !important;
	}
	.ml-sm-2 {
		margin-left: 0.5rem !important;
	}
	.ml-sm-3 {
		margin-left: 1rem !important;
	}
	.ml-sm-4 {
		margin-left: 1.5rem !important;
	}
	.ml-sm-5 {
		margin-left: 3rem !important;
	}
	.ml-sm-6 {
		margin-left: 5rem !important;
	}
	.ml-sm-7 {
		margin-left: 8rem !important;
	}
	.ml-sm-8 {
		margin-left: 10rem !important;
	}
	.ml-sm-9 {
		margin-left: 11rem !important;
	}
	.ml-sm-10 {
		margin-left: 14rem !important;
	}
	.ml-sm-11 {
		margin-left: 16rem !important;
	}
	.ml-sm-12 {
		margin-left: 20rem !important;
	}
	.ml-sm-sm {
		margin-left: 1rem !important;
	}
	.ml-sm-md {
		margin-left: 2rem !important;
	}
	.ml-sm-lg {
		margin-left: 4rem !important;
	}
	.ml-sm-xl {
		margin-left: 8rem !important;
	}
	.ml-sm-auto {
		margin-left: auto !important;
	}
	.m-sm-n1 {
		margin: -0.25rem !important;
	}
	.m-sm-n2 {
		margin: -0.5rem !important;
	}
	.m-sm-n3 {
		margin: -1rem !important;
	}
	.m-sm-n4 {
		margin: -1.5rem !important;
	}
	.m-sm-n5 {
		margin: -3rem !important;
	}
	.m-sm-n6 {
		margin: -5rem !important;
	}
	.m-sm-n7 {
		margin: -8rem !important;
	}
	.m-sm-n8 {
		margin: -10rem !important;
	}
	.m-sm-n9 {
		margin: -11rem !important;
	}
	.m-sm-n10 {
		margin: -14rem !important;
	}
	.m-sm-n11 {
		margin: -16rem !important;
	}
	.m-sm-n12 {
		margin: -20rem !important;
	}
	.m-sm-nsm {
		margin: -1rem !important;
	}
	.m-sm-nmd {
		margin: -2rem !important;
	}
	.m-sm-nlg {
		margin: -4rem !important;
	}
	.m-sm-nxl {
		margin: -8rem !important;
	}
	.mx-sm-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mx-sm-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mx-sm-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-sm-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mx-sm-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mx-sm-n6 {
		margin-right: -5rem !important;
		margin-left: -5rem !important;
	}
	.mx-sm-n7 {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.mx-sm-n8 {
		margin-right: -10rem !important;
		margin-left: -10rem !important;
	}
	.mx-sm-n9 {
		margin-right: -11rem !important;
		margin-left: -11rem !important;
	}
	.mx-sm-n10 {
		margin-right: -14rem !important;
		margin-left: -14rem !important;
	}
	.mx-sm-n11 {
		margin-right: -16rem !important;
		margin-left: -16rem !important;
	}
	.mx-sm-n12 {
		margin-right: -20rem !important;
		margin-left: -20rem !important;
	}
	.mx-sm-nsm {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-sm-nmd {
		margin-right: -2rem !important;
		margin-left: -2rem !important;
	}
	.mx-sm-nlg {
		margin-right: -4rem !important;
		margin-left: -4rem !important;
	}
	.mx-sm-nxl {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.my-sm-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.my-sm-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.my-sm-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-sm-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.my-sm-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.my-sm-n6 {
		margin-top: -5rem !important;
		margin-bottom: -5rem !important;
	}
	.my-sm-n7 {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.my-sm-n8 {
		margin-top: -10rem !important;
		margin-bottom: -10rem !important;
	}
	.my-sm-n9 {
		margin-top: -11rem !important;
		margin-bottom: -11rem !important;
	}
	.my-sm-n10 {
		margin-top: -14rem !important;
		margin-bottom: -14rem !important;
	}
	.my-sm-n11 {
		margin-top: -16rem !important;
		margin-bottom: -16rem !important;
	}
	.my-sm-n12 {
		margin-top: -20rem !important;
		margin-bottom: -20rem !important;
	}
	.my-sm-nsm {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-sm-nmd {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.my-sm-nlg {
		margin-top: -4rem !important;
		margin-bottom: -4rem !important;
	}
	.my-sm-nxl {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.mt-sm-n1 {
		margin-top: -0.25rem !important;
	}
	.mt-sm-n2 {
		margin-top: -0.5rem !important;
	}
	.mt-sm-n3 {
		margin-top: -1rem !important;
	}
	.mt-sm-n4 {
		margin-top: -1.5rem !important;
	}
	.mt-sm-n5 {
		margin-top: -3rem !important;
	}
	.mt-sm-n6 {
		margin-top: -5rem !important;
	}
	.mt-sm-n7 {
		margin-top: -8rem !important;
	}
	.mt-sm-n8 {
		margin-top: -10rem !important;
	}
	.mt-sm-n9 {
		margin-top: -11rem !important;
	}
	.mt-sm-n10 {
		margin-top: -14rem !important;
	}
	.mt-sm-n11 {
		margin-top: -16rem !important;
	}
	.mt-sm-n12 {
		margin-top: -20rem !important;
	}
	.mt-sm-nsm {
		margin-top: -1rem !important;
	}
	.mt-sm-nmd {
		margin-top: -2rem !important;
	}
	.mt-sm-nlg {
		margin-top: -4rem !important;
	}
	.mt-sm-nxl {
		margin-top: -8rem !important;
	}
	.mr-sm-n1 {
		margin-right: -0.25rem !important;
	}
	.mr-sm-n2 {
		margin-right: -0.5rem !important;
	}
	.mr-sm-n3 {
		margin-right: -1rem !important;
	}
	.mr-sm-n4 {
		margin-right: -1.5rem !important;
	}
	.mr-sm-n5 {
		margin-right: -3rem !important;
	}
	.mr-sm-n6 {
		margin-right: -5rem !important;
	}
	.mr-sm-n7 {
		margin-right: -8rem !important;
	}
	.mr-sm-n8 {
		margin-right: -10rem !important;
	}
	.mr-sm-n9 {
		margin-right: -11rem !important;
	}
	.mr-sm-n10 {
		margin-right: -14rem !important;
	}
	.mr-sm-n11 {
		margin-right: -16rem !important;
	}
	.mr-sm-n12 {
		margin-right: -20rem !important;
	}
	.mr-sm-nsm {
		margin-right: -1rem !important;
	}
	.mr-sm-nmd {
		margin-right: -2rem !important;
	}
	.mr-sm-nlg {
		margin-right: -4rem !important;
	}
	.mr-sm-nxl {
		margin-right: -8rem !important;
	}
	.mb-sm-n1 {
		margin-bottom: -0.25rem !important;
	}
	.mb-sm-n2 {
		margin-bottom: -0.5rem !important;
	}
	.mb-sm-n3 {
		margin-bottom: -1rem !important;
	}
	.mb-sm-n4 {
		margin-bottom: -1.5rem !important;
	}
	.mb-sm-n5 {
		margin-bottom: -3rem !important;
	}
	.mb-sm-n6 {
		margin-bottom: -5rem !important;
	}
	.mb-sm-n7 {
		margin-bottom: -8rem !important;
	}
	.mb-sm-n8 {
		margin-bottom: -10rem !important;
	}
	.mb-sm-n9 {
		margin-bottom: -11rem !important;
	}
	.mb-sm-n10 {
		margin-bottom: -14rem !important;
	}
	.mb-sm-n11 {
		margin-bottom: -16rem !important;
	}
	.mb-sm-n12 {
		margin-bottom: -20rem !important;
	}
	.mb-sm-nsm {
		margin-bottom: -1rem !important;
	}
	.mb-sm-nmd {
		margin-bottom: -2rem !important;
	}
	.mb-sm-nlg {
		margin-bottom: -4rem !important;
	}
	.mb-sm-nxl {
		margin-bottom: -8rem !important;
	}
	.ml-sm-n1 {
		margin-left: -0.25rem !important;
	}
	.ml-sm-n2 {
		margin-left: -0.5rem !important;
	}
	.ml-sm-n3 {
		margin-left: -1rem !important;
	}
	.ml-sm-n4 {
		margin-left: -1.5rem !important;
	}
	.ml-sm-n5 {
		margin-left: -3rem !important;
	}
	.ml-sm-n6 {
		margin-left: -5rem !important;
	}
	.ml-sm-n7 {
		margin-left: -8rem !important;
	}
	.ml-sm-n8 {
		margin-left: -10rem !important;
	}
	.ml-sm-n9 {
		margin-left: -11rem !important;
	}
	.ml-sm-n10 {
		margin-left: -14rem !important;
	}
	.ml-sm-n11 {
		margin-left: -16rem !important;
	}
	.ml-sm-n12 {
		margin-left: -20rem !important;
	}
	.ml-sm-nsm {
		margin-left: -1rem !important;
	}
	.ml-sm-nmd {
		margin-left: -2rem !important;
	}
	.ml-sm-nlg {
		margin-left: -4rem !important;
	}
	.ml-sm-nxl {
		margin-left: -8rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.p-sm-6 {
		padding: 5rem !important;
	}
	.p-sm-7 {
		padding: 8rem !important;
	}
	.p-sm-8 {
		padding: 10rem !important;
	}
	.p-sm-9 {
		padding: 11rem !important;
	}
	.p-sm-10 {
		padding: 14rem !important;
	}
	.p-sm-11 {
		padding: 16rem !important;
	}
	.p-sm-12 {
		padding: 20rem !important;
	}
	.p-sm-sm {
		padding: 1rem !important;
	}
	.p-sm-md {
		padding: 2rem !important;
	}
	.p-sm-lg {
		padding: 4rem !important;
	}
	.p-sm-xl {
		padding: 8rem !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.px-sm-6 {
		padding-right: 5rem !important;
		padding-left: 5rem !important;
	}
	.px-sm-7 {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.px-sm-8 {
		padding-right: 10rem !important;
		padding-left: 10rem !important;
	}
	.px-sm-9 {
		padding-right: 11rem !important;
		padding-left: 11rem !important;
	}
	.px-sm-10 {
		padding-right: 14rem !important;
		padding-left: 14rem !important;
	}
	.px-sm-11 {
		padding-right: 16rem !important;
		padding-left: 16rem !important;
	}
	.px-sm-12 {
		padding-right: 20rem !important;
		padding-left: 20rem !important;
	}
	.px-sm-sm {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-md {
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}
	.px-sm-lg {
		padding-right: 4rem !important;
		padding-left: 4rem !important;
	}
	.px-sm-xl {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.py-sm-6 {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
	.py-sm-7 {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.py-sm-8 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.py-sm-9 {
		padding-top: 11rem !important;
		padding-bottom: 11rem !important;
	}
	.py-sm-10 {
		padding-top: 14rem !important;
		padding-bottom: 14rem !important;
	}
	.py-sm-11 {
		padding-top: 16rem !important;
		padding-bottom: 16rem !important;
	}
	.py-sm-12 {
		padding-top: 20rem !important;
		padding-bottom: 20rem !important;
	}
	.py-sm-sm {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-md {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
	.py-sm-lg {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
	.py-sm-xl {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pt-sm-6 {
		padding-top: 5rem !important;
	}
	.pt-sm-7 {
		padding-top: 8rem !important;
	}
	.pt-sm-8 {
		padding-top: 10rem !important;
	}
	.pt-sm-9 {
		padding-top: 11rem !important;
	}
	.pt-sm-10 {
		padding-top: 14rem !important;
	}
	.pt-sm-11 {
		padding-top: 16rem !important;
	}
	.pt-sm-12 {
		padding-top: 20rem !important;
	}
	.pt-sm-sm {
		padding-top: 1rem !important;
	}
	.pt-sm-md {
		padding-top: 2rem !important;
	}
	.pt-sm-lg {
		padding-top: 4rem !important;
	}
	.pt-sm-xl {
		padding-top: 8rem !important;
	}
	.pr-sm-0 {
		padding-right: 0 !important;
	}
	.pr-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pr-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pr-sm-3 {
		padding-right: 1rem !important;
	}
	.pr-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pr-sm-5 {
		padding-right: 3rem !important;
	}
	.pr-sm-6 {
		padding-right: 5rem !important;
	}
	.pr-sm-7 {
		padding-right: 8rem !important;
	}
	.pr-sm-8 {
		padding-right: 10rem !important;
	}
	.pr-sm-9 {
		padding-right: 11rem !important;
	}
	.pr-sm-10 {
		padding-right: 14rem !important;
	}
	.pr-sm-11 {
		padding-right: 16rem !important;
	}
	.pr-sm-12 {
		padding-right: 20rem !important;
	}
	.pr-sm-sm {
		padding-right: 1rem !important;
	}
	.pr-sm-md {
		padding-right: 2rem !important;
	}
	.pr-sm-lg {
		padding-right: 4rem !important;
	}
	.pr-sm-xl {
		padding-right: 8rem !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pb-sm-6 {
		padding-bottom: 5rem !important;
	}
	.pb-sm-7 {
		padding-bottom: 8rem !important;
	}
	.pb-sm-8 {
		padding-bottom: 10rem !important;
	}
	.pb-sm-9 {
		padding-bottom: 11rem !important;
	}
	.pb-sm-10 {
		padding-bottom: 14rem !important;
	}
	.pb-sm-11 {
		padding-bottom: 16rem !important;
	}
	.pb-sm-12 {
		padding-bottom: 20rem !important;
	}
	.pb-sm-sm {
		padding-bottom: 1rem !important;
	}
	.pb-sm-md {
		padding-bottom: 2rem !important;
	}
	.pb-sm-lg {
		padding-bottom: 4rem !important;
	}
	.pb-sm-xl {
		padding-bottom: 8rem !important;
	}
	.pl-sm-0 {
		padding-left: 0 !important;
	}
	.pl-sm-1 {
		padding-left: 0.25rem !important;
	}
	.pl-sm-2 {
		padding-left: 0.5rem !important;
	}
	.pl-sm-3 {
		padding-left: 1rem !important;
	}
	.pl-sm-4 {
		padding-left: 1.5rem !important;
	}
	.pl-sm-5 {
		padding-left: 3rem !important;
	}
	.pl-sm-6 {
		padding-left: 5rem !important;
	}
	.pl-sm-7 {
		padding-left: 8rem !important;
	}
	.pl-sm-8 {
		padding-left: 10rem !important;
	}
	.pl-sm-9 {
		padding-left: 11rem !important;
	}
	.pl-sm-10 {
		padding-left: 14rem !important;
	}
	.pl-sm-11 {
		padding-left: 16rem !important;
	}
	.pl-sm-12 {
		padding-left: 20rem !important;
	}
	.pl-sm-sm {
		padding-left: 1rem !important;
	}
	.pl-sm-md {
		padding-left: 2rem !important;
	}
	.pl-sm-lg {
		padding-left: 4rem !important;
	}
	.pl-sm-xl {
		padding-left: 8rem !important;
	}
	.text-sm-left {
		text-align: left !important;
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
		text-align: center !important;
	}
	.floating-sm {
		animation: floating-sm 3s ease infinite;
		will-change: transform;
		&:hover {
			animation-play-state: paused;
		}
	}
	.display-1-sm {
		font-size: 5rem;
	}
	.display-2-sm {
		font-size: 3.5rem;
	}
	.display-3-sm {
		font-size: 2.5rem;
	}
	.display-4-sm {
		font-size: 1.875rem;
	}
	.section {
		position: relative;
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	.section-header {
		position: relative;
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	.section-header.section-sm {
		padding-top: 4rem;
		padding-bottom: 3rem;
	}
	.section-xl {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	.section-lg {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	.section-sm {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	.breadcrumb-item {
		font-size: 0.875rem;
		a {
			font-size: 0.875rem;
		}
	}
	.effect-img-2 {
		position: absolute;
		right: 5rem;
		top: 19%;
		z-index: 2;
		margin: 0;
		margin: 0 0 3rem;
		width: 350px;
		height: auto;
	}
	.effect-img-1 {
		margin: 0 0 3rem;
		width: 350px;
		height: auto;
	}
	.pixel-tab {
		.nav-pills {
			.nav-link {
				border: 0.0625rem solid #f5f8fb;
			}
		}
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
	.container-sm {
		max-width: 720px;
	}
	.container-md {
		max-width: 720px;
	}
	.col-md {
		flex: 1 0 0%;
	}
	.row-cols-md-auto {
		>* {
			flex: 0 0 auto;
			width: auto;
		}
	}
	.row-cols-md-1 {
		>* {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	.row-cols-md-2 {
		>* {
			flex: 0 0 auto;
			width: 50%;
		}
	}
	.row-cols-md-3 {
		>* {
			flex: 0 0 auto;
			width: 33.33333%;
		}
	}
	.row-cols-md-4 {
		>* {
			flex: 0 0 auto;
			width: 25%;
		}
	}
	.row-cols-md-5 {
		>* {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.row-cols-md-6 {
		>* {
			flex: 0 0 auto;
			width: 16.66667%;
		}
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-md-1 {
		flex: 0 0 auto;
		width: 8.33333%;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 16.66667%;
	}
	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-md-4 {
		flex: 0 0 auto;
		width: 33.33333%;
	}
	.col-md-5 {
		flex: 0 0 auto;
		width: 41.66667%;
	}
	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-md-7 {
		flex: 0 0 auto;
		width: 58.33333%;
	}
	.col-md-8 {
		flex: 0 0 auto;
		width: 66.66667%;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-md-10 {
		flex: 0 0 auto;
		width: 83.33333%;
	}
	.col-md-11 {
		flex: 0 0 auto;
		width: 91.66667%;
	}
	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.33333%;
	}
	.offset-md-2 {
		margin-left: 16.66667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.33333%;
	}
	.offset-md-5 {
		margin-left: 41.66667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.33333%;
	}
	.offset-md-8 {
		margin-left: 66.66667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.33333%;
	}
	.offset-md-11 {
		margin-left: 91.66667%;
	}
	.g-md-0 {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.gx-md-0 {
		--bs-gutter-x: 0;
	}
	.gy-md-0 {
		--bs-gutter-y: 0;
	}
	.g-md-1 {
		--bs-gutter-x: 0.25rem;
		--bs-gutter-y: 0.25rem;
	}
	.gx-md-1 {
		--bs-gutter-x: 0.25rem;
	}
	.gy-md-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-md-2 {
		--bs-gutter-x: 0.5rem;
		--bs-gutter-y: 0.5rem;
	}
	.gx-md-2 {
		--bs-gutter-x: 0.5rem;
	}
	.gy-md-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-md-3 {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-md-3 {
		--bs-gutter-x: 1rem;
	}
	.gy-md-3 {
		--bs-gutter-y: 1rem;
	}
	.g-md-4 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 1.5rem;
	}
	.gx-md-4 {
		--bs-gutter-x: 1.5rem;
	}
	.gy-md-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-md-5 {
		--bs-gutter-x: 3rem;
		--bs-gutter-y: 3rem;
	}
	.gx-md-5 {
		--bs-gutter-x: 3rem;
	}
	.gy-md-5 {
		--bs-gutter-y: 3rem;
	}
	.g-md-6 {
		--bs-gutter-x: 5rem;
		--bs-gutter-y: 5rem;
	}
	.gx-md-6 {
		--bs-gutter-x: 5rem;
	}
	.gy-md-6 {
		--bs-gutter-y: 5rem;
	}
	.g-md-7 {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-md-7 {
		--bs-gutter-x: 8rem;
	}
	.gy-md-7 {
		--bs-gutter-y: 8rem;
	}
	.g-md-8 {
		--bs-gutter-x: 10rem;
		--bs-gutter-y: 10rem;
	}
	.gx-md-8 {
		--bs-gutter-x: 10rem;
	}
	.gy-md-8 {
		--bs-gutter-y: 10rem;
	}
	.g-md-9 {
		--bs-gutter-x: 11rem;
		--bs-gutter-y: 11rem;
	}
	.gx-md-9 {
		--bs-gutter-x: 11rem;
	}
	.gy-md-9 {
		--bs-gutter-y: 11rem;
	}
	.g-md-10 {
		--bs-gutter-x: 14rem;
		--bs-gutter-y: 14rem;
	}
	.gx-md-10 {
		--bs-gutter-x: 14rem;
	}
	.gy-md-10 {
		--bs-gutter-y: 14rem;
	}
	.g-md-11 {
		--bs-gutter-x: 16rem;
		--bs-gutter-y: 16rem;
	}
	.gx-md-11 {
		--bs-gutter-x: 16rem;
	}
	.gy-md-11 {
		--bs-gutter-y: 16rem;
	}
	.g-md-12 {
		--bs-gutter-x: 20rem;
		--bs-gutter-y: 20rem;
	}
	.gx-md-12 {
		--bs-gutter-x: 20rem;
	}
	.gy-md-12 {
		--bs-gutter-y: 20rem;
	}
	.g-md-sm {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-md-sm {
		--bs-gutter-x: 1rem;
	}
	.gy-md-sm {
		--bs-gutter-y: 1rem;
	}
	.g-md-md {
		--bs-gutter-x: 2rem;
		--bs-gutter-y: 2rem;
	}
	.gx-md-md {
		--bs-gutter-x: 2rem;
	}
	.gy-md-md {
		--bs-gutter-y: 2rem;
	}
	.g-md-lg {
		--bs-gutter-x: 4rem;
		--bs-gutter-y: 4rem;
	}
	.gx-md-lg {
		--bs-gutter-x: 4rem;
	}
	.gy-md-lg {
		--bs-gutter-y: 4rem;
	}
	.g-md-xl {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-md-xl {
		--bs-gutter-x: 8rem;
	}
	.gy-md-xl {
		--bs-gutter-y: 8rem;
	}
	.dropdown-menu-md-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-md {
		flex-wrap: nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.8rem;
				padding-left: 0.8rem;
			}
		}
		.navbar-collapse {
			display: flex !important;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-md {
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 0.0625rem;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -0.0625rem;
					border-left-width: 0.0625rem;
				}
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
	}
	.sticky-md-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
	.order-md-first {
		order: -1 !important;
	}
	.order-md-0 {
		order: 0 !important;
	}
	.order-md-1 {
		order: 1 !important;
	}
	.order-md-2 {
		order: 2 !important;
	}
	.order-md-3 {
		order: 3 !important;
	}
	.order-md-4 {
		order: 4 !important;
	}
	.order-md-5 {
		order: 5 !important;
	}
	.order-md-last {
		order: 6 !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.m-md-6 {
		margin: 5rem !important;
	}
	.m-md-7 {
		margin: 8rem !important;
	}
	.m-md-8 {
		margin: 10rem !important;
	}
	.m-md-9 {
		margin: 11rem !important;
	}
	.m-md-10 {
		margin: 14rem !important;
	}
	.m-md-11 {
		margin: 16rem !important;
	}
	.m-md-12 {
		margin: 20rem !important;
	}
	.m-md-sm {
		margin: 1rem !important;
	}
	.m-md-md {
		margin: 2rem !important;
	}
	.m-md-lg {
		margin: 4rem !important;
	}
	.m-md-xl {
		margin: 8rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-md-6 {
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}
	.mx-md-7 {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-md-8 {
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.mx-md-9 {
		margin-right: 11rem !important;
		margin-left: 11rem !important;
	}
	.mx-md-10 {
		margin-right: 14rem !important;
		margin-left: 14rem !important;
	}
	.mx-md-11 {
		margin-right: 16rem !important;
		margin-left: 16rem !important;
	}
	.mx-md-12 {
		margin-right: 20rem !important;
		margin-left: 20rem !important;
	}
	.mx-md-sm {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-md {
		margin-right: 2rem !important;
		margin-left: 2rem !important;
	}
	.mx-md-lg {
		margin-right: 4rem !important;
		margin-left: 4rem !important;
	}
	.mx-md-xl {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-md-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.my-md-7 {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-md-8 {
		margin-top: 10rem !important;
		margin-bottom: 10rem !important;
	}
	.my-md-9 {
		margin-top: 11rem !important;
		margin-bottom: 11rem !important;
	}
	.my-md-10 {
		margin-top: 14rem !important;
		margin-bottom: 14rem !important;
	}
	.my-md-11 {
		margin-top: 16rem !important;
		margin-bottom: 16rem !important;
	}
	.my-md-12 {
		margin-top: 20rem !important;
		margin-bottom: 20rem !important;
	}
	.my-md-sm {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-md {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.my-md-lg {
		margin-top: 4rem !important;
		margin-bottom: 4rem !important;
	}
	.my-md-xl {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mt-md-6 {
		margin-top: 5rem !important;
	}
	.mt-md-7 {
		margin-top: 8rem !important;
	}
	.mt-md-8 {
		margin-top: 10rem !important;
	}
	.mt-md-9 {
		margin-top: 11rem !important;
	}
	.mt-md-10 {
		margin-top: 14rem !important;
	}
	.mt-md-11 {
		margin-top: 16rem !important;
	}
	.mt-md-12 {
		margin-top: 20rem !important;
	}
	.mt-md-sm {
		margin-top: 1rem !important;
	}
	.mt-md-md {
		margin-top: 2rem !important;
	}
	.mt-md-lg {
		margin-top: 4rem !important;
	}
	.mt-md-xl {
		margin-top: 8rem !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.mr-md-0 {
		margin-right: 0 !important;
	}
	.mr-md-1 {
		margin-right: 0.25rem !important;
	}
	.mr-md-2 {
		margin-right: 0.5rem !important;
	}
	.mr-md-3 {
		margin-right: 1rem !important;
	}
	.mr-md-4 {
		margin-right: 1.5rem !important;
	}
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.mr-md-6 {
		margin-right: 5rem !important;
	}
	.mr-md-7 {
		margin-right: 8rem !important;
	}
	.mr-md-8 {
		margin-right: 10rem !important;
	}
	.mr-md-9 {
		margin-right: 11rem !important;
	}
	.mr-md-10 {
		margin-right: 14rem !important;
	}
	.mr-md-11 {
		margin-right: 16rem !important;
	}
	.mr-md-12 {
		margin-right: 20rem !important;
	}
	.mr-md-sm {
		margin-right: 1rem !important;
	}
	.mr-md-md {
		margin-right: 2rem !important;
	}
	.mr-md-lg {
		margin-right: 4rem !important;
	}
	.mr-md-xl {
		margin-right: 8rem !important;
	}
	.mr-md-auto {
		margin-right: auto !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.mb-md-6 {
		margin-bottom: 5rem !important;
	}
	.mb-md-7 {
		margin-bottom: 8rem !important;
	}
	.mb-md-8 {
		margin-bottom: 10rem !important;
	}
	.mb-md-9 {
		margin-bottom: 11rem !important;
	}
	.mb-md-10 {
		margin-bottom: 14rem !important;
	}
	.mb-md-11 {
		margin-bottom: 16rem !important;
	}
	.mb-md-12 {
		margin-bottom: 20rem !important;
	}
	.mb-md-sm {
		margin-bottom: 1rem !important;
	}
	.mb-md-md {
		margin-bottom: 2rem !important;
	}
	.mb-md-lg {
		margin-bottom: 4rem !important;
	}
	.mb-md-xl {
		margin-bottom: 8rem !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-0 {
		margin-left: 0 !important;
	}
	.ml-md-1 {
		margin-left: 0.25rem !important;
	}
	.ml-md-2 {
		margin-left: 0.5rem !important;
	}
	.ml-md-3 {
		margin-left: 1rem !important;
	}
	.ml-md-4 {
		margin-left: 1.5rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.ml-md-6 {
		margin-left: 5rem !important;
	}
	.ml-md-7 {
		margin-left: 8rem !important;
	}
	.ml-md-8 {
		margin-left: 10rem !important;
	}
	.ml-md-9 {
		margin-left: 11rem !important;
	}
	.ml-md-10 {
		margin-left: 14rem !important;
	}
	.ml-md-11 {
		margin-left: 16rem !important;
	}
	.ml-md-12 {
		margin-left: 20rem !important;
	}
	.ml-md-sm {
		margin-left: 1rem !important;
	}
	.ml-md-md {
		margin-left: 2rem !important;
	}
	.ml-md-lg {
		margin-left: 4rem !important;
	}
	.ml-md-xl {
		margin-left: 8rem !important;
	}
	.ml-md-auto {
		margin-left: auto !important;
	}
	.m-md-n1 {
		margin: -0.25rem !important;
	}
	.m-md-n2 {
		margin: -0.5rem !important;
	}
	.m-md-n3 {
		margin: -1rem !important;
	}
	.m-md-n4 {
		margin: -1.5rem !important;
	}
	.m-md-n5 {
		margin: -3rem !important;
	}
	.m-md-n6 {
		margin: -5rem !important;
	}
	.m-md-n7 {
		margin: -8rem !important;
	}
	.m-md-n8 {
		margin: -10rem !important;
	}
	.m-md-n9 {
		margin: -11rem !important;
	}
	.m-md-n10 {
		margin: -14rem !important;
	}
	.m-md-n11 {
		margin: -16rem !important;
	}
	.m-md-n12 {
		margin: -20rem !important;
	}
	.m-md-nsm {
		margin: -1rem !important;
	}
	.m-md-nmd {
		margin: -2rem !important;
	}
	.m-md-nlg {
		margin: -4rem !important;
	}
	.m-md-nxl {
		margin: -8rem !important;
	}
	.mx-md-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mx-md-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mx-md-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-md-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mx-md-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mx-md-n6 {
		margin-right: -5rem !important;
		margin-left: -5rem !important;
	}
	.mx-md-n7 {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.mx-md-n8 {
		margin-right: -10rem !important;
		margin-left: -10rem !important;
	}
	.mx-md-n9 {
		margin-right: -11rem !important;
		margin-left: -11rem !important;
	}
	.mx-md-n10 {
		margin-right: -14rem !important;
		margin-left: -14rem !important;
	}
	.mx-md-n11 {
		margin-right: -16rem !important;
		margin-left: -16rem !important;
	}
	.mx-md-n12 {
		margin-right: -20rem !important;
		margin-left: -20rem !important;
	}
	.mx-md-nsm {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-md-nmd {
		margin-right: -2rem !important;
		margin-left: -2rem !important;
	}
	.mx-md-nlg {
		margin-right: -4rem !important;
		margin-left: -4rem !important;
	}
	.mx-md-nxl {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.my-md-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.my-md-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.my-md-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-md-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.my-md-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.my-md-n6 {
		margin-top: -5rem !important;
		margin-bottom: -5rem !important;
	}
	.my-md-n7 {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.my-md-n8 {
		margin-top: -10rem !important;
		margin-bottom: -10rem !important;
	}
	.my-md-n9 {
		margin-top: -11rem !important;
		margin-bottom: -11rem !important;
	}
	.my-md-n10 {
		margin-top: -14rem !important;
		margin-bottom: -14rem !important;
	}
	.my-md-n11 {
		margin-top: -16rem !important;
		margin-bottom: -16rem !important;
	}
	.my-md-n12 {
		margin-top: -20rem !important;
		margin-bottom: -20rem !important;
	}
	.my-md-nsm {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-md-nmd {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.my-md-nlg {
		margin-top: -4rem !important;
		margin-bottom: -4rem !important;
	}
	.my-md-nxl {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.mt-md-n1 {
		margin-top: -0.25rem !important;
	}
	.mt-md-n2 {
		margin-top: -0.5rem !important;
	}
	.mt-md-n3 {
		margin-top: -1rem !important;
	}
	.mt-md-n4 {
		margin-top: -1.5rem !important;
	}
	.mt-md-n5 {
		margin-top: -3rem !important;
	}
	.mt-md-n6 {
		margin-top: -5rem !important;
	}
	.mt-md-n7 {
		margin-top: -8rem !important;
	}
	.mt-md-n8 {
		margin-top: -10rem !important;
	}
	.mt-md-n9 {
		margin-top: -11rem !important;
	}
	.mt-md-n10 {
		margin-top: -14rem !important;
	}
	.mt-md-n11 {
		margin-top: -16rem !important;
	}
	.mt-md-n12 {
		margin-top: -20rem !important;
	}
	.mt-md-nsm {
		margin-top: -1rem !important;
	}
	.mt-md-nmd {
		margin-top: -2rem !important;
	}
	.mt-md-nlg {
		margin-top: -4rem !important;
	}
	.mt-md-nxl {
		margin-top: -8rem !important;
	}
	.mr-md-n1 {
		margin-right: -0.25rem !important;
	}
	.mr-md-n2 {
		margin-right: -0.5rem !important;
	}
	.mr-md-n3 {
		margin-right: -1rem !important;
	}
	.mr-md-n4 {
		margin-right: -1.5rem !important;
	}
	.mr-md-n5 {
		margin-right: -3rem !important;
	}
	.mr-md-n6 {
		margin-right: -5rem !important;
	}
	.mr-md-n7 {
		margin-right: -8rem !important;
	}
	.mr-md-n8 {
		margin-right: -10rem !important;
	}
	.mr-md-n9 {
		margin-right: -11rem !important;
	}
	.mr-md-n10 {
		margin-right: -14rem !important;
	}
	.mr-md-n11 {
		margin-right: -16rem !important;
	}
	.mr-md-n12 {
		margin-right: -20rem !important;
	}
	.mr-md-nsm {
		margin-right: -1rem !important;
	}
	.mr-md-nmd {
		margin-right: -2rem !important;
	}
	.mr-md-nlg {
		margin-right: -4rem !important;
	}
	.mr-md-nxl {
		margin-right: -8rem !important;
	}
	.mb-md-n1 {
		margin-bottom: -0.25rem !important;
	}
	.mb-md-n2 {
		margin-bottom: -0.5rem !important;
	}
	.mb-md-n3 {
		margin-bottom: -1rem !important;
	}
	.mb-md-n4 {
		margin-bottom: -1.5rem !important;
	}
	.mb-md-n5 {
		margin-bottom: -3rem !important;
	}
	.mb-md-n6 {
		margin-bottom: -5rem !important;
	}
	.mb-md-n7 {
		margin-bottom: -8rem !important;
	}
	.mb-md-n8 {
		margin-bottom: -10rem !important;
	}
	.mb-md-n9 {
		margin-bottom: -11rem !important;
	}
	.mb-md-n10 {
		margin-bottom: -14rem !important;
	}
	.mb-md-n11 {
		margin-bottom: -16rem !important;
	}
	.mb-md-n12 {
		margin-bottom: -20rem !important;
	}
	.mb-md-nsm {
		margin-bottom: -1rem !important;
	}
	.mb-md-nmd {
		margin-bottom: -2rem !important;
	}
	.mb-md-nlg {
		margin-bottom: -4rem !important;
	}
	.mb-md-nxl {
		margin-bottom: -8rem !important;
	}
	.ml-md-n1 {
		margin-left: -0.25rem !important;
	}
	.ml-md-n2 {
		margin-left: -0.5rem !important;
	}
	.ml-md-n3 {
		margin-left: -1rem !important;
	}
	.ml-md-n4 {
		margin-left: -1.5rem !important;
	}
	.ml-md-n5 {
		margin-left: -3rem !important;
	}
	.ml-md-n6 {
		margin-left: -5rem !important;
	}
	.ml-md-n7 {
		margin-left: -8rem !important;
	}
	.ml-md-n8 {
		margin-left: -10rem !important;
	}
	.ml-md-n9 {
		margin-left: -11rem !important;
	}
	.ml-md-n10 {
		margin-left: -14rem !important;
	}
	.ml-md-n11 {
		margin-left: -16rem !important;
	}
	.ml-md-n12 {
		margin-left: -20rem !important;
	}
	.ml-md-nsm {
		margin-left: -1rem !important;
	}
	.ml-md-nmd {
		margin-left: -2rem !important;
	}
	.ml-md-nlg {
		margin-left: -4rem !important;
	}
	.ml-md-nxl {
		margin-left: -8rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.p-md-6 {
		padding: 5rem !important;
	}
	.p-md-7 {
		padding: 8rem !important;
	}
	.p-md-8 {
		padding: 10rem !important;
	}
	.p-md-9 {
		padding: 11rem !important;
	}
	.p-md-10 {
		padding: 14rem !important;
	}
	.p-md-11 {
		padding: 16rem !important;
	}
	.p-md-12 {
		padding: 20rem !important;
	}
	.p-md-sm {
		padding: 1rem !important;
	}
	.p-md-md {
		padding: 2rem !important;
	}
	.p-md-lg {
		padding: 4rem !important;
	}
	.p-md-xl {
		padding: 8rem !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.px-md-6 {
		padding-right: 5rem !important;
		padding-left: 5rem !important;
	}
	.px-md-7 {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.px-md-8 {
		padding-right: 10rem !important;
		padding-left: 10rem !important;
	}
	.px-md-9 {
		padding-right: 11rem !important;
		padding-left: 11rem !important;
	}
	.px-md-10 {
		padding-right: 14rem !important;
		padding-left: 14rem !important;
	}
	.px-md-11 {
		padding-right: 16rem !important;
		padding-left: 16rem !important;
	}
	.px-md-12 {
		padding-right: 20rem !important;
		padding-left: 20rem !important;
	}
	.px-md-sm {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-md {
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}
	.px-md-lg {
		padding-right: 4rem !important;
		padding-left: 4rem !important;
	}
	.px-md-xl {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.py-md-6 {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
	.py-md-7 {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.py-md-8 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.py-md-9 {
		padding-top: 11rem !important;
		padding-bottom: 11rem !important;
	}
	.py-md-10 {
		padding-top: 14rem !important;
		padding-bottom: 14rem !important;
	}
	.py-md-11 {
		padding-top: 16rem !important;
		padding-bottom: 16rem !important;
	}
	.py-md-12 {
		padding-top: 20rem !important;
		padding-bottom: 20rem !important;
	}
	.py-md-sm {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-md {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
	.py-md-lg {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
	.py-md-xl {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pt-md-6 {
		padding-top: 5rem !important;
	}
	.pt-md-7 {
		padding-top: 8rem !important;
	}
	.pt-md-8 {
		padding-top: 10rem !important;
	}
	.pt-md-9 {
		padding-top: 11rem !important;
	}
	.pt-md-10 {
		padding-top: 14rem !important;
	}
	.pt-md-11 {
		padding-top: 16rem !important;
	}
	.pt-md-12 {
		padding-top: 20rem !important;
	}
	.pt-md-sm {
		padding-top: 1rem !important;
	}
	.pt-md-md {
		padding-top: 2rem !important;
	}
	.pt-md-lg {
		padding-top: 4rem !important;
	}
	.pt-md-xl {
		padding-top: 8rem !important;
	}
	.pr-md-0 {
		padding-right: 0 !important;
	}
	.pr-md-1 {
		padding-right: 0.25rem !important;
	}
	.pr-md-2 {
		padding-right: 0.5rem !important;
	}
	.pr-md-3 {
		padding-right: 1rem !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.pr-md-6 {
		padding-right: 5rem !important;
	}
	.pr-md-7 {
		padding-right: 8rem !important;
	}
	.pr-md-8 {
		padding-right: 10rem !important;
	}
	.pr-md-9 {
		padding-right: 11rem !important;
	}
	.pr-md-10 {
		padding-right: 14rem !important;
	}
	.pr-md-11 {
		padding-right: 16rem !important;
	}
	.pr-md-12 {
		padding-right: 20rem !important;
	}
	.pr-md-sm {
		padding-right: 1rem !important;
	}
	.pr-md-md {
		padding-right: 2rem !important;
	}
	.pr-md-lg {
		padding-right: 4rem !important;
	}
	.pr-md-xl {
		padding-right: 8rem !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.pb-md-6 {
		padding-bottom: 5rem !important;
	}
	.pb-md-7 {
		padding-bottom: 8rem !important;
	}
	.pb-md-8 {
		padding-bottom: 10rem !important;
	}
	.pb-md-9 {
		padding-bottom: 11rem !important;
	}
	.pb-md-10 {
		padding-bottom: 14rem !important;
	}
	.pb-md-11 {
		padding-bottom: 16rem !important;
	}
	.pb-md-12 {
		padding-bottom: 20rem !important;
	}
	.pb-md-sm {
		padding-bottom: 1rem !important;
	}
	.pb-md-md {
		padding-bottom: 2rem !important;
	}
	.pb-md-lg {
		padding-bottom: 4rem !important;
	}
	.pb-md-xl {
		padding-bottom: 8rem !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.pl-md-1 {
		padding-left: 0.25rem !important;
	}
	.pl-md-2 {
		padding-left: 0.5rem !important;
	}
	.pl-md-3 {
		padding-left: 1rem !important;
	}
	.pl-md-4 {
		padding-left: 1.5rem !important;
	}
	.pl-md-5 {
		padding-left: 3rem !important;
	}
	.pl-md-6 {
		padding-left: 5rem !important;
	}
	.pl-md-7 {
		padding-left: 8rem !important;
	}
	.pl-md-8 {
		padding-left: 10rem !important;
	}
	.pl-md-9 {
		padding-left: 11rem !important;
	}
	.pl-md-10 {
		padding-left: 14rem !important;
	}
	.pl-md-11 {
		padding-left: 16rem !important;
	}
	.pl-md-12 {
		padding-left: 20rem !important;
	}
	.pl-md-sm {
		padding-left: 1rem !important;
	}
	.pl-md-md {
		padding-left: 2rem !important;
	}
	.pl-md-lg {
		padding-left: 4rem !important;
	}
	.pl-md-xl {
		padding-left: 8rem !important;
	}
	.text-md-left {
		text-align: left !important;
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
		text-align: center !important;
	}
	.floating-md {
		animation: floating-md 3s ease infinite;
		will-change: transform;
		&:hover {
			animation-play-state: paused;
		}
	}
	.display-1-md {
		font-size: 5rem;
	}
	.display-2-md {
		font-size: 3.5rem;
	}
	.display-3-md {
		font-size: 2.5rem;
	}
	.display-4-md {
		font-size: 1.875rem;
	}
	.section-hero {
		height: 100vh;
	}
	.presentation-box {
		.gadget {
			height: 600px;
			max-width: 100%;
		}
		.pricing-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 18%;
			top: 40%;
			height: 150px;
		}
		.dropdown-btn-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 30%;
			top: 9%;
			height: 25px;
		}
		.blog-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 7%;
			top: 20%;
			height: 80px;
		}
		.profile-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 65%;
			top: 12%;
			height: 150px;
		}
		.navbar-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 51%;
			top: 55%;
			height: 80px;
		}
		.modal-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 36%;
			top: 80%;
			height: 100px;
		}
		.social-btn-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 60%;
			top: 80%;
			height: 25px;
		}
	}
	.card-box {
		.card-component.card-component-lg {
			max-height: 410px;
		}
	}
	.sidebar {
		width: 100%;
		max-width: 214px;
		&.collapsed {
			max-width: 64px;
			.sidebar-text {
				display: none;
			}
		}
	}
	.content {
		margin-left: 214px;
		&.collapsed {
			margin-left: 64px;
		}
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
	.container-sm {
		max-width: 960px;
	}
	.container-md {
		max-width: 960px;
	}
	.container-lg {
		max-width: 960px;
	}
	.col-lg {
		flex: 1 0 0%;
	}
	.row-cols-lg-auto {
		>* {
			flex: 0 0 auto;
			width: auto;
		}
	}
	.row-cols-lg-1 {
		>* {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	.row-cols-lg-2 {
		>* {
			flex: 0 0 auto;
			width: 50%;
		}
	}
	.row-cols-lg-3 {
		>* {
			flex: 0 0 auto;
			width: 33.33333%;
		}
	}
	.row-cols-lg-4 {
		>* {
			flex: 0 0 auto;
			width: 25%;
		}
	}
	.row-cols-lg-5 {
		>* {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.row-cols-lg-6 {
		>* {
			flex: 0 0 auto;
			width: 16.66667%;
		}
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.33333%;
	}
	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66667%;
	}
	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333%;
	}
	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.66667%;
	}
	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.33333%;
	}
	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.66667%;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333%;
	}
	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.66667%;
	}
	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.33333%;
	}
	.offset-lg-2 {
		margin-left: 16.66667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.33333%;
	}
	.offset-lg-5 {
		margin-left: 41.66667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.33333%;
	}
	.offset-lg-8 {
		margin-left: 66.66667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.33333%;
	}
	.offset-lg-11 {
		margin-left: 91.66667%;
	}
	.g-lg-0 {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.gx-lg-0 {
		--bs-gutter-x: 0;
	}
	.gy-lg-0 {
		--bs-gutter-y: 0;
	}
	.g-lg-1 {
		--bs-gutter-x: 0.25rem;
		--bs-gutter-y: 0.25rem;
	}
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem;
	}
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-lg-2 {
		--bs-gutter-x: 0.5rem;
		--bs-gutter-y: 0.5rem;
	}
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem;
	}
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-lg-3 {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-lg-3 {
		--bs-gutter-x: 1rem;
	}
	.gy-lg-3 {
		--bs-gutter-y: 1rem;
	}
	.g-lg-4 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 1.5rem;
	}
	.gx-lg-4 {
		--bs-gutter-x: 1.5rem;
	}
	.gy-lg-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-lg-5 {
		--bs-gutter-x: 3rem;
		--bs-gutter-y: 3rem;
	}
	.gx-lg-5 {
		--bs-gutter-x: 3rem;
	}
	.gy-lg-5 {
		--bs-gutter-y: 3rem;
	}
	.g-lg-6 {
		--bs-gutter-x: 5rem;
		--bs-gutter-y: 5rem;
	}
	.gx-lg-6 {
		--bs-gutter-x: 5rem;
	}
	.gy-lg-6 {
		--bs-gutter-y: 5rem;
	}
	.g-lg-7 {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-lg-7 {
		--bs-gutter-x: 8rem;
	}
	.gy-lg-7 {
		--bs-gutter-y: 8rem;
	}
	.g-lg-8 {
		--bs-gutter-x: 10rem;
		--bs-gutter-y: 10rem;
	}
	.gx-lg-8 {
		--bs-gutter-x: 10rem;
	}
	.gy-lg-8 {
		--bs-gutter-y: 10rem;
	}
	.g-lg-9 {
		--bs-gutter-x: 11rem;
		--bs-gutter-y: 11rem;
	}
	.gx-lg-9 {
		--bs-gutter-x: 11rem;
	}
	.gy-lg-9 {
		--bs-gutter-y: 11rem;
	}
	.g-lg-10 {
		--bs-gutter-x: 14rem;
		--bs-gutter-y: 14rem;
	}
	.gx-lg-10 {
		--bs-gutter-x: 14rem;
	}
	.gy-lg-10 {
		--bs-gutter-y: 14rem;
	}
	.g-lg-11 {
		--bs-gutter-x: 16rem;
		--bs-gutter-y: 16rem;
	}
	.gx-lg-11 {
		--bs-gutter-x: 16rem;
	}
	.gy-lg-11 {
		--bs-gutter-y: 16rem;
	}
	.g-lg-12 {
		--bs-gutter-x: 20rem;
		--bs-gutter-y: 20rem;
	}
	.gx-lg-12 {
		--bs-gutter-x: 20rem;
	}
	.gy-lg-12 {
		--bs-gutter-y: 20rem;
	}
	.g-lg-sm {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-lg-sm {
		--bs-gutter-x: 1rem;
	}
	.gy-lg-sm {
		--bs-gutter-y: 1rem;
	}
	.g-lg-md {
		--bs-gutter-x: 2rem;
		--bs-gutter-y: 2rem;
	}
	.gx-lg-md {
		--bs-gutter-x: 2rem;
	}
	.gy-lg-md {
		--bs-gutter-y: 2rem;
	}
	.g-lg-lg {
		--bs-gutter-x: 4rem;
		--bs-gutter-y: 4rem;
	}
	.gx-lg-lg {
		--bs-gutter-x: 4rem;
	}
	.gy-lg-lg {
		--bs-gutter-y: 4rem;
	}
	.g-lg-xl {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-lg-xl {
		--bs-gutter-x: 8rem;
	}
	.gy-lg-xl {
		--bs-gutter-y: 8rem;
	}
	.dropdown-menu-lg-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.8rem;
				padding-left: 0.8rem;
			}
		}
		.navbar-collapse {
			display: flex !important;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-lg {
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 0.0625rem;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -0.0625rem;
					border-left-width: 0.0625rem;
				}
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
	}
	.modal-lg {
		max-width: 800px;
	}
	.modal-xl {
		max-width: 800px;
	}
	.sticky-lg-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
	.order-lg-first {
		order: -1 !important;
	}
	.order-lg-0 {
		order: 0 !important;
	}
	.order-lg-1 {
		order: 1 !important;
	}
	.order-lg-2 {
		order: 2 !important;
	}
	.order-lg-3 {
		order: 3 !important;
	}
	.order-lg-4 {
		order: 4 !important;
	}
	.order-lg-5 {
		order: 5 !important;
	}
	.order-lg-last {
		order: 6 !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.m-lg-6 {
		margin: 5rem !important;
	}
	.m-lg-7 {
		margin: 8rem !important;
	}
	.m-lg-8 {
		margin: 10rem !important;
	}
	.m-lg-9 {
		margin: 11rem !important;
	}
	.m-lg-10 {
		margin: 14rem !important;
	}
	.m-lg-11 {
		margin: 16rem !important;
	}
	.m-lg-12 {
		margin: 20rem !important;
	}
	.m-lg-sm {
		margin: 1rem !important;
	}
	.m-lg-md {
		margin: 2rem !important;
	}
	.m-lg-lg {
		margin: 4rem !important;
	}
	.m-lg-xl {
		margin: 8rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-lg-6 {
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}
	.mx-lg-7 {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-lg-8 {
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.mx-lg-9 {
		margin-right: 11rem !important;
		margin-left: 11rem !important;
	}
	.mx-lg-10 {
		margin-right: 14rem !important;
		margin-left: 14rem !important;
	}
	.mx-lg-11 {
		margin-right: 16rem !important;
		margin-left: 16rem !important;
	}
	.mx-lg-12 {
		margin-right: 20rem !important;
		margin-left: 20rem !important;
	}
	.mx-lg-sm {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-md {
		margin-right: 2rem !important;
		margin-left: 2rem !important;
	}
	.mx-lg-lg {
		margin-right: 4rem !important;
		margin-left: 4rem !important;
	}
	.mx-lg-xl {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-lg-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.my-lg-7 {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-lg-8 {
		margin-top: 10rem !important;
		margin-bottom: 10rem !important;
	}
	.my-lg-9 {
		margin-top: 11rem !important;
		margin-bottom: 11rem !important;
	}
	.my-lg-10 {
		margin-top: 14rem !important;
		margin-bottom: 14rem !important;
	}
	.my-lg-11 {
		margin-top: 16rem !important;
		margin-bottom: 16rem !important;
	}
	.my-lg-12 {
		margin-top: 20rem !important;
		margin-bottom: 20rem !important;
	}
	.my-lg-sm {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-md {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.my-lg-lg {
		margin-top: 4rem !important;
		margin-bottom: 4rem !important;
	}
	.my-lg-xl {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mt-lg-6 {
		margin-top: 5rem !important;
	}
	.mt-lg-7 {
		margin-top: 8rem !important;
	}
	.mt-lg-8 {
		margin-top: 10rem !important;
	}
	.mt-lg-9 {
		margin-top: 11rem !important;
	}
	.mt-lg-10 {
		margin-top: 14rem !important;
	}
	.mt-lg-11 {
		margin-top: 16rem !important;
	}
	.mt-lg-12 {
		margin-top: 20rem !important;
	}
	.mt-lg-sm {
		margin-top: 1rem !important;
	}
	.mt-lg-md {
		margin-top: 2rem !important;
	}
	.mt-lg-lg {
		margin-top: 4rem !important;
	}
	.mt-lg-xl {
		margin-top: 8rem !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.mr-lg-0 {
		margin-right: 0 !important;
	}
	.mr-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mr-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mr-lg-3 {
		margin-right: 1rem !important;
	}
	.mr-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mr-lg-5 {
		margin-right: 3rem !important;
	}
	.mr-lg-6 {
		margin-right: 5rem !important;
	}
	.mr-lg-7 {
		margin-right: 8rem !important;
	}
	.mr-lg-8 {
		margin-right: 10rem !important;
	}
	.mr-lg-9 {
		margin-right: 11rem !important;
	}
	.mr-lg-10 {
		margin-right: 14rem !important;
	}
	.mr-lg-11 {
		margin-right: 16rem !important;
	}
	.mr-lg-12 {
		margin-right: 20rem !important;
	}
	.mr-lg-sm {
		margin-right: 1rem !important;
	}
	.mr-lg-md {
		margin-right: 2rem !important;
	}
	.mr-lg-lg {
		margin-right: 4rem !important;
	}
	.mr-lg-xl {
		margin-right: 8rem !important;
	}
	.mr-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.mb-lg-6 {
		margin-bottom: 5rem !important;
	}
	.mb-lg-7 {
		margin-bottom: 8rem !important;
	}
	.mb-lg-8 {
		margin-bottom: 10rem !important;
	}
	.mb-lg-9 {
		margin-bottom: 11rem !important;
	}
	.mb-lg-10 {
		margin-bottom: 14rem !important;
	}
	.mb-lg-11 {
		margin-bottom: 16rem !important;
	}
	.mb-lg-12 {
		margin-bottom: 20rem !important;
	}
	.mb-lg-sm {
		margin-bottom: 1rem !important;
	}
	.mb-lg-md {
		margin-bottom: 2rem !important;
	}
	.mb-lg-lg {
		margin-bottom: 4rem !important;
	}
	.mb-lg-xl {
		margin-bottom: 8rem !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-0 {
		margin-left: 0 !important;
	}
	.ml-lg-1 {
		margin-left: 0.25rem !important;
	}
	.ml-lg-2 {
		margin-left: 0.5rem !important;
	}
	.ml-lg-3 {
		margin-left: 1rem !important;
	}
	.ml-lg-4 {
		margin-left: 1.5rem !important;
	}
	.ml-lg-5 {
		margin-left: 3rem !important;
	}
	.ml-lg-6 {
		margin-left: 5rem !important;
	}
	.ml-lg-7 {
		margin-left: 8rem !important;
	}
	.ml-lg-8 {
		margin-left: 10rem !important;
	}
	.ml-lg-9 {
		margin-left: 11rem !important;
	}
	.ml-lg-10 {
		margin-left: 14rem !important;
	}
	.ml-lg-11 {
		margin-left: 16rem !important;
	}
	.ml-lg-12 {
		margin-left: 20rem !important;
	}
	.ml-lg-sm {
		margin-left: 1rem !important;
	}
	.ml-lg-md {
		margin-left: 2rem !important;
	}
	.ml-lg-lg {
		margin-left: 4rem !important;
	}
	.ml-lg-xl {
		margin-left: 8rem !important;
	}
	.ml-lg-auto {
		margin-left: auto !important;
	}
	.m-lg-n1 {
		margin: -0.25rem !important;
	}
	.m-lg-n2 {
		margin: -0.5rem !important;
	}
	.m-lg-n3 {
		margin: -1rem !important;
	}
	.m-lg-n4 {
		margin: -1.5rem !important;
	}
	.m-lg-n5 {
		margin: -3rem !important;
	}
	.m-lg-n6 {
		margin: -5rem !important;
	}
	.m-lg-n7 {
		margin: -8rem !important;
	}
	.m-lg-n8 {
		margin: -10rem !important;
	}
	.m-lg-n9 {
		margin: -11rem !important;
	}
	.m-lg-n10 {
		margin: -14rem !important;
	}
	.m-lg-n11 {
		margin: -16rem !important;
	}
	.m-lg-n12 {
		margin: -20rem !important;
	}
	.m-lg-nsm {
		margin: -1rem !important;
	}
	.m-lg-nmd {
		margin: -2rem !important;
	}
	.m-lg-nlg {
		margin: -4rem !important;
	}
	.m-lg-nxl {
		margin: -8rem !important;
	}
	.mx-lg-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mx-lg-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mx-lg-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-lg-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mx-lg-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mx-lg-n6 {
		margin-right: -5rem !important;
		margin-left: -5rem !important;
	}
	.mx-lg-n7 {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.mx-lg-n8 {
		margin-right: -10rem !important;
		margin-left: -10rem !important;
	}
	.mx-lg-n9 {
		margin-right: -11rem !important;
		margin-left: -11rem !important;
	}
	.mx-lg-n10 {
		margin-right: -14rem !important;
		margin-left: -14rem !important;
	}
	.mx-lg-n11 {
		margin-right: -16rem !important;
		margin-left: -16rem !important;
	}
	.mx-lg-n12 {
		margin-right: -20rem !important;
		margin-left: -20rem !important;
	}
	.mx-lg-nsm {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-lg-nmd {
		margin-right: -2rem !important;
		margin-left: -2rem !important;
	}
	.mx-lg-nlg {
		margin-right: -4rem !important;
		margin-left: -4rem !important;
	}
	.mx-lg-nxl {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.my-lg-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.my-lg-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.my-lg-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-lg-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.my-lg-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.my-lg-n6 {
		margin-top: -5rem !important;
		margin-bottom: -5rem !important;
	}
	.my-lg-n7 {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.my-lg-n8 {
		margin-top: -10rem !important;
		margin-bottom: -10rem !important;
	}
	.my-lg-n9 {
		margin-top: -11rem !important;
		margin-bottom: -11rem !important;
	}
	.my-lg-n10 {
		margin-top: -14rem !important;
		margin-bottom: -14rem !important;
	}
	.my-lg-n11 {
		margin-top: -16rem !important;
		margin-bottom: -16rem !important;
	}
	.my-lg-n12 {
		margin-top: -20rem !important;
		margin-bottom: -20rem !important;
	}
	.my-lg-nsm {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-lg-nmd {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.my-lg-nlg {
		margin-top: -4rem !important;
		margin-bottom: -4rem !important;
	}
	.my-lg-nxl {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.mt-lg-n1 {
		margin-top: -0.25rem !important;
	}
	.mt-lg-n2 {
		margin-top: -0.5rem !important;
	}
	.mt-lg-n3 {
		margin-top: -1rem !important;
	}
	.mt-lg-n4 {
		margin-top: -1.5rem !important;
	}
	.mt-lg-n5 {
		margin-top: -3rem !important;
	}
	.mt-lg-n6 {
		margin-top: -5rem !important;
	}
	.mt-lg-n7 {
		margin-top: -8rem !important;
	}
	.mt-lg-n8 {
		margin-top: -10rem !important;
	}
	.mt-lg-n9 {
		margin-top: -11rem !important;
	}
	.mt-lg-n10 {
		margin-top: -14rem !important;
	}
	.mt-lg-n11 {
		margin-top: -16rem !important;
	}
	.mt-lg-n12 {
		margin-top: -20rem !important;
	}
	.mt-lg-nsm {
		margin-top: -1rem !important;
	}
	.mt-lg-nmd {
		margin-top: -2rem !important;
	}
	.mt-lg-nlg {
		margin-top: -4rem !important;
	}
	.mt-lg-nxl {
		margin-top: -8rem !important;
	}
	.mr-lg-n1 {
		margin-right: -0.25rem !important;
	}
	.mr-lg-n2 {
		margin-right: -0.5rem !important;
	}
	.mr-lg-n3 {
		margin-right: -1rem !important;
	}
	.mr-lg-n4 {
		margin-right: -1.5rem !important;
	}
	.mr-lg-n5 {
		margin-right: -3rem !important;
	}
	.mr-lg-n6 {
		margin-right: -5rem !important;
	}
	.mr-lg-n7 {
		margin-right: -8rem !important;
	}
	.mr-lg-n8 {
		margin-right: -10rem !important;
	}
	.mr-lg-n9 {
		margin-right: -11rem !important;
	}
	.mr-lg-n10 {
		margin-right: -14rem !important;
	}
	.mr-lg-n11 {
		margin-right: -16rem !important;
	}
	.mr-lg-n12 {
		margin-right: -20rem !important;
	}
	.mr-lg-nsm {
		margin-right: -1rem !important;
	}
	.mr-lg-nmd {
		margin-right: -2rem !important;
	}
	.mr-lg-nlg {
		margin-right: -4rem !important;
	}
	.mr-lg-nxl {
		margin-right: -8rem !important;
	}
	.mb-lg-n1 {
		margin-bottom: -0.25rem !important;
	}
	.mb-lg-n2 {
		margin-bottom: -0.5rem !important;
	}
	.mb-lg-n3 {
		margin-bottom: -1rem !important;
	}
	.mb-lg-n4 {
		margin-bottom: -1.5rem !important;
	}
	.mb-lg-n5 {
		margin-bottom: -3rem !important;
	}
	.mb-lg-n6 {
		margin-bottom: -5rem !important;
	}
	.mb-lg-n7 {
		margin-bottom: -8rem !important;
	}
	.mb-lg-n8 {
		margin-bottom: -10rem !important;
	}
	.mb-lg-n9 {
		margin-bottom: -11rem !important;
	}
	.mb-lg-n10 {
		margin-bottom: -14rem !important;
	}
	.mb-lg-n11 {
		margin-bottom: -16rem !important;
	}
	.mb-lg-n12 {
		margin-bottom: -20rem !important;
	}
	.mb-lg-nsm {
		margin-bottom: -1rem !important;
	}
	.mb-lg-nmd {
		margin-bottom: -2rem !important;
	}
	.mb-lg-nlg {
		margin-bottom: -4rem !important;
	}
	.mb-lg-nxl {
		margin-bottom: -8rem !important;
	}
	.ml-lg-n1 {
		margin-left: -0.25rem !important;
	}
	.ml-lg-n2 {
		margin-left: -0.5rem !important;
	}
	.ml-lg-n3 {
		margin-left: -1rem !important;
	}
	.ml-lg-n4 {
		margin-left: -1.5rem !important;
	}
	.ml-lg-n5 {
		margin-left: -3rem !important;
	}
	.ml-lg-n6 {
		margin-left: -5rem !important;
	}
	.ml-lg-n7 {
		margin-left: -8rem !important;
	}
	.ml-lg-n8 {
		margin-left: -10rem !important;
	}
	.ml-lg-n9 {
		margin-left: -11rem !important;
	}
	.ml-lg-n10 {
		margin-left: -14rem !important;
	}
	.ml-lg-n11 {
		margin-left: -16rem !important;
	}
	.ml-lg-n12 {
		margin-left: -20rem !important;
	}
	.ml-lg-nsm {
		margin-left: -1rem !important;
	}
	.ml-lg-nmd {
		margin-left: -2rem !important;
	}
	.ml-lg-nlg {
		margin-left: -4rem !important;
	}
	.ml-lg-nxl {
		margin-left: -8rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.p-lg-6 {
		padding: 5rem !important;
	}
	.p-lg-7 {
		padding: 8rem !important;
	}
	.p-lg-8 {
		padding: 10rem !important;
	}
	.p-lg-9 {
		padding: 11rem !important;
	}
	.p-lg-10 {
		padding: 14rem !important;
	}
	.p-lg-11 {
		padding: 16rem !important;
	}
	.p-lg-12 {
		padding: 20rem !important;
	}
	.p-lg-sm {
		padding: 1rem !important;
	}
	.p-lg-md {
		padding: 2rem !important;
	}
	.p-lg-lg {
		padding: 4rem !important;
	}
	.p-lg-xl {
		padding: 8rem !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.px-lg-6 {
		padding-right: 5rem !important;
		padding-left: 5rem !important;
	}
	.px-lg-7 {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.px-lg-8 {
		padding-right: 10rem !important;
		padding-left: 10rem !important;
	}
	.px-lg-9 {
		padding-right: 11rem !important;
		padding-left: 11rem !important;
	}
	.px-lg-10 {
		padding-right: 14rem !important;
		padding-left: 14rem !important;
	}
	.px-lg-11 {
		padding-right: 16rem !important;
		padding-left: 16rem !important;
	}
	.px-lg-12 {
		padding-right: 20rem !important;
		padding-left: 20rem !important;
	}
	.px-lg-sm {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-md {
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}
	.px-lg-lg {
		padding-right: 4rem !important;
		padding-left: 4rem !important;
	}
	.px-lg-xl {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.py-lg-6 {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
	.py-lg-7 {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.py-lg-8 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.py-lg-9 {
		padding-top: 11rem !important;
		padding-bottom: 11rem !important;
	}
	.py-lg-10 {
		padding-top: 14rem !important;
		padding-bottom: 14rem !important;
	}
	.py-lg-11 {
		padding-top: 16rem !important;
		padding-bottom: 16rem !important;
	}
	.py-lg-12 {
		padding-top: 20rem !important;
		padding-bottom: 20rem !important;
	}
	.py-lg-sm {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-md {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
	.py-lg-lg {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
	.py-lg-xl {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pt-lg-6 {
		padding-top: 5rem !important;
	}
	.pt-lg-7 {
		padding-top: 8rem !important;
	}
	.pt-lg-8 {
		padding-top: 10rem !important;
	}
	.pt-lg-9 {
		padding-top: 11rem !important;
	}
	.pt-lg-10 {
		padding-top: 14rem !important;
	}
	.pt-lg-11 {
		padding-top: 16rem !important;
	}
	.pt-lg-12 {
		padding-top: 20rem !important;
	}
	.pt-lg-sm {
		padding-top: 1rem !important;
	}
	.pt-lg-md {
		padding-top: 2rem !important;
	}
	.pt-lg-lg {
		padding-top: 4rem !important;
	}
	.pt-lg-xl {
		padding-top: 8rem !important;
	}
	.pr-lg-0 {
		padding-right: 0 !important;
	}
	.pr-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pr-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pr-lg-3 {
		padding-right: 1rem !important;
	}
	.pr-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pr-lg-5 {
		padding-right: 3rem !important;
	}
	.pr-lg-6 {
		padding-right: 5rem !important;
	}
	.pr-lg-7 {
		padding-right: 8rem !important;
	}
	.pr-lg-8 {
		padding-right: 10rem !important;
	}
	.pr-lg-9 {
		padding-right: 11rem !important;
	}
	.pr-lg-10 {
		padding-right: 14rem !important;
	}
	.pr-lg-11 {
		padding-right: 16rem !important;
	}
	.pr-lg-12 {
		padding-right: 20rem !important;
	}
	.pr-lg-sm {
		padding-right: 1rem !important;
	}
	.pr-lg-md {
		padding-right: 2rem !important;
	}
	.pr-lg-lg {
		padding-right: 4rem !important;
	}
	.pr-lg-xl {
		padding-right: 8rem !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pb-lg-6 {
		padding-bottom: 5rem !important;
	}
	.pb-lg-7 {
		padding-bottom: 8rem !important;
	}
	.pb-lg-8 {
		padding-bottom: 10rem !important;
	}
	.pb-lg-9 {
		padding-bottom: 11rem !important;
	}
	.pb-lg-10 {
		padding-bottom: 14rem !important;
	}
	.pb-lg-11 {
		padding-bottom: 16rem !important;
	}
	.pb-lg-12 {
		padding-bottom: 20rem !important;
	}
	.pb-lg-sm {
		padding-bottom: 1rem !important;
	}
	.pb-lg-md {
		padding-bottom: 2rem !important;
	}
	.pb-lg-lg {
		padding-bottom: 4rem !important;
	}
	.pb-lg-xl {
		padding-bottom: 8rem !important;
	}
	.pl-lg-0 {
		padding-left: 0 !important;
	}
	.pl-lg-1 {
		padding-left: 0.25rem !important;
	}
	.pl-lg-2 {
		padding-left: 0.5rem !important;
	}
	.pl-lg-3 {
		padding-left: 1rem !important;
	}
	.pl-lg-4 {
		padding-left: 1.5rem !important;
	}
	.pl-lg-5 {
		padding-left: 3rem !important;
	}
	.pl-lg-6 {
		padding-left: 5rem !important;
	}
	.pl-lg-7 {
		padding-left: 8rem !important;
	}
	.pl-lg-8 {
		padding-left: 10rem !important;
	}
	.pl-lg-9 {
		padding-left: 11rem !important;
	}
	.pl-lg-10 {
		padding-left: 14rem !important;
	}
	.pl-lg-11 {
		padding-left: 16rem !important;
	}
	.pl-lg-12 {
		padding-left: 20rem !important;
	}
	.pl-lg-sm {
		padding-left: 1rem !important;
	}
	.pl-lg-md {
		padding-left: 2rem !important;
	}
	.pl-lg-lg {
		padding-left: 4rem !important;
	}
	.pl-lg-xl {
		padding-left: 8rem !important;
	}
	.text-lg-left {
		text-align: left !important;
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
		text-align: center !important;
	}
	.floating-lg {
		animation: floating-lg 3s ease infinite;
		will-change: transform;
		&:hover {
			animation-play-state: paused;
		}
	}
	.vh-lg-100 {
		height: 100vh !important;
	}
	.display-1-lg {
		font-size: 5rem;
	}
	.display-2-lg {
		font-size: 3.5rem;
	}
	.display-3-lg {
		font-size: 2.5rem;
	}
	.display-4-lg {
		font-size: 1.875rem;
	}
	.transform-perspective-right {
		transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
	}
	.transform-perspective-left {
		transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg);
	}
	.navbar {
		.navbar-nav {
			.dropdown-megamenu {
				min-width: 580px;
			}
			.dropdown-megamenu-sm {
				min-width: 580px;
				min-width: 320px;
			}
			.dropdown-megamenu-md {
				min-width: 580px;
				min-width: 960px;
			}
			.dropdown-menu {
				.dropdown-toggle {
					.nav-link-arrow {
						transform: rotate(-90deg);
					}
				}
			}
			.dropdown.show {
				>.dropdown-toggle {
					.nav-link-arrow {
						transform: rotate(180deg);
					}
				}
			}
			.dropdown-toggle {
				&:hover {
					.nav-link-arrow {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
	.navbar-nav {
		.nav-item {
			[data-toggle="dropdown"] {
				&::after {
					transition: all 0.2s ease;
				}
			}
		}
		.nav-item.show {
			[data-toggle="dropdown"] {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
		.nav-link {
			i {
				margin-right: .3rem;
				font-size: 0.75rem;
			}
		}
		.nav-link-icon {
			padding-left: .5rem;
			padding-right: .5rem;
			font-size: 1rem;
			i {
				margin-right: 0;
			}
		}
		.dropdown-menu {
			pointer-events: none;
			margin: 0;
			border-radius: 0.5rem;
			position: relative;
			display: block;
			opacity: 0;
			pointer-events: none;
			transition: all 0.2s ease;
		}
		.dropdown-menu-right {
			&:before {
				right: 20px;
				left: auto;
			}
		}
		.dropdown-menu-center {
			left: 50%;
			transform: translateX(-50%);
		}
		.dropdown-menu.show {
			opacity: 1;
			pointer-events: auto;
		}
		.dropdown-megamenu.show {
			opacity: 1;
			pointer-events: auto;
		}
		.dropdown-megamenu-md.show {
			opacity: 1;
			pointer-events: auto;
		}
		.dropdown-menu.close {
			display: block;
		}
		.dropdown-megamenu.close {
			display: block;
		}
		.dropdown-megamenu-md.close {
			display: block;
		}
		.dropdown-submenu {
			.dropdown-menu {
				transform: none;
			}
		}
		.dropdown.show {
			>.dropdown-menu {
				display: block;
				opacity: 1;
				pointer-events: auto;
			}
		}
		.dropdown-submenu.show {
			>.dropdown-menu {
				display: block;
				opacity: 1;
				pointer-events: auto;
			}
		}
		.dropdown-menu-inner {
			position: relative;
			padding: 1rem;
		}
	}
	.navbar-transparent {
		.navbar-nav {
			.nav-link.disabled {
				color: rgba(255, 255, 255, 0.9);
			}
		}
		.navbar-brand {
			color: rgba(255, 255, 255, 0.9);
		}
	}
	.section {
		position: relative;
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	.section-header {
		position: relative;
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
	.section-header.section-sm {
		padding-top: 4rem;
		padding-bottom: 3rem;
	}
	.section-xl {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
	.section-lg {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	.section-sm {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	.presentation-box {
		width: 650px;
		.gadget {
			height: auto;
		}
		.pricing-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 0%;
			top: 30%;
			height: 165px;
		}
		.dropdown-btn-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 30%;
			top: -12%;
			height: 25px;
		}
		.blog-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 7%;
			top: 3%;
			height: 100px;
		}
		.profile-card {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 75%;
			top: 3%;
			height: 200px;
		}
		.navbar-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 55%;
			top: 65%;
			height: 120px;
		}
		.modal-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 19%;
			top: 67%;
			height: 170px;
		}
		.social-btn-img {
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
			border-radius: 0.5rem;
			left: 2%;
			top: 78%;
			height: 25px;
		}
	}
	.card-box {
		.card-component {
			height: 450px;
		}
		width: 260px;
	}
	#doc-index {
		display: block;
	}
	.doc-sidebar {
		display: block;
		height: calc(100vh - 2rem);
		overflow-y: scroll;
		position: -webkit-sticky;
		position: sticky;
		top: 2rem;
		.nav-link[data-toggle="collapse"] {
			>.icon {
				transform: rotateZ(-90deg);
				position: relative;
				right: .25rem;
			}
		}
		.nav {
			.nav-item {
				font-size: 0.875rem;
			}
		}
	}
	.card.timeline-card {
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			margin-left: .75rem;
			border: 8px solid #2e3650;
			border-color: transparent #e0e6ec #e0e6ec transparent;
			box-sizing: border-box;
			transform-origin: 0 0;
			transform: rotate(135deg);
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		}
	}
	.card.left-timeline-card {
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: -5px;
			border: 8px solid #2e3650;
			border-color: transparent #e0e6ec #e0e6ec transparent;
			box-sizing: border-box;
			transform-origin: 0 0;
			transform: rotate(-45deg);
			box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
		}
	}
}
@media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0%;
	}
	.row-cols-xxl-auto {
		>* {
			flex: 0 0 auto;
			width: auto;
		}
	}
	.row-cols-xxl-1 {
		>* {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	.row-cols-xxl-2 {
		>* {
			flex: 0 0 auto;
			width: 50%;
		}
	}
	.row-cols-xxl-3 {
		>* {
			flex: 0 0 auto;
			width: 33.33333%;
		}
	}
	.row-cols-xxl-4 {
		>* {
			flex: 0 0 auto;
			width: 25%;
		}
	}
	.row-cols-xxl-5 {
		>* {
			flex: 0 0 auto;
			width: 20%;
		}
	}
	.row-cols-xxl-6 {
		>* {
			flex: 0 0 auto;
			width: 16.66667%;
		}
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.33333%;
	}
	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.66667%;
	}
	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.33333%;
	}
	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.66667%;
	}
	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.33333%;
	}
	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.66667%;
	}
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.33333%;
	}
	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.66667%;
	}
	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.33333%;
	}
	.offset-xxl-2 {
		margin-left: 16.66667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.33333%;
	}
	.offset-xxl-5 {
		margin-left: 41.66667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.33333%;
	}
	.offset-xxl-8 {
		margin-left: 66.66667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.33333%;
	}
	.offset-xxl-11 {
		margin-left: 91.66667%;
	}
	.g-xxl-0 {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}
	.g-xxl-1 {
		--bs-gutter-x: 0.25rem;
		--bs-gutter-y: 0.25rem;
	}
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xxl-2 {
		--bs-gutter-x: 0.5rem;
		--bs-gutter-y: 0.5rem;
	}
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xxl-3 {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xxl-4 {
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 1.5rem;
	}
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xxl-5 {
		--bs-gutter-x: 3rem;
		--bs-gutter-y: 3rem;
	}
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
	.g-xxl-6 {
		--bs-gutter-x: 5rem;
		--bs-gutter-y: 5rem;
	}
	.gx-xxl-6 {
		--bs-gutter-x: 5rem;
	}
	.gy-xxl-6 {
		--bs-gutter-y: 5rem;
	}
	.g-xxl-7 {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-xxl-7 {
		--bs-gutter-x: 8rem;
	}
	.gy-xxl-7 {
		--bs-gutter-y: 8rem;
	}
	.g-xxl-8 {
		--bs-gutter-x: 10rem;
		--bs-gutter-y: 10rem;
	}
	.gx-xxl-8 {
		--bs-gutter-x: 10rem;
	}
	.gy-xxl-8 {
		--bs-gutter-y: 10rem;
	}
	.g-xxl-9 {
		--bs-gutter-x: 11rem;
		--bs-gutter-y: 11rem;
	}
	.gx-xxl-9 {
		--bs-gutter-x: 11rem;
	}
	.gy-xxl-9 {
		--bs-gutter-y: 11rem;
	}
	.g-xxl-10 {
		--bs-gutter-x: 14rem;
		--bs-gutter-y: 14rem;
	}
	.gx-xxl-10 {
		--bs-gutter-x: 14rem;
	}
	.gy-xxl-10 {
		--bs-gutter-y: 14rem;
	}
	.g-xxl-11 {
		--bs-gutter-x: 16rem;
		--bs-gutter-y: 16rem;
	}
	.gx-xxl-11 {
		--bs-gutter-x: 16rem;
	}
	.gy-xxl-11 {
		--bs-gutter-y: 16rem;
	}
	.g-xxl-12 {
		--bs-gutter-x: 20rem;
		--bs-gutter-y: 20rem;
	}
	.gx-xxl-12 {
		--bs-gutter-x: 20rem;
	}
	.gy-xxl-12 {
		--bs-gutter-y: 20rem;
	}
	.g-xxl-sm {
		--bs-gutter-x: 1rem;
		--bs-gutter-y: 1rem;
	}
	.gx-xxl-sm {
		--bs-gutter-x: 1rem;
	}
	.gy-xxl-sm {
		--bs-gutter-y: 1rem;
	}
	.g-xxl-md {
		--bs-gutter-x: 2rem;
		--bs-gutter-y: 2rem;
	}
	.gx-xxl-md {
		--bs-gutter-x: 2rem;
	}
	.gy-xxl-md {
		--bs-gutter-y: 2rem;
	}
	.g-xxl-lg {
		--bs-gutter-x: 4rem;
		--bs-gutter-y: 4rem;
	}
	.gx-xxl-lg {
		--bs-gutter-x: 4rem;
	}
	.gy-xxl-lg {
		--bs-gutter-y: 4rem;
	}
	.g-xxl-xl {
		--bs-gutter-x: 8rem;
		--bs-gutter-y: 8rem;
	}
	.gx-xxl-xl {
		--bs-gutter-x: 8rem;
	}
	.gy-xxl-xl {
		--bs-gutter-y: 8rem;
	}
	.dropdown-menu-xxl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xxl-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-xxl {
		flex-wrap: nowrap;
		justify-content: flex-start;
		.navbar-nav {
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: 0.8rem;
				padding-left: 0.8rem;
			}
		}
		.navbar-collapse {
			display: flex !important;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-xxl {
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}
			+ {
				.list-group-item {
					border-top-width: 0.0625rem;
					border-left-width: 0;
				}
				.list-group-item.active {
					margin-left: -0.0625rem;
					border-left-width: 0.0625rem;
				}
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
	}
	.sticky-xxl-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.float-xxl-left {
		float: left !important;
	}
	.float-xxl-right {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
	.d-xxl-none {
		display: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
	.order-xxl-first {
		order: -1 !important;
	}
	.order-xxl-0 {
		order: 0 !important;
	}
	.order-xxl-1 {
		order: 1 !important;
	}
	.order-xxl-2 {
		order: 2 !important;
	}
	.order-xxl-3 {
		order: 3 !important;
	}
	.order-xxl-4 {
		order: 4 !important;
	}
	.order-xxl-5 {
		order: 5 !important;
	}
	.order-xxl-last {
		order: 6 !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.m-xxl-6 {
		margin: 5rem !important;
	}
	.m-xxl-7 {
		margin: 8rem !important;
	}
	.m-xxl-8 {
		margin: 10rem !important;
	}
	.m-xxl-9 {
		margin: 11rem !important;
	}
	.m-xxl-10 {
		margin: 14rem !important;
	}
	.m-xxl-11 {
		margin: 16rem !important;
	}
	.m-xxl-12 {
		margin: 20rem !important;
	}
	.m-xxl-sm {
		margin: 1rem !important;
	}
	.m-xxl-md {
		margin: 2rem !important;
	}
	.m-xxl-lg {
		margin: 4rem !important;
	}
	.m-xxl-xl {
		margin: 8rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xxl-6 {
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}
	.mx-xxl-7 {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-xxl-8 {
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.mx-xxl-9 {
		margin-right: 11rem !important;
		margin-left: 11rem !important;
	}
	.mx-xxl-10 {
		margin-right: 14rem !important;
		margin-left: 14rem !important;
	}
	.mx-xxl-11 {
		margin-right: 16rem !important;
		margin-left: 16rem !important;
	}
	.mx-xxl-12 {
		margin-right: 20rem !important;
		margin-left: 20rem !important;
	}
	.mx-xxl-sm {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-md {
		margin-right: 2rem !important;
		margin-left: 2rem !important;
	}
	.mx-xxl-lg {
		margin-right: 4rem !important;
		margin-left: 4rem !important;
	}
	.mx-xxl-xl {
		margin-right: 8rem !important;
		margin-left: 8rem !important;
	}
	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xxl-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.my-xxl-7 {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-xxl-8 {
		margin-top: 10rem !important;
		margin-bottom: 10rem !important;
	}
	.my-xxl-9 {
		margin-top: 11rem !important;
		margin-bottom: 11rem !important;
	}
	.my-xxl-10 {
		margin-top: 14rem !important;
		margin-bottom: 14rem !important;
	}
	.my-xxl-11 {
		margin-top: 16rem !important;
		margin-bottom: 16rem !important;
	}
	.my-xxl-12 {
		margin-top: 20rem !important;
		margin-bottom: 20rem !important;
	}
	.my-xxl-sm {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-md {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}
	.my-xxl-lg {
		margin-top: 4rem !important;
		margin-bottom: 4rem !important;
	}
	.my-xxl-xl {
		margin-top: 8rem !important;
		margin-bottom: 8rem !important;
	}
	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xxl-0 {
		margin-top: 0 !important;
	}
	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xxl-3 {
		margin-top: 1rem !important;
	}
	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xxl-5 {
		margin-top: 3rem !important;
	}
	.mt-xxl-6 {
		margin-top: 5rem !important;
	}
	.mt-xxl-7 {
		margin-top: 8rem !important;
	}
	.mt-xxl-8 {
		margin-top: 10rem !important;
	}
	.mt-xxl-9 {
		margin-top: 11rem !important;
	}
	.mt-xxl-10 {
		margin-top: 14rem !important;
	}
	.mt-xxl-11 {
		margin-top: 16rem !important;
	}
	.mt-xxl-12 {
		margin-top: 20rem !important;
	}
	.mt-xxl-sm {
		margin-top: 1rem !important;
	}
	.mt-xxl-md {
		margin-top: 2rem !important;
	}
	.mt-xxl-lg {
		margin-top: 4rem !important;
	}
	.mt-xxl-xl {
		margin-top: 8rem !important;
	}
	.mt-xxl-auto {
		margin-top: auto !important;
	}
	.mr-xxl-0 {
		margin-right: 0 !important;
	}
	.mr-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.mr-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.mr-xxl-3 {
		margin-right: 1rem !important;
	}
	.mr-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.mr-xxl-5 {
		margin-right: 3rem !important;
	}
	.mr-xxl-6 {
		margin-right: 5rem !important;
	}
	.mr-xxl-7 {
		margin-right: 8rem !important;
	}
	.mr-xxl-8 {
		margin-right: 10rem !important;
	}
	.mr-xxl-9 {
		margin-right: 11rem !important;
	}
	.mr-xxl-10 {
		margin-right: 14rem !important;
	}
	.mr-xxl-11 {
		margin-right: 16rem !important;
	}
	.mr-xxl-12 {
		margin-right: 20rem !important;
	}
	.mr-xxl-sm {
		margin-right: 1rem !important;
	}
	.mr-xxl-md {
		margin-right: 2rem !important;
	}
	.mr-xxl-lg {
		margin-right: 4rem !important;
	}
	.mr-xxl-xl {
		margin-right: 8rem !important;
	}
	.mr-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xxl-6 {
		margin-bottom: 5rem !important;
	}
	.mb-xxl-7 {
		margin-bottom: 8rem !important;
	}
	.mb-xxl-8 {
		margin-bottom: 10rem !important;
	}
	.mb-xxl-9 {
		margin-bottom: 11rem !important;
	}
	.mb-xxl-10 {
		margin-bottom: 14rem !important;
	}
	.mb-xxl-11 {
		margin-bottom: 16rem !important;
	}
	.mb-xxl-12 {
		margin-bottom: 20rem !important;
	}
	.mb-xxl-sm {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-md {
		margin-bottom: 2rem !important;
	}
	.mb-xxl-lg {
		margin-bottom: 4rem !important;
	}
	.mb-xxl-xl {
		margin-bottom: 8rem !important;
	}
	.mb-xxl-auto {
		margin-bottom: auto !important;
	}
	.ml-xxl-0 {
		margin-left: 0 !important;
	}
	.ml-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.ml-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.ml-xxl-3 {
		margin-left: 1rem !important;
	}
	.ml-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.ml-xxl-5 {
		margin-left: 3rem !important;
	}
	.ml-xxl-6 {
		margin-left: 5rem !important;
	}
	.ml-xxl-7 {
		margin-left: 8rem !important;
	}
	.ml-xxl-8 {
		margin-left: 10rem !important;
	}
	.ml-xxl-9 {
		margin-left: 11rem !important;
	}
	.ml-xxl-10 {
		margin-left: 14rem !important;
	}
	.ml-xxl-11 {
		margin-left: 16rem !important;
	}
	.ml-xxl-12 {
		margin-left: 20rem !important;
	}
	.ml-xxl-sm {
		margin-left: 1rem !important;
	}
	.ml-xxl-md {
		margin-left: 2rem !important;
	}
	.ml-xxl-lg {
		margin-left: 4rem !important;
	}
	.ml-xxl-xl {
		margin-left: 8rem !important;
	}
	.ml-xxl-auto {
		margin-left: auto !important;
	}
	.m-xxl-n1 {
		margin: -0.25rem !important;
	}
	.m-xxl-n2 {
		margin: -0.5rem !important;
	}
	.m-xxl-n3 {
		margin: -1rem !important;
	}
	.m-xxl-n4 {
		margin: -1.5rem !important;
	}
	.m-xxl-n5 {
		margin: -3rem !important;
	}
	.m-xxl-n6 {
		margin: -5rem !important;
	}
	.m-xxl-n7 {
		margin: -8rem !important;
	}
	.m-xxl-n8 {
		margin: -10rem !important;
	}
	.m-xxl-n9 {
		margin: -11rem !important;
	}
	.m-xxl-n10 {
		margin: -14rem !important;
	}
	.m-xxl-n11 {
		margin: -16rem !important;
	}
	.m-xxl-n12 {
		margin: -20rem !important;
	}
	.m-xxl-nsm {
		margin: -1rem !important;
	}
	.m-xxl-nmd {
		margin: -2rem !important;
	}
	.m-xxl-nlg {
		margin: -4rem !important;
	}
	.m-xxl-nxl {
		margin: -8rem !important;
	}
	.mx-xxl-n1 {
		margin-right: -0.25rem !important;
		margin-left: -0.25rem !important;
	}
	.mx-xxl-n2 {
		margin-right: -0.5rem !important;
		margin-left: -0.5rem !important;
	}
	.mx-xxl-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-xxl-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mx-xxl-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mx-xxl-n6 {
		margin-right: -5rem !important;
		margin-left: -5rem !important;
	}
	.mx-xxl-n7 {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.mx-xxl-n8 {
		margin-right: -10rem !important;
		margin-left: -10rem !important;
	}
	.mx-xxl-n9 {
		margin-right: -11rem !important;
		margin-left: -11rem !important;
	}
	.mx-xxl-n10 {
		margin-right: -14rem !important;
		margin-left: -14rem !important;
	}
	.mx-xxl-n11 {
		margin-right: -16rem !important;
		margin-left: -16rem !important;
	}
	.mx-xxl-n12 {
		margin-right: -20rem !important;
		margin-left: -20rem !important;
	}
	.mx-xxl-nsm {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mx-xxl-nmd {
		margin-right: -2rem !important;
		margin-left: -2rem !important;
	}
	.mx-xxl-nlg {
		margin-right: -4rem !important;
		margin-left: -4rem !important;
	}
	.mx-xxl-nxl {
		margin-right: -8rem !important;
		margin-left: -8rem !important;
	}
	.my-xxl-n1 {
		margin-top: -0.25rem !important;
		margin-bottom: -0.25rem !important;
	}
	.my-xxl-n2 {
		margin-top: -0.5rem !important;
		margin-bottom: -0.5rem !important;
	}
	.my-xxl-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-xxl-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.my-xxl-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.my-xxl-n6 {
		margin-top: -5rem !important;
		margin-bottom: -5rem !important;
	}
	.my-xxl-n7 {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.my-xxl-n8 {
		margin-top: -10rem !important;
		margin-bottom: -10rem !important;
	}
	.my-xxl-n9 {
		margin-top: -11rem !important;
		margin-bottom: -11rem !important;
	}
	.my-xxl-n10 {
		margin-top: -14rem !important;
		margin-bottom: -14rem !important;
	}
	.my-xxl-n11 {
		margin-top: -16rem !important;
		margin-bottom: -16rem !important;
	}
	.my-xxl-n12 {
		margin-top: -20rem !important;
		margin-bottom: -20rem !important;
	}
	.my-xxl-nsm {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.my-xxl-nmd {
		margin-top: -2rem !important;
		margin-bottom: -2rem !important;
	}
	.my-xxl-nlg {
		margin-top: -4rem !important;
		margin-bottom: -4rem !important;
	}
	.my-xxl-nxl {
		margin-top: -8rem !important;
		margin-bottom: -8rem !important;
	}
	.mt-xxl-n1 {
		margin-top: -0.25rem !important;
	}
	.mt-xxl-n2 {
		margin-top: -0.5rem !important;
	}
	.mt-xxl-n3 {
		margin-top: -1rem !important;
	}
	.mt-xxl-n4 {
		margin-top: -1.5rem !important;
	}
	.mt-xxl-n5 {
		margin-top: -3rem !important;
	}
	.mt-xxl-n6 {
		margin-top: -5rem !important;
	}
	.mt-xxl-n7 {
		margin-top: -8rem !important;
	}
	.mt-xxl-n8 {
		margin-top: -10rem !important;
	}
	.mt-xxl-n9 {
		margin-top: -11rem !important;
	}
	.mt-xxl-n10 {
		margin-top: -14rem !important;
	}
	.mt-xxl-n11 {
		margin-top: -16rem !important;
	}
	.mt-xxl-n12 {
		margin-top: -20rem !important;
	}
	.mt-xxl-nsm {
		margin-top: -1rem !important;
	}
	.mt-xxl-nmd {
		margin-top: -2rem !important;
	}
	.mt-xxl-nlg {
		margin-top: -4rem !important;
	}
	.mt-xxl-nxl {
		margin-top: -8rem !important;
	}
	.mr-xxl-n1 {
		margin-right: -0.25rem !important;
	}
	.mr-xxl-n2 {
		margin-right: -0.5rem !important;
	}
	.mr-xxl-n3 {
		margin-right: -1rem !important;
	}
	.mr-xxl-n4 {
		margin-right: -1.5rem !important;
	}
	.mr-xxl-n5 {
		margin-right: -3rem !important;
	}
	.mr-xxl-n6 {
		margin-right: -5rem !important;
	}
	.mr-xxl-n7 {
		margin-right: -8rem !important;
	}
	.mr-xxl-n8 {
		margin-right: -10rem !important;
	}
	.mr-xxl-n9 {
		margin-right: -11rem !important;
	}
	.mr-xxl-n10 {
		margin-right: -14rem !important;
	}
	.mr-xxl-n11 {
		margin-right: -16rem !important;
	}
	.mr-xxl-n12 {
		margin-right: -20rem !important;
	}
	.mr-xxl-nsm {
		margin-right: -1rem !important;
	}
	.mr-xxl-nmd {
		margin-right: -2rem !important;
	}
	.mr-xxl-nlg {
		margin-right: -4rem !important;
	}
	.mr-xxl-nxl {
		margin-right: -8rem !important;
	}
	.mb-xxl-n1 {
		margin-bottom: -0.25rem !important;
	}
	.mb-xxl-n2 {
		margin-bottom: -0.5rem !important;
	}
	.mb-xxl-n3 {
		margin-bottom: -1rem !important;
	}
	.mb-xxl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.mb-xxl-n5 {
		margin-bottom: -3rem !important;
	}
	.mb-xxl-n6 {
		margin-bottom: -5rem !important;
	}
	.mb-xxl-n7 {
		margin-bottom: -8rem !important;
	}
	.mb-xxl-n8 {
		margin-bottom: -10rem !important;
	}
	.mb-xxl-n9 {
		margin-bottom: -11rem !important;
	}
	.mb-xxl-n10 {
		margin-bottom: -14rem !important;
	}
	.mb-xxl-n11 {
		margin-bottom: -16rem !important;
	}
	.mb-xxl-n12 {
		margin-bottom: -20rem !important;
	}
	.mb-xxl-nsm {
		margin-bottom: -1rem !important;
	}
	.mb-xxl-nmd {
		margin-bottom: -2rem !important;
	}
	.mb-xxl-nlg {
		margin-bottom: -4rem !important;
	}
	.mb-xxl-nxl {
		margin-bottom: -8rem !important;
	}
	.ml-xxl-n1 {
		margin-left: -0.25rem !important;
	}
	.ml-xxl-n2 {
		margin-left: -0.5rem !important;
	}
	.ml-xxl-n3 {
		margin-left: -1rem !important;
	}
	.ml-xxl-n4 {
		margin-left: -1.5rem !important;
	}
	.ml-xxl-n5 {
		margin-left: -3rem !important;
	}
	.ml-xxl-n6 {
		margin-left: -5rem !important;
	}
	.ml-xxl-n7 {
		margin-left: -8rem !important;
	}
	.ml-xxl-n8 {
		margin-left: -10rem !important;
	}
	.ml-xxl-n9 {
		margin-left: -11rem !important;
	}
	.ml-xxl-n10 {
		margin-left: -14rem !important;
	}
	.ml-xxl-n11 {
		margin-left: -16rem !important;
	}
	.ml-xxl-n12 {
		margin-left: -20rem !important;
	}
	.ml-xxl-nsm {
		margin-left: -1rem !important;
	}
	.ml-xxl-nmd {
		margin-left: -2rem !important;
	}
	.ml-xxl-nlg {
		margin-left: -4rem !important;
	}
	.ml-xxl-nxl {
		margin-left: -8rem !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.p-xxl-6 {
		padding: 5rem !important;
	}
	.p-xxl-7 {
		padding: 8rem !important;
	}
	.p-xxl-8 {
		padding: 10rem !important;
	}
	.p-xxl-9 {
		padding: 11rem !important;
	}
	.p-xxl-10 {
		padding: 14rem !important;
	}
	.p-xxl-11 {
		padding: 16rem !important;
	}
	.p-xxl-12 {
		padding: 20rem !important;
	}
	.p-xxl-sm {
		padding: 1rem !important;
	}
	.p-xxl-md {
		padding: 2rem !important;
	}
	.p-xxl-lg {
		padding: 4rem !important;
	}
	.p-xxl-xl {
		padding: 8rem !important;
	}
	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.px-xxl-6 {
		padding-right: 5rem !important;
		padding-left: 5rem !important;
	}
	.px-xxl-7 {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.px-xxl-8 {
		padding-right: 10rem !important;
		padding-left: 10rem !important;
	}
	.px-xxl-9 {
		padding-right: 11rem !important;
		padding-left: 11rem !important;
	}
	.px-xxl-10 {
		padding-right: 14rem !important;
		padding-left: 14rem !important;
	}
	.px-xxl-11 {
		padding-right: 16rem !important;
		padding-left: 16rem !important;
	}
	.px-xxl-12 {
		padding-right: 20rem !important;
		padding-left: 20rem !important;
	}
	.px-xxl-sm {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-md {
		padding-right: 2rem !important;
		padding-left: 2rem !important;
	}
	.px-xxl-lg {
		padding-right: 4rem !important;
		padding-left: 4rem !important;
	}
	.px-xxl-xl {
		padding-right: 8rem !important;
		padding-left: 8rem !important;
	}
	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.py-xxl-6 {
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}
	.py-xxl-7 {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.py-xxl-8 {
		padding-top: 10rem !important;
		padding-bottom: 10rem !important;
	}
	.py-xxl-9 {
		padding-top: 11rem !important;
		padding-bottom: 11rem !important;
	}
	.py-xxl-10 {
		padding-top: 14rem !important;
		padding-bottom: 14rem !important;
	}
	.py-xxl-11 {
		padding-top: 16rem !important;
		padding-bottom: 16rem !important;
	}
	.py-xxl-12 {
		padding-top: 20rem !important;
		padding-bottom: 20rem !important;
	}
	.py-xxl-sm {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-md {
		padding-top: 2rem !important;
		padding-bottom: 2rem !important;
	}
	.py-xxl-lg {
		padding-top: 4rem !important;
		padding-bottom: 4rem !important;
	}
	.py-xxl-xl {
		padding-top: 8rem !important;
		padding-bottom: 8rem !important;
	}
	.pt-xxl-0 {
		padding-top: 0 !important;
	}
	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xxl-3 {
		padding-top: 1rem !important;
	}
	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xxl-5 {
		padding-top: 3rem !important;
	}
	.pt-xxl-6 {
		padding-top: 5rem !important;
	}
	.pt-xxl-7 {
		padding-top: 8rem !important;
	}
	.pt-xxl-8 {
		padding-top: 10rem !important;
	}
	.pt-xxl-9 {
		padding-top: 11rem !important;
	}
	.pt-xxl-10 {
		padding-top: 14rem !important;
	}
	.pt-xxl-11 {
		padding-top: 16rem !important;
	}
	.pt-xxl-12 {
		padding-top: 20rem !important;
	}
	.pt-xxl-sm {
		padding-top: 1rem !important;
	}
	.pt-xxl-md {
		padding-top: 2rem !important;
	}
	.pt-xxl-lg {
		padding-top: 4rem !important;
	}
	.pt-xxl-xl {
		padding-top: 8rem !important;
	}
	.pr-xxl-0 {
		padding-right: 0 !important;
	}
	.pr-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pr-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pr-xxl-3 {
		padding-right: 1rem !important;
	}
	.pr-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pr-xxl-5 {
		padding-right: 3rem !important;
	}
	.pr-xxl-6 {
		padding-right: 5rem !important;
	}
	.pr-xxl-7 {
		padding-right: 8rem !important;
	}
	.pr-xxl-8 {
		padding-right: 10rem !important;
	}
	.pr-xxl-9 {
		padding-right: 11rem !important;
	}
	.pr-xxl-10 {
		padding-right: 14rem !important;
	}
	.pr-xxl-11 {
		padding-right: 16rem !important;
	}
	.pr-xxl-12 {
		padding-right: 20rem !important;
	}
	.pr-xxl-sm {
		padding-right: 1rem !important;
	}
	.pr-xxl-md {
		padding-right: 2rem !important;
	}
	.pr-xxl-lg {
		padding-right: 4rem !important;
	}
	.pr-xxl-xl {
		padding-right: 8rem !important;
	}
	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.pb-xxl-6 {
		padding-bottom: 5rem !important;
	}
	.pb-xxl-7 {
		padding-bottom: 8rem !important;
	}
	.pb-xxl-8 {
		padding-bottom: 10rem !important;
	}
	.pb-xxl-9 {
		padding-bottom: 11rem !important;
	}
	.pb-xxl-10 {
		padding-bottom: 14rem !important;
	}
	.pb-xxl-11 {
		padding-bottom: 16rem !important;
	}
	.pb-xxl-12 {
		padding-bottom: 20rem !important;
	}
	.pb-xxl-sm {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-md {
		padding-bottom: 2rem !important;
	}
	.pb-xxl-lg {
		padding-bottom: 4rem !important;
	}
	.pb-xxl-xl {
		padding-bottom: 8rem !important;
	}
	.pl-xxl-0 {
		padding-left: 0 !important;
	}
	.pl-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.pl-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.pl-xxl-3 {
		padding-left: 1rem !important;
	}
	.pl-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.pl-xxl-5 {
		padding-left: 3rem !important;
	}
	.pl-xxl-6 {
		padding-left: 5rem !important;
	}
	.pl-xxl-7 {
		padding-left: 8rem !important;
	}
	.pl-xxl-8 {
		padding-left: 10rem !important;
	}
	.pl-xxl-9 {
		padding-left: 11rem !important;
	}
	.pl-xxl-10 {
		padding-left: 14rem !important;
	}
	.pl-xxl-11 {
		padding-left: 16rem !important;
	}
	.pl-xxl-12 {
		padding-left: 20rem !important;
	}
	.pl-xxl-sm {
		padding-left: 1rem !important;
	}
	.pl-xxl-md {
		padding-left: 2rem !important;
	}
	.pl-xxl-lg {
		padding-left: 4rem !important;
	}
	.pl-xxl-xl {
		padding-left: 8rem !important;
	}
	.text-xxl-left {
		text-align: left !important;
		text-align: left !important;
	}
	.text-xxl-right {
		text-align: right !important;
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
		text-align: center !important;
	}
	.floating-xxl {
		animation: floating-xxl 3s ease infinite;
		will-change: transform;
		&:hover {
			animation-play-state: paused;
		}
	}
	.display-1-xxl {
		font-size: 5rem;
	}
	.display-2-xxl {
		font-size: 3.5rem;
	}
	.display-3-xxl {
		font-size: 2.5rem;
	}
	.display-4-xxl {
		font-size: 1.875rem;
	}
}
@media (max-width: 575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.modal-fullscreen-sm-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
		}
		.modal-header {
			border-radius: 0;
		}
		.modal-body {
			overflow-y: auto;
		}
		.modal-footer {
			border-radius: 0;
		}
	}
	.sidebar {
		width: 100%;
	}
	.pixel-pro-badge {
		font-size: 1rem;
		right: -13px;
		padding: 5px 10px;
		top: -23px;
	}
	.dropdown-menu-lg {
		min-width: 285px;
		transform: translateX(15%);
	}
	.search-bar {
		.form-control {
			width: 120px;
			&:focus {
				width: 150px;
			}
		}
	}
	.nav-pills {
		&:not(.nav-pills-circle) {
			.nav-item {
				padding-right: 0;
			}
		}
	}
	.pixel-tab {
		.col-lg-2 {
			padding-right: 15px;
		}
		.col-lg-10 {
			padding-left: 15px;
		}
		.nav-pills {
			border: 0;
			.nav-link {
				background-color: #f5f8fb;
				border: 0;
				margin-bottom: 0;
			}
			.nav-link.active {
				border-left: 0;
				border-top: 0.25rem solid #262B40;
			}
		}
		.tab-content {
			.pricing-box {
				padding: 20px;
			}
		}
	}
	.display-4 {
		font-size: 1.5rem;
	}
	.timeline.timeline-one {
		.timeline-item {
			width: 100%;
			margin-left: 0;
			&:nth-child(2n+1) {
				padding-right: 0;
				padding-left: 2.75rem;
				text-align: left;
				&:before {
					right: auto;
					left: 0;
				}
				&:after {
					right: auto;
					left: 0;
				}
			}
		}
	}
	.timeline.timeline-four {
		&::before {
			margin: 0;
			left: 0;
		}
		.timeline-item {
			&:nth-child(2n) {
				.timeline-content {
					width: 95%;
					padding: 0.5rem 0;
					text-align: center;
				}
			}
		}
		.icon {
			margin: auto -7px;
			top: 3rem;
		}
		.date-content {
			width: 95%;
			float: right;
		}
		.date-outer {
			&::before {
				width: 130px;
				height: 130px;
			}
			&::after {
				width: 130px;
				height: 130px;
			}
		}
		.date {
			top: 27%;
		}
		.timeline-content {
			width: 95%;
			padding: 0.5rem 0;
			text-align: center;
		}
	}
	.vertical-timeline {
		.row.timeline-inner {
			.right-bottom {
				top: auto;
				bottom: calc(50% - 6px);
				left: calc(50% - 4px);
			}
			.top-left {
				top: auto;
				bottom: -50%;
			}
		}
		.row {
			.col-md-6 {
				text-align: center !important;
				margin-top: 3rem;
			}
			.rectangle {
				line-height: 3;
				margin-bottom: 2rem;
			}
			.right {
				margin-bottom: 0;
			}
		}
	}
}
@media (max-width: 767.98px) {
	.table-responsive-md {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.modal-fullscreen-md-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
		}
		.modal-header {
			border-radius: 0;
		}
		.modal-body {
			overflow-y: auto;
		}
		.modal-footer {
			border-radius: 0;
		}
	}
	.navbar {
		.navbar-nav {
			.nav-link {
				font-size: 1.2rem;
				display: flex;
				-moz-justify-content: space-between;
				-ms-justify-content: space-between;
				justify-content: space-between;
				-ms-flex-pack: space-between;
				-moz-align-items: center;
				-ms-align-items: center;
				align-items: center;
			}
		}
	}
	.navbar-theme-primary.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-primary {
		.navbar-nav {
			.nav-link {
				color: #262B40;
				background: transparent;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #262B40;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #262B40;
					background: transparent;
					&:focus {
						color: #262B40;
						background: transparent;
					}
					&:hover {
						color: #262B40;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #262B40;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #262B40;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #262B40;
				background: transparent;
			}
			.list-group-item {
				color: #262B40;
				background: transparent;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #262B40;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-secondary.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-secondary {
		.navbar-nav {
			.nav-link {
				color: #F8BD7A;
				background: transparent;
				&:focus {
					color: #F8BD7A;
					background: transparent;
				}
				&:hover {
					color: #F8BD7A;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #F8BD7A;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #F8BD7A;
					background: transparent;
					&:focus {
						color: #F8BD7A;
						background: transparent;
					}
					&:hover {
						color: #F8BD7A;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #F8BD7A;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #F8BD7A;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #F8BD7A;
					background: transparent;
				}
				&:hover {
					color: #F8BD7A;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #F8BD7A;
				background: transparent;
			}
			.list-group-item {
				color: #F8BD7A;
				background: transparent;
				&:focus {
					color: #F8BD7A;
					background: transparent;
				}
				&:hover {
					color: #F8BD7A;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #F8BD7A;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-tertiary.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-tertiary {
		.navbar-nav {
			.nav-link {
				color: #1B998B;
				background: transparent;
				&:focus {
					color: #1B998B;
					background: transparent;
				}
				&:hover {
					color: #1B998B;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #1B998B;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #1B998B;
					background: transparent;
					&:focus {
						color: #1B998B;
						background: transparent;
					}
					&:hover {
						color: #1B998B;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #1B998B;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #1B998B;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #1B998B;
					background: transparent;
				}
				&:hover {
					color: #1B998B;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #1B998B;
				background: transparent;
			}
			.list-group-item {
				color: #1B998B;
				background: transparent;
				&:focus {
					color: #1B998B;
					background: transparent;
				}
				&:hover {
					color: #1B998B;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #1B998B;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-quaternary.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-quaternary {
		.navbar-nav {
			.nav-link {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #0948B3;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #0948B3;
					background: transparent;
					&:focus {
						color: #0948B3;
						background: transparent;
					}
					&:hover {
						color: #0948B3;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #0948B3;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #0948B3;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #0948B3;
				background: transparent;
			}
			.list-group-item {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #0948B3;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-primary-app.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-primary-app {
		.navbar-nav {
			.nav-link {
				color: #EBF4F6;
				background: transparent;
				&:focus {
					color: #EBF4F6;
					background: transparent;
				}
				&:hover {
					color: #EBF4F6;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #EBF4F6;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #EBF4F6;
					background: transparent;
					&:focus {
						color: #EBF4F6;
						background: transparent;
					}
					&:hover {
						color: #EBF4F6;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #EBF4F6;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #EBF4F6;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #EBF4F6;
					background: transparent;
				}
				&:hover {
					color: #EBF4F6;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #EBF4F6;
				background: transparent;
			}
			.list-group-item {
				color: #EBF4F6;
				background: transparent;
				&:focus {
					color: #EBF4F6;
					background: transparent;
				}
				&:hover {
					color: #EBF4F6;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #EBF4F6;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-secondary-app.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-secondary-app {
		.navbar-nav {
			.nav-link {
				color: #424AA0;
				background: transparent;
				&:focus {
					color: #424AA0;
					background: transparent;
				}
				&:hover {
					color: #424AA0;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #424AA0;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #424AA0;
					background: transparent;
					&:focus {
						color: #424AA0;
						background: transparent;
					}
					&:hover {
						color: #424AA0;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #424AA0;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #424AA0;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #424AA0;
					background: transparent;
				}
				&:hover {
					color: #424AA0;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #424AA0;
				background: transparent;
			}
			.list-group-item {
				color: #424AA0;
				background: transparent;
				&:focus {
					color: #424AA0;
					background: transparent;
				}
				&:hover {
					color: #424AA0;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #424AA0;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-light.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-light {
		.navbar-nav {
			.nav-link {
				color: #eaedf2;
				background: transparent;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #eaedf2;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #eaedf2;
					background: transparent;
					&:focus {
						color: #eaedf2;
						background: transparent;
					}
					&:hover {
						color: #eaedf2;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #eaedf2;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #eaedf2;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #eaedf2;
				background: transparent;
			}
			.list-group-item {
				color: #eaedf2;
				background: transparent;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #eaedf2;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-lighten.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-lighten {
		.navbar-nav {
			.nav-link {
				color: #F0F3F6;
				background: transparent;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #F0F3F6;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #F0F3F6;
					background: transparent;
					&:focus {
						color: #F0F3F6;
						background: transparent;
					}
					&:hover {
						color: #F0F3F6;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #F0F3F6;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #F0F3F6;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #F0F3F6;
				background: transparent;
			}
			.list-group-item {
				color: #F0F3F6;
				background: transparent;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #F0F3F6;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-success.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-success {
		.navbar-nav {
			.nav-link {
				color: #05A677;
				background: transparent;
				&:focus {
					color: #05A677;
					background: transparent;
				}
				&:hover {
					color: #05A677;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #05A677;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #05A677;
					background: transparent;
					&:focus {
						color: #05A677;
						background: transparent;
					}
					&:hover {
						color: #05A677;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #05A677;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #05A677;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #05A677;
					background: transparent;
				}
				&:hover {
					color: #05A677;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #05A677;
				background: transparent;
			}
			.list-group-item {
				color: #05A677;
				background: transparent;
				&:focus {
					color: #05A677;
					background: transparent;
				}
				&:hover {
					color: #05A677;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #05A677;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-info.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-info {
		.navbar-nav {
			.nav-link {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #0948B3;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #0948B3;
					background: transparent;
					&:focus {
						color: #0948B3;
						background: transparent;
					}
					&:hover {
						color: #0948B3;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #0948B3;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #0948B3;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #0948B3;
				background: transparent;
			}
			.list-group-item {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #0948B3;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-warning.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-warning {
		.navbar-nav {
			.nav-link {
				color: #f5b759;
				background: transparent;
				&:focus {
					color: #f5b759;
					background: transparent;
				}
				&:hover {
					color: #f5b759;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #f5b759;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #f5b759;
					background: transparent;
					&:focus {
						color: #f5b759;
						background: transparent;
					}
					&:hover {
						color: #f5b759;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #f5b759;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #f5b759;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #f5b759;
					background: transparent;
				}
				&:hover {
					color: #f5b759;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #f5b759;
				background: transparent;
			}
			.list-group-item {
				color: #f5b759;
				background: transparent;
				&:focus {
					color: #f5b759;
					background: transparent;
				}
				&:hover {
					color: #f5b759;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #f5b759;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-danger.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-danger {
		.navbar-nav {
			.nav-link {
				color: #FA5252;
				background: transparent;
				&:focus {
					color: #FA5252;
					background: transparent;
				}
				&:hover {
					color: #FA5252;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #FA5252;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #FA5252;
					background: transparent;
					&:focus {
						color: #FA5252;
						background: transparent;
					}
					&:hover {
						color: #FA5252;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #FA5252;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #FA5252;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #FA5252;
					background: transparent;
				}
				&:hover {
					color: #FA5252;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #FA5252;
				background: transparent;
			}
			.list-group-item {
				color: #FA5252;
				background: transparent;
				&:focus {
					color: #FA5252;
					background: transparent;
				}
				&:hover {
					color: #FA5252;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #FA5252;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-white.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-white {
		.navbar-nav {
			.nav-link {
				color: #ffffff;
				background: transparent;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #ffffff;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #ffffff;
					background: transparent;
					&:focus {
						color: #ffffff;
						background: transparent;
					}
					&:hover {
						color: #ffffff;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #ffffff;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #ffffff;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #ffffff;
				background: transparent;
			}
			.list-group-item {
				color: #ffffff;
				background: transparent;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #ffffff;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray {
		.navbar-nav {
			.nav-link {
				color: #4A5073;
				background: transparent;
				&:focus {
					color: #4A5073;
					background: transparent;
				}
				&:hover {
					color: #4A5073;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #4A5073;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #4A5073;
					background: transparent;
					&:focus {
						color: #4A5073;
						background: transparent;
					}
					&:hover {
						color: #4A5073;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #4A5073;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #4A5073;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #4A5073;
					background: transparent;
				}
				&:hover {
					color: #4A5073;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #4A5073;
				background: transparent;
			}
			.list-group-item {
				color: #4A5073;
				background: transparent;
				&:focus {
					color: #4A5073;
					background: transparent;
				}
				&:hover {
					color: #4A5073;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #4A5073;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-neutral.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-neutral {
		.navbar-nav {
			.nav-link {
				color: #ffffff;
				background: transparent;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #ffffff;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #ffffff;
					background: transparent;
					&:focus {
						color: #ffffff;
						background: transparent;
					}
					&:hover {
						color: #ffffff;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #ffffff;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #ffffff;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #ffffff;
				background: transparent;
			}
			.list-group-item {
				color: #ffffff;
				background: transparent;
				&:focus {
					color: #ffffff;
					background: transparent;
				}
				&:hover {
					color: #ffffff;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #ffffff;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-dark.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-dark {
		.navbar-nav {
			.nav-link {
				color: #262B40;
				background: transparent;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #262B40;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #262B40;
					background: transparent;
					&:focus {
						color: #262B40;
						background: transparent;
					}
					&:hover {
						color: #262B40;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #262B40;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #262B40;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #262B40;
				background: transparent;
			}
			.list-group-item {
				color: #262B40;
				background: transparent;
				&:focus {
					color: #262B40;
					background: transparent;
				}
				&:hover {
					color: #262B40;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #262B40;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-soft.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-soft {
		.navbar-nav {
			.nav-link {
				color: #f5f8fb;
				background: transparent;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #f5f8fb;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #f5f8fb;
					background: transparent;
					&:focus {
						color: #f5f8fb;
						background: transparent;
					}
					&:hover {
						color: #f5f8fb;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #f5f8fb;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #f5f8fb;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #f5f8fb;
				background: transparent;
			}
			.list-group-item {
				color: #f5f8fb;
				background: transparent;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #f5f8fb;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-black.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-black {
		.navbar-nav {
			.nav-link {
				color: #2e3650;
				background: transparent;
				&:focus {
					color: #2e3650;
					background: transparent;
				}
				&:hover {
					color: #2e3650;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #2e3650;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #2e3650;
					background: transparent;
					&:focus {
						color: #2e3650;
						background: transparent;
					}
					&:hover {
						color: #2e3650;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #2e3650;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #2e3650;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #2e3650;
					background: transparent;
				}
				&:hover {
					color: #2e3650;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #2e3650;
				background: transparent;
			}
			.list-group-item {
				color: #2e3650;
				background: transparent;
				&:focus {
					color: #2e3650;
					background: transparent;
				}
				&:hover {
					color: #2e3650;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #2e3650;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-blue.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-blue {
		.navbar-nav {
			.nav-link {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #0948B3;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #0948B3;
					background: transparent;
					&:focus {
						color: #0948B3;
						background: transparent;
					}
					&:hover {
						color: #0948B3;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #0948B3;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #0948B3;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #0948B3;
				background: transparent;
			}
			.list-group-item {
				color: #0948B3;
				background: transparent;
				&:focus {
					color: #0948B3;
					background: transparent;
				}
				&:hover {
					color: #0948B3;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #0948B3;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-pink.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-pink {
		.navbar-nav {
			.nav-link {
				color: #C96480;
				background: transparent;
				&:focus {
					color: #C96480;
					background: transparent;
				}
				&:hover {
					color: #C96480;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #C96480;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #C96480;
					background: transparent;
					&:focus {
						color: #C96480;
						background: transparent;
					}
					&:hover {
						color: #C96480;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #C96480;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #C96480;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #C96480;
					background: transparent;
				}
				&:hover {
					color: #C96480;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #C96480;
				background: transparent;
			}
			.list-group-item {
				color: #C96480;
				background: transparent;
				&:focus {
					color: #C96480;
					background: transparent;
				}
				&:hover {
					color: #C96480;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #C96480;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-cyan.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-cyan {
		.navbar-nav {
			.nav-link {
				color: #63b1bd;
				background: transparent;
				&:focus {
					color: #63b1bd;
					background: transparent;
				}
				&:hover {
					color: #63b1bd;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #63b1bd;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #63b1bd;
					background: transparent;
					&:focus {
						color: #63b1bd;
						background: transparent;
					}
					&:hover {
						color: #63b1bd;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #63b1bd;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #63b1bd;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #63b1bd;
					background: transparent;
				}
				&:hover {
					color: #63b1bd;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #63b1bd;
				background: transparent;
			}
			.list-group-item {
				color: #63b1bd;
				background: transparent;
				&:focus {
					color: #63b1bd;
					background: transparent;
				}
				&:hover {
					color: #63b1bd;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #63b1bd;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-purple.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-purple {
		.navbar-nav {
			.nav-link {
				color: #8965e0;
				background: transparent;
				&:focus {
					color: #8965e0;
					background: transparent;
				}
				&:hover {
					color: #8965e0;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #8965e0;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #8965e0;
					background: transparent;
					&:focus {
						color: #8965e0;
						background: transparent;
					}
					&:hover {
						color: #8965e0;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #8965e0;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #8965e0;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #8965e0;
					background: transparent;
				}
				&:hover {
					color: #8965e0;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #8965e0;
				background: transparent;
			}
			.list-group-item {
				color: #8965e0;
				background: transparent;
				&:focus {
					color: #8965e0;
					background: transparent;
				}
				&:hover {
					color: #8965e0;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #8965e0;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-soft-indigo.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-soft-indigo {
		.navbar-nav {
			.nav-link {
				color: #f5e8ff;
				background: transparent;
				&:focus {
					color: #f5e8ff;
					background: transparent;
				}
				&:hover {
					color: #f5e8ff;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #f5e8ff;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #f5e8ff;
					background: transparent;
					&:focus {
						color: #f5e8ff;
						background: transparent;
					}
					&:hover {
						color: #f5e8ff;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #f5e8ff;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #f5e8ff;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #f5e8ff;
					background: transparent;
				}
				&:hover {
					color: #f5e8ff;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #f5e8ff;
				background: transparent;
			}
			.list-group-item {
				color: #f5e8ff;
				background: transparent;
				&:focus {
					color: #f5e8ff;
					background: transparent;
				}
				&:hover {
					color: #f5e8ff;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #f5e8ff;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-soft-green.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-soft-green {
		.navbar-nav {
			.nav-link {
				color: #2CA58D;
				background: transparent;
				&:focus {
					color: #2CA58D;
					background: transparent;
				}
				&:hover {
					color: #2CA58D;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #2CA58D;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #2CA58D;
					background: transparent;
					&:focus {
						color: #2CA58D;
						background: transparent;
					}
					&:hover {
						color: #2CA58D;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #2CA58D;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #2CA58D;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #2CA58D;
					background: transparent;
				}
				&:hover {
					color: #2CA58D;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #2CA58D;
				background: transparent;
			}
			.list-group-item {
				color: #2CA58D;
				background: transparent;
				&:focus {
					color: #2CA58D;
					background: transparent;
				}
				&:hover {
					color: #2CA58D;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #2CA58D;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-100.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-100 {
		.navbar-nav {
			.nav-link {
				color: #f3f7fa;
				background: transparent;
				&:focus {
					color: #f3f7fa;
					background: transparent;
				}
				&:hover {
					color: #f3f7fa;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #f3f7fa;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #f3f7fa;
					background: transparent;
					&:focus {
						color: #f3f7fa;
						background: transparent;
					}
					&:hover {
						color: #f3f7fa;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #f3f7fa;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #f3f7fa;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #f3f7fa;
					background: transparent;
				}
				&:hover {
					color: #f3f7fa;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #f3f7fa;
				background: transparent;
			}
			.list-group-item {
				color: #f3f7fa;
				background: transparent;
				&:focus {
					color: #f3f7fa;
					background: transparent;
				}
				&:hover {
					color: #f3f7fa;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #f3f7fa;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-200.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-200 {
		.navbar-nav {
			.nav-link {
				color: #f5f8fb;
				background: transparent;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #f5f8fb;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #f5f8fb;
					background: transparent;
					&:focus {
						color: #f5f8fb;
						background: transparent;
					}
					&:hover {
						color: #f5f8fb;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #f5f8fb;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #f5f8fb;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #f5f8fb;
				background: transparent;
			}
			.list-group-item {
				color: #f5f8fb;
				background: transparent;
				&:focus {
					color: #f5f8fb;
					background: transparent;
				}
				&:hover {
					color: #f5f8fb;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #f5f8fb;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-300.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-300 {
		.navbar-nav {
			.nav-link {
				color: #F0F3F6;
				background: transparent;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #F0F3F6;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #F0F3F6;
					background: transparent;
					&:focus {
						color: #F0F3F6;
						background: transparent;
					}
					&:hover {
						color: #F0F3F6;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #F0F3F6;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #F0F3F6;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #F0F3F6;
				background: transparent;
			}
			.list-group-item {
				color: #F0F3F6;
				background: transparent;
				&:focus {
					color: #F0F3F6;
					background: transparent;
				}
				&:hover {
					color: #F0F3F6;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #F0F3F6;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-400.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-400 {
		.navbar-nav {
			.nav-link {
				color: #eaedf2;
				background: transparent;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #eaedf2;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #eaedf2;
					background: transparent;
					&:focus {
						color: #eaedf2;
						background: transparent;
					}
					&:hover {
						color: #eaedf2;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #eaedf2;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #eaedf2;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #eaedf2;
				background: transparent;
			}
			.list-group-item {
				color: #eaedf2;
				background: transparent;
				&:focus {
					color: #eaedf2;
					background: transparent;
				}
				&:hover {
					color: #eaedf2;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #eaedf2;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-500.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-500 {
		.navbar-nav {
			.nav-link {
				color: #d1d7e0;
				background: transparent;
				&:focus {
					color: #d1d7e0;
					background: transparent;
				}
				&:hover {
					color: #d1d7e0;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #d1d7e0;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #d1d7e0;
					background: transparent;
					&:focus {
						color: #d1d7e0;
						background: transparent;
					}
					&:hover {
						color: #d1d7e0;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #d1d7e0;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #d1d7e0;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #d1d7e0;
					background: transparent;
				}
				&:hover {
					color: #d1d7e0;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #d1d7e0;
				background: transparent;
			}
			.list-group-item {
				color: #d1d7e0;
				background: transparent;
				&:focus {
					color: #d1d7e0;
					background: transparent;
				}
				&:hover {
					color: #d1d7e0;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #d1d7e0;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-600.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-600 {
		.navbar-nav {
			.nav-link {
				color: #93a5be;
				background: transparent;
				&:focus {
					color: #93a5be;
					background: transparent;
				}
				&:hover {
					color: #93a5be;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #93a5be;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #93a5be;
					background: transparent;
					&:focus {
						color: #93a5be;
						background: transparent;
					}
					&:hover {
						color: #93a5be;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #93a5be;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #93a5be;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #93a5be;
					background: transparent;
				}
				&:hover {
					color: #93a5be;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #93a5be;
				background: transparent;
			}
			.list-group-item {
				color: #93a5be;
				background: transparent;
				&:focus {
					color: #93a5be;
					background: transparent;
				}
				&:hover {
					color: #93a5be;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #93a5be;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-700.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-700 {
		.navbar-nav {
			.nav-link {
				color: #66799e;
				background: transparent;
				&:focus {
					color: #66799e;
					background: transparent;
				}
				&:hover {
					color: #66799e;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #66799e;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #66799e;
					background: transparent;
					&:focus {
						color: #66799e;
						background: transparent;
					}
					&:hover {
						color: #66799e;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #66799e;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #66799e;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #66799e;
					background: transparent;
				}
				&:hover {
					color: #66799e;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #66799e;
				background: transparent;
			}
			.list-group-item {
				color: #66799e;
				background: transparent;
				&:focus {
					color: #66799e;
					background: transparent;
				}
				&:hover {
					color: #66799e;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #66799e;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-gray-800.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-gray-800 {
		.navbar-nav {
			.nav-link {
				color: #506690;
				background: transparent;
				&:focus {
					color: #506690;
					background: transparent;
				}
				&:hover {
					color: #506690;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #506690;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #506690;
					background: transparent;
					&:focus {
						color: #506690;
						background: transparent;
					}
					&:hover {
						color: #506690;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #506690;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #506690;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #506690;
					background: transparent;
				}
				&:hover {
					color: #506690;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #506690;
				background: transparent;
			}
			.list-group-item {
				color: #506690;
				background: transparent;
				&:focus {
					color: #506690;
					background: transparent;
				}
				&:hover {
					color: #506690;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #506690;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-facebook.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-facebook {
		.navbar-nav {
			.nav-link {
				color: #3b5999;
				background: transparent;
				&:focus {
					color: #3b5999;
					background: transparent;
				}
				&:hover {
					color: #3b5999;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #3b5999;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #3b5999;
					background: transparent;
					&:focus {
						color: #3b5999;
						background: transparent;
					}
					&:hover {
						color: #3b5999;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #3b5999;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #3b5999;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #3b5999;
					background: transparent;
				}
				&:hover {
					color: #3b5999;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #3b5999;
				background: transparent;
			}
			.list-group-item {
				color: #3b5999;
				background: transparent;
				&:focus {
					color: #3b5999;
					background: transparent;
				}
				&:hover {
					color: #3b5999;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #3b5999;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-twitter.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-twitter {
		.navbar-nav {
			.nav-link {
				color: #1da1f2;
				background: transparent;
				&:focus {
					color: #1da1f2;
					background: transparent;
				}
				&:hover {
					color: #1da1f2;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #1da1f2;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #1da1f2;
					background: transparent;
					&:focus {
						color: #1da1f2;
						background: transparent;
					}
					&:hover {
						color: #1da1f2;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #1da1f2;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #1da1f2;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #1da1f2;
					background: transparent;
				}
				&:hover {
					color: #1da1f2;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #1da1f2;
				background: transparent;
			}
			.list-group-item {
				color: #1da1f2;
				background: transparent;
				&:focus {
					color: #1da1f2;
					background: transparent;
				}
				&:hover {
					color: #1da1f2;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #1da1f2;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-google.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-google {
		.navbar-nav {
			.nav-link {
				color: #DB4337;
				background: transparent;
				&:focus {
					color: #DB4337;
					background: transparent;
				}
				&:hover {
					color: #DB4337;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #DB4337;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #DB4337;
					background: transparent;
					&:focus {
						color: #DB4337;
						background: transparent;
					}
					&:hover {
						color: #DB4337;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #DB4337;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #DB4337;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #DB4337;
					background: transparent;
				}
				&:hover {
					color: #DB4337;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #DB4337;
				background: transparent;
			}
			.list-group-item {
				color: #DB4337;
				background: transparent;
				&:focus {
					color: #DB4337;
					background: transparent;
				}
				&:hover {
					color: #DB4337;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #DB4337;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-instagram.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-instagram {
		.navbar-nav {
			.nav-link {
				color: #e4405f;
				background: transparent;
				&:focus {
					color: #e4405f;
					background: transparent;
				}
				&:hover {
					color: #e4405f;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #e4405f;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #e4405f;
					background: transparent;
					&:focus {
						color: #e4405f;
						background: transparent;
					}
					&:hover {
						color: #e4405f;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #e4405f;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #e4405f;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #e4405f;
					background: transparent;
				}
				&:hover {
					color: #e4405f;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #e4405f;
				background: transparent;
			}
			.list-group-item {
				color: #e4405f;
				background: transparent;
				&:focus {
					color: #e4405f;
					background: transparent;
				}
				&:hover {
					color: #e4405f;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #e4405f;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-pinterest.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-pinterest {
		.navbar-nav {
			.nav-link {
				color: #bd081c;
				background: transparent;
				&:focus {
					color: #bd081c;
					background: transparent;
				}
				&:hover {
					color: #bd081c;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #bd081c;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #bd081c;
					background: transparent;
					&:focus {
						color: #bd081c;
						background: transparent;
					}
					&:hover {
						color: #bd081c;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #bd081c;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #bd081c;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #bd081c;
					background: transparent;
				}
				&:hover {
					color: #bd081c;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #bd081c;
				background: transparent;
			}
			.list-group-item {
				color: #bd081c;
				background: transparent;
				&:focus {
					color: #bd081c;
					background: transparent;
				}
				&:hover {
					color: #bd081c;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #bd081c;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-youtube.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-youtube {
		.navbar-nav {
			.nav-link {
				color: #cd201f;
				background: transparent;
				&:focus {
					color: #cd201f;
					background: transparent;
				}
				&:hover {
					color: #cd201f;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #cd201f;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #cd201f;
					background: transparent;
					&:focus {
						color: #cd201f;
						background: transparent;
					}
					&:hover {
						color: #cd201f;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #cd201f;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #cd201f;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #cd201f;
					background: transparent;
				}
				&:hover {
					color: #cd201f;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #cd201f;
				background: transparent;
			}
			.list-group-item {
				color: #cd201f;
				background: transparent;
				&:focus {
					color: #cd201f;
					background: transparent;
				}
				&:hover {
					color: #cd201f;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #cd201f;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-slack.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-slack {
		.navbar-nav {
			.nav-link {
				color: #3aaf85;
				background: transparent;
				&:focus {
					color: #3aaf85;
					background: transparent;
				}
				&:hover {
					color: #3aaf85;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #3aaf85;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #3aaf85;
					background: transparent;
					&:focus {
						color: #3aaf85;
						background: transparent;
					}
					&:hover {
						color: #3aaf85;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #3aaf85;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #3aaf85;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #3aaf85;
					background: transparent;
				}
				&:hover {
					color: #3aaf85;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #3aaf85;
				background: transparent;
			}
			.list-group-item {
				color: #3aaf85;
				background: transparent;
				&:focus {
					color: #3aaf85;
					background: transparent;
				}
				&:hover {
					color: #3aaf85;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #3aaf85;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-dribbble.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-dribbble {
		.navbar-nav {
			.nav-link {
				color: #ea4c89;
				background: transparent;
				&:focus {
					color: #ea4c89;
					background: transparent;
				}
				&:hover {
					color: #ea4c89;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #ea4c89;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #ea4c89;
					background: transparent;
					&:focus {
						color: #ea4c89;
						background: transparent;
					}
					&:hover {
						color: #ea4c89;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #ea4c89;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #ea4c89;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #ea4c89;
					background: transparent;
				}
				&:hover {
					color: #ea4c89;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #ea4c89;
				background: transparent;
			}
			.list-group-item {
				color: #ea4c89;
				background: transparent;
				&:focus {
					color: #ea4c89;
					background: transparent;
				}
				&:hover {
					color: #ea4c89;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #ea4c89;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-dropbox.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-dropbox {
		.navbar-nav {
			.nav-link {
				color: #1E90FF;
				background: transparent;
				&:focus {
					color: #1E90FF;
					background: transparent;
				}
				&:hover {
					color: #1E90FF;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #1E90FF;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #1E90FF;
					background: transparent;
					&:focus {
						color: #1E90FF;
						background: transparent;
					}
					&:hover {
						color: #1E90FF;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #1E90FF;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #1E90FF;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #1E90FF;
					background: transparent;
				}
				&:hover {
					color: #1E90FF;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #1E90FF;
				background: transparent;
			}
			.list-group-item {
				color: #1E90FF;
				background: transparent;
				&:focus {
					color: #1E90FF;
					background: transparent;
				}
				&:hover {
					color: #1E90FF;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #1E90FF;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-twitch.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-twitch {
		.navbar-nav {
			.nav-link {
				color: #4B367C;
				background: transparent;
				&:focus {
					color: #4B367C;
					background: transparent;
				}
				&:hover {
					color: #4B367C;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #4B367C;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #4B367C;
					background: transparent;
					&:focus {
						color: #4B367C;
						background: transparent;
					}
					&:hover {
						color: #4B367C;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #4B367C;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #4B367C;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #4B367C;
					background: transparent;
				}
				&:hover {
					color: #4B367C;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #4B367C;
				background: transparent;
			}
			.list-group-item {
				color: #4B367C;
				background: transparent;
				&:focus {
					color: #4B367C;
					background: transparent;
				}
				&:hover {
					color: #4B367C;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #4B367C;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-paypal.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-paypal {
		.navbar-nav {
			.nav-link {
				color: #ecb32c;
				background: transparent;
				&:focus {
					color: #ecb32c;
					background: transparent;
				}
				&:hover {
					color: #ecb32c;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #ecb32c;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #ecb32c;
					background: transparent;
					&:focus {
						color: #ecb32c;
						background: transparent;
					}
					&:hover {
						color: #ecb32c;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #ecb32c;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #ecb32c;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #ecb32c;
					background: transparent;
				}
				&:hover {
					color: #ecb32c;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #ecb32c;
				background: transparent;
			}
			.list-group-item {
				color: #ecb32c;
				background: transparent;
				&:focus {
					color: #ecb32c;
					background: transparent;
				}
				&:hover {
					color: #ecb32c;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #ecb32c;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-behance.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-behance {
		.navbar-nav {
			.nav-link {
				color: #0057ff;
				background: transparent;
				&:focus {
					color: #0057ff;
					background: transparent;
				}
				&:hover {
					color: #0057ff;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #0057ff;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #0057ff;
					background: transparent;
					&:focus {
						color: #0057ff;
						background: transparent;
					}
					&:hover {
						color: #0057ff;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #0057ff;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #0057ff;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #0057ff;
					background: transparent;
				}
				&:hover {
					color: #0057ff;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #0057ff;
				background: transparent;
			}
			.list-group-item {
				color: #0057ff;
				background: transparent;
				&:focus {
					color: #0057ff;
					background: transparent;
				}
				&:hover {
					color: #0057ff;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #0057ff;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-reddit.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-reddit {
		.navbar-nav {
			.nav-link {
				color: #E84422;
				background: transparent;
				&:focus {
					color: #E84422;
					background: transparent;
				}
				&:hover {
					color: #E84422;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #E84422;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #E84422;
					background: transparent;
					&:focus {
						color: #E84422;
						background: transparent;
					}
					&:hover {
						color: #E84422;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #E84422;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #E84422;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #E84422;
					background: transparent;
				}
				&:hover {
					color: #E84422;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #E84422;
				background: transparent;
			}
			.list-group-item {
				color: #E84422;
				background: transparent;
				&:focus {
					color: #E84422;
					background: transparent;
				}
				&:hover {
					color: #E84422;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #E84422;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-theme-github.navbar-light {
		&:not(.headroom) {
			.navbar-nav {
				.nav-link {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.nav-link.active {
					color: #262B40;
				}
				.show {
					>.nav-link {
						color: #262B40;
						&:focus {
							color: #262B40;
						}
						&:hover {
							color: #262B40;
						}
					}
					>.nav-link.active {
						color: #262B40;
					}
				}
				.dropdown-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.dropdown-item.active {
					color: #262B40;
				}
				.list-group-item {
					color: #262B40;
					&:focus {
						color: #262B40;
					}
					&:hover {
						color: #262B40;
					}
				}
				.list-group-item.active {
					color: #262B40;
				}
			}
		}
	}
	.navbar-theme-github {
		.navbar-nav {
			.nav-link {
				color: #222222;
				background: transparent;
				&:focus {
					color: #222222;
					background: transparent;
				}
				&:hover {
					color: #222222;
					background: transparent;
				}
			}
			.nav-link.active {
				color: #222222;
				background: transparent;
			}
			.show {
				>.nav-link {
					color: #222222;
					background: transparent;
					&:focus {
						color: #222222;
						background: transparent;
					}
					&:hover {
						color: #222222;
						background: transparent;
					}
				}
				>.nav-link.active {
					color: #222222;
					background: transparent;
				}
				>.nav-link.disabled {
					color: rgba(46, 54, 80, 0.9);
				}
			}
			.dropdown-item {
				color: #222222;
				background: transparent;
				padding-right: 0;
				&:focus {
					color: #222222;
					background: transparent;
				}
				&:hover {
					color: #222222;
					background: transparent;
				}
			}
			.dropdown-item.active {
				color: #222222;
				background: transparent;
			}
			.list-group-item {
				color: #222222;
				background: transparent;
				&:focus {
					color: #222222;
					background: transparent;
				}
				&:hover {
					color: #222222;
					background: transparent;
				}
			}
			.list-group-item.active {
				color: #222222;
				background: transparent;
			}
			.nav-link.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.list-group-item.disabled {
				color: rgba(46, 54, 80, 0.9);
			}
			.dropdown {
				.dropdown-menu {
					padding: 0;
				}
				&:not(.mega-dropdown) {
					.dropdown-item {
						padding-left: 0;
					}
				}
			}
		}
	}
	.navbar-nav {
		.nav-link {
			padding: .625rem 0;
		}
		.dropdown-menu {
			box-shadow: none;
			width: 100%;
			.media {
				svg {
					width: 30px;
				}
			}
		}
	}
	.dropdown-menu-center {
		right: 0;
		left: auto;
		transform: translate(0, 0);
	}
	.navbar-collapse {
		width: calc(100% - 30px);
		position: absolute;
		top: 0;
		right: 15px;
		z-index: 1050;
		overflow-y: auto;
		height: calc(100vh - 30px) !important;
		opacity: 0;
		.navbar-toggler {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			padding: 0;
			span {
				display: block;
				position: absolute;
				width: 100%;
				height: 2px;
				border-radius: 2px;
				opacity: 1;
				background: #283448;
			}
		}
		.navbar-collapse-header {
			display: block;
			padding-bottom: 1rem;
			margin-bottom: 1rem;
		}
		.collapse-brand {
			img {
				height: 35px;
			}
		}
		.collapse-close {
			text-align: right;
		}
	}
	.navbar-collapse.collapsing {
		padding: 1.5rem;
		border-radius: 0.5rem;
		background: #ffffff;
		animation: show-navbar-collapse .2s ease forwards;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}
	.navbar-collapse.show {
		padding: 1.5rem;
		border-radius: 0.5rem;
		background: #ffffff;
		animation: show-navbar-collapse .2s ease forwards;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}
	.navbar-collapse.collapsing-out {
		animation: hide-navbar-collapse .2s ease forwards;
	}
	.section-profile-cover {
		height: 400px;
	}
	.card-box {
		width: 200px;
	}
	.user-avatar.lg-avatar {
		height: 2rem;
		width: 2rem;
		min-height: 2rem;
		min-width: 2rem;
	}
	.effect-img-2 {
		right: .425rem;
		top: 0;
	}
	.pricing-card {
		margin-top: 1rem;
	}
	.profile-card-alternative {
		h4 {
			font-size: 1.25rem;
		}
		.h4 {
			font-size: 1.25rem;
		}
	}
	.pattern {
		background-size: 700px 203px;
		background-size: 700px 203px;
	}
	.lead {
		font-size: 1rem;
	}
	.display-2 {
		font-size: 1.875rem;
	}
	.blockquote.blockquote-large {
		font-size: 1.25rem;
	}
	.blockquote {
		.blockquote-footer {
			font-size: 1.25rem;
		}
	}
	.timeline.timeline-four {
		.timeline-item {
			&:nth-child(2n) {
				.timeline-content {
					padding: 0.5rem 1.75rem 0.5rem 0;
				}
			}
		}
		.timeline-content {
			padding: 0.5rem 0 0.5rem 1.75rem;
		}
	}
}
@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.modal-fullscreen-lg-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
		}
		.modal-header {
			border-radius: 0;
		}
		.modal-body {
			overflow-y: auto;
		}
		.modal-footer {
			border-radius: 0;
		}
	}
	.display-3 {
		font-size: 1.875rem;
	}
}
@media (max-width: 1199.98px) {
	.table-responsive-xl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.modal-fullscreen-xl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
		}
		.modal-header {
			border-radius: 0;
		}
		.modal-body {
			overflow-y: auto;
		}
		.modal-footer {
			border-radius: 0;
		}
	}
}
@media (max-width: 1399.98px) {
	.table-responsive-xxl {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.modal-fullscreen-xxl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
		}
		.modal-header {
			border-radius: 0;
		}
		.modal-body {
			overflow-y: auto;
		}
		.modal-footer {
			border-radius: 0;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
	.form-check-input {
		transition: none;
	}
	.form-range {
		&::-webkit-slider-thumb {
			-webkit-transition: none;
			transition: none;
		}
		&::-moz-range-thumb {
			-moz-transition: none;
			transition: none;
		}
		&::-ms-thumb {
			-ms-transition: none;
			transition: none;
		}
	}
	.btn {
		transition: none;
	}
	.fade {
		transition: none;
	}
	.collapsing {
		transition: none;
	}
	.nav-link {
		transition: none;
	}
	.navbar-toggler {
		transition: none;
	}
	.progress-bar {
		transition: none;
	}
	.progress-bar-animated {
		animation: none;
	}
	.modal.fade {
		.modal-dialog {
			transition: none;
		}
	}
	.carousel-item {
		transition: none;
	}
	.carousel-fade {
		.active.carousel-item-left {
			transition: none;
		}
		.active.carousel-item-right {
			transition: none;
		}
	}
	.carousel-control-prev {
		transition: none;
	}
	.carousel-control-next {
		transition: none;
	}
	.carousel-indicators {
		li {
			transition: none;
		}
	}
	.headroom {
		transition: none;
	}
	.card-lift-hover {
		&:hover {
			transition: none;
		}
	}
	.page-preview {
		.show-on-hover {
			transition: none;
		}
	}
	.team-mask {
		transition: none;
	}
	.table-hover {
		tr {
			transition: none;
		}
	}
	.dataTable-input {
		transition: none;
	}
}
@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	code[class*="language-"] {
		text-shadow: none;
	}
	pre[class*="language-"] {
		text-shadow: none;
	}
}
@media (max-width: 22.5rem) {
	.datepicker-view {
		.week {
			width: 1.96875rem;
		}
	}
	.calendar-weeks {
		+ {
			.days {
				.datepicker-grid {
					width: 13.78125rem;
				}
			}
		}
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.profile-page {
		.card-profile {
			.card-profile-stats {
				margin-top: 30px;
			}
		}
	}
}
@media screen and (-ms-high-contrast: active) {
	.rotating-card-container {
		.card {
			.front {
				-webkit-backface-visibility: visible;
				backface-visibility: visible;
				z-index: 4;
			}
			.back {
				-webkit-backface-visibility: visible;
				backface-visibility: visible;
				visibility: hidden;
				transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
			}
		}
		&:not(.manual-flip) {
			&:hover {
				.card {
					.back {
						z-index: 5;
						visibility: visible;
					}
				}
			}
		}
	}
	.rotating-card-container.manual-flip.hover {
		.card {
			.back {
				z-index: 5;
				visibility: visible;
			}
		}
	}
}
@media (-ms-high-contrast: none) {
	.rotating-card-container {
		.card {
			.front {
				-webkit-backface-visibility: visible;
				backface-visibility: visible;
				z-index: 4;
			}
			.back {
				-webkit-backface-visibility: visible;
				backface-visibility: visible;
				visibility: hidden;
				transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
			}
		}
		&:not(.manual-flip) {
			&:hover {
				.card {
					.back {
						z-index: 5;
						visibility: visible;
					}
				}
			}
		}
	}
	.rotating-card-container.manual-flip.hover {
		.card {
			.back {
				z-index: 5;
				visibility: visible;
			}
		}
	}
}
