body {
	overflow-x: hidden;
}
.sidebar .nav-item.active:not(.no-active) > .nav-link .sidebar-icon {
	color: #10b981;
}
.select2-dropdown {
	z-index: 999999 !important;
}
span.select2 {
	width: 100% !important;
	max-width: 100%;
}

$warningOptionBg: #ffffcb;
$warningBorder: #ffff00;

.select2-container[has-warning-option] {outline: 2px solid $warningBorder}

.select2-container:has(.select2-warning__option) {
	& .select2-results__option:has(.select2-warning__option) { padding: 0 !important }

	& .select2-results__option--highlighted .select2-warning__option {
		background-color: darken($warningOptionBg, 5%)
	}

	& .select2-warning__option {
		background-color: $warningOptionBg;
		padding: .375rem .75rem;
		display: block;
	}
}

.select2-container--bootstrap-5 {
	.select2-selection {
		min-height: calc(1.5em + 0.667rem);

		&--single {
			padding: 0.3rem 0.65rem;

			.select2-selection__rendered {
				line-height: 1.3;
				padding: 0;
				color: #506690;
			}
		}

		&--multiple {
			padding-left: 0.3rem;

			.select2-selection__rendered {
				padding: 3px;
			}
		}
	}

	.select2-results {
		&__option {
			&--load-more, &.loading-results {
				color: #bcc0c6 !important;
			}
		}
	}
}

.datepicker-cell.today:not(.selected) {
	background-color: #f5b759 !important;
}

.form-control, .form-control:focus {
	color: #506690;
}

#paginate_by {
	text-align-last: center;
}

.list-notifications {
	.notification:not(:last-child) .notification-block {
		border-bottom: 1px solid #d1d7e0;
		padding-bottom: 1rem;
	}
}
.notifications-count-info {
	font-size: 1.2rem;
}

.form-select option :disabled {
	color: #c3c3c3;
}
.user-preview td > span {
	width: 30px;
}
* {
	&::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #f0f3f6;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #93a5be;
	}
}
.btn-no-style {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active,
.btn:first-child:hover,
:not(.btn-check)+.btn:hover {
    background-color: unset;
    border-color: unset;
    color: unset;
}

.dropdown-menu {
	border: 1px solid darkgrey;
	padding: 0;
	border-radius: 0 !important;
}
.popover {
	border: 1px solid darkgrey;
	border-radius: 0;
}
.table {
	thead {
		background-color: white;
		border-bottom: 2px solid black;
	}
	th {
		vertical-align: middle !important;
		font-weight: 900 !important;
	}
	.form-check {
		min-height: 0;
		margin-bottom: 0;
	}
}
.card {
	.table {
		td {
			padding-left: 1.2rem;
			padding-right: 1.2rem;
		}
		th {
			padding-left: 1.2rem;
			padding-right: 1.2rem;
		}
	}
	overflow-x: auto;
	border-color: #d1d7e0 !important;
	border-radius: 0;
}
.visible-dropdown {
	overflow-y: visible;
    overflow-x: inherit;

	&-clip {
		overflow-x: clip;
	}
}

.mw-500px {
	max-width: 500px !important;
}
.mw-350px {
	max-width: 350px !important;
	min-width: 250px !important;
	white-space: normal !important;
}
.mw-250px {
	max-width: 250px !important;
	min-width: 200px !important;
	white-space: normal !important;
}
.mw-200px {
	max-width: 200px !important;
	min-width: 150px !important;
	white-space: normal !important;
}
.mw-150px {
	max-width: 150px !important;
	min-width: 100px !important;
	white-space: normal !important;
}
.mw-100px {
	max-width: 100px !important;
	min-width: 50px !important;
	white-space: normal !important;
}
.card-header :first-child, .card-header:first-child {
	border-radius: 0;
}

.btn, .font-weight-700 {
	font-weight: 700;
}

.btn,
.alert,
.form-control,
.select2-container--bootstrap-5 .select2-selection,
.nav-pills .nav-link {
	border-radius: 0.2rem;
}
.alert {
	border-radius: 0;
}
.tooltip.show {
	opacity: 1;
}
.tooltip-inner {
	width: 350px !important;
	max-width: 350px !important;
	border-radius: 0.2rem;
	padding: 0.4rem 0.6rem;
}
#agent-preview-container .table td {
	border-bottom-width: 0;
}
#user-preview-container .user-preview .table td {
	border-bottom-width: 0;
}
.table>:not(:first-child) {
	border-top: 0px solid;
}

.table-tabs .nav-link {
	font-size: .875rem;
	padding: .25rem .75rem;
}
.table-tabs .nav-item {
	line-height: 1.313rem;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	background-color: white;
}

.excel-info-card {
	& {
		width: 100%;
		padding: 1rem 0;
		border: 2px solid #d1d7e0;
		border-radius: .2rem;
		color: #d1d7e0;
		cursor: pointer;
		& [data-fa-i2svg] {
			font-size: 25px;
		}
	}
	&.active {
		border: 2px solid #05a677;
		color: #05a677;
	}
}

#filter .form-group { 
	& label {
		font-size: 13px;
	}
}

[data-formset-form] {
	overflow-x: hidden;
}

.btn-narrow {
	padding-top: 0;
	padding-bottom: 0;
}

.pointer {
	cursor: pointer !important;
}

#cookieAccept {
	border-radius: 10px;
    bottom: 50px;
    position: fixed;
    width: 85%;
    z-index: 9999;
    right: 0;
    left: 0;
    margin: 0 auto;
    max-width: 900px;
	text-align: center;

	& > div {
		display: flex;
		justify-content: center;
		align-self: center;
		border: .15rem solid #2e3650;
	}

	& > div > span {
		font-size: 15px;
		display: flex;
		align-items: center;
	}
}

.navbar {
	& .navbar-title {
		font-size: 1.7rem;
		font-weight: 700;
		margin-bottom: 0;
	}
}

.badge {
	font-size: .79rem !important;
}

.toggle-collapse {
	font-size: 20px;
	padding-top: 5px;
	cursor: pointer;
}

table.payments .paid-link {
	padding: 0 !important;
	border: none !important;
	box-shadow: none !important;
}

.user-email-edit {
	display: inline-flex;
	max-width: 500px;
	width: 100%;
}

.page-buttons > div,
.page-buttons > div > div {
	gap: 1rem;
}
.page-buttons > div > div {
	flex-wrap: wrap;
	align-self: stretch;
	min-height: 32px;

	.nav {
		flex-wrap: nowrap;
	}
	button, a {
		gap: 0.5rem;
		display: flex;
		align-items: center;
		min-height: 32px;
	}
	.nav {
		height: 100%;
		.nav-item {
			height: 100%;
			.nav-link {
				height: 100%;
			}
		}
	}
}
.page-buttons > div > div:last-child {
	justify-content: flex-end;
}

.page-paginations > div {
	gap: 1rem;
}

#filter .filter-buttons {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.page-item {
	&.disabled {
		cursor: not-allowed;
	}
}

.vertical-divider {
	height: 40%;
	border-left: 1px solid black;
}

.payment-card-form [data-formset-body] {
	display: flex;
    flex-direction: column;
    gap: 7px;

	.form-control {
		&:disabled {
			background-color: #eaedf2;
		}
		&::placeholder {
			color: #dbdee3;
		}
	}
	.form-control[readonly] {
		background-color: #eaedf2;
	}
}

svg[class*="fa"] {
	min-width: 18px;
	text-align: center;
}

.user-dropdown-container {
	& > a {
		&:hover {
			color: #506690;
		}

		svg {
			width: 21px;
			height: 21px;

			&:hover {
				color: #506690;
			}
		}
	}
}

.table.salary-data > tbody > tr > td {
	color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (max-width: 991px) {
	.notifications-dropdown, .user-dropdown {
		left: auto !important;
		right: 0 !important;
	}
	.page-buttons > div > div {
		button, a {
			height: fit-content;
		}
		button span:not(:first-child),
		a span:not(:first-child) {
			display: none;
		}
	}
	.vertical-divider {
		display: none;
	}
}

@media (max-width: 767px) {
	.btn-xs-block {
		display: block;
		width: 100%;
		+ {
			.btn-block {
				margin-top: 0.5rem;
			}
			.btn-xs-block {
				margin-top: 0.5rem;
			}
		}
	}
	input[type="submit"].btn-xs-block {
		width: 100%;
	}
	input[type="reset"].btn-xs-block {
		width: 100%;
	}
	input[type="button"].btn-xs-block {
		width: 100%;
	}
	.btn-block {
		+ {
			.btn-xs-block {
				margin-top: 0.5rem;
			}
		}
	}

	.toggle-collapse {
		display: none;
	}

	#djDebugToolbarHandle {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.btn-sm-block {
		display: block;
		width: 100%;
		+ {
			.btn-block {
				margin-top: 0.5rem;
			}
			.btn-sm-block {
				margin-top: 0.5rem;
			}
		}
	}
	input[type="submit"].btn-sm-block {
		width: 100%;
	}
	input[type="reset"].btn-sm-block {
		width: 100%;
	}
	input[type="button"].btn-sm-block {
		width: 100%;
	}
	.btn-block {
		+ {
			.btn-sm-block {
				margin-top: 0.5rem;
			}
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.btn-md-block {
		display: block;
		width: 100%;
		+ {
			.btn-block {
				margin-top: 0.5rem;
			}
			.btn-md-block {
				margin-top: 0.5rem;
			}
		}
	}
	input[type="submit"].btn-md-block {
		width: 100%;
	}
	input[type="reset"].btn-md-block {
		width: 100%;
	}
	input[type="button"].btn-md-block {
		width: 100%;
	}
	.btn-block {
		+ {
			.btn-md-block {
				margin-top: 0.5rem;
			}
		}
	}
}
@media (min-width: 1200px) {
	.btn-lg-block {
		display: block;
		width: 100%;
		+ {
			.btn-block {
				margin-top: 0.5rem;
			}
			.btn-lg-block {
				margin-top: 0.5rem;
			}
		}
	}
	input[type="submit"].btn-lg-block {
		width: 100%;
	}
	input[type="reset"].btn-lg-block {
		width: 100%;
	}
	input[type="button"].btn-lg-block {
		width: 100%;
	}
	.btn-block {
		+ {
			.btn-lg-block {
				margin-top: 0.5rem;
			}
		}
	}
}
