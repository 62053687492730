/*!
 * Select2 v4 Bootstrap 5 theme v1.1.1
*/
.select2-container--bootstrap-5 {
    display: block;
  }
  
  .select2-container--bootstrap-5 *:focus {
    outline: 0;
  }
  
  .select2-container--bootstrap-5 .select2-selection {
    display: block;
    width: 100%;
    min-height: calc(1.5em + 1.225rem);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #66799e;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0.0625rem solid #d1d7e0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  }
  
  @media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap-5 .select2-selection {
      transition: none;
    }
  }
  
  .select2-container--bootstrap-5.select2-container--focus .select2-selection, .select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  
  .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 1px solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .select2-container--bootstrap-5 .select2-search {
    width: 100%;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    position: absolute;
    top: 50%;
    right: 2.25rem;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    transform: translateY(-50%);
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear > span,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear > span {
    display: none;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown {
    border-color: #ced4da;
    border-radius: 0.25rem;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
    border-top: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-search {
    padding: 0.375rem 0.75rem;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
      transition: none;
    }
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options {
    max-height: 15rem;
    overflow-y: auto;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message {
    color: #6c757d;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
    color: #000;
    background-color: #f8f9fa;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected="true"] {
    color: #fff;
    background-color: #0d6efd;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--disabled, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-disabled="true"] {
    color: #6c757d;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role="group"] {
    padding: 0;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__group {
    padding: 0.375rem 0.375rem;
    font-weight: 500;
    line-height: 1.5;
    color: #6c757d;
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__options--nested .select2-results__option {
    padding: 0.375rem 0.75rem;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
    padding: 0;
    font-weight: 400;
    padding-top: 0.25rem;
    line-height: 1.5;
    color: #212529;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__arrow {
    display: none;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0;
    list-style: none;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.35em 0.65em;
    margin-right: .375rem;
    margin-bottom: .375rem;
    font-size: 1rem;
    color: #212529;
    cursor: auto;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    margin-right: .25rem;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    border: 0;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove > span {
    display: none;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
    display: block;
    width: 100%;
    height: 1.5em;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
    width: 100%;
    height: 1.5em;
    margin-top: 0;
    margin-left: 0;
    font-family: inherit;
    line-height: 1.5;
    background-color: transparent;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    right: 0.75rem;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection {
    color: #6c757d;
    cursor: not-allowed;
    background-color: #e9ecef;
    border-color: #ced4da;
    box-shadow: none;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__clear, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__clear {
    display: none;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice {
    cursor: not-allowed;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    display: none;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty), .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) {
    padding-bottom: 0;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search {
    display: none;
  }
  
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-text ~ .select2-container--bootstrap-5 .select2-selection,
  .input-group > .btn ~ .select2-container--bootstrap-5 .select2-selection,
  .input-group > .dropdown-menu ~ .select2-container--bootstrap-5 .select2-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group .select2-container--bootstrap-5 {
    flex-grow: 1;
  }
  
  .input-group .select2-container--bootstrap-5 .select2-selection {
    height: 100%;
  }
  
  .is-valid + .select2-container--bootstrap-5 .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5 .select2-selection {
    border-color: #198754;
  }
  
  .is-valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }
  
  .is-valid + .select2-container--bootstrap-5.select2-container--focus.select2-container--below .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus.select2-container--below .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 1px solid transparent;
  }
  
  .is-valid + .select2-container--bootstrap-5.select2-container--focus.select2-container--above .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus.select2-container--above .select2-selection,
  form.was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .is-invalid + .select2-container--bootstrap-5 .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5 .select2-selection {
    border-color: #dc3545;
  }
  
  .is-invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  
  .is-invalid + .select2-container--bootstrap-5.select2-container--focus.select2-container--below .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus.select2-container--below .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 1px solid transparent;
  }
  
  .is-invalid + .select2-container--bootstrap-5.select2-container--focus.select2-container--above .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus.select2-container--above .select2-selection,
  form.was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear, .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear:hover, .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-search .select2-search__field {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__group {
    padding: 0.25rem 0.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__options--nested .select2-results__option {
    padding: 0.25rem 0.5rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--single {
    padding: 0.25rem 2.25rem 0.25rem 0.5rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered:not(:empty) {
    padding-bottom: 0.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 0.875rem;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
    right: 0.5rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear, .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear:hover, .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-search .select2-search__field {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__group {
    padding: 0.5rem 0.5rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__options--nested .select2-results__option {
    padding: 0.5rem 1rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--single {
    padding: 0.5rem 2.25rem 0.5rem 1rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered:not(:empty) {
    padding-bottom: 0.5rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 1.25rem;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
    right: 1rem;
  }